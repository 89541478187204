import React from 'react'
import {RXOTextField} from '../assets/styles'
import PropTypes from 'prop-types'

export default function MultilineTextInput(props) {
  const onChangeHandler = value => {
    if (typeof props.onChange !== 'function') {
      return
    }

    if (typeof props.maxLength === 'number') {
      value = value.substring(0, props.maxLength)
    }

    props.onChange(value)
  }

  return (
    <RXOTextField.FormTextField
      label={props.label}
      variant={'outlined'}
      required={props.isRequired}
      fullWidth
      placeholder={props.placeholder}
      helperText={
        props.helperText
          ? props.helperText
          : props.maxLength
          ? `${props.value ? props.value.length.toLocaleString() + ' / ' : ''}${props.maxLength.toLocaleString()} character limit`
          : undefined
      }
      onChange={e => onChangeHandler(e.target.value)}
      value={props.value || ''}
      disabled={props.isDisabled}
      readOnly={props.isReadOnly}
      error={props.error}
      multiline
      minRows={props.rows}
      maxRows={props.rowsMax}
      InputLabelProps={{
        shrink: true,
      }}
      className={`${props.className || ''}`}
      onKeyDown={props.onKeyDown}
    />
  )
}
MultilineTextInput.propTypes = {
  label: PropTypes.string,
  isRequired: PropTypes.bool, // will append * to label
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  isDisabled: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  error: PropTypes.bool,
  rows: PropTypes.number,
  rowsMax: PropTypes.number,
  maxLength: PropTypes.number,
  onKeyDown: PropTypes.func,
}
