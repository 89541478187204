import React, {useEffect, useState} from 'react'
import {makeStyles} from '@mui/styles'
import {bodyText, H4HeaderBlack, subBodyText, totalText} from '../../../assets/styles/typography'
import {spaceExtraLarge, spaceMedium} from '../../../assets/styles/spacing'
import {rxrBlackColor} from '../../../assets/styles/color'
import PropTypes from 'prop-types'
import {getDiscountsForResident} from '../../../lib/queries'
import {useSelector} from 'react-redux'
import {AntTab, AntTabs} from '../../AntTabs'
import VendorServiceDiscount from '../../../lib/VendorServiceDiscount'
import moment from 'moment'
import EmptyStateIconAndMessage from '../../EmptyStateIconAndMessage'
import RXRIcon from '../../RXRIcon'

const useStyles = makeStyles(theme => ({
  discountContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: spaceMedium,
  },

  discountTitle: {},
  discountExpiryDate: {},
}))

const TAB_ACTIVE = 0
const TAB_PAST = 1

function DiscountsBreakdown(props) {
  const today = new Date()
  const classes = useStyles()
  const buildingId = useSelector(state => state.Buildings.activeBuildingId)
  const [tab, setTab] = useState(TAB_ACTIVE)
  const [discounts, setDiscounts] = useState([])

  useEffect(() => {
    if (!props.residentId) {
      setDiscounts([])
      return
    }

    getDiscountsForResident(props.residentId, buildingId).then(d => setDiscounts(d))
  }, [props.residentId])

  const active = []
  const past = []
  discounts.forEach(d => {
    if (d.vendorAppointmentId || d.expiredAt < today) {
      past.push(d)
    } else {
      active.push(d)
    }
  })

  const discountsToShow = tab === TAB_ACTIVE ? active : past

  discountsToShow.sort((a, b) => (a.expiredAt < b.expiredAt ? 1 : a.expiredAt > b.expiredAt ? -1 : 0))

  return (
    <div>
      <AntTabs value={tab} onChange={(e, t) => setTab(t)}>
        <AntTab label={'Active'} />
        <AntTab label={'Used or Expired'} />
      </AntTabs>

      <div>
        {discountsToShow.length > 0 ? (
          discountsToShow.map(d => {
            return (
              <div key={d.id} className={classes.discountContainer}>
                <div>
                  <h4 className={classes.discountTitle}>{d.title}</h4>
                  <p className={classes.discountExpiryDate}>exp. {moment(d.expiredAt).format('MMM D, YYYY')}</p>
                </div>
                <div>{VendorServiceDiscount.getDiscountString(d)}</div>
              </div>
            )
          })
        ) : (
          <EmptyStateIconAndMessage message={'No discounts to show'} icon={RXRIcon.DISCOUNTS} iconHeight={50} />
        )}
      </div>
    </div>
  )
}

DiscountsBreakdown.propTypes = {
  residentId: PropTypes.string.isRequired,
}

export default DiscountsBreakdown
