import React from 'react'
import {useDispatch} from 'react-redux'
import Paper from '@mui/material/Paper'
import {makeStyles} from '@mui/styles'
import Typography from '@mui/material/Typography'
import moment from 'moment'
import {withRouter} from 'react-router-dom'
import Routes from '../../constants/RouteConstants'
import {rxrBlueDarkColor, rxrBlackDarkerColor, shadowSoft, rxrBlue} from '../../assets/styles/color'
import {navigateToScreen} from '../../actions/kyrWidgetActions'
import RXRIcon from '../RXRIcon'
import {spaceSmall} from '../../assets/styles/spacing'
import {
  MAP_PRIMARY_RECORD_TO_EVENT_CATEGORY_NAME,
  MAP_PRIMARY_RECORD_TO_SCREENS,
  MAP_PRIMARY_RECORD_TO_ICON,
  MAP_PRIMARY_RECORD_TO_ROUTE_CONSTANTS,
} from './KYRConstants'

const useStyles = makeStyles(theme => ({
  paper: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    minHeight: '54px',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: '10px',
    paddingBottom: '10px',
    borderRadius: '5px',
    '&:focus': {
      outline: '0px auto transparent',
    },
    cursor: 'pointer',
    boxShadow: shadowSoft,
  },
  contentContainer: {
    minWidth: 0,
  },
  categoryTitleStyle: {
    fontSize: '12px',
    lineHeight: '12px',
    fontWeight: '700',
    letterSpacing: '0.8px',
    textTransform: 'uppercase',
    color: rxrBlueDarkColor,
  },
  dateTimeStyle: {
    letterSpacing: '0.8px',
    fontSize: '12px',
    color: rxrBlueDarkColor,
    fontWeight: '400',
  },
  textStyle: {
    fontSize: '14px',
    color: rxrBlackDarkerColor,
    fontWeight: '400',
  },

  icon: {
    height: 36,
    width: 36,
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    marginRight: spaceSmall,
  },
}))

const TimeLineCard = ({eventData, navigationType, ...props}) => {
  const classes = useStyles()
  const dateTimeObj = new Date(eventData.createdAt)
  const dateTime = `${moment(dateTimeObj).format('MMM D - h:mmA').toUpperCase()}`
  const dispatch = useDispatch()

  const handleCardClick = () => {
    if (navigationType === 'kyrWidget') {
      MAP_PRIMARY_RECORD_TO_SCREENS[eventData.primaryRecord] &&
        navigateToScreen(dispatch, MAP_PRIMARY_RECORD_TO_SCREENS[eventData.primaryRecord], {
          primaryRecordId: eventData.primaryRecordId,
          residentId: eventData.residentId,
        })
    }

    if (navigationType === 'default') {
      MAP_PRIMARY_RECORD_TO_ROUTE_CONSTANTS[eventData.primaryRecord] &&
        props.history.push(
          Routes.constructPath(MAP_PRIMARY_RECORD_TO_ROUTE_CONSTANTS[eventData.primaryRecord], {
            residentId: eventData.residentId,
            primaryRecordId: eventData.primaryRecordId,
          }),
        )
    }
  }
  return (
    <Paper className={classes.paper} onClick={handleCardClick}>
      {/* The 44 appended controls the opacity */}
      <span className={classes.icon} style={{backgroundColor: MAP_PRIMARY_RECORD_TO_ICON[eventData.primaryRecord].color + '44'}}>
        <RXRIcon
          icon={MAP_PRIMARY_RECORD_TO_ICON[eventData.primaryRecord].icon}
          color={MAP_PRIMARY_RECORD_TO_ICON[eventData.primaryRecord].color}
        />
      </span>
      <div className={classes.contentContainer}>
        <div>
          <span className={classes.categoryTitleStyle}>{MAP_PRIMARY_RECORD_TO_EVENT_CATEGORY_NAME[eventData.primaryRecord]}</span>
          <span className={classes.dateTimeStyle}> - {dateTime}</span>
        </div>
        <Typography className={classes.textStyle} noWrap>
          {eventData.label}
        </Typography>
      </div>
    </Paper>
  )
}

export default withRouter(TimeLineCard)
