import React, {useEffect, useState} from 'react'
import {Grid} from '@mui/material'
import SelectInput from '../SelectInput'
import DateInputWeek from '../DateInputWeek'
import AmenityWeekCalendar from './AmenityWeekCalendar'
import moment from 'moment'
import {makeStyles} from '@mui/styles'
import {spaceSmall, spaceLarge} from '../../assets/styles/spacing'
import {rxrBlackColor, rxrWhiteColor} from '../../assets/styles/color'
import {Typography as TypographyStyle} from '../../assets/styles'
import {useSelector} from 'react-redux'
import Routes from '../../constants/RouteConstants'
import PropTypes from 'prop-types'
import {matchPath, useHistory, useLocation} from 'react-router-dom'

function IndividualAmenityCalendarView(props) {
  const isMobile = useSelector(state => state.App.isMobile)
  const classes = useStyles({isMobile})
  const amenitiesLookup = useSelector(state => state.Amenities.amenitiesLookup)
  const filteredAmenities =
    typeof props.amenitiesFilter === 'function'
      ? Object.values(amenitiesLookup).filter(props.amenitiesFilter)
      : // by default, we show all amenities
        Object.values(amenitiesLookup)
  const amenitySelectOptions = filteredAmenities
    .map(a => ({
      label: a.label,
      value: a.id,
      isDisabled: !a.isVisible,
    }))
    .sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0))
  const [startDate, setStartDate] = useState(moment().startOf('week').toDate())

  const history = useHistory()
  const currentLocation = useLocation()

  const amenityPath = matchPath(currentLocation.pathname, props.individualAmenityRoute)
  const amenityId =
    amenityPath && amenityPath.params.amenityId
      ? amenityPath.params.amenityId
      : amenitySelectOptions.length > 0
      ? amenitySelectOptions[0].value
      : ''

  return (
    <React.Fragment>
      <Grid container className={classes.controlsContainer} alignItems={'center'}>
        <Grid item xs={12} md={4} lg={4} sm={4} className={classes.selectInputContainer}>
          <SelectInput
            className={classes.amenitiesSelect}
            onChange={val => history.push(Routes.constructPath(props.individualAmenityRoute, {amenityId: val}))}
            options={amenitySelectOptions}
            value={amenityId}
          />
        </Grid>
        <Grid item xs={12} md={4} lg={4} sm={4} style={{textAlign: 'center'}}>
          <DateInputWeek onChange={setStartDate} date={startDate} />
        </Grid>
      </Grid>
      {amenityId ? (
        <AmenityWeekCalendar date={startDate} amenityId={amenityId} />
      ) : (
        <div className={classes.noDataMessage}>Select an Amenity to display calendar</div>
      )}
    </React.Fragment>
  )
}

const useStyles = makeStyles(() => ({
  controlsContainer: props => ({
    padding: spaceLarge,
    paddingTop: props.isMobile ? spaceSmall : spaceLarge,
  }),
  amenitiesSelect: {
    borderRadius: 13,
    // just looks nice with this width
    width: 260,
    color: '#000000',
    boxShadow: '0px 4px 20px 4px rgba(0, 0, 0, 0.03)',
    '& .MuiInputBase-root': {
      borderRadius: 13,
      '& .MuiSelect-select': {
        padding: spaceSmall,
        color: rxrBlackColor,
        '&:focus': {
          borderRadius: 13,
          backgroundColor: rxrWhiteColor,
        },
      },
    },
  },
  noDataMessage: {
    ...TypographyStyle.H3Header,
    fontStyle: 'italic',
    fontWeight: 'normal',
    textAlign: 'center',
    marginTop: '48px',
  },
  selectInputContainer: props => ({
    paddingBottom: props.isMobile ? spaceSmall : undefined,
  }),
}))

IndividualAmenityCalendarView.propTypes = {
  amenitiesFilter: PropTypes.func,
}

export default IndividualAmenityCalendarView
