import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {Grid, IconButton, Paper} from '@mui/material'
import {makeStyles} from '@mui/styles'
import {Colors, TextFieldBorders} from './../../assets/styles'
import {connect} from 'react-redux'
import {navigateToScreen} from '../../actions/kyrWidgetActions'
import SearchResident from '../SearchResident'
import {SCREEN_RESIDENT_PROFILE, SCREEN_TIMELINE} from './KYRConstants'
import {iconButton} from '../../assets/styles/buttons'
import RXRIcon from '../RXRIcon'

const inactiveIconColor = Colors.rxrMidBlue
const activeIconColor = Colors.rxrTealColor

const useStyles = makeStyles(theme => ({
  buttonIconOutline: {
    ...iconButton,
    width: RXRIcon.BUTTON_SIZE_LARGE,
    height: RXRIcon.BUTTON_SIZE_LARGE,
  },
  actionHeaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
    width: '100%',
  },
  actionHeader: {
    transitionDuration: '1s',
    backgroundColor: Colors.rxrWhiteColor,
    minHeight: 60,
    maxHeight: 60,
    width: 320,
    maxWidth: 320,
    position: 'relative',
    borderRadius: '5px',
    alignItems: 'center',
    textAlign: 'center',
    boxShadow: Colors.shadowSoft,
  },
  searchBarField: {
    marginTop: '0px',
    marginBottom: '1px',
    '& .MuiOutlinedInput-root': {
      color: Colors.rxrMidBlue,
      borderRadius: '5px',
      ...Colors.backgroundWhite,
      '& fieldset': {
        ...TextFieldBorders.textfieldInputBorderTransparent,
      },
      '&:hover fieldset': {
        ...TextFieldBorders.textfieldInputBorderTransparent,
      },
      '&.Mui-focused fieldset': {
        ...TextFieldBorders.textfieldInputBorderTransparent,
      },
    },
  },
  closeSearchBarButton: {
    ...iconButton,
    position: 'absolute',
    width: RXRIcon.BUTTON_SIZE_LARGE,
    height: RXRIcon.BUTTON_SIZE_LARGE,
    right: 0,
    top: 6,
    color: Colors.rxrTealColor,
  },
}))

function HeaderButtonComponent(props) {
  const classes = useStyles()
  const [showSearchBar, setShowSearchBar] = useState(false)

  const handleProfileButtonClick = residentId => {
    props.dispatchNavigateToResidentProfile(residentId)
  }

  const handleTimelineButtonClick = residentId => {
    props.dispatchNavigateToTimeLine(residentId)
  }

  const handleTenantAndUnit = resident => {
    props.dispatchNavigateToResidentProfile(resident.id)
  }

  return (
    (<div className={classes.actionHeaderContainer}>
      {!showSearchBar ? (
        <Grid container className={classes.actionHeader}>
          <Grid item md={4} sm={4} xs={4}>
            <IconButton
              className={classes.buttonIconOutline}
              onClick={() => handleProfileButtonClick(props.residentId)}
              size="large">
              <RXRIcon
                icon={RXRIcon.PROFILE}
                size={RXRIcon.SIZE_LARGE}
                color={props.currentScreenName === SCREEN_RESIDENT_PROFILE ? activeIconColor : inactiveIconColor}
              />
            </IconButton>
          </Grid>
          <Grid item md={4} sm={4} xs={4}>
            <IconButton
              className={classes.buttonIconOutline}
              onClick={() => handleTimelineButtonClick(props.residentId)}
              size="large">
              <RXRIcon
                icon={RXRIcon.TIMELINE}
                size={RXRIcon.SIZE_LARGE}
                color={props.currentScreenName === SCREEN_TIMELINE ? activeIconColor : inactiveIconColor}
              />
            </IconButton>
          </Grid>
          <Grid item md={4} sm={4} xs={4}>
            <IconButton
              className={classes.buttonIconOutline}
              onClick={() => {
                setShowSearchBar(true)
              }}
              size="large">
              <RXRIcon icon={RXRIcon.SEARCH} size={RXRIcon.SIZE_LARGE} color={inactiveIconColor} />
            </IconButton>
          </Grid>
        </Grid>
      ) : (
        <Grid item md={12} sm={12} xs={12} className={classes.actionHeader}>
          <SearchResident
            value={null}
            labelText={''}
            placeholderText={'Search for a resident, unit...'}
            onSelectResident={handleTenantAndUnit}
            textFieldStyle={classes.searchBarField}
          />
          <IconButton
            className={classes.closeSearchBarButton}
            onClick={() => setShowSearchBar(false)}
            size="large">
            <RXRIcon size={RXRIcon.SIZE_LARGE} icon={RXRIcon.CLOSE} />
          </IconButton>
        </Grid>
      )}
    </div>)
  );
}

HeaderButtonComponent.propTypes = {
  residentId: PropTypes.string.isRequired,
}

const mapStateToProps = state => ({
  currentScreenName: state.KYRWidget.current.name,
})

const mapDispatchToProps = dispatch => ({
  dispatchNavigateToTimeLine: residentId => navigateToScreen(dispatch, SCREEN_TIMELINE, {residentId: residentId}),
  dispatchNavigateToResidentProfile: residentId => navigateToScreen(dispatch, SCREEN_RESIDENT_PROFILE, {residentId: residentId}),
})

export default connect(mapStateToProps, mapDispatchToProps)(HeaderButtonComponent)
