import React, {useRef} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Menu} from '@mui/material'
import {makeStyles} from '@mui/styles'
import SettingButton from './SettingButton'
import {rxrMonotoneLightGreyColor} from '../../assets/styles/color'
import {spaceSmall} from '../../assets/styles/spacing'
import RXRIcon from '../RXRIcon'
import PropTypes from 'prop-types'
import DashboardToggle from '../DashboardToggle'
import {setHidePopups, setMuteSounds} from '../../actions/appActions'

const useStyles = makeStyles(theme => ({
  container: {
    minWidth: 300,
  },

  divider: {
    height: 0,
    width: '100%',
    borderTop: `1px solid ${rxrMonotoneLightGreyColor}`,
    marginTop: spaceSmall,
    marginBottom: spaceSmall,
  },
}))

function NotificationsButton(props) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const isMuted = useSelector(state => state.App.muteSounds)
  const popupsHidden = useSelector(state => state.App.hidePopups)

  return (
    <SettingButton
      title={'Manage Notification Settings'}
      label={'Notifications'}
      icon={RXRIcon.SOUNDS_ON}
      style={props.style}
      isActive={!isMuted && !popupsHidden}
    >
      <div className={classes.container}>
        <DashboardToggle onChange={() => setMuteSounds(dispatch, !isMuted)} label={isMuted ? 'Sounds off' : 'Sounds on'} value={!isMuted} />
        <div className={classes.divider} />
        <DashboardToggle
          onChange={() => setHidePopups(dispatch, !popupsHidden)}
          label={popupsHidden ? 'Pop-up notifications off' : 'Pop-up notifications on'}
          value={!popupsHidden}
        />
      </div>
    </SettingButton>
  )
}

NotificationsButton.propTypes = {
  style: PropTypes.any,
}

export default NotificationsButton
