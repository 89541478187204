import React, {useState, useMemo, Fragment} from 'react'
import {useHistory} from 'react-router-dom'
import Routes from '../../constants/RouteConstants'
import {Grid} from '@mui/material'
import Constant from './SettingsConstants'
import {useSelector, useDispatch} from 'react-redux'
import PageHeader from '../PageHeader'
import ClearableInput from '../ClearableInput'
import RXRButton from '../RXRButton'
import {PageContainer} from '../../assets/styles/layout'
import {AntTab, AntTabs} from '../AntTabs'
import {makeStyles} from '@mui/styles'
import DashboardUsersTable from './DashboardUsersTable'
import {rxrContainerColor} from '../../assets/styles/color'
import SidePanel from '../SidePanel'
import useListCreateViewPattern from '../hooks/useListCreateViewPattern'
import useFormChanged, {NonEmptyValidator, EmailValidator} from '../hooks/useFormChanged'
import UserForm from './UserForm'
import {createGroupUser, updateGroupUser, disableGroupUser} from '../../lib/queries'
import DiscardChangesDialog from '../DiscardChangesDialog'
import {loadBuildingStaffForBuilding} from '../../actions/staffActions'
import {spaceMedium} from '../../assets/styles/spacing'
import {PERMISSION_GROUP_NOT_RESTRICTED, PERMISSION_GROUP_SUPER_ADMIN} from '../../constants/ModelConstants'

const TAB_ACTIVE = 0

const EMPTY_FORM_STATE = {
  id: '',
  staffName: '',
  email: '',
  userType: PERMISSION_GROUP_NOT_RESTRICTED,
  isDisabled: null,
}

function DashboardUsers(props) {
  const isMobile = useSelector(state => state.App.isMobile)
  const classes = useStyles()
  const [filterTerm, setFilterTerm] = useState('')
  const [isSaving, setIsSaving] = useState(false)
  const [isDisablingUser, setIsDisablingUser] = useState(false)
  const history = useHistory()
  const dispatch = useDispatch()
  const staffLookup = useSelector(state => state.Staff.staffLookup)
  const activeBuildingId = useSelector(state => state.Buildings.activeBuildingId)

  const {form, setForm, formChanged, resetInitialForm, invalidItems, validateForm} = useFormChanged(
    EMPTY_FORM_STATE,
    useFormChanged.PropLevelValidation({
      staffName: NonEmptyValidator,
      email: EmailValidator,
      userType: NonEmptyValidator,
    }),
  )

  const resetForm = () => {
    resetInitialForm(EMPTY_FORM_STATE, hideSlidingPanel)
  }

  const {isCreateMode, focusedItemId} = useListCreateViewPattern(
    Routes.SETTINGS_DASHBOARD_USERS_CREATE,
    Routes.SETTINGS_DASHBOARD_USERS_VIEW_SINGLE,
    'userId',
    EMPTY_FORM_STATE,
    form,
    resetInitialForm,
    staffLookup,
    user => ({
      id: user.id,
      staffName: user.displayName,
      email: user.userName,
      userType: user.permissionGroup,
      isDisabled: user.isDisabled,
    }),
    isSaving,
  )

  const tab = TAB_ACTIVE

  const filteredStaff = useMemo(() => {
    const filterLowerCase = filterTerm.toLowerCase()
    return Object.values(staffLookup).filter(staff => {
      return !staff.isDisabled && (!filterLowerCase || staff.displayName.toLowerCase().includes(filterLowerCase))
    })
  }, [staffLookup, filterTerm])

  const hideSlidingPanel = () => {
    history.push(Routes.SETTINGS_DASHBOARD_USERS)
  }

  const handleSubmit = async () => {
    // make sure the form is valid
    if (!validateForm()) {
      return
    }
    setIsSaving(true)
    try {
      if (form.id) {
        await updateGroupUser({
          staffId: form.id,
          displayName: form.staffName.trim(),
          userName: form.email.trim(),
          groupName: form.userType,
        })
      } else {
        await createGroupUser({
          displayName: form.staffName.trim(),
          userName: form.email.trim(),
          buildingId: [activeBuildingId],
          groupName: form.userType,
          password: '!Test123456',
        })
      }
      await loadBuildingStaffForBuilding(dispatch, activeBuildingId)
      setIsSaving(false)
      resetForm()
    } catch (er) {
      setIsSaving(false)
      console.error(er)
      alert('Sorry, we could not process this request. Please re-enter the staff user information.')
    }
  }

  const handleDisableUser = async () => {
    setIsDisablingUser(true)

    try {
      await disableGroupUser({
        staffId: form.id,
        buildingId: activeBuildingId,
      })
      await loadBuildingStaffForBuilding(dispatch, activeBuildingId)
      setIsDisablingUser(false)
      resetForm()
    } catch (er) {
      setIsDisablingUser(false)
      console.error(er)
      alert('Sorry, we could not process this request. Please try again.')
    }
  }

  const isEditingASuperAdminOrFormerUser = !isCreateMode && (form.userType === PERMISSION_GROUP_SUPER_ADMIN || form.isDisabled)

  return (
    <div style={PageContainer}>
      <PageHeader
        title={Constant.PAGE_TITLE}
        rightControlComponent={
          <RXRButton
            type={isMobile ? RXRButton.TYPE_DEFAULT : RXRButton.TYPE_LARGE}
            onClick={() => history.push(Routes.SETTINGS_DASHBOARD_USERS_CREATE)}
          >
            {Constant.BTN_ADD}
          </RXRButton>
        }
      >
        <Grid container>
          <Grid item lg={6} md={6}>
            <div className={classes.placeSearch}>
              <ClearableInput value={filterTerm} onChange={setFilterTerm} placeholder={Constant.ENTER_SEARCH_KEYWORDS_CONSTANT} />
            </div>
            <AntTabs value={tab} onChange={(e, t) => {}}>
              <AntTab label={'Active'} />
            </AntTabs>
          </Grid>
        </Grid>
      </PageHeader>
      <div className={classes.mainContainer}>
        {tab === TAB_ACTIVE ? <DashboardUsersTable users={filteredStaff} filterTerm={filterTerm} /> : null}
      </div>
      <DiscardChangesDialog hasChanges={formChanged} />
      <SidePanel
        isOpen={!!focusedItemId || isCreateMode}
        onClose={hideSlidingPanel}
        title={isCreateMode ? 'Create dashboard user' : 'Edit dashboard user'}
      >
        <Fragment>
          <UserForm {...form} updateForm={setForm} invalidItems={invalidItems} />
          <Grid container spacing={3} style={{marginTop: 20}}>
            <Grid item>
              <RXRButton onClick={handleSubmit} isLoading={isSaving} disabled={isEditingASuperAdminOrFormerUser}>
                {Constant.BTN_POST}
              </RXRButton>
              {form.id && (
                <RXRButton
                  type={RXRButton.TYPE_DESTRUCTIVE}
                  className={classes.buttonWithMarginLeft}
                  onClick={handleDisableUser}
                  disabled={isEditingASuperAdminOrFormerUser}
                  isLoading={isDisablingUser}
                >
                  {Constant.BTN_DISABLE_USER}
                </RXRButton>
              )}
              <RXRButton
                type={RXRButton.TYPE_TEXT}
                className={classes.buttonWithMarginLeft}
                onClick={() => history.push(Routes.SETTINGS_DASHBOARD_USERS)}
              >
                {Constant.BTN_CANCEL}
              </RXRButton>
            </Grid>
          </Grid>
        </Fragment>
      </SidePanel>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  placeSearch: {
    width: '367px',
  },
  mainContainer: {
    backgroundColor: rxrContainerColor,
    flex: 1,
    width: '100%',
  },
  buttonWithMarginLeft: {
    marginLeft: spaceMedium,
  },
}))

export default DashboardUsers
