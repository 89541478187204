import React, {useEffect, useState} from 'react'
import {PageContainer} from '../../assets/styles/layout'
import {Grid} from '@mui/material'
import {makeStyles} from '@mui/styles'
import NPSScoreSummaryCards from './NPSScoreSummaryCards'
import moment from 'moment'
import {Spacing, Colors, Typography} from '../../assets/styles'
import PageHeader from '../PageHeader'
import LineGraph from '../Charts/LineGraph'
import MetricDataAPI from '../../lib/MetricData/MetricDataAPI'
import NPSDetails from './DetailCards/NPSDetails'
import {borderRadiusMedium} from '../../assets/styles/border'
import {useSelector} from 'react-redux'
import LoadingStateIconAndMessage from '../LoadingStateIconAndMessage'
import SentimentDetails from './DetailCards/SentimentDetails'
import CategoryRatingDetails from './DetailCards/CategoryRatingDetails'
import SuperForecaster from './Forecaster/SuperForecaster'

const GRAPH_HEIGHT = 300

function ResidentSentiment() {
  const classes = useStyles()

  const [isLoading, setIsLoading] = useState(false)
  /** @type {ResidentFeedbackSummaryAndTimeSeriesPayload} timeSeriesData */
  const [timeSeriesData, setTimeSeriesData] = useState(null)
  const activeBuildingId = useSelector(state => state.Buildings.activeBuildingId)
  const buildingsLookup = useSelector(state => state.Buildings.buildingsLookup)
  const activeBuildingYardiId = buildingsLookup[activeBuildingId]?.yardiId

  useEffect(() => {
    setIsLoading(true)
    MetricDataAPI.fetchResidentFeedbackSummaryAndTimeSeriesData(activeBuildingYardiId)
      .then(r => {
        setTimeSeriesData(r)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [activeBuildingYardiId])

  return (
    <div className={classes.residentSentimentPage}>
      <PageHeader title={'Resident Sentiment'} className={classes.titleContainer} />
      <div className={classes.innerPage}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div className={classes.section}>
              <SuperForecaster />
            </div>
            {isLoading || !timeSeriesData ? (
              <LoadingStateIconAndMessage message={'Loading Resident Sentiment data'} />
            ) : (
              <React.Fragment>
                <div className={classes.section}>
                  <NPSScoreSummaryCards {...timeSeriesData.summary} />
                </div>

                <div className={classes.section}>
                  <h3 className={classes.sectionTitle}>NPS Score</h3>
                  <h4 className={classes.sectionSubTitle}>{`${moment(timeSeriesData.nps.columns[0]).format('MMM YYYY')} - ${moment(
                    timeSeriesData.nps.columns[timeSeriesData.nps.columns.length - 1],
                  ).format('MMM YYYY')}`}</h4>
                  <div style={{height: GRAPH_HEIGHT}}>
                    <LineGraph
                      yAxisLabels={['-100', '-50', '0', '50', '100']}
                      data={timeSeriesData.nps.data}
                      xAxisLabels={timeSeriesData.nps.columns}
                      formatXAxis={s => moment(s).format('M/D')}
                      yAxisMin={-100}
                      yAxisMax={100}
                      palette={[Colors.magenta]}
                      onHover={d => d.yValue}
                    />
                  </div>
                  <hr className={classes.divider} />
                  <h3 className={classes.sectionWithNoChatTitle}>NPS Details</h3>
                  <NPSDetails />
                </div>

                <div className={classes.section}>
                  <h3 className={classes.sectionTitle}>Message Sentiment</h3>
                  <h4 className={classes.sectionSubTitle}>{`${moment(timeSeriesData.sentiment.columns[0]).format('MMM YYYY')} - ${moment(
                    timeSeriesData.nps.columns[timeSeriesData.sentiment.columns.length - 1],
                  ).format('MMM YYYY')}`}</h4>
                  <div style={{height: GRAPH_HEIGHT}}>
                    <LineGraph
                      yAxisLabels={['-1', '-0.5', '0', '0.5', '1']}
                      data={timeSeriesData.sentiment.data}
                      xAxisLabels={timeSeriesData.sentiment.columns}
                      formatXAxis={s => moment(s).format('M/D')}
                      yAxisMin={-1}
                      yAxisMax={1}
                      palette={[Colors.magenta]}
                      onHover={d => d.yValue}
                    />
                  </div>
                  <hr className={classes.divider} />
                  <h3 className={classes.sectionWithNoChatTitle}>View message sentiment details</h3>
                  <SentimentDetails />
                </div>

                <div className={classes.section}>
                  <h3 className={classes.sectionTitle}>Amenity Rating</h3>
                  <h4 className={classes.sectionSubTitle}>{`${moment(timeSeriesData.amenities.columns[0]).format('MMM YYYY')} - ${moment(
                    timeSeriesData.nps.columns[timeSeriesData.amenities.columns.length - 1],
                  ).format('MMM YYYY')}`}</h4>
                  <div style={{height: GRAPH_HEIGHT}}>
                    <LineGraph
                      yAxisLabels={['-1', '-0.5', '0', '0.5', '1']}
                      data={timeSeriesData.amenities.data}
                      xAxisLabels={timeSeriesData.amenities.columns}
                      formatXAxis={s => moment(s).format('M/D')}
                      yAxisMin={-1}
                      yAxisMax={1}
                      palette={[Colors.magenta]}
                      onHover={d => d.yValue}
                    />
                  </div>
                </div>

                <div className={classes.section}>
                  <h3 className={classes.sectionTitle}>Service Rating</h3>
                  <h4 className={classes.sectionSubTitle}>{`${moment(timeSeriesData.services.columns[0]).format('MMM YYYY')} - ${moment(
                    timeSeriesData.nps.columns[timeSeriesData.services.columns.length - 1],
                  ).format('MMM YYYY')}`}</h4>
                  <div style={{height: GRAPH_HEIGHT}}>
                    <LineGraph
                      yAxisLabels={['-1', '-0.5', '0', '0.5', '1']}
                      data={timeSeriesData.services.data}
                      xAxisLabels={timeSeriesData.services.columns}
                      formatXAxis={s => moment(s).format('M/D')}
                      yAxisMin={-1}
                      yAxisMax={1}
                      palette={[Colors.magenta]}
                      onHover={d => d.yValue}
                    />
                  </div>
                </div>

                <div className={classes.section}>
                  <h3 className={classes.sectionTitle}>Maintenance Rating</h3>
                  <h4 className={classes.sectionSubTitle}>{`${moment(timeSeriesData.maintenance.columns[0]).format('MMM YYYY')} - ${moment(
                    timeSeriesData.nps.columns[timeSeriesData.maintenance.columns.length - 1],
                  ).format('MMM YYYY')}`}</h4>
                  <div style={{height: GRAPH_HEIGHT}}>
                    <LineGraph
                      yAxisLabels={['-1', '-0.5', '0', '0.5', '1']}
                      data={timeSeriesData.maintenance.data}
                      xAxisLabels={timeSeriesData.maintenance.columns}
                      formatXAxis={s => moment(s).format('M/D')}
                      yAxisMin={-1}
                      yAxisMax={1}
                      palette={[Colors.magenta]}
                      onHover={d => d.yValue}
                    />
                  </div>
                </div>

                <div className={classes.section}>
                  <h3 className={classes.sectionWithNoChatTitle}>Rating categories comments</h3>
                  <CategoryRatingDetails />
                </div>
              </React.Fragment>
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  residentSentimentPage: {
    ...PageContainer,
    height: 'auto',
    backgroundColor: Colors.rxrSidePanelBackgroundCoor,
  },
  titleContainer: {
    borderBottom: 'none',
    backgroundColor: 'transparent',
  },
  innerPage: {
    padding: Spacing.spaceMedium,
    paddingTop: 0,
    height: '100%',
  },

  section: {
    marginBottom: Spacing.spaceMedium,
    padding: Spacing.spaceMedium,
    backgroundColor: Colors.rxrWhiteColor,
    borderRadius: borderRadiusMedium,
    boxShadow: Colors.shadowSoft,
    wdith: '100%',
    overflowX: 'auto',
  },

  divider: {
    marginTop: Spacing.spaceMedium,
    marginBottom: Spacing.spaceMedium,
    height: 0,
    width: '100%',
    border: `1px solid ${Colors.rxrLightGreyColor}`,
  },

  sectionTitle: {
    ...Typography.totalText,
    paddingBottom: 0,
  },

  sectionWithNoChatTitle: {
    ...Typography.totalText,
    paddingBottom: 24,
  },

  sectionSubTitle: {
    fontSize: 11,
    color: Colors.rxrDarkGreyColor,
    paddingBottom: Spacing.spaceMedium,
  },
}))

export default ResidentSentiment
