import React from 'react'
import {useDispatch} from 'react-redux'
import Paper from '@mui/material/Paper'
import {makeStyles} from '@mui/styles'
import Grid from '@mui/material/Grid'
import moment from 'moment'
import {
  rxrTealColor,
  rxrBlueColor,
  shadowSoft,
  rxrOrangeColor,
  rxrWhiteColor,
  rxrStatusGreenColor,
  rxrRedColor,
} from '../../assets/styles/color'
import {fontSizeMediumLarge, fontSizeExtraExtraSmall} from '../../assets/styles/typography'
import {SCREEN_TIMELINE} from '../KnowYourResident/KYRConstants'
import RXRIcon from '../RXRIcon'
import {spaceSmall} from '../../assets/styles/spacing'
import PropTypes from 'prop-types'
import {navigateToScreen, setIsWidgetOpen} from '../../actions/kyrWidgetActions'
import ProgressBar from '../ProgressBar'
import {isOnboardingTaskComplete, isOnboardingTaskPendingApproval, isOnboardingTaskChangesRequested} from '../../Utils/onboardingUtils'
import {ONBOARDING_IMPORTANCE_CRITICAL, ONBOARDING_IMPORTANCE_REQUIRED} from '../../constants/ModelConstants'

const useStyles = makeStyles(theme => {
  return {
    parentStyle: {
      alignItems: 'center',
      paddingRight: spaceSmall,
    },
    cardContainer: {
      width: '100%',
      minHeight: theme.spacing(9), //72px
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: '22px',
      paddingBottom: '10px',
      marginBottom: '10px',
      borderRadius: '1px',
      '&:focus': {
        outline: '0px auto transparent',
      },
      boxShadow: shadowSoft,
      borderLeft: `10px solid transparent`,
    },
    borderLeft: {
      borderLeft: `10px solid`,
    },
    labelTextStyle: {
      width: '100%',
    },
    divlableStyle: {marginLeft: '10px'},

    residentName: {
      fontSize: fontSizeMediumLarge,
      lineHeight: '12px',
      letterSpacing: '0.8px',
      cursor: 'pointer',
      marginTop: '25px',
      textDecoration: 'underline',
    },
    residentMoveInCopy: {
      fontSize: fontSizeMediumLarge,
      lineHeight: '12px',
      letterSpacing: '0.8px',
      cursor: 'pointer',
      marginTop: '25px',
    },
    textStyle: {
      fontSize: '12px',
      color: rxrTealColor,
    },
    lableStyle: {
      fontSize: fontSizeMediumLarge,
      color: rxrBlueColor,
      letterSpacing: '1.3px',
    },
    onboardingTasksCopyContainer: {
      width: '45px',
      marginRight: spaceSmall,
    },
    arrowIcon: {paddingRight: spaceSmall},
    progressSectionContainer: {
      width: '30%',
      paddingRight: spaceSmall,
    },
    progressBarContainer: {
      width: '100%',
      height: '10px',
    },
    reviewButton: {
      marginTop: spaceSmall,
      width: 'fit-content',
      paddingLeft: spaceSmall,
      paddingRight: spaceSmall,
      height: '15x',
      borderRadius: '7.5px',
      fontSize: fontSizeExtraExtraSmall,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }
})

const OnboardingResidentRowContainer = ({resident, ...props}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const hasOnboardingTasks =
    resident.onboardingTasks &&
    Array.isArray(resident.onboardingTasks) &&
    resident.onboardingTasks.filter(
      task => task.importance === ONBOARDING_IMPORTANCE_CRITICAL || task.importance === ONBOARDING_IMPORTANCE_REQUIRED,
    ).length > 0
  const onboardingTasks = hasOnboardingTasks
    ? resident.onboardingTasks.filter(
        task => task.importance === ONBOARDING_IMPORTANCE_CRITICAL || task.importance === ONBOARDING_IMPORTANCE_REQUIRED,
      )
    : []
  const totalOnboardingTasks = hasOnboardingTasks ? onboardingTasks.length : 0
  const completedOnboardingTasks = hasOnboardingTasks
    ? onboardingTasks.reduce((accum, currVal) => {
        if (isOnboardingTaskComplete(currVal)) {
          accum++
        }
        return accum
      }, 0)
    : 0
  const numTasksPendingApproval = hasOnboardingTasks
    ? onboardingTasks.reduce((accum, currVal) => {
        if (isOnboardingTaskPendingApproval(currVal)) {
          accum++
        }
        return accum
      }, 0)
    : 0
  const numTasksChangesRequested = hasOnboardingTasks
    ? onboardingTasks.reduce((accum, currVal) => {
        if (isOnboardingTaskChangesRequested(currVal)) {
          accum++
        }
        return accum
      }, 0)
    : 0

  const completedAllTasks = hasOnboardingTasks && completedOnboardingTasks === totalOnboardingTasks
  const dynamicColor =
    numTasksPendingApproval > 0
      ? rxrOrangeColor
      : numTasksChangesRequested > 0
      ? rxrRedColor
      : completedAllTasks
      ? rxrStatusGreenColor
      : rxrTealColor
  const dynamicCopy =
    numTasksPendingApproval > 0
      ? 'Review'
      : numTasksChangesRequested > 0
      ? 'Changes requested'
      : completedAllTasks
      ? 'Ready for check-in'
      : null

  const dispatchNavigateToKYRResidentProfile = () => {
    setIsWidgetOpen(dispatch, true)
    navigateToScreen(dispatch, SCREEN_TIMELINE, {residentId: resident.id})
  }

  return (
    <Paper className={[classes.cardContainer, classes.borderLeft].join(' ')} style={{borderLeftColor: dynamicColor}}>
      <Grid container className={classes.parentStyle} direction="row" wrap="nowrap" spacing={0}>
        <Grid>
          <RXRIcon icon={RXRIcon.ARROW_LONG_RIGHT} color={dynamicColor} size={RXRIcon.SIZE_LARGE} className={classes.arrowIcon} />
        </Grid>

        <Grid item className={classes.labelTextStyle}>
          <div className={classes.divlableStyle}>
            <span className={classes.residentName} onClick={dispatchNavigateToKYRResidentProfile} style={{color: dynamicColor}}>
              {resident.displayName}
            </span>
            <span className={classes.residentMoveInCopy}> is moving-in {moment(resident.moveInDate).fromNow()}</span>
          </div>
        </Grid>
        <Grid item className={classes.progressSectionContainer}>
          {totalOnboardingTasks > 0 && (
            <>
              <div className={classes.progressBarContainer}>
                <ProgressBar color={dynamicColor} progress={hasOnboardingTasks ? completedOnboardingTasks / totalOnboardingTasks : 0} />
              </div>
              {numTasksPendingApproval > 0 || numTasksChangesRequested > 0 || completedAllTasks ? (
                <div className={classes.reviewButton} style={{backgroundColor: dynamicColor, color: rxrWhiteColor}}>
                  {dynamicCopy}
                </div>
              ) : null}
            </>
          )}
        </Grid>
        <Grid item>
          {totalOnboardingTasks > 0 ? (
            <div className={classes.onboardingTasksCopyContainer}>
              <span style={{color: dynamicColor}}>
                {completedOnboardingTasks}/{totalOnboardingTasks}
              </span>
            </div>
          ) : (
            <div>Manual onboarding</div>
          )}
        </Grid>
      </Grid>
    </Paper>
  )
}

OnboardingResidentRowContainer.propTypes = {
  resident: PropTypes.any.isRequired,
}

export default OnboardingResidentRowContainer
