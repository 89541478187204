import React, {useEffect, useState} from 'react'
import {makeStyles} from '@mui/styles'
import {useDispatch, useSelector} from 'react-redux'
import {Grid, Typography} from '@mui/material'
import {residentProfileTabHeading} from '../../assets/styles/typography'
import {selectResidentsByUnit} from '../../Utils/residentUtils'
import {setUpdateResident} from '../../actions/buildingsActions'
import {Colors} from '../../assets/styles'
import * as Constant from './ResidentConstant'
import DiscardChangesDialog from '../DiscardChangesDialog'
import {RXRButton} from '../RXRButton'
import useFormChanged from '../hooks/useFormChanged'
import TextInput from '../TextInput'
import DateInput from '../DateInput'
import MultilineTextInput from '../MultilineTextInput'
import SelectInput from '../SelectInput'
import CustomCheckbox from '../CustomCheckbox'
import PropTypes from 'prop-types'
import SlimMultiSelectInput from '../SlimMultiSelectInput'
import ImageGallerySelector from '../ImageGallerySelector'
import {spaceSmall} from '../../assets/styles/spacing'
import {selectAuthedUserId} from '../../reducers/selectors'
import FileHelper from '../../lib/FileHelper'

const USER_PACKAGE_PREFERENCES = [
  {value: 'frontDesk', label: 'Front Desk'},
  {value: 'inUnit', label: 'In Unit'},
]

// this is a little hacky, but basically if the new profile image equals this string, we're instead going to remove the existing one
const REMOVE_IMAGE_SECRET_KEY = 'REMOVE_IMAGE_SECRET_KEY'

const USER_GENDERS = [
  {value: 'male', label: 'Male'},
  {value: 'female', label: 'Female'},
  {value: 'non-binary', label: 'Non-binary'},
]

const useStyles = makeStyles(theme => ({
  profileImageContainer: {
    marginTop: `-${spaceSmall}px`,
  },

  textPTE: {
    ...Colors.rxrDarkGreyColor,
    marginLeft: '10px',
    marginTop: '-8px',
    fontSize: 12,
    ...Colors.darkGrey,
  },
  cursorHand: {
    cursor: 'pointer',
  },
  editProfileContainer: {
    marginLeft: '40px',
    maxWidth: '800px',
  },
  tabHeading: {
    ...residentProfileTabHeading,
    marginTop: '32px',
  },
}))

const EMPTY_FORM_STATE = {}

function UserProfileEditor(props) {
  const classes = useStyles()
  const [isSaving, setIsSaving] = useState(false)

  const authedUserId = useSelector(selectAuthedUserId)
  const activeBuilding = useSelector(state => state.Buildings.buildingsLookup[state.Buildings.activeBuildingId])
  const residentsLookup = useSelector(state => state.Residents.residentsLookup)
  const dispatch = useDispatch()

  const selectedResident = residentsLookup[props.residentId]
  const {form, formChanged, resetInitialForm, setForm} = useFormChanged(EMPTY_FORM_STATE)

  useEffect(() => {
    if (!selectedResident) {
      resetInitialForm(EMPTY_FORM_STATE)
    } else {
      resetInitialForm(selectedResident.userProfileObj)
    }
  }, [selectedResident])

  let roommates = []
  if (selectedResident) {
    roommates = selectResidentsByUnit(residentsLookup, selectedResident.occupancy.unit.id)
      .map(r => r.displayName)
      .filter(r => r !== selectedResident.displayName)
  }

  const handlePTEChange = (isChecked, name) => {
    let setVal
    if (isChecked) {
      setVal = new Date().toISOString() + ';' + authedUserId
    } else {
      setVal = false
    }
    setForm({[name]: setVal})
  }

  const saveChanges = () => {
    setIsSaving(true)

    // pull the profile image out of the form (we don't want to save it on the profile object)
    const newProfileImage = form.stagedProfileImage
    delete form.stagedProfileImage

    const input = {
      id: props.residentId,
      userProfile: JSON.stringify(form),
    }

    // if there was a new profile image, we add it to our input
    if (newProfileImage) {
      if (newProfileImage === REMOVE_IMAGE_SECRET_KEY) {
        input.profileImage = null
      } else {
        input.profileImage = FileHelper.formatS3ObjectForInput(newProfileImage)
      }
    }

    setUpdateResident(dispatch, input)
      .then(() => {
        resetInitialForm(form, () => {
          props.onSaved(form)
        })
      })
      .catch(err => {
        window.alert(err.message)
      })
      .finally(() => {
        setIsSaving(false)
      })
  }

  const handleNewImage = newImage => {
    if (newImage) {
      setForm({stagedProfileImage: newImage})
    } else if (form.stagedProfileImage) {
      setForm({stagedProfileImage: undefined})
    } else if (selectedResident.profileImage) {
      setForm({stagedProfileImage: REMOVE_IMAGE_SECRET_KEY})
    }
  }

  const profilePhotoToRender =
    form.stagedProfileImage === REMOVE_IMAGE_SECRET_KEY
      ? undefined
      : form.stagedProfileImage
      ? form.stagedProfileImage
      : selectedResident.profileImage
      ? selectedResident.profileImage
      : undefined

  return (
    <div className={classes.editProfileContainer}>
      <div className={classes.tabHeading}>{Constant.RESIDENT_DETAILS}</div>
      <Grid container spacing={3}>
        <Grid item xs={12} className={classes.profileImageContainer}>
          <ImageGallerySelector
            maxPhotos={1}
            value={profilePhotoToRender ? [profilePhotoToRender] : []}
            onChange={v => handleNewImage(v[0])}
            helperText={'Resident profile image'}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextInput label="Name" placeholder="" value={selectedResident.displayName} isDisabled />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextInput label="Goes by" placeholder="" onChange={val => setForm({goesBy: val})} value={form.goesBy} />
        </Grid>
        <Grid item md={6} xs={12}>
          <SelectInput onChange={val => setForm({gender: val})} label="Gender" options={USER_GENDERS} value={form.gender} />
        </Grid>
        <Grid item md={6} xs={12}>
          <DateInput label="Birthday" value={form.birthday || null} onChange={date => setForm({birthday: date})} />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextInput label="Phone" placeholder="" value={selectedResident.phoneNumber} isDisabled />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextInput label="Email" placeholder="" value={selectedResident.email} isDisabled />
        </Grid>
      </Grid>

      <div className={classes.tabHeading}>{Constant.UNIT_DETAILS}</div>

      <Grid container spacing={3}>
        <Grid item md={6} xs={12}>
          <TextInput label="Building" placeholder="" isDisabled value={activeBuilding.displayName} />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextInput label="Unit" placeholder="" isDisabled value={selectedResident.occupancy.unit.number} />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextInput label="Roommates" placeholder="" isDisabled value={roommates.length > 0 ? roommates.join(', ') : ''} />
        </Grid>
      </Grid>

      <div className={classes.tabHeading}>{Constant.RESIDENT_PREFERENCES}</div>

      <Grid container spacing={3}>
        <Grid item md={6} xs={12}>
          <SelectInput
            onChange={val => setForm({packagePreference: val})}
            label="Packages"
            options={USER_PACKAGE_PREFERENCES}
            value={form.packagePreference}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Typography className={classes.textPTE}>{Constant.LBL_PTE}</Typography>
          <CustomCheckbox
            label={Constant.RESIDENT_MAINTENANCE}
            isChecked={!!form.maintenancePermission}
            onChange={val => handlePTEChange(val, 'maintenancePermission')}
          />
          <CustomCheckbox
            label={Constant.RESIDENT_CLEANING}
            isChecked={!!form.cleaningPermission}
            onChange={val => handlePTEChange(val, 'cleaningPermission')}
          />
          <CustomCheckbox
            label={Constant.RESIDENT_PACKAGE_DELIVERY}
            isChecked={!!form.deliveryPermission}
            onChange={val => handlePTEChange(val, 'deliveryPermission')}
          />
          <CustomCheckbox
            label={Constant.RESIDENT_THIRD_PARTY_SERVICE_PROVIDER}
            isChecked={!!form.thirdPartyPermission}
            onChange={val => handlePTEChange(val, 'thirdPartyPermission')}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SlimMultiSelectInput
            label={'Gift preferences'}
            valueLabel={(form.giftPreferences || []).map(g => Constant.GIFT_PREFERENCES_LABELS[g]).join(', ')}
            options={Constant.GIFT_PREFERENCES_OPTIONS}
            onChange={vals => setForm({giftPreferences: vals})}
            value={form.giftPreferences}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SlimMultiSelectInput
            label={'Alcohol preferences'}
            valueLabel={(form.alcoholPreferences || []).map(a => Constant.ALCOHOL_PREFERENCES_LABELS[a]).join(', ')}
            options={Constant.ALCOHOL_PREFERENCES_OPTIONS}
            onChange={vals => setForm({alcoholPreferences: vals})}
            value={form.alcoholPreferences}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SlimMultiSelectInput
            label={'Food preferences'}
            valueLabel={(form.foodPreferences || []).map(f => Constant.FOOD_PREFERENCES_LABELS[f]).join(', ')}
            options={Constant.FOOD_PREFERENCES_OPTIONS}
            onChange={vals => setForm({foodPreferences: vals})}
            value={form.foodPreferences}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <MultilineTextInput
            label="Dietary restrictions"
            placeholder=""
            rows={3}
            value={form.dietaryRestrictions}
            onChange={val => setForm({dietaryRestrictions: val})}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <MultilineTextInput label="Likes" placeholder="" rows={3} value={form.likes} onChange={val => setForm({likes: val})} />
        </Grid>
        <Grid item xs={12} md={6}>
          <MultilineTextInput label="Dislikes" placeholder="" rows={3} value={form.dislikes} onChange={val => setForm({dislikes: val})} />
        </Grid>
        <Grid item xs={12} md={6}>
          <MultilineTextInput
            label="Cleaning preferences"
            placeholder=""
            rows={3}
            value={form.cleaningPreferences}
            onChange={val => setForm({cleaningPreferences: val})}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <MultilineTextInput label="Notes" placeholder="" rows={3} value={form.notes} onChange={val => setForm({notes: val})} />
        </Grid>
        <Grid item>
          <RXRButton disabled={!formChanged} isLoading={isSaving} onClick={saveChanges}>
            {Constant.BTN_SAVE_CHANGES}
          </RXRButton>
        </Grid>
        <Grid item className={classes.cursorHand}>
          <RXRButton type={RXRButton.TYPE_SECONDARY} onClick={props.onCancel} disabled={isSaving}>
            {Constant.BTN_CANCEL}
          </RXRButton>
        </Grid>
      </Grid>
      <DiscardChangesDialog hasChanges={formChanged} />
    </div>
  )
}

UserProfileEditor.propTypes = {
  residentId: PropTypes.string,
  onSaved: PropTypes.func,
  onCancel: PropTypes.func,
}

export default UserProfileEditor
