//import React from 'react'
import React from 'react'
import {Grid} from '@mui/material'
import AnnouncementsForm from './AnnouncementsForm'
import AnnouncementsTable from './AnnouncementsTable'
import {makeStyles} from '@mui/styles'
import 'react-sliding-pane/dist/react-sliding-pane.css'
import {useSelector} from 'react-redux'
import DiscardChangesDialog from '../DiscardChangesDialog'
import {useHistory} from 'react-router-dom'
import ConfirmDeleteDialog from '../ConfirmDeleteDialog'
import PageHeader from '../PageHeader'
import Routes from '../../constants/RouteConstants'
import Constant from '../Messages/MessageConstant'
import AnnouncementsConstant from './AnnouncementsConstant'
import EmptyStateIconAndMessage from '../EmptyStateIconAndMessage'
import {RXRButton} from '../RXRButton'
import useFormChanged, {
  ConstructGreaterThanOtherDateValidator,
  DateObjectValidator,
  NonEmptyArrayValidator,
  NonEmptyValidator,
} from '../hooks/useFormChanged'
import {PageContainer} from '../../assets/styles/layout'
import SidePanel from '../SidePanel'
import {deleteAnnouncement, listAnnouncements, updateAnnouncement, createNewAnnouncement} from '../../lib/queries'
import moment from 'moment'
import useBuildingDataRefresh from '../hooks/useBuildingDataRefresh'
import useListCreateViewPattern from '../hooks/useListCreateViewPattern'
import RXRIcon from '../RXRIcon'
import {spaceMedium} from '../../assets/styles/spacing'
import {H3Header} from '../../assets/styles/typography'
import FileHelper from '../../lib/FileHelper'
import SimpleSpinner from '../SimpleSpinner'
import {pluralizeWord} from '../../Utils/StringFormatter'
import {selectAuthedUserId} from '../../reducers/selectors'

const useStyles = makeStyles(theme => ({
  buttonsContainer: {
    marginTop: spaceMedium,
  },

  longLoadingContainer: {
    textAlign: 'center',
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    backgroundColor: 'rgba(255,255,255,0.8)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 100,

    '& p': {
      ...H3Header,
      margin: spaceMedium,
    },
  },
}))

const EMPTY_FORM_STATE = {
  headline: '',
  activeStart: null,
  activeEnd: null,
  text: '',
  htmlText: '<p></p>', // we start off with an empty p tag because without it the first character entered appears after the caret:  typing "abcd" -> "bcda"
  attachments: [],
  residentIds: [],
  audienceLabel: '',
}

function ViewAnnouncementsPage(props) {
  const isMobile = useSelector(state => state.App.isMobile)
  const classes = useStyles()
  const [announcements, setAnnouncements] = React.useState([])
  const [deleteDialogAnnouncement, setDeleteDialogAnnouncement] = React.useState(null)
  const [isSaving, setIsSaving] = React.useState(false)
  const [isCreatingNew, setIsCreatingNew] = React.useState(false)
  const history = useHistory()
  const {form, setForm, formChanged, resetInitialForm, invalidItems, validateForm} = useFormChanged(
    EMPTY_FORM_STATE,
    useFormChanged.PropLevelValidation({
      activeStart: DateObjectValidator,
      activeEnd: ConstructGreaterThanOtherDateValidator('activeStart'),
      headline: NonEmptyValidator,
      text: NonEmptyValidator,
      residentIds: NonEmptyArrayValidator,
    }),
  )
  const authedStaffId = useSelector(selectAuthedUserId)

  const permissionsObject = useSelector(state => state.GroupPermissions.permissionsObject)
  const {activeBuildingId, isLoadingData: isLoadingAnnouncements} = useBuildingDataRefresh(fetchAnnouncements)

  const {isCreateMode, focusedItemId} = useListCreateViewPattern(
    Routes.ANNOUNCEMENTS_CREATE,
    Routes.ANNOUNCEMENTS_VIEW_SINGLE,
    'announcementId',
    EMPTY_FORM_STATE,
    form,
    resetInitialForm,
    announcements,
    announcement => ({
      id: announcement.id,
      headline: announcement.headline,
      activeStart: new Date(announcement.activeStart),
      activeEnd: new Date(announcement.activeEnd),
      text: announcement.text,
      htmlText: announcement.htmlText,
      attachments: announcement.attachments,
      audienceLabel: announcement.audienceLabel,
      // this is set by the listAnnouncements function in queries.js
      residentIds: announcement.residentIds || [],
    }),
    isLoadingAnnouncements,
  )

  const resetForm = () => {
    resetInitialForm(EMPTY_FORM_STATE, hideSlidingPanel)
  }

  const hideSlidingPanel = () => {
    history.push(Routes.ANNOUNCEMENTS)
  }

  const handleSave = async () => {
    // make sure the form is valid
    if (!validateForm()) {
      return
    }

    const input = {
      id: form.id,
      text: form.text,
      htmlText: form.htmlText,
      attachments: form.attachments.map(a => FileHelper.formatS3ObjectForInput(a)),
      activeStart: form.activeStart,
      activeEnd: form.activeEnd,
      headline: form.headline,
      audienceLabel: form.audienceLabel,
      staffId: authedStaffId, // we set it here so that even if it's edited we know who touched it last
    }

    setIsSaving(true)

    try {
      if (form.id) {
        await updateAnnouncement(input)
      } else {
        setIsCreatingNew(true)
        delete input.id
        input.announcementsBuildingId = activeBuildingId
        await createNewAnnouncement(input, form.residentIds)
      }

      await fetchAnnouncements()
      resetForm()
    } catch (er) {
      console.error(er)
      alert('Sorry, we could not process this request. Please re-enter the event information.')
    }
    setIsCreatingNew(false)
    setIsSaving(false)
  }

  const removeAnnouncement = announcementId => {
    if (!deleteDialogAnnouncement || deleteDialogAnnouncement !== announcementId) {
      setDeleteDialogAnnouncement(announcementId)
      return
    }

    setDeleteDialogAnnouncement(null)
    let itemToRemove = announcements.find(a => a.id === announcementId)
    setAnnouncements(announcements.filter(a => a.id !== announcementId))
    deleteAnnouncement(announcementId).catch(err => {
      // not likely to happen. It's ok if the experience is a little bit janky
      console.error(err)
      window.alert(`Failed to delete ${itemToRemove.headline} event, please try again.`)
      // put the event back into our list
      setAnnouncements([...announcements, itemToRemove])
      history.push(Routes.constructPath(Routes.ANNOUNCEMENTS_VIEW_SINGLE, {eventId: announcementId}))
    })
    resetForm()
  }

  async function fetchAnnouncements() {
    let existingEvents = await listAnnouncements(activeBuildingId)
    let sortedEvents = existingEvents.sort((a, b) => {
      return new Date(a.startTime) - new Date(b.startTime)
    })
    setAnnouncements(sortedEvents)
  }

  const selectedAnnouncement = focusedItemId && announcements.find(a => a.id === focusedItemId)

  return (
    <div style={PageContainer}>
      <PageHeader
        title={'Announcements'}
        rightControlComponent={
          <RXRButton
            type={isMobile ? RXRButton.TYPE_DEFAULT : RXRButton.TYPE_LARGE}
            disabled={!permissionsObject.announcementsWrite}
            onClick={() => history.push(Routes.ANNOUNCEMENTS_CREATE)}
          >
            {AnnouncementsConstant.BTN_ADD}
          </RXRButton>
        }
      />
      <div style={{height: '100%'}}>
        {announcements.length === 0 ? (
          <EmptyStateIconAndMessage message={Constant.MESSAGE_ANNOUNCEMENT_CONSTANT} icon={RXRIcon.ANNOUNCEMENTS} />
        ) : (
          <AnnouncementsTable announcements={announcements} />
        )}
      </div>
      <DiscardChangesDialog hasChanges={formChanged} />
      <SidePanel
        isOpen={!!focusedItemId || isCreateMode}
        onClose={hideSlidingPanel}
        title={focusedItemId ? 'View announcement' : 'Add new announcement'}
      >
        {isCreatingNew && (
          <div className={classes.longLoadingContainer}>
            <div>
              <SimpleSpinner size={SimpleSpinner.SIZE_X_LARGE} />
              <p>
                Sending new announcement to {pluralizeWord('resident', form.residentIds.length, true)}.
                <br />
                This may take a moment...
              </p>
            </div>
          </div>
        )}

        <AnnouncementsForm invalidItems={invalidItems} {...form} updateForm={setForm} />
        <Grid container spacing={3} alignItems="center" className={classes.buttonsContainer}>
          <Grid item>
            <RXRButton isLoading={isSaving} disabled={!formChanged || !permissionsObject.announcementsWrite} onClick={handleSave}>
              {AnnouncementsConstant.BTN_SAVE}
            </RXRButton>
          </Grid>
          {selectedAnnouncement && (
            <Grid item>
              <RXRButton
                type={RXRButton.TYPE_DESTRUCTIVE}
                disabled={!permissionsObject.announcementsWrite || moment(selectedAnnouncement.activeEnd) < moment()}
                onClick={() => removeAnnouncement(focusedItemId)}
              >
                {AnnouncementsConstant.BTN_DELETE}
              </RXRButton>
            </Grid>
          )}

          <Grid item>
            <RXRButton type={RXRButton.TYPE_TEXT} onClick={hideSlidingPanel}>
              {AnnouncementsConstant.BTN_CANCEL}
            </RXRButton>
          </Grid>
        </Grid>
      </SidePanel>
      <ConfirmDeleteDialog
        prompt={'Are you sure you want to delete this announcement?'}
        isOpen={!!deleteDialogAnnouncement}
        onConfirmDelete={() => removeAnnouncement(deleteDialogAnnouncement)}
        onCancel={() => setDeleteDialogAnnouncement(null)}
      />
    </div>
  )
}

export default ViewAnnouncementsPage
