import React, {useState} from 'react'
import {makeStyles} from '@mui/styles'
import {heading, inputRow} from './authStyles'
import TextInput from '../TextInput'
import RXRButton from '../RXRButton'
import AuthPage from './AuthPage'
import {useHistory} from 'react-router-dom'
import Routes from '../../constants/RouteConstants'
import {spaceExtraLarge, spaceMedium} from '../../assets/styles/spacing'
import AppAuth from '../../lib/AppAuth'
import {Colors} from '../../assets/styles'
import {STATE_SENDING, STATE_SENT, STATE_ERROR, STATE_IDLE} from './authConstants'

function ForgotPassword(props) {
  const classes = useStyles()
  const [userName, setUserName] = useState('')
  const [resetError, setResetError] = useState('')
  const [sendState, setSendState] = useState(STATE_IDLE)
  const history = useHistory()

  const handleSendReset = () => {
    setSendState(STATE_SENDING)
    AppAuth.Instance()
      .sendPasswordResetEmail(userName)
      .then(() => {
        setSendState(STATE_SENT)
      })
      .catch(err => {
        setResetError(err.message)
        setSendState(STATE_ERROR)
      })
  }

  return (
    <AuthPage isLoading={sendState === STATE_SENDING}>
      {sendState === STATE_SENT ? (
        <React.Fragment>
          <p className={classes.heading}>Reset email sent</p>
          <p className={classes.confirmationText}>
            We sent you a link to reset you password. Email has been sent to <strong style={{fontWeight: 'bold'}}>{userName}</strong>
          </p>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <p className={classes.heading}>Forgot Password?</p>
          <div className={classes.inputRow}>
            {resetError ? (
              <p style={{color: Colors.rxrErrorColor}}>ERROR: {resetError}</p>
            ) : (
              <p>For non-email users, please contact your system admin for a password reset.</p>
            )}
          </div>
          <div className={classes.inputRow}>
            <TextInput label="Enter your email" isRequired={true} onChange={setUserName} value={userName} error={!!resetError} />
          </div>
          <div className={classes.inputRow}>
            <RXRButton onClick={handleSendReset} disabled={!userName}>
              Send me a reset link
            </RXRButton>
          </div>
        </React.Fragment>
      )}
      <RXRButton className={classes.backButton} type={RXRButton.TYPE_TEXT} onClick={() => history.push(Routes.LOGIN)}>
        Back to log-in
      </RXRButton>
    </AuthPage>
  )
}

const useStyles = makeStyles(theme => ({
  heading: heading,
  inputRow: inputRow,
  backButton: {
    marginTop: spaceMedium,
  },
  confirmationText: {
    ...inputRow,
    margin: `${spaceExtraLarge}px auto`,
  },
}))

export default ForgotPassword
