import {isProductionEnvironment} from '../Utils/environmentUtils'

// testing environment(s) use the qa-static sub directory
export const S3_URL = 'https://rxo-onboarding.s3.amazonaws.com' + (isProductionEnvironment() ? '' : '/qa-static')
export const BUILDING_CONFIG_FILE_NAME = 'building-config.json'

export const ONBOARDING_TASK_KEYS = {
  BUILDING_RULES: 'Building rules',
  PET_PROFILE_CREATION: 'Pet profile creation',
  PERSONAL_PREFERENCES: 'Personal preferences',
  MEET_YOUR_PROPERTY_STAFF: 'Meet your property staff',
  DOWNLOAD_RXO_HOME: 'Download RXO Home',
  MOVING_SERVICES: 'Moving services',
  FURNITURE_RENTAL: 'Furniture rental',
  CLEANING_SERVICES: 'Cleaning services',
  SMART_LOCK: 'Smart lock',
  SECURITY_DEPOSIT_AND_RENT_LINK_OUT: 'Security deposit and rent - Link out',
  SECURITY_DEPOSIT_AND_RENT_CHECK: 'Security deposit and rent - Check',
  SECURITY_DEPOSIT_AND_RENT_HYBRID: 'Security deposit and rent - Hybrid',
  ELEVATOR_RESERVATION: 'Elevator reservation',
  ELEVATOR_RESERVATION_MULTIPLE: 'Elevator reservation - Multiple',
  UTILITIES_SETUP: 'Utilities setup',
  PET_SCREENING: 'Pet screening',
  PET_SCREENING_LINK_OUT_REQUIRED: 'Pet screening - Link out required',
  MOVING_INFO_SELF_MOVE_NO_WAIVER: 'Moving info - Self move no waiver',
  MOVING_INFO_SELF_MOVE_WITH_WAIVER: 'Moving info - Self move with waiver',
  RENTERS_INSURANCE: `Renter's insurance`,
  RENTERS_INSURANCE_475C: `Renter's insurance - 475C`,
  RENTERS_INSURANCE_WILLOUGH: `Renter's insurance - Willough`,
  RENTERS_INSURANCE_ASW: `Renter's insurance - ASW`,
  RENTERS_INSURANCE_AS1: `Renter's insurance - AS1`,
  KEY_PICKUP: 'Key pick-up',
  KEY_PICKUP_READ_ONLY: 'Key pick-up - Read only',
  COBU_LINKOUT: 'Cobu linkout',
  WELCOME_LETTER: 'Welcome letter',
}

export const MOVING_METHOD_SELF_MOVE = 'Self-move'
export const MOVING_METHOD_MOVERS = 'Movers'
export const MOVING_METHOD_NO_MOVE_NEEDED = 'No move needed'

export const MOVING_INFO_OPTIONS = [
  {value: MOVING_METHOD_SELF_MOVE, label: 'Self-move'},
  {value: MOVING_METHOD_MOVERS, label: 'Movers'},
  {value: MOVING_METHOD_NO_MOVE_NEEDED, label: 'No move needed'},
]

export const PET_SCREENING_NO_PETS = 'No pets'
export const PET_SCREENING_HAS_PETS = 'Has pets'
export const PET_SCREENING_OPTIONS = [
  {value: PET_SCREENING_NO_PETS, label: 'No pets - screening waived'},
  {value: PET_SCREENING_HAS_PETS, label: 'Has pets - screening submitted'},
]
