import React, {useState, useEffect, useContext} from 'react'
import {WhitelabelContext} from '../OnboardingWhitelabel'
import {Grid} from '@mui/material'
import SelectInput from '../../SelectInput'
import useFormChanged, {NonEmptyStringValidator, DateStringValidator} from '../../hooks/useFormChanged'
import {FormComponentPropTypes} from '../onboardingTypes'
import DateInput from '../../DateInput'
import TextInput from '../../TextInput'
import useSharedStyles from '../useSharedStyles'
import RejectOrAcceptWithFileAttacher from '../FormCTAs/RejectOrAcceptWithFileAttacher'
import SyncedFiles from '../SyncedFiles'
import {makeStyles} from '@mui/styles'
import {spaceMedium} from '../../../assets/styles/spacing'
import {getSyncedAndNotSyncedFilesFromSubmission, isOnboardingTaskStateCompleted} from '../../../Utils/onboardingUtils'

const EMPTY_FORM = {
  insuranceCompany: null,
  policyNumber: null,
  liabilityCoverageAmount: null,
  policyStartDate: null,
  policyEndDate: null,
  propertyListedAsInterestedParty: null,
}

function RentersInsurance(props) {
  const sharedClasses = useSharedStyles()
  const [isLoading, setIsLoading] = useState(false)
  const classes = useStyles()
  const {setForm, form, formChanged, validateForm, invalidItems, resetInitialForm} = useFormChanged(
    EMPTY_FORM,
    useFormChanged.PropLevelValidation({
      insuranceCompany: NonEmptyStringValidator,
      policyNumber: NonEmptyStringValidator,
      liabilityCoverageAmount: NonEmptyStringValidator,
      policyStartDate: DateStringValidator,
      policyEndDate: DateStringValidator,
    }),
  )
  const {syncedAttachments, notSyncedAttachments} = getSyncedAndNotSyncedFilesFromSubmission(props.submission)
  const [isEditing, setIsEditing] = useState(false)
  const {rentersInsurance} = useContext(WhitelabelContext)

  const handleStartEditing = () => {
    setIsEditing(true)
  }

  const handleFinishEditing = () => {
    setIsEditing(false)
  }

  useEffect(() => {
    if (props.submission) {
      resetInitialForm({
        insuranceCompany: props.submission.insuranceCompany,
        policyNumber: props.submission.policyNumber,
        liabilityCoverageAmount: props.submission.liabilityCoverageAmount,
        policyStartDate: props.submission.policyStartDate,
        policyEndDate: props.submission.policyEndDate,
        propertyListedAsInterestedParty: props.submission.propertyListedAsInterestedParty,
      })
    }
  }, [props.submission])

  const handleSyncedAttachments = newSyncedAttachmentsArray => {
    props.onUpdateSubmission({
      ...props.submission,
      attachments: [...notSyncedAttachments, ...newSyncedAttachmentsArray],
    })
  }

  const handleUpdateSubmission = updatedSubmission => {
    props.onUpdateSubmission({
      ...updatedSubmission,
      ...form,
    })
  }

  const isFormCompleted = form => {
    return (
      !!form.insuranceCompany && !!form.policyNumber && !!form.liabilityCoverageAmount && !!form.policyStartDate && !!form.policyEndDate
    )
  }

  const formIsCompletedButUserIsNotEditing = props.submission && isOnboardingTaskStateCompleted(props.submission.state) && !isEditing
  const isEditingCompletedSubmission = props.submission && isOnboardingTaskStateCompleted(props.submission.state) && isEditing
  const formIsEditable = !formIsCompletedButUserIsNotEditing

  return (
    <React.Fragment>
      <div className={sharedClasses.bodyContainer}>
        <div>Please check that all details are correct before confirming the requirement as accepted.</div>
        <div className={classes.gridContainer}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextInput
                onChange={val => setForm({insuranceCompany: val})}
                label={'Insurance company'}
                value={form.insuranceCompany}
                isDisabled={!formIsEditable}
                isRequired={true}
                error={invalidItems.includes('insuranceCompany')}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInput
                onChange={val => setForm({policyNumber: val})}
                label={'Policy number'}
                value={form.policyNumber}
                isDisabled={!formIsEditable}
                isRequired={true}
                error={invalidItems.includes('policyNumber')}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInput
                onChange={val => setForm({liabilityCoverageAmount: val})}
                label={'Liability coverage amount'}
                value={form.liabilityCoverageAmount}
                isDisabled={!formIsEditable}
                isRequired={true}
                error={invalidItems.includes('liabilityCoverageAmount')}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DateInput
                label={'Policy start date'}
                value={form.policyStartDate}
                onChange={val => setForm({policyStartDate: val})}
                disabled={!formIsEditable}
                isRequired={true}
                error={invalidItems.includes('policyStartDate')}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DateInput
                label={'Policy end date'}
                value={form.policyEndDate}
                onChange={val => setForm({policyEndDate: val})}
                disabled={!formIsEditable}
                isRequired={true}
                error={invalidItems.includes('policyEndDate')}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <SelectInput
                onChange={val => setForm({propertyListedAsInterestedParty: val})}
                label={rentersInsurance.rentersInsuranceInterestedPartyCopy}
                value={form.propertyListedAsInterestedParty}
                options={[
                  {value: true, label: 'Yes'},
                  {value: false, label: 'No'},
                ]}
                disabled={!formIsEditable}
              />
            </Grid>
          </Grid>
          <div className={classes.syncedAttachmentsContainer}>
            <SyncedFiles value={syncedAttachments} onChange={handleSyncedAttachments} />
          </div>
        </div>
      </div>
      <RejectOrAcceptWithFileAttacher
        submission={props.submission}
        onUpdateSubmission={handleUpdateSubmission}
        isEditing={isEditing}
        onEdit={handleStartEditing}
        onFinishEditing={handleFinishEditing}
        isDisabled={!isFormCompleted(form) || (isEditingCompletedSubmission && !formChanged)}
      />
    </React.Fragment>
  )
}

const useStyles = makeStyles(theme => ({
  gridContainer: {
    marginTop: spaceMedium,
  },
  syncedAttachmentsContainer: {
    marginTop: spaceMedium,
  },
}))

export default RentersInsurance

RentersInsurance.propTypes = FormComponentPropTypes
