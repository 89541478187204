import React from 'react'
import moment from 'moment'
import {makeStyles} from '@mui/styles'
import Grid from '@mui/material/Grid'
import SearchResident from '../SearchResident'
import SelectInput from '../SelectInput'
import MultilineTextInput from '../MultilineTextInput'
import ManyBuildingActionsInput from './ManyBuildingActionsInput'
import DateInput from '../DateInput'
import NumberInput from '../NumberInput'
import {BUILDING_NUDGE_TYPE_LABELS} from './AssistantConstants'
import {convertKeyLabelMapIntoSelectOptions} from '../../Utils/objectUtil'
import {spaceMedium} from '../../assets/styles/spacing'
import PropTypes from 'prop-types'

const BUILDING_NUDGE_TYPE_OPTIONS = convertKeyLabelMapIntoSelectOptions(BUILDING_NUDGE_TYPE_LABELS)

const useStyles = makeStyles(theme => ({
  container: {
    flexGrow: 1,
    marginBottom: 22,
  },
  gridStyling: {
    marginTop: spaceMedium,
  },
}))

export default function LogActionForm(props) {
  const classes = useStyles()
  const isCreatingForNudge = !!props.buildingNudgeId

  return (
    <div className={classes.container}>
      <SearchResident
        isRequired={true}
        disabled={isCreatingForNudge}
        value={props.residentId}
        onSelectResident={r => props.updateForm({residentId: r ? r.id : null})}
        error={props.invalidItems.includes('residentId')}
      />

      <div className={classes.gridStyling}>
        <ManyBuildingActionsInput
          value={props.actions}
          onChange={a => props.updateForm({actions: a})}
          error={props.invalidItems.includes('actions')}
        />
      </div>

      <Grid container spacing={3} className={classes.gridStyling}>
        <Grid item lg={6} md={6} sm={6} xs={6}>
          <DateInput
            label="Date action was taken"
            onChange={date =>
              props.updateForm({
                actionTakenAt: moment(date).startOf('day').toDate(),
              })
            }
            value={props.actionTakenAt}
            isRequired={true}
          />
        </Grid>

        <Grid item lg={6} md={6} sm={6} xs={6}>
          <NumberInput
            label="Total price"
            onChange={v => props.updateForm({cost: v})}
            min={0}
            value={typeof props.cost === 'number' ? props.cost : null}
            showDollarSign={true}
          />
        </Grid>
      </Grid>

      <div className={classes.gridStyling}>
        <SelectInput
          onChange={val =>
            props.updateForm({
              reason: val,
            })
          }
          isRequired={true}
          label={'Reason'}
          disabled={isCreatingForNudge}
          options={BUILDING_NUDGE_TYPE_OPTIONS}
          value={props.reason}
          error={props.invalidItems.includes('reason')}
        />
      </div>

      <div className={classes.gridStyling}>
        <MultilineTextInput
          label="Notes"
          placeholder=""
          rows={4}
          rowsMax={4}
          onChange={val => props.updateForm({notes: val})}
          value={props.notes}
        />
      </div>
    </div>
  )
}

LogActionForm.propTypes = {
  updateForm: PropTypes.func,
  invalidItems: PropTypes.array,
}
