import React, {useState, useEffect} from 'react'
import {makeStyles} from '@mui/styles'
import {Colors} from '../../assets/styles'
import {useDispatch} from 'react-redux'
import Routes from '../../constants/RouteConstants'
import {useLocation, withRouter, matchPath} from 'react-router-dom'
import PageHeader from '../PageHeader'
import {Grid, Typography} from '@mui/material'
import {AntTab, AntTabs} from '../AntTabs'
import ClearableInput from '../ClearableInput'
import IncomingPackagesTable from './IncomingPackagesTable'
import moment from 'moment'
import IncomingDeliveredPackagesTable from './IncomingDeliveredPackagesTable'
import IncomingDeliveriesForm from './IncomingDeliveriesForm'
import Constant from './DeliveryConstants'
import DiscardChangesDialog from '../DiscardChangesDialog'
import EmptyStateIconAndMessage from '../EmptyStateIconAndMessage'
import {rxrBlueColor} from '../../assets/styles/color'
import DateRangeInput from '../DateRangeInput'
import Loader from '../Loader'
import {unMarkedDelivered} from '../../actions/deliveryActions'
import useFormChanged, {NonEmptyValidator} from '../hooks/useFormChanged'
import {RXRButton} from '../RXRButton'
import {
  getIncomingDeliveriesForBuilding,
  deleteIncomingDelivery,
  unDeleteIncomingDelivery,
  updateIncomingDelivery,
  CreateNewDelivery,
} from '../../lib/queries'
import {useSelector} from 'react-redux'
import {selectAuthedUserId, selectPTEForOccupancy} from '../../reducers/selectors'
import {PageContainer} from '../../assets/styles/layout'
import LoadingStateIconAndMessage from '../LoadingStateIconAndMessage'
import SidePanel from '../SidePanel'
import useResidentLookup from '../hooks/useResidentLookup'
import useBuildingDataRefresh from '../hooks/useBuildingDataRefresh'
import RXRIcon from '../RXRIcon'
import {loadBuildingStaffForBuilding} from '../../actions/staffActions'
import {CORE_FEATURE_PACKAGE_DELIVERY} from '../../constants/ModelConstants'

const useStyles = makeStyles(theme => ({
  cursorHand: {
    cursor: 'pointer',
  },
  formContainer: {
    maxWidth: theme.breakpoints.values.sm,
  },
  dateFilterContainer: {
    textAlign: 'right',
    display: 'flex',
    justifyContent: 'flex-end',

    '& .MuiInput-underline:before': {
      display: 'block',
    },
  },
  textAuthDate: {
    width: '193px',
    textAlign: 'center',
  },

  placeSearch: props => ({
    width: props.isMobile ? '100%' : '418px',
  }),

  slidingPaneContainer: {},
  selectDeliveryCloseIcon: {
    textAlign: 'right',
  },
  peopleIconContainer: {
    fontSize: 80,
    lineHeight: 0,
    color: rxrBlueColor,
  },
  deleteAnnouncementButton: {
    width: '100%',
  },
  notificatinHeading: {
    fontSize: 12,
    color: Colors.rxrRedColor,
    paddingBottom: 10,
  },
}))

const EMPTY_FORM_STATE = {
  id: null,
  carrier: null,
  packageType: null,
  trackingNumber: null,
  priority: Constant.PRIORITY_OPTIONS[0].value,
  residentId: null,
  unitId: null,
  arrivedOn: null,
  deliveredOn: null,
  notes: null,
  recipientName: null,
  deliveredTo: null,
  staffId: null,
}

function IncomingViewDeliveriesPage(props) {
  const isMobile = useSelector(state => state.App.isMobile)
  const classes = useStyles({isMobile})

  const [tab, setTab] = useState(Constant.TAB_INCOMING_PACKAGES)
  const [filterTerm, setFilterTerm] = useState('')
  const [filterDateStart, setFilterDateStart] = useState(null)
  const [filterDateEnd, setFilterDateEnd] = useState(null)
  const [incomingPackages, setIncomingPackages] = useState([])
  const [deliveredPackages, setDeliveredPackages] = useState([])
  const [isLoadingAllPackages, setIsLoadingAllPackages] = useState(true)
  const [deleteDiscardDialog, setDeleteDiscardDialog] = React.useState(false)
  const [unmarkPackageDialog, setUnmarkPackageDialog] = React.useState(false)
  const [discardIcon, setDiscardIcon] = React.useState(null)
  const [discardTitle, setDiscardTitle] = React.useState(null)
  const [discardMessage, setDiscardMessage] = React.useState(null)
  const [discardButton, setDiscardButton] = React.useState(null)
  const [unDeletePackageDialog, setUnDeletePackageDialog] = React.useState(false)
  const [shouldReloadData, setShouldReloadData] = React.useState(false)
  const {activeBuildingId} = useBuildingDataRefresh(settingIncomingDeliveredPackages)
  const {getResident} = useResidentLookup()

  const recentlyMarkedDeliveryIds = useSelector(state => state.Delivery.recentlyMarkedDeliveryIds)
  const coreFeatures = useSelector(state => state.GroupPermissions.coreFeatures)
  const staffLookup = useSelector(state => state.Staff.staffLookup)
  const authedUserId = useSelector(selectAuthedUserId)

  const dispatch = useDispatch()

  const currentLocation = useLocation()

  let focusedDeliveryId
  let focusedDeliveryObject = {}
  let isCreateMode = false
  let viewSingleMatch = matchPath(currentLocation.pathname, {path: Routes.DELIVERIES_IN_VIEW_SINGLE})
  let createMatch = matchPath(currentLocation.pathname, {path: Routes.DELIVERIES_IN_CREATE})

  if (createMatch && createMatch.isExact) {
    isCreateMode = true
  } else if (viewSingleMatch && viewSingleMatch.isExact) {
    focusedDeliveryId = viewSingleMatch.params.deliveryId
    focusedDeliveryObject = deliveredPackages.concat(incomingPackages).find(p => p.id === focusedDeliveryId) || {}
  }

  const {form, setForm, formChanged, resetInitialForm, validateForm, invalidItems} = useFormChanged(
    EMPTY_FORM_STATE,
    useFormChanged.PropLevelValidation({
      carrier: NonEmptyValidator,
      packageType: NonEmptyValidator,
      priority: NonEmptyValidator,
      residentId: NonEmptyValidator,
      unitId: NonEmptyValidator,
    }),
  )

  // TODO: How can we avoid loading the entire redux state?
  const state = useSelector(state => state)

  async function settingIncomingDeliveredPackages() {
    setIsLoadingAllPackages(true)
    const packageItems = await fetchDeliveryPackages(activeBuildingId)

    const [incomingPackages, deliveredPackages] = packageItems.reduce(
      (retVal, delivery) => {
        let deliveryObj = {...delivery, PTE: false}
        let resident = getResident(delivery.residentId)

        if (resident && resident.userProfile) {
          deliveryObj = {
            ...delivery,
            PTE: selectPTEForOccupancy(state, resident.occupancy.unit.id, 'deliveryPermission'),
          }
        }
        if (!!delivery.receivedAt || delivery.isDeleted) {
          retVal[1].push(deliveryObj)
        } else {
          retVal[0].push(deliveryObj)
        }
        return retVal
      },
      [[], []],
    )
    setIncomingPackages(incomingPackages)
    setDeliveredPackages(deliveredPackages)
    setIsLoadingAllPackages(false)
  }

  useEffect(() => {
    if (shouldReloadData || recentlyMarkedDeliveryIds.length > 0) {
      unMarkedDelivered(dispatch)
      settingIncomingDeliveredPackages().then()
    }
  }, [tab])

  //Redirecting to Messages page if packageDelivery is disabled
  useEffect(() => {
    !coreFeatures[CORE_FEATURE_PACKAGE_DELIVERY] && props.history.push(Routes.MESSAGES)
  }, [coreFeatures, activeBuildingId])

  // Load the staffLookup on activeBuildingId changes
  useEffect(() => {
    loadBuildingStaffForBuilding(dispatch, activeBuildingId).then()
  }, [activeBuildingId])

  const packagesFiltered = getFilteredItems(
    tab === Constant.TAB_INCOMING_PACKAGES ? incomingPackages : deliveredPackages,
    filterDateStart,
    filterDateEnd,
    filterTerm,
    tab,
    getResident,
  )

  // --------------------------------------------------------------------------------------------
  // Delivery Editing stuff

  const [isLoadingDelivery, setIsLoadingDelivery] = useState(false)

  // USES THE VIEW_SINGLE / CREATE LOAD PATTERN
  useEffect(() => {
    if ((!focusedDeliveryId && form.id) || (!focusedDeliveryId && !isCreateMode)) {
      resetInitialForm(EMPTY_FORM_STATE)
    } else if (focusedDeliveryId && form.id !== focusedDeliveryId) {
      const incomingDelivery = incomingPackages.find(g => g.id === focusedDeliveryId)
      const deliveredDelivery = deliveredPackages.find(g => g.id === focusedDeliveryId)

      const delivery = deliveredDelivery || incomingDelivery

      if (delivery) {
        if (incomingDelivery) {
          setTab(Constant.TAB_INCOMING_PACKAGES)
        } else {
          setTab(Constant.TAB_DELIVERED_PACKAGES)
        }

        let initialForm = {
          id: delivery.id,
          carrier: delivery.carrier,
          packageType: delivery.packageType,
          trackingNumber: delivery.trackingNumber,
          priority: delivery.priority,
          residentId: delivery.residentId,
          unitId: delivery.unitId,
          arrivedOn: delivery.createdAt ? moment(delivery.createdAt) : null,
          deliveredOn: delivery.receivedAt ? moment(delivery.receivedAt) : null,
          notes: delivery.notes,
          recipientName: delivery.deliveredDetails ? delivery.deliveredDetails.recipientName : null,
          deliveredTo: delivery.deliveredDetails ? delivery.deliveredDetails.deliveredTo : null,
          staffId: delivery.staffId ? delivery.staffId : null,
        }
        resetInitialForm(initialForm, () => setIsLoadingDelivery(false))
      } else if (!isLoadingDelivery) {
        setIsLoadingDelivery(true)
      }
    }
  }, [incomingPackages, deliveredPackages, focusedDeliveryId, isCreateMode, isLoadingDelivery])

  const hideSlidingPanel = () => {
    props.history.push(Routes.DELIVERIES_IN)
  }

  const discardDialog = () => {
    if (deleteDiscardDialog) {
      deleteDeliveryRecord(form.id, true)
    } else if (unmarkPackageDialog) {
      updateDeliveryRecord(true)
    } else if (unDeletePackageDialog) {
      deleteDeliveryRecord(focusedDeliveryId, false)
    }
    setDeleteDiscardDialog(false)
    setUnmarkPackageDialog(false)
    setUnDeletePackageDialog(false)
  }

  /**
   * @returns {Promise<void>}
   */
  const updateDeliveryRecord = async unMarkedDelivered => {
    try {
      let deliveryForm = {...form}
      if (unMarkedDelivered) {
        deliveryForm = {...form, deliveredOn: null, deliveredDetails: null}
      }
      await pushUpdateDeliveryRecord(deliveryForm)

      // reload to reflect these changes to the table
      settingIncomingDeliveredPackages().then()

      resetInitialForm(EMPTY_FORM_STATE, hideSlidingPanel)
    } catch (er) {
      console.error(er)
      // TODO: Better error handling
      window.alert(er.message)
    }
  }

  const deleteDeliveryRecord = async (deliveryId, isDeleteDelivery) => {
    try {
      // we're going to mark isDeleted only on the object in memory after a successful save
      // this is a little hacky, but we don't want to reload the data until we need to
      if (isDeleteDelivery) {
        await deleteIncomingDelivery(deliveryId)
        let found = incomingPackages.find(d => d.id === deliveryId)
        found.isDeleted = true
        setIncomingPackages([...incomingPackages])
      } else {
        await unDeleteIncomingDelivery(deliveryId)
        let found = deliveredPackages.find(d => d.id === deliveryId)
        found.isDeleted = true
        setDeliveredPackages([...deliveredPackages])
      }

      setShouldReloadData(true)
      resetInitialForm(EMPTY_FORM_STATE, hideSlidingPanel)
    } catch (er) {
      console.error(er)
      // TODO: Better error handling
      window.alert(er.message)
    }
  }

  /**
   * @param {*} input
   * @returns {Promise<*>}
   */
  async function handleCreateNewDelivery(input) {
    return await CreateNewDelivery(input)
  }

  const createNewPackage = logAnother => {
    if (!validateForm()) {
      return
    }

    const input = {
      buildingId: activeBuildingId,
      carrier: form.carrier,
      notes: form.notes,
      packageType: form.packageType,
      priority: form.priority,
      trackingNumber: form.trackingNumber,
      residentId: form.residentId,
      unitId: form.unitId,
      staffId: authedUserId,
    }

    try {
      handleCreateNewDelivery(input).then(async result => {
        const deliveryPackage = result
        if (logAnother) {
          resetInitialForm({...EMPTY_FORM_STATE, carrier: form.carrier})
        } else {
          resetInitialForm(EMPTY_FORM_STATE, hideSlidingPanel)
        }
        // reload to reflect these changes to the table
        settingIncomingDeliveredPackages().then()
        return deliveryPackage
      })
    } catch (er) {
      console.error(er)
      window.alert('Sorry, we could not process this request. Please re-enter the package information.')
    }
  }

  // --------------------------------------------------------------------------------------------

  let visibleTableComponent

  if (isLoadingAllPackages) {
    visibleTableComponent = <LoadingStateIconAndMessage message={'Loading incoming deliveries...'} />
  } else if (tab === Constant.TAB_INCOMING_PACKAGES) {
    if (incomingPackages.length === 0) {
      visibleTableComponent = <EmptyStateIconAndMessage message={Constant.NO_INCOMING_PACKAGE_MESSAGE} icon={RXRIcon.DELIVERIES} />
    } else {
      visibleTableComponent = <IncomingPackagesTable incomingPackages={packagesFiltered} filterTerm={filterTerm} />
    }
  } else {
    if (deliveredPackages.length === 0) {
      visibleTableComponent = <EmptyStateIconAndMessage message={Constant.NO_PACKAGE_DELIVERED_MESSAGE} icon={RXRIcon.DELIVERIES} />
    } else {
      visibleTableComponent = <IncomingDeliveredPackagesTable deliveredPackages={packagesFiltered} filterTerm={filterTerm} />
    }
  }

  if (isLoadingDelivery) {
    return <Loader />
  }

  return (
    <div style={PageContainer}>
      <PageHeader
        title={Constant.TITLE}
        rightControlComponent={
          <RXRButton
            type={isMobile ? RXRButton.TYPE_DEFAULT : RXRButton.TYPE_LARGE}
            onClick={() => props.history.push(Routes.DELIVERIES_IN_CREATE)}
          >
            {isMobile ? Constant.ADD_BUTTON_MOBILE : Constant.ADD_BUTTON}
          </RXRButton>
        }
      >
        <Grid container spacing={3}>
          <Grid item lg={6} md={6} xs={12}>
            <div className={classes.placeSearch}>
              <ClearableInput value={filterTerm} onChange={setFilterTerm} placeholder={Constant.ENTER_SEARCH_KEYWORDS_CONSTANT} />
            </div>
          </Grid>

          <Grid item lg={6} md={6}>
            <div className={classes.dateFilterContainer}>
              {/* <DateInput onChange={setFilterDate} value={filterDate} /> */}
              <DateRangeInput
                onChange={(start, end) => {
                  setFilterDateStart(start)
                  setFilterDateEnd(end)
                }}
                startDate={filterDateStart}
                endDate={filterDateEnd}
              />
            </div>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item lg={6} md={6}>
            <AntTabs value={tab} onChange={(e, t) => setTab(t)}>
              <AntTab label={Constant.INCOMING_PACKAGES} />
              <AntTab label={Constant.DELIVERED_PACKAGES} />
            </AntTabs>
          </Grid>
        </Grid>
      </PageHeader>

      <DiscardChangesDialog hasChanges={formChanged} />

      <DiscardChangesDialog
        isOpen={deleteDiscardDialog || unmarkPackageDialog || unDeletePackageDialog}
        discardIcon={discardIcon}
        discardTitle={discardTitle}
        discardMessage={discardMessage}
        discardButton={discardButton}
        onDiscard={discardDialog}
        onCancel={() => {
          setDeleteDiscardDialog(false)
          setUnmarkPackageDialog(false)
          setUnDeletePackageDialog(false)
        }}
      />

      <div style={{height: '100%'}}>{visibleTableComponent}</div>

      <SidePanel
        isOpen={!!focusedDeliveryId || isCreateMode}
        onClose={hideSlidingPanel}
        title={focusedDeliveryObject.isDeleted ? 'Deleted package details' : isCreateMode ? 'New incoming package' : 'Package details'}
      >
        <IncomingDeliveriesForm {...form} updateForm={setForm} invalidItems={invalidItems} staffLookup={staffLookup} />
        {isCreateMode ? (
          <Grid container spacing={3} alignItems="center">
            <Grid item>
              <RXRButton onClick={() => createNewPackage(true)}>{Constant.BTN_SAVE_LOG_ANOTHER}</RXRButton>
            </Grid>
            <Grid item>
              <RXRButton onClick={() => createNewPackage(false)}>{Constant.BTN_SAVE}</RXRButton>
            </Grid>
            <Grid item>
              <RXRButton type={RXRButton.TYPE_TEXT} onClick={hideSlidingPanel}>
                {Constant.BTN_CANCEL}
              </RXRButton>
            </Grid>
          </Grid>
        ) : tab === Constant.TAB_INCOMING_PACKAGES ? (
          <Grid container spacing={3} alignItems="center">
            <Grid item>
              <RXRButton disabled={!formChanged} onClick={() => updateDeliveryRecord(false)}>
                {Constant.BTN_SAVE}
              </RXRButton>
            </Grid>
            <Grid item>
              <RXRButton
                type={RXRButton.TYPE_DESTRUCTIVE}
                className={classes.deleteAnnouncementButton}
                onClick={() => {
                  setDeleteDiscardDialog(true)
                  setDiscardIcon(null)
                  setDiscardTitle(Constant.DELETE_PACKAGE_DISCARD_TITLE)
                  setDiscardMessage(Constant.DELETE_PACKAGE_DISCARD_MESSAGE)
                  setDiscardButton(null)
                }}
              >
                {Constant.BTN_DELETE_PACKAGE}
              </RXRButton>
            </Grid>
            <Grid item className={classes.cursorHand}>
              <RXRButton type={RXRButton.TYPE_TEXT} onClick={hideSlidingPanel}>
                {Constant.BTN_CANCEL}
              </RXRButton>
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={3} alignItems="center">
            <Grid item>
              {focusedDeliveryObject.isDeleted ? (
                <React.Fragment>
                  <Typography className={classes.notificatinHeading}>{Constant.UNDELETE_NOTIFICATION_TEXT}</Typography>
                  <RXRButton
                    onClick={() => {
                      setUnDeletePackageDialog(true)
                      setDiscardIcon(RXRIcon.UNDO)
                      setDiscardTitle(Constant.UNDELETE_PACKAGE_DISCARD_TITLE)
                      setDiscardMessage(Constant.UNMARK_PACKAGE_DISCARD_MESSAGE)
                      setDiscardButton('Yes')
                    }}
                  >
                    {Constant.BTN_UNDELETE_DELIVERED}
                  </RXRButton>
                </React.Fragment>
              ) : (
                <RXRButton
                  onClick={() => {
                    setUnmarkPackageDialog(true)
                    setDiscardIcon(RXRIcon.UNDO)
                    setDiscardTitle(Constant.UNMARK_PACKAGE_DISCARD_TITLE)
                    setDiscardMessage(Constant.UNMARK_PACKAGE_DISCARD_MESSAGE)
                    setDiscardButton('Yes')
                  }}
                >
                  {Constant.BTN_UNMARK_DELIVERED}
                </RXRButton>
              )}
            </Grid>
          </Grid>
        )}
      </SidePanel>
    </div>
  )
}

// ------------------------------------------------------------------------------------------------------------
// GraphQL Queries & Mutations
// ------------------------------------------------------------------------------------------------------------

/**
 * @param {string} buildingId
 * @returns {Promise<*[]|Array<*>>}
 */
async function fetchDeliveryPackages(buildingId) {
  if (!buildingId) {
    return []
  }

  try {
    return await getIncomingDeliveriesForBuilding(buildingId)
  } catch (e) {
    if (e && Array.isArray(e.errors)) {
      throw new Error(e.errors[0].message)
    }
    throw e
  }
}

/**
 * @param {*} form
 * @returns {Promise<*>}
 */
async function pushUpdateDeliveryRecord(form) {
  return await updateIncomingDelivery({
    id: form.id,
    residentId: form.residentId,
    unitId: form.unitId,
    carrier: form.carrier,
    packageType: form.packageType,
    trackingNumber: form.trackingNumber,
    priority: form.priority,
    createdAt: form.arrivedOn,
    receivedAt: form.deliveredOn,
    notes: form.notes,
    deliveredDetails: form.deliveredDetails,
  })
}

const UNIT_KEYWORD = 'unit:'
/**
 * @param {Array<*>} items
 * @param {Date|null} filterDateStart
 * @param {Date|null} filterDateEnd
 * @param {string|null} filterTerm
 * @param {number} tab
 * @param {function} getResident
 */
function getFilteredItems(items, filterDateStart, filterDateEnd, filterTerm, tab, getResident) {
  const formattedStartDate = filterDateStart ? filterDateStart.toISOString() : null
  const formattedEndDate = filterDateEnd ? filterDateEnd.toISOString() : null
  const formattedTerm = filterTerm.toLowerCase()
  const dateProp = tab === Constant.TAB_INCOMING_PACKAGES ? 'createdAt' : 'deliveredAt'
  let unitFilter = null

  if (formattedTerm.indexOf(UNIT_KEYWORD) === 0) {
    unitFilter = formattedTerm.substring(UNIT_KEYWORD.length)
  }

  // then we filter the incomingPackages array according to the search terms
  return items.filter(g => {
    const resident = getResident(g.residentId)
    // filter by: resident name, unit number, package type, carrier and tracking number
    return (
      (!formattedStartDate || g[dateProp] >= formattedStartDate) &&
      (!formattedEndDate || g[dateProp] <= formattedEndDate) &&
      (unitFilter
        ? resident.occupancy.unit.number.toLowerCase().includes(unitFilter)
        : (g.trackingNumber && g.trackingNumber.toLowerCase().includes(formattedTerm)) ||
          g.packageType.toLowerCase().includes(formattedTerm) ||
          (g.carrier && g.carrier.toLowerCase().includes(formattedTerm)) ||
          resident.displayName.toLowerCase().includes(formattedTerm) ||
          resident.occupancy.unit.number.toLowerCase().includes(formattedTerm))
    )
  })
}

export default withRouter(IncomingViewDeliveriesPage)
