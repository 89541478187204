import React from 'react'
import {useSelector} from 'react-redux'
import PrefixComplete from '../PrefixComplete'
import {selectResidentsByUnit} from '../../Utils/residentUtils'
import PropTypes from 'prop-types'

export default function NamePicker(props) {
  const residentsLookup = useSelector(state => state.Residents.residentsLookup)
  let residentNames = []
  if (props.selectedPackageUnit) {
    residentNames = selectResidentsByUnit(residentsLookup, props.selectedPackageUnit).map(r => r.displayName)
  }

  return <PrefixComplete options={residentNames} onChange={props.setName} value={name} />
}

NamePicker.propTypes = {
  selectedPackageUnit: PropTypes.string,
  name: PropTypes.string,
  setName: PropTypes.func.isRequired,
}
