import React from 'react'
import {Typography} from '@mui/material'
import {Typography as TypographyStyle} from '../../assets/styles'
import {rxrBlueColor, rxrLightGreyColor, rxrDarkGreyColor} from '../../assets/styles/color'
import {makeStyles} from '@mui/styles'
import {spaceExtraSmall, spaceMedium} from '../../assets/styles/spacing'
import PropTypes from 'prop-types'
import VendorServiceDiscount from '../../lib/VendorServiceDiscount'
import {useSelector} from 'react-redux'
import {calculateTaxAndTotal} from '../../Utils/money'
import {calculateTaxAndTotalForVendorAppointment} from './servicesUtilities'

const rowStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}

const useStyles = makeStyles(theme => ({
  row: {marginBottom: spaceMedium},
  priceBreakdownContainer: {
    backgroundColor: rxrLightGreyColor,
    padding: spaceMedium,
  },

  priceRow: {
    ...rowStyles,
  },

  totalRow: {
    ...rowStyles,
    borderTop: `1px solid ${rxrDarkGreyColor}`,
    color: rxrBlueColor,
    marginTop: spaceExtraSmall,
    paddingTop: spaceExtraSmall,
  },

  priceLabel: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))

function CostBreakdown(props) {
  const classes = useStyles()

  const activeBuilding = useSelector(state => state.Buildings.buildingsLookup[state.Buildings.activeBuildingId])
  const basePrice = typeof props.priceOverride === 'number' ? props.priceOverride : props.service.price
  const taxAndTotal = calculateTaxAndTotalForVendorAppointment(activeBuilding, basePrice, props.addOns, props.discounts)

  return (
    <React.Fragment>
      <Typography style={{...TypographyStyle.H4HeaderWithoutBold, color: rxrBlueColor}}>Price breakdown:</Typography>
      <div className={classes.priceBreakdownContainer}>
        <div className={classes.priceRow}>
          <div>{props.service.label}</div>
          <div>${basePrice.toFixed(2)}</div>
        </div>
        {Array.isArray(props.addOns) &&
          props.addOns.map(a => {
            return (
              <div key={a.label} className={classes.priceRow}>
                <div className={classes.priceLabel}>{a.label}</div>
                <div>${a.cost.toFixed(2)}</div>
              </div>
            )
          })}
        {Array.isArray(props.discounts) &&
          props.discounts.map(d => {
            return (
              <div key={d.id} className={classes.priceRow}>
                <div className={classes.priceLabel}>{VendorServiceDiscount.toShortLabel(d)}</div>
                <div>-${taxAndTotal.priceAfterDiscounts.discountContributions[d.id].toFixed(2)}</div>
              </div>
            )
          })}
        <div className={classes.priceRow}>
          <div className={classes.priceLabel}>Tax ({activeBuilding.taxRate}%)</div>
          <div>${taxAndTotal.tax}</div>
        </div>

        <div className={classes.totalRow}>
          <div className={classes.priceLabel}>Total</div>
          <div>${taxAndTotal.total}</div>
        </div>
      </div>
    </React.Fragment>
  )
}

CostBreakdown.propTypes = {
  service: PropTypes.object,
  priceOverride: PropTypes.number,
  addOns: PropTypes.array,
  discounts: PropTypes.array,
}

export default CostBreakdown
