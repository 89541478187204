import React from 'react'
import PropTypes from 'prop-types'
import {Grid, Typography, IconButton} from '@mui/material'
import {makeStyles} from '@mui/styles'
import {Typography as TypographyStyle} from '../../assets/styles'

const useStyles = makeStyles(theme => ({
  title: {
    ...TypographyStyle.KYCSubHeaderTitle,
    textAlign: 'center',
  },
  rightIconButton: {
    textAlign: 'right',
    marginRight: 12,
  },
  leftIconButton: {
    textAlign: 'center',
    marginRight: 6,
  },
  buttonIconOutline: {
    '&:focus': {
      outline: 'none',
    },
  },
}))

function SubHeader({RightIconComponent, LeftIconComponent, ...props}) {
  const classes = useStyles()
  return (
    (<Grid container justifyContent="center" alignItems="center">
      <Grid item xs={3}>
        {LeftIconComponent && (
          <div className={classes.leftIconButton}>
            <LeftIconComponent />
          </div>
        )}
      </Grid>
      <Grid item xs={6}>
        <Typography className={classes.title}>{props.title}</Typography>
      </Grid>
      <Grid item xs={3}>
        {RightIconComponent && (
          <div className={classes.rightIconButton}>
            <RightIconComponent />
          </div>
        )}
      </Grid>
    </Grid>)
  );
}

SubHeader.propTypes = {
  title: PropTypes.string.isRequired,
  LeftIconComponent: PropTypes.func,
  RightIconComponent: PropTypes.func,
}

export default SubHeader
