import React from 'react'
import moment from 'moment'
import {useSelector} from 'react-redux'
import {makeStyles} from '@mui/styles'
import Grid from '@mui/material/Grid'
import {Typography as TypographyStyle, Colors} from './../../assets/styles'
import DateInput from '../DateInput'
import TextInput from '../TextInput'
import DateTimeInput from '../DateTimeInput'
import SelectResidentsByUnitTags from '../UnitTags/SelectResidentsByUnitTags'
import RichTextInput from '../RichTextInput'
import GenericFileSelector from '../GenericFileSelector'
import {pluralizeWord} from '../../Utils/StringFormatter'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  heading: {
    ...TypographyStyle.BodyHeader,
    lineHeight: '19px',
    marginBottom: '18px',
    color: Colors.rxrRedColor,
    fontSize: 12,
  },
}))

export default function AnnouncementsForm(props) {
  const classes = useStyles()

  const isEditing = !!props.id
  const groupPermissions = useSelector(state => state.GroupPermissions.permissionsObject)

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextInput
            label="Announcement title"
            isRequired={true}
            placeholder=""
            maxLength={120}
            onChange={val => props.updateForm({headline: val})}
            value={props.headline}
            isDisabled={!groupPermissions.announcementsWrite}
            error={props.invalidItems.includes('headline')}
          />
        </Grid>
        <Grid item xs={12}>
          {isEditing ? (
            <TextInput
              isDisabled={true}
              value={`${props.audienceLabel} (${pluralizeWord('resident', props.residentIds.length, true)})`}
              error={props.invalidItems.includes('residentIds')}
            />
          ) : (
            <SelectResidentsByUnitTags
              isDisabled={!groupPermissions.announcementsWrite}
              isRequired={true}
              label={'Send to'}
              onChange={(ids, label) => {
                props.updateForm({
                  residentIds: ids,
                  audienceLabel: label,
                })
              }}
              hasError={props.invalidItems.includes('residentIds')}
            />
          )}
        </Grid>
        <Grid item md={6} xs={12}>
          <DateTimeInput
            label="Posting date/time"
            value={props.activeStart}
            max={props.activeEnd ? moment(props.activeEnd).subtract(1, 'days').toDate() : null}
            onChange={date =>
              props.updateForm({
                activeStart: date,
              })
            }
            disabled={!groupPermissions.announcementsWrite}
            error={props.invalidItems.includes('activeStart')}
            isRequired={true}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <DateTimeInput
            label="Expiry date/time"
            value={props.activeEnd}
            min={props.activeStart ? moment(props.activeStart).add(1, 'days').toDate() : null}
            onChange={date =>
              props.updateForm({
                activeEnd: date,
              })
            }
            disabled={!groupPermissions.announcementsWrite}
            error={props.invalidItems.includes('activeEnd')}
            isRequired={true}
          />
        </Grid>
        <Grid item xs={12}>
          <RichTextInput
            label="Full announcement details"
            isRequired={true}
            placeholder=""
            value={props.htmlText || props.text} // need the || props.text in case it's an old announcement
            onChange={(valHTML, valText) => {
              props.updateForm({
                text: valText,
                htmlText: valHTML,
              })
            }}
            isDisabled={!groupPermissions.announcementsWrite}
            hasError={props.invalidItems.includes('text') || props.invalidItems.includes('htmlText')}
          />
        </Grid>
        <Grid item xs={12}>
          <GenericFileSelector
            maxFiles={3}
            value={props.attachments}
            onChange={a => props.updateForm({attachments: a})}
            isDisabled={!groupPermissions.announcementsWrite}
          />
        </Grid>
      </Grid>
    </div>
  )
}
