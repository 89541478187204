import React, {useState} from 'react'
import {makeStyles} from '@mui/styles'
import AllAmenitiesCalendarView from '../Amenities/AllAmenitiesCalendarView'
import IndividualAmenityCalendarView from '../Amenities/IndividualAmenityCalendarView'
import {useSelector} from 'react-redux'
import ToggleButtons from '../ToggleButtons'
import {spaceLarge, spaceMedium} from '../../assets/styles/spacing'
import {onboardingElevatorAmenityFilter} from './Onboarding'
import Routes from '../../constants/RouteConstants'

const TAB_SINGLE = 0
const TAB_ALL = 1

function ElevatorsCalendarView(props) {
  const isMobile = useSelector(state => state.App.isMobile)
  const classes = useStyles({isMobile})
  const [tab, setTab] = useState(TAB_SINGLE)

  const amenitiesLookup = useSelector(state => state.Amenities.amenitiesLookup)
  const filteredAmenities = Object.values(amenitiesLookup).filter(onboardingElevatorAmenityFilter)

  return (
    <div className={classes.mainContainer}>
      {filteredAmenities.length > 1 && (
        <div className={classes.toggleContainer}>
          <ToggleButtons selected={tab} onChange={setTab} buttonLabels={['Individual', 'All elevators']} inverseColors={true} />
        </div>
      )}
      <div className={classes.componentContainer}>
        {tab === TAB_ALL ? (
          <AllAmenitiesCalendarView amenitiesFilter={onboardingElevatorAmenityFilter} />
        ) : (
          <IndividualAmenityCalendarView
            amenitiesFilter={onboardingElevatorAmenityFilter}
            individualAmenityRoute={Routes.ELEVATOR_CALENDAR_VIEW_INDIVIDUAL_AMENITY}
          />
        )}
      </div>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  mainContainer: {
    position: 'relative',
  },
  toggleContainer: props => ({
    width: '260px',
    position: props.isMobile ? undefined : 'absolute',
    top: props.isMobile ? undefined : spaceLarge,
    right: props.isMobile ? undefined : spaceLarge,
    marginTop: props.isMobile ? spaceMedium : undefined,
    marginLeft: props.isMobile ? spaceLarge : undefined,
  }),
}))

export default ElevatorsCalendarView
