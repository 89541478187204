import React, {useState, useEffect} from 'react'
import {connect, useSelector} from 'react-redux'
import PropTypes from 'prop-types'
import {Grid, Typography, Button} from '@mui/material'
import {makeStyles} from '@mui/styles'
import {selectResidentsByUnit} from '../../Utils/residentUtils'
import TextInput from '../TextInput'
import SelectInput from '../SelectInput'
import DateInput from '../DateInput'
import CustomCheckbox from '../CustomCheckbox'
import MultilineTextInput from '../MultilineTextInput'
import {RXRButton} from '../../components/RXRButton'
import KYRGenericCardComponent from './KYRGenericCardComponent'
import SubHeader from './SubHeader'
import {navigateToScreen} from '../../actions/kyrWidgetActions'
import {setUpdateResident} from '../../actions/buildingsActions'
import {SCREEN_RESIDENT_PROFILE} from './KYRConstants'
import Loader from '../Loader'
import useFormChanged from '../hooks/useFormChanged'
import {Buttons} from '../../assets/styles'
import {KYRScreenLayout} from '../../assets/styles/layout'
import {spaceMedium} from '../../assets/styles/spacing'
import {selectAuthedUserId} from '../../reducers/selectors'

const useStyles = makeStyles(theme => ({
  loader: {
    paddingTop: '120px',
    backgroundColor: 'transparent ',
    opacity: 1,
  },
  scrollContainer: {
    ...KYRScreenLayout,
    marginLeft: '40px',
    paddingTop: 20,
    width: 330,
  },
  headerContainer: {
    paddingTop: 10,
    textAlign: 'center',
  },
  dataInputContainer: {
    paddingBottom: '24px',
  },
  pteTitle: {
    fontSize: 12,
    lineHeight: '18px',
    fontWeight: 'normal',
    fontStyle: 'normal',
  },
  pteDataContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  saveChangesCTA: {
    width: 'initial',
  },
  cancelButton: {
    marginLeft: spaceMedium,
  },
}))

const USER_GENDERS = [
  {value: 'male', label: 'Male'},
  {value: 'female', label: 'Female'},
  {value: 'non-binary', label: 'Non-binary'},
]

const USER_PACKAGE_PREFERANCES = [
  {value: 'frontDesk', label: 'Front Desk'},
  {value: 'inUnit', label: 'In Unit'},
]

const EMPTY_FORM_STATE = {
  name: null,
  gender: null,
  birthday: null,
  goesBy: null,
  phone: null,
  email: null,
  building: null,
  unit: null,
  roommate: null,
  cleaningPreferences: null,
  likes: null,
  dislikes: null,
  notes: null,
  packagePreference: null,
  maintenancePermission: null,
  cleaningPermission: null,
  deliveryPermission: null,
  thirdPartyPermission: null,
}

function EditResidentProfile(props) {
  const classes = useStyles()
  const [isUpdatingProfile, setIsUpdatingProfile] = useState(false)
  const {form, setForm, formChanged, resetInitialForm} = useFormChanged(EMPTY_FORM_STATE)

  let resident = props.resident ? props.resident : {occupancy: {unit: {}}}
  let userProfile
  if (resident && resident.userProfile) {
    userProfile = JSON.parse(resident.userProfile)
  }

  const residentsLookup = useSelector(state => state.Residents.residentsLookup)

  let roommates = []
  if (resident.occupancy && resident.occupancy.unit && resident.occupancy.unit.id) {
    roommates = selectResidentsByUnit(residentsLookup, resident.occupancy.unit.id)
      .map(r => r.displayName)
      .filter(r => r !== resident.displayName)
  }

  useEffect(() => {
    if (resident && resident.userProfile) {
      let initialForm = {
        name: resident.displayName,
        goesBy: userProfile.goesBy,
        gender: userProfile.gender,
        birthday: userProfile.birthday ? userProfile.birthday : '',
        phone: resident.phoneNumber,
        email: resident.email,
        building: props.activeBuilding ? props.activeBuilding.displayName : '',
        unit: resident.occupancy.unit.number,
        roommate: roommates.length > 0 ? roommates.join() : '',
        cleaningPreferences: userProfile.cleaningPreferences,
        likes: userProfile.likes,
        dislikes: userProfile.dislikes,
        notes: userProfile.notes,
        packagePreference: userProfile.packagePreference,
        maintenancePermission: userProfile.maintenancePermission,
        cleaningPermission: userProfile.cleaningPermission,
        deliveryPermission: userProfile.deliveryPermission,
        thirdPartyPermission: userProfile.thirdPartyPermission,
      }
      resetInitialForm(initialForm)
    }
  }, [resident])

  const updatingProfile = async () => {
    setIsUpdatingProfile(true)

    //this is to avoid over riding the fields that are not from the userProfile as the below mentioned fields are from resident object
    const dataToSave = {...form}
    delete dataToSave.name
    delete dataToSave.phone
    delete dataToSave.email
    delete dataToSave.unit

    let input = {
      id: props.navParams.residentId,
      userProfile: JSON.stringify({...userProfile, ...dataToSave}),
      email: form.email, //updating the user email from the widget is expected behaviour
    }

    await props
      .dispatchResidentUpdate(input)
      .then(() => {
        setIsUpdatingProfile(false)
        resetInitialForm(form, () => props.dispatchNavigateToResidentProfile(props.navParams.residentId))
      })
      .catch(err => {
        setIsUpdatingProfile(false)
        console.log('Error in Updating Profile', err)
      })
  }

  /**
   * function for rendeing Text Inputs.
   * @param {*} title
   * @param {*} fieldName
   * @param {*} isDisabled
   * @returns {TextInput}
   */
  const renderTextInput = (title, fieldName, isDisabled) => (
    <Grid container direction="column" className={classes.dataInputContainer}>
      <Grid item>
        <TextInput
          label={title}
          isDisabled={isDisabled}
          onChange={val => setForm({[fieldName]: val})}
          value={form[fieldName] ? form[fieldName] : ''}
        />
      </Grid>
    </Grid>
  )

  /**
   * function for rendeing MultiLine Text Inputs.
   * @param {*} title
   * @param {*} fieldName
   * @returns {MultilineTextInput}
   */
  const renderMultilineTextInput = (title, fieldName) => (
    <Grid container direction="column" className={classes.dataInputContainer}>
      <Grid item>
        <MultilineTextInput
          label={title}
          rows={3}
          value={form[fieldName] ? form[fieldName] : ''}
          onChange={val => setForm({[fieldName]: val})}
        />
      </Grid>
    </Grid>
  )

  /**
   * function for rendeing Dropdown Inputs.
   * @param {*} title
   * @param {*} fieldName
   * @param {*} options
   * @returns {renderSelectInput}
   */
  const renderSelectInput = (title, fieldName, options) => (
    <Grid container direction="column" className={classes.dataInputContainer}>
      <Grid item>
        <SelectInput
          label={title}
          options={options}
          value={form[fieldName] ? form[fieldName] : ''}
          onChange={val => setForm({[fieldName]: val})}
        />
      </Grid>
    </Grid>
  )

  /**
   * function for rendeing Date Inputs.
   * @param {*} title
   * @param {*} fieldName
   * @returns {DateInput}
   */
  const renderDateInput = (title, fieldName) => (
    <Grid container direction="column" className={classes.dataInputContainer}>
      <Grid item>
        <DateInput label={title} value={form[fieldName] ? form[fieldName] : ''} onChange={val => setForm({[fieldName]: val})} />
      </Grid>
    </Grid>
  )

  const renderSaveButton = title => (
    <Grid container style={{paddingBottom: '12px', justifyContent: 'center'}}>
      <Grid item>
        <RXRButton className={classes.saveChangesCTA} disabled={!formChanged} onClick={() => updatingProfile()}>
          {title}
        </RXRButton>
        <RXRButton
          type={RXRButton.TYPE_TEXT}
          className={classes.cancelButton}
          onClick={() => props.dispatchNavigateToResidentProfile(props.navParams.residentId)}
        >
          Cancel
        </RXRButton>
      </Grid>
    </Grid>
  )

  const renderPTE = () => (
    <Grid container direction="column" style={{paddingBottom: '24px'}}>
      <Grid item style={{marginLeft: 10}}>
        <div className={classes.pteTitle}>PTE preferences</div>
      </Grid>
      <Grid item className={classes.pteDataContainer}>
        <CustomCheckbox
          isChecked={!!form.maintenancePermission}
          onChange={checked => setForm({maintenancePermission: checked ? new Date().toISOString() + ';' + props.authedUserId : false})}
          name="maintenancePermission"
          label="Maintenance"
        />
      </Grid>
      <Grid item className={classes.pteDataContainer}>
        <CustomCheckbox
          isChecked={!!form.cleaningPermission}
          onChange={checked => setForm({cleaningPermission: checked ? new Date().toISOString() + ';' + props.authedUserId : false})}
          name="cleaningPermission"
          label="Cleaning"
        />
      </Grid>
      <Grid item className={classes.pteDataContainer}>
        <CustomCheckbox
          isChecked={!!form.deliveryPermission}
          onChange={checked => setForm({deliveryPermission: checked ? new Date().toISOString() + ';' + props.authedUserId : false})}
          name="deliveryPermission"
          label="Package delivery"
        />
      </Grid>
      <Grid item className={classes.pteDataContainer}>
        <CustomCheckbox
          isChecked={!!form.thirdPartyPermission}
          onChange={checked => setForm({thirdPartyPermission: checked ? new Date().toISOString() + ';' + props.authedUserId : false})}
          name="thirdPartyPermission"
          label="3rd party service provider"
        />
      </Grid>
    </Grid>
  )

  return (
    <KYRGenericCardComponent residentId={props.navParams.residentId}>
      <div className={classes.headerContainer}>
        <SubHeader title={'Edit Resident Profile'} />
      </div>
      {!userProfile ? (
        <div className={classes.headerContainer}>Resident profile not Editable.</div>
      ) : isUpdatingProfile ? (
        <Loader className={classes.loader} />
      ) : (
        <div className={classes.scrollContainer}>
          {renderTextInput('Name', 'name', true)}
          {renderTextInput('Goes by', 'goesBy', false)}
          {renderSelectInput('Gender', 'gender', USER_GENDERS)}
          {renderDateInput('Birthday', 'birthday')}
          {renderTextInput('Phone number', 'phone', true)}
          {renderTextInput('Email', 'email', false)}
          {renderTextInput('Building', 'building', true)}
          {renderTextInput('Unit', 'unit', true)}
          {renderTextInput('Roommate', 'roommate', true)}
          {renderSelectInput('Packages', 'packagePreference', USER_PACKAGE_PREFERANCES)}
          {renderPTE()}
          {renderMultilineTextInput('Cleaning preferences', 'cleaningPreferences')}
          {renderMultilineTextInput('Likes', 'likes')}
          {renderMultilineTextInput('Dislikes', 'dislikes')}
          {renderMultilineTextInput('Notes', 'notes')}
          {renderSaveButton('Save changes')}
        </div>
      )}
    </KYRGenericCardComponent>
  )
}

EditResidentProfile.propTypes = {
  residentId: PropTypes.string.isRequired,
}

const mapDispatchToProps = dispatch => ({
  dispatchResidentUpdate: resident => setUpdateResident(dispatch, resident),
  dispatchNavigateToResidentProfile: residentId => navigateToScreen(dispatch, SCREEN_RESIDENT_PROFILE, {residentId: residentId}),
})

const mapStateToProps = (state, ownProps) => ({
  resident: state.Residents.residentsLookup[ownProps.navParams.residentId],
  activeBuilding: state.Buildings.buildingsLookup[state.Buildings.activeBuildingId],
  authedUserId: selectAuthedUserId(state),
})

export default connect(mapStateToProps, mapDispatchToProps)(EditResidentProfile)
