import ActionTypes from '../actions/types'

const initialState = {
  chatParticipantsLookup: {},
}

// --------------------------------------------------------------------------------
// State permutation functions:

function reduceChatParticipantsListToObj(state, chatParticipantsList) {
  return {
    ...state,
    chatParticipantsLookup: chatParticipantsList.reduce((acc, chatParticipant) => {
      acc[chatParticipant.id] = chatParticipant
      return acc
    }, {}),
  }
}

// --------------------------------------------------------------------------------

export default function(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.CHAT_PARTICIPANTS_UPDATE_ALL:
      return reduceChatParticipantsListToObj(state, action.payload)

    default:
      return state
  }
}
