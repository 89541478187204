import React from 'react'
import {rxrMidBlue} from '../../../assets/styles/color'
import SubHeader from '../SubHeader'
import KYRGenericCardComponent from '../KYRGenericCardComponent'
import {KYRScreenLayout} from '../../../assets/styles/layout'
import MaintenanceDetail from '../MaintenanceDetail'
import AbstractKYRScreen from './AbstractKYRScreen'
import {withStyles} from '@mui/styles'
import {SCREEN_TIMELINE} from '../KYRConstants'
import {navigateToScreen} from '../../../actions/kyrWidgetActions'
import {connect} from 'react-redux'
import {compose} from 'redux'
import KYRWidgetBackButton from '../KYRWidgetBackButton'

const styles = theme => ({
  headerContainer: {
    width: '400px',
    backgroundColor: rxrMidBlue,
    minHeight: '154px',
    borderRadius: '10px 10px 0px 0px',
    boxShadow: '0px 1px 18px rgba(151, 151, 151, 0.2)',
    justifyContent: 'space-around',
  },
  buttonIconOutline: {
    '&:focus': {
      outline: 'none',
    },
    padding: '6px',
  },
  scrollContainer: {
    ...KYRScreenLayout,
    marginLeft: '40px',
  },
})

class MaintenanceComponent extends AbstractKYRScreen {
  render() {
    return (
      <KYRGenericCardComponent residentId={this.props.navParams.residentId}>
        <SubHeader title={'Maintenance request'} LeftIconComponent={KYRWidgetBackButton} />
        <div className={this.props.classes.scrollContainer}>
          <MaintenanceDetail serviceRequestId={this.props.navParams.primaryRecordId} />
        </div>
      </KYRGenericCardComponent>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  dispatchNavigateToTimeline: residentId => navigateToScreen(dispatch, SCREEN_TIMELINE, {residentId: residentId}),
})

export default compose(withStyles(styles), connect(undefined, mapDispatchToProps))(MaintenanceComponent)
