import React, {useState} from 'react'
import PropTypes from 'prop-types'
import SelectInput from '../SelectInput'
import {convertKeyLabelMapIntoSelectOptions} from '../../Utils/objectUtil'
import {FrequencyLabels, FREQUENCY_ONCE} from './ServicesConstants'
import {makeStyles} from '@mui/styles'
import {spaceMedium} from '../../assets/styles/spacing'
import moment from 'moment'
import {getFrequencyQualities} from './servicesUtilities'
import {rxrWhiteColor, rxrGreyColor, rxrBlueColor} from '../../assets/styles/color'

const FrequencyOptionsArray = convertKeyLabelMapIntoSelectOptions(FrequencyLabels)

// --------------------------------------------------------------------------------------------------
// Copied from ui/components/VendorServices/BookingSteps/Comps/Scheduling/SelectFrequencyDays.js
/**
 * @param {string} base
 * @param {number} magnitude
 * @param {Array<number>?} weekDays
 * @returns {string}
 */
function constructFrequencyFromParts(base, magnitude, weekDays) {
  let retVal = `${base}_${magnitude}`

  if (weekDays && weekDays.length > 0) {
    retVal += `:${weekDays.sort().join('')}`
  }

  return retVal
}

// this will construct an array of "Su", "Mo", etc ...
const dayLabels = Array.from(Array(7).keys()).map(dow => moment().startOf('week').add(dow, 'days').format('dd'))
// --------------------------------------------------------------------------------------------------

const useStyles = makeStyles(theme => ({
  weekdayPickerContainer: {
    marginTop: spaceMedium,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  dayCircle: {
    backgroundColor: rxrGreyColor,
    color: rxrBlueColor,
    width: 36,
    height: 36,
    borderRadius: '50%',
    cursor: 'pointer',
    textAlign: 'center',
    lineHeight: '36px',
  },
}))

function FrequencyBuilder(props) {
  const classes = useStyles()
  const forcedDay = props.startingDate ? parseInt(moment(props.startingDate).format('d')) : null
  const [base, magnitude, days] = getFrequencyQualities(props.frequency, props.startingDate)

  const handleChangeSelector = newValue => {
    props.onChange(newValue)
  }

  const handleToggleDay = dow => {
    const newDays = days.includes(dow) ? days.filter(d => d !== dow) : [...days, dow]

    // reconstruct our frequency value with the new days array
    props.onChange(constructFrequencyFromParts(base, magnitude, newDays))
  }

  const selectorValue = typeof magnitude === 'number' && magnitude !== 1 ? constructFrequencyFromParts(base, magnitude) : base

  return (
    <div>
      <SelectInput
        label={'Repeat frequency'}
        disabled={props.isDisabled} // cannot edit frequency for an existing cleaning yet
        value={selectorValue}
        onChange={handleChangeSelector}
        options={FrequencyOptionsArray}
      />

      {base !== FREQUENCY_ONCE && (
        <div className={classes.weekdayPickerContainer}>
          {dayLabels.map((label, dow) => {
            const isSelected = days.includes(dow) || dow === forcedDay
            const isDisabled = dow === forcedDay || props.isDisabled

            return (
              <div
                key={label}
                className={classes.dayCircle}
                style={{
                  ...(isDisabled
                    ? {
                        cursor: 'not-allowed',
                      }
                    : undefined),
                  ...(isSelected
                    ? {
                        backgroundColor: rxrBlueColor,
                        color: rxrWhiteColor,
                      }
                    : undefined),
                }}
                onClick={isDisabled ? undefined : () => handleToggleDay(dow)}
              >
                {label}
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

FrequencyBuilder.propTypes = {
  frequency: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  startingDate: PropTypes.instanceOf(Date),
  isDisabled: PropTypes.bool,
}

export default FrequencyBuilder
