import React, {useState} from 'react'
import {makeStyles} from '@mui/styles'
import {spaceMedium} from '../../assets/styles/spacing'
import PropTypes from 'prop-types'
import FileHelper from '../../lib/FileHelper'
import AttachedFile from './AttachedFile'
import ConfirmDeleteDialog from '../ConfirmDeleteDialog'

function SyncedFiles(props) {
  const classes = useStyles(props)
  const [isRemovingSyncedAttachment, setIsRemovingSyncedAttachment] = useState(false)
  const [toBeRemovedSyncedAttachmentIndex, setToBeRemovedSyncedAttachmentIndex] = useState(null)

  const filesArray = Array.isArray(props.value) ? props.value : []

  const handleRemoveAttachment = index => {
    setIsRemovingSyncedAttachment(true)
    setToBeRemovedSyncedAttachmentIndex(index)
  }

  const onConfirmDelete = () => {
    setIsRemovingSyncedAttachment(false)
    let newFiles = [...filesArray]
    newFiles.splice(toBeRemovedSyncedAttachmentIndex, 1)
    props.onChange(newFiles.map(obj => FileHelper.formatS3ObjectForInput(obj)))
    setToBeRemovedSyncedAttachmentIndex(null)
  }

  return (
    <div className={classes.syncedFiles}>
      {filesArray.length > 0 && (
        <div className={classes.attachmentList}>
          {filesArray.map((file, idx) => {
            return (
              <AttachedFile
                key={`${file.displayName}-${idx}`}
                file={file}
                handleRemoveAttachment={handleRemoveAttachment}
                idx={idx}
                renderTrashIconOnHover={true}
              />
            )
          })}
        </div>
      )}
      <ConfirmDeleteDialog
        prompt={'This file will be removed from our database but must be manually deleted from Yardi. Are you sure you want to continue?'}
        isOpen={isRemovingSyncedAttachment}
        onConfirmDelete={onConfirmDelete}
        onCancel={() => setIsRemovingSyncedAttachment(false)}
        deleteButtonText={'Remove attachment'}
        cancelButtonText={'Cancel'}
      />
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  syncedFiles: {},
  attachmentList: {
    marginTop: spaceMedium,
  },
}))

SyncedFiles.propTypes = {
  value: PropTypes.array, // Array of S3 objects
  onChange: PropTypes.func.isRequired, // callback invoked with the selected files prop
  isDisabled: PropTypes.bool,
}

export default SyncedFiles
