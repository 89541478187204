import React, {useEffect, useState, Fragment} from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@mui/styles'
import {KYRLoaderScreen} from '../../../assets/styles/layout'
import moment from 'moment'
import Loader from '../../Loader'
import KYRDataField from '../KYRDataField'
import {capitalizeFirstLetter} from '../../../Utils/StringFormatter'
import {getVendorAppointmentInfo} from '../../../lib/queries'
import TimelineEvent from '../TimelineEvent'
import {formatTimeRanges} from '../../../Utils/dateTimeUtil'

const FREQUENCY_OPTIONS = [
  {
    name: 'weekly_1',
    label: 'Weekly',
  },
  {
    name: 'weekly_2',
    label: 'Every 2 weeks',
  },
  {
    name: 'weekly_4',
    label: 'Every 4 weeks',
  },
]

const useStyles = makeStyles(theme => ({
  loader: {
    ...KYRLoaderScreen,
  },
}))

function VendorAppointment(props) {
  const classes = useStyles()
  const [vendorAppointmentInfo, setVendorAppointmentInfo] = useState({})
  const [isVendorAppointmentInfoLoading, setVendorAppointmentInfoLoading] = useState(true)

  useEffect(() => {
    getVendorAppointmentInfo(props.vendorAppointmentId)
      .then(res => {
        setVendorAppointmentInfo(res)
        setVendorAppointmentInfoLoading(false)
      })
      .catch(err => console.error(err))
  }, [])

  const timeFrameDetails = (
    <div>
      {formatTimeRanges(vendorAppointmentInfo.startAt, vendorAppointmentInfo.endAt)}
      <br />
      {moment(vendorAppointmentInfo.startAt).format('dddd, MMMM DD')}
    </div>
  )

  let frequencyLabel = props.frequencyLabel
  if (vendorAppointmentInfo && !!vendorAppointmentInfo.recurringVendorAppointmentId) {
    let frequencyMatching = FREQUENCY_OPTIONS.find(o => o.name === vendorAppointmentInfo.recurringVendorAppointment.frequency)
    frequencyLabel = frequencyMatching ? frequencyMatching.label : ''
  }

  let priceDetails = `${'$'}${vendorAppointmentInfo.cost}`
  if (vendorAppointmentInfo && vendorAppointmentInfo.paymentId) {
    priceDetails = priceDetails + ', paid'
  }

  return (
    <Fragment>
      {isVendorAppointmentInfoLoading ? (
        <Loader className={classes.loader} />
      ) : (
        <div>
          <KYRDataField title="Status" data={capitalizeFirstLetter(vendorAppointmentInfo.status)} />
          <KYRDataField title={props.label} data={capitalizeFirstLetter(vendorAppointmentInfo.vendorService.label)} />
          <KYRDataField title={props.timeframe} data={timeFrameDetails} />
          <KYRDataField title="Frequency" data={frequencyLabel} />
          <KYRDataField title="Resident’s instructions" data={vendorAppointmentInfo.notes} />
          <KYRDataField title="Price" data={priceDetails} />
          <TimelineEvent primaryRecordId={props.vendorAppointmentId} />
        </div>
      )}
    </Fragment>
  )
}

VendorAppointment.propTypes = {
  vendorAppointmentId: PropTypes.string.isRequired,
  frequencyLabel: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  timeframe: PropTypes.string.isRequired,
}

export default VendorAppointment
