import React, {useState} from 'react'
import moment from 'moment'
import SearchResident from '../SearchResident'
import SelectInput from '../SelectInput'
import DateInput from './../DateInput'
import {Grid, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import {H4HeaderWithoutBold} from '../../assets/styles/typography'
import {rxrRedColor} from '../../assets/styles/color'
import {spaceMedium} from '../../assets/styles/spacing'
import MultilineTextInput from '../MultilineTextInput'
import {useSelector} from 'react-redux'
import PropTypes from 'prop-types'
import {RXRButton} from '../RXRButton'
import Constant from './AmenitiesConstants'
import DiscardChangesDialog from '../DiscardChangesDialog'
import RXRIcon from '../RXRIcon'
import ConfirmDeleteDialog from '../ConfirmDeleteDialog'
import {reservationNeedsApproval, reservationIsUpcoming, reservationIsPast} from './ViewAmenityReservationsPage'
import useAmenityReservationScheduling from './useAmenityReservationScheduling'
import TextInput from '../TextInput'
import ToggleButtons from '../ToggleButtons'

import {chunkAvailabilityTimeRanges} from './amenityCalendarHelpers'

const useStyles = makeStyles(theme => ({
  container: {
    flexGrow: 1,
    marginBottom: 22,
  },
  userTypeToggleContainer: {
    width: 240,
  },
  errorText: {
    marginTop: spaceMedium,
    color: rxrRedColor,
  },
}))

function CompactAmenityReservationEditor(props) {
  const classes = useStyles()
  const [showDenyRequestDialog, setShowDenyRequestDialog] = useState(false)
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [timeRanges, setTimeRanges] = useState([])
  const [selectedTimeRange, setSelectedTimeRange] = useState(null)

  const amenitiesLookup = useSelector(state => state.Amenities.amenitiesLookup)

  const {
    isEditMode,
    form,
    invalidItems,
    setForm,
    focusedReservation,
    formChanged,
    isLoadingCalendar,
    isSaving,
    saveError,
    saveOrApprove,
    makeReservation,
    handleReservationDate,
    loadMonthData,
    shouldDisableDate,
    deleteRequest,
    isFormComplete,
    updateRequest,
    resetForm,
    reservationDate,
    isLoadingAmenityDetails,
    isPlacingStaffReservation,
    setIsPlacingStaffReservation,
    chatResident,
    denyRequest,
  } = useAmenityReservationScheduling(props, (_, amenityCalendarDetail) => {
    const timeRangeChunks = chunkAvailabilityTimeRanges(
      amenityCalendarDetail.availability,
      amenityCalendarDetail.generalAvailability,
      amenityCalendarDetail.minReservationDuration,
      amenityCalendarDetail.minReservationDuration,
    )
    const timeRangeOptions = timeRangeChunks.map((range, index) => ({
      value: '' + index,
      label: `${moment(range.start).format('hh:mm A')} - ${moment(range.end).format('hh:mm A')}`,
    }))

    setTimeRanges(timeRangeOptions)
  })

  const handleSelectTimeRange = indexOfSelectedTimeRange => {
    // Extract the start and end times from the selected time range
    const timeRange = timeRanges[parseInt(indexOfSelectedTimeRange)]
    const [startTimeString, endTimeString] = timeRange.label.split(' - ')

    // Create a moment object from the reservationDate string
    const reservationDateMoment = moment(reservationDate)

    // Combine the reservation date with the start and end times and convert to ISO strings
    const startAt = reservationDateMoment
      .clone()
      .hour(moment(startTimeString, 'hh:mm A').hour())
      .minute(moment(startTimeString, 'hh:mm A').minute())
      .toDate()
    const endAt = reservationDateMoment
      .clone()
      .hour(moment(endTimeString, 'hh:mm A').hour())
      .minute(moment(endTimeString, 'hh:mm A').minute())
      .toDate()

    // Update the form
    setForm({startAt, endAt})

    // Update the time range state variable
    setSelectedTimeRange(indexOfSelectedTimeRange)
  }

  const filteredAmenities =
    typeof props.amenitiesFilter === 'function'
      ? Object.values(amenitiesLookup).filter(props.amenitiesFilter)
      : Object.values(amenitiesLookup)
  const amenitiesOptions = filteredAmenities.map(a => ({
    value: a.id,
    label: a.label,
  }))
  const isReservationSystemControlled = !!focusedReservation && focusedReservation.isSystemControlled
  const isReservationPast = !!focusedReservation && reservationIsPast(focusedReservation)

  const isSelectTimeDisabled = !!(!form.partySize || !reservationDate || isReservationSystemControlled || isReservationPast)

  return (
    <React.Fragment>
      <div className={classes.container}>
        {isReservationSystemControlled && (
          <Typography style={{...H4HeaderWithoutBold, color: rxrRedColor, marginBottom: spaceMedium}}>
            This reservation is controlled by the system and cannot be modified
          </Typography>
        )}
        {!isEditMode ? (
          <div className={classes.userTypeToggleContainer}>
            <ToggleButtons
              selected={isPlacingStaffReservation ? 1 : 0}
              buttonLabels={['Resident', 'Staff']}
              onChange={v => setIsPlacingStaffReservation(!!v)}
              inverseColors={true}
            />
          </div>
        ) : null}

        {props.amenityId ? null : (
          <div style={{marginTop: spaceMedium}}>
            <Grid container spacing={3}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <SelectInput
                  onChange={id => setForm({amenityId: id})}
                  label={'Amenity'}
                  isRequired={true}
                  options={amenitiesOptions}
                  value={form.amenityId}
                  disabled={!!(isReservationSystemControlled || isEditMode || (props.initialValues && props.initialValues.amenityId))}
                  error={invalidItems.includes('amenityId')}
                />
              </Grid>
            </Grid>
          </div>
        )}

        <Grid container spacing={3}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            {!isPlacingStaffReservation ? (
              <SearchResident
                value={form.residentId}
                isRequired={true}
                disabled={isEditMode}
                onSelectResident={r => setForm({residentId: r ? r.id : null})}
                error={invalidItems.includes('residentId')}
              />
            ) : (
              <TextInput
                isRequired={true}
                error={invalidItems.includes('title')}
                label="Title"
                value={form.title}
                onChange={val => setForm({title: val})}
              />
            )}
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <DateInput
              onChange={handleReservationDate}
              onMonthChange={loadMonthData}
              shouldDisableDate={shouldDisableDate}
              min={moment().startOf('day').toDate()}
              label={'Reservation date'}
              isRequired={true}
              value={reservationDate}
              disabled={isReservationSystemControlled || isReservationPast}
              error={invalidItems.includes('startAt')}
              isLoading={isLoadingAmenityDetails}
            />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            {isReservationPast ? (
              <TextInput
                isDisabled={true}
                value={`${moment(form.startAt).format('hh:mm A')} - ${moment(form.endAt).format('hh:mm A')}`}
                label={'Time Range'}
              />
            ) : (
              <SelectInput
                label="Time Range"
                value={selectedTimeRange}
                options={timeRanges}
                isLoading={isLoadingCalendar}
                onChange={handleSelectTimeRange}
                isRequired={true}
                isDisabled={isSelectTimeDisabled}
              />
            )}
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <MultilineTextInput
              label="Notes"
              placeholder=""
              rows={4}
              rowsMax={4}
              isRequired={false}
              maxLength={2000}
              onChange={val => setForm({notes: val})}
              isDisabled={isReservationSystemControlled || isReservationPast}
              value={form.notes}
              error={invalidItems.includes('notes')}
            />
          </Grid>
        </Grid>
      </div>
      {saveError ? <div className={classes.errorText}>{`${saveError}`}</div> : null}
      <Grid style={{marginTop: spaceMedium}}>
        {/* Create mode CTA's */}
        {!props.amenityReservationId && (
          <Grid container spacing={3} alignItems="center">
            <Grid item>
              <RXRButton onClick={() => makeReservation(true)} isLoading={isSaving}>
                {Constant.BTN_SAVE}
              </RXRButton>
            </Grid>
            <Grid item className={classes.cursorHand}>
              <RXRButton type={RXRButton.TYPE_TEXT} onClick={resetForm}>
                {Constant.BTN_CANCEL}
              </RXRButton>
            </Grid>
          </Grid>
        )}
        {/* Edit mode needs approval CTA's */}
        {!!props.amenityReservationId && reservationNeedsApproval(form) && (
          <Grid container spacing={3} alignItems="center">
            <Grid item>
              <RXRButton onClick={() => saveOrApprove()} disabled={!isFormComplete(form)} isLoading={isSaving}>
                {formChanged ? Constant.BTN_SAVE_AND_APPROVE_REQUEST : Constant.BTN_APPROVE_REQUEST}
              </RXRButton>
            </Grid>
            <Grid item>
              <RXRButton type={RXRButton.TYPE_DESTRUCTIVE} onClick={() => setShowDenyRequestDialog(true)} isLoading={isSaving}>
                {Constant.BTN_DENY_REQUEST}
              </RXRButton>
            </Grid>
            <Grid item className={classes.cursorHand}>
              <RXRButton type={RXRButton.TYPE_TEXT} onClick={resetForm}>
                {Constant.BTN_CANCEL}
              </RXRButton>
            </Grid>
          </Grid>
        )}
        {/* Edit mode past CTA's */}
        {!!props.amenityReservationId && reservationIsUpcoming(form) && (
          <Grid container spacing={3} alignItems="center">
            <RXRButton
              onClick={() => updateRequest()}
              disabled={!isFormComplete(form) || form.isSystemControlled || !formChanged}
              isLoading={isSaving}
            >
              {Constant.BTN_UPDATE_REQUEST}
            </RXRButton>

            <Grid item>
              <RXRButton onClick={() => chatResident()}>{Constant.BTN_CHAT_RESIDENT}</RXRButton>
            </Grid>

            <Grid item>
              <RXRButton
                type={RXRButton.TYPE_DESTRUCTIVE}
                onClick={() => setShowDeleteDialog(true)}
                disabled={form.isSystemControlled}
                isLoading={isSaving}
              >
                {Constant.BTN_DELETE}
              </RXRButton>
            </Grid>
          </Grid>
        )}
        {/* Edit mode past CTA's */}
        {!!props.amenityReservationId && reservationIsPast(form) && (
          <Grid container spacing={3} alignItems="center">
            <Grid item>
              <RXRButton onClick={() => chatResident()}>{Constant.BTN_CHAT_RESIDENT}</RXRButton>
            </Grid>
          </Grid>
        )}
      </Grid>
      <DiscardChangesDialog
        isOpen={showDenyRequestDialog}
        discardIcon={RXRIcon.NOT_ALLOWED}
        discardTitle={'Deny request'}
        discardMessage={'Are you sure you want to deny this request?'}
        discardButton={'Deny'}
        onCancel={() => {
          setShowDenyRequestDialog(false)
        }}
        onDiscard={async () => {
          await denyRequest()
        }}
      />
      <DiscardChangesDialog hasChanges={formChanged} onDiscard={resetForm} />
      <ConfirmDeleteDialog
        isOpen={showDeleteDialog}
        prompt={'Are you sure you want to delete this reservation?'}
        onConfirmDelete={deleteRequest}
        onCancel={() => setShowDeleteDialog(false)}
      />
    </React.Fragment>
  )
}

CompactAmenityReservationEditor.propTypes = {
  onComplete: PropTypes.func.isRequired,
  amenityReservationId: PropTypes.string,
  initialValues: PropTypes.object,
  onUpdateForm: PropTypes.func,
  amenitiesFilter: PropTypes.func,
}

export default CompactAmenityReservationEditor
