import React from 'react'
import {makeStyles} from '@mui/styles'
import IndividualAmenityCalendarView from '../Amenities/IndividualAmenityCalendarView'
import {spaceLarge} from '../../assets/styles/spacing'
import {onboardingKeyPickupAmenityFilter} from './Onboarding'

function KeyPickupCalendarView(props) {
  const classes = useStyles()

  return (
    <div className={classes.mainContainer}>
      <IndividualAmenityCalendarView amenitiesFilter={onboardingKeyPickupAmenityFilter} />
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  mainContainer: {
    position: 'relative',
  },
  toggleContainer: {
    width: '260px',
    position: 'absolute',
    top: spaceLarge,
    right: spaceLarge,
  },
}))

export default KeyPickupCalendarView
