import React, {useState} from 'react'
import {Grid} from '@mui/material'
import {makeStyles} from '@mui/styles'
import {spaceLarge, spaceMedium, spaceSmall} from '../../../assets/styles/spacing'
import {H4HeaderWithoutBold} from '../../../assets/styles/typography'
import TextInput from '../../TextInput'
import PropTypes from 'prop-types'
import DashboardToggle from '../../DashboardToggle'
import MultilineTextInput from '../../MultilineTextInput'
import {Colors} from '../../../assets/styles'
import ImageGallerySelector from '../../ImageGallerySelector'
import DefaultPartnerLogoSelector from './DefaultPartnerLogoSelector'

function PartnerForm({partner, onChange, invalidItems}) {
  const classes = useStyles()
  const [openPicker, setOpenPicker] = useState(false)

  const handleSelectDefaultLogo = o => {
    onChange({logo: o})
    setOpenPicker(false)
  }

  return (
    <div>
      <Grid container spacing={3} className={classes.row}>
        <Grid item xs={12}>
          <TextInput
            label={'Partner Name'}
            value={partner.displayName}
            onChange={v => onChange({displayName: v})}
            maxLength={20}
            error={invalidItems.includes('displayName')}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3} className={classes.row}>
        <Grid item xs={12}>
          <p style={{color: Colors.rxrBlueColor}}>Homepage feature</p>
          <DashboardToggle
            label={partner.isFeatured ? 'Featured on homepage' : 'Not featured'}
            value={partner.isFeatured}
            onChange={v => onChange({isFeatured: v})}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3} className={classes.row}>
        <Grid item xs={12}>
          <TextInput
            label={'CTA Text'}
            value={partner.callToAction}
            onChange={v => onChange({callToAction: v})}
            error={invalidItems.includes('callToAction')}
            maxLength={20}
          />
        </Grid>
      </Grid>

      <div className={classes.contactContainer}>
        <p style={{color: Colors.rxrBlueColor, marginBottom: spaceMedium}}>Contact options (at least 1 is required)</p>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <TextInput label={'Email'} value={partner.email} onChange={v => onChange({email: v})} error={invalidItems.includes('email')} />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              label={'Phone number'}
              value={partner.phoneNumber}
              onChange={v => onChange({phoneNumber: v})}
              error={invalidItems.includes('phoneNumber')}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              label={'Website'}
              value={partner.landingUrl}
              onChange={v => onChange({landingUrl: v})}
              error={invalidItems.includes('landingUrl')}
              helperText={'Must start with http:// or https://'}
            />
          </Grid>
        </Grid>
      </div>

      <Grid container spacing={3} className={classes.row}>
        <Grid item xs={12}>
          <MultilineTextInput
            label={'Short Description, Homepage'}
            error={invalidItems.includes('shortDescription')}
            value={partner.shortDescription}
            onChange={v => onChange({shortDescription: v})}
            maxLength={70}
            rowsMax={2}
            rows={3}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3} className={classes.row}>
        <Grid item xs={12}>
          <MultilineTextInput
            label={'Full Description, Details Page'}
            error={invalidItems.includes('description')}
            value={partner.description}
            onChange={v => onChange({description: v})}
            maxLength={1000}
            rowsMax={10}
            rows={6}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3} className={classes.row}>
        <Grid item xs={12} md={6} style={{position: 'relative'}}>
          <ImageGallerySelector
            helperText={'Attach a logo (.png or .jpg)'}
            maxPhotos={1}
            value={partner.logo ? [partner.logo] : []}
            onChange={v => onChange({logo: v.length > 0 ? v[0] : null})}
            isRequired={true}
            error={invalidItems.includes('logo')}
          />

          <p onClick={() => setOpenPicker(!openPicker)} className={classes.selectLogoText}>
            If no logo is available, select one here
          </p>

          {openPicker && <DefaultPartnerLogoSelector className={classes.logoPicker} onSelectLogo={handleSelectDefaultLogo} />}
        </Grid>
        <Grid item xs={12} md={6}>
          <ImageGallerySelector
            helperText={'Attach a cover image (.png or .jpg)'}
            maxPhotos={1}
            value={partner.coverImage ? [partner.coverImage] : []}
            onChange={v => onChange({coverImage: v.length > 0 ? v[0] : null})}
            isRequired={true}
            error={invalidItems.includes('coverImage')}
          />
        </Grid>
      </Grid>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  row: {},
  contactContainer: {
    padding: `${spaceSmall}px ${spaceMedium}px`,
    border: `1px dashed ${Colors.rxrBlueColor}`,
    borderRadius: 4,
    margin: `${spaceMedium}px 0`,
    backgroundColor: Colors.whiteOverlay50,
  },
  warning: {
    padding: spaceSmall,
    border: `1px solid ${Colors.rxrRedColor}`,
    borderRadius: 4,
    ...H4HeaderWithoutBold,
    color: Colors.rxrRedColor,
    marginBottom: spaceMedium,
    backgroundColor: Colors.rxrWhiteColor,
  },

  selectLogoText: {
    marginTop: spaceSmall,
    color: Colors.rxrBlueColor,
    textDecoration: 'underline',
    cursor: 'pointer',
  },

  logoPicker: {
    position: 'absolute',
    bottom: spaceLarge,
    zIndex: 10,
    left: 0,
  },
}))

PartnerForm.propTypes = {
  partner: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  invalidItems: PropTypes.arrayOf(PropTypes.string),
}

export default PartnerForm
