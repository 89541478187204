import React, {useEffect, useState} from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import CalendarWithToolTip, {DUMMY_EVENT_ID} from '../Calendar/CalendarWithToolTip'
import SimpleSpinner from '../SimpleSpinner'
import {makeStyles} from '@mui/styles'
import {useDispatch, useSelector} from 'react-redux'
import AmenityConstants from './AmenitiesConstants'
import {loadAmenityReservationsForBuilding} from '../../actions/amenitiesActions'
import {getAmenityDetails} from '../../lib/queries'
import useResidentLookup from '../hooks/useResidentLookup'
import {Colors, Spacing, Typography} from '../../assets/styles'
import AmenitiesConstants from './AmenitiesConstants'
import {convertGeneralAvailabilityIntoClosureEvents, splitAndFlattenClosuresByDay} from './amenityCalendarHelpers'
import AmenityReservationEditor from './AmenityReservationEditor'
import CompactAmenityReservationEditor from './CompactAmenityReservationEditor'
import {useLocation} from 'react-router-dom'
import {onboardingElevatorAmenityFilter} from '../../Utils/amenityUtils'
import Routes from '../../constants/RouteConstants'

function AmenityWeekCalendar(props) {
  const classes = useStyles()
  const [isLoadingDetails, setIsLoadingDetails] = useState(false)
  // closure events are closure records from Dynamo
  const [closureEvents, setClosureEvents] = useState([])
  // general closures are times the amenity is closed due to non-existent GeneralAvailabilities
  const [generalClosureEvents, setGeneralClosureEvents] = useState([])
  const [events, setEvents] = useState([])
  const [startAndEndTimes, setStartAndEndTimes] = useState({start: 0, end: 1440})
  const dispatch = useDispatch()
  const amenityReservationsLookup = useSelector(state => state.Amenities.amenityReservationsLookup)
  const activeBuildingId = useSelector(state => state.Buildings.activeBuildingId)
  const {getResident} = useResidentLookup()
  const currentLocation = useLocation()
  const isElevatorAmenity = currentLocation.pathname.includes(Routes.ELEVATOR_CALENDAR_VIEW)
  const startOfWeek = moment(props.date).startOf('week')
  const endOfWeek = moment(props.date).endOf('week')
  // columns are always the days of the week starting with Sunday ("Sun")
  const columns = [...new Array(7)].map((e, i) => moment(startOfWeek).add(i, 'days').format('ddd'))

  const loadAmenityDetails = async () => {
    const [details] = await Promise.all([
      getAmenityDetails(props.amenityId),
      loadAmenityReservationsForBuilding(dispatch, activeBuildingId),
    ])

    // we determine the start/end time of the entire grid based on the largest combination of start/end times from general availability
    const se = details.generalAvailability.reduce(
      (agr, a) => {
        agr.start = Math.min(a.startTime, agr.start)
        agr.end = Math.max(a.endTime, agr.end)
        return agr
      },
      {start: 1440, end: 0}, // start and end are set at opposite extremes so they'll reduce correctly
    )

    // group our closures by day of the week
    const generalClosuresByDay = AmenityConstants.daysInAWeek.map(() => [])
    // these are already sorted from the query response, but we do assume NO overlaps
    details.generalAvailability.forEach(av => {
      generalClosuresByDay[av.dayOfWeek].push(av)
    })

    const generalClosures = []
    // build the general closure events one day of the week at a time
    generalClosuresByDay.forEach((gAs, dow) => {
      // NOTE: it does not matter that these general closures are not rebuilt when the date input is changed
      // because the calendar component only cares about start times relative to midnight (regardless of day)
      const startOfDay = moment(startOfWeek).add(dow, 'days').startOf('day')

      generalClosures.push(...convertGeneralAvailabilityIntoClosureEvents(props.amenityId, se.start, se.end, startOfDay, gAs))
    })

    setGeneralClosureEvents(
      generalClosures.map(c => ({
        id: c.id,
        ...getColumnStartAndEndTimes(c),
        content: `CLOSED`,
        isDisabled: true,
      })),
    )

    setStartAndEndTimes(se)
    // split any closure spanning multiple days into multiple closures, one per day
    const allClosures = splitAndFlattenClosuresByDay(details.closures)
    setClosureEvents(allClosures)
  }

  useEffect(() => {
    setEvents(
      // our events comprise all reservations in this time period
      Object.values(amenityReservationsLookup)
        .filter(
          r =>
            r.amenityId === props.amenityId &&
            r.startAt >= startOfWeek.toISOString() &&
            r.endAt <= endOfWeek.toISOString() &&
            ![AmenitiesConstants.STATE_CANCELLED, AmenitiesConstants.STATE_DECLINED, AmenitiesConstants.STATE_TIMEDOUT].includes(
              r.reservationState,
            ),
        )
        .map(r => {
          const res = getResident(r.residentId)
          const isPending = r.reservationState === AmenityConstants.STATE_REQUESTED
          return {
            id: r.id,
            ...getColumnStartAndEndTimes(r),
            content: (
              <div>
                {isPending && <div className={classes.pendingTag}>Pending Approval</div>}
                {r.staffId ? r.title : `${res.displayName}, ${res.occupancy.unit.number}`}
              </div>
            ),
            color: isPending ? Colors.rxrOrangeColor : Colors.rxrTealColor,
          }
        })
        .concat(
          // along with all created closures in this time period
          closureEvents
            .filter(c => c.startAt >= startOfWeek.toISOString() && c.endAt <= endOfWeek.toISOString())
            .map(c => ({
              id: c.id,
              ...getColumnStartAndEndTimes(c),
              content: `Closed for ${c.label}`,
              isDisabled: true,
            })),
        ),
    )
  }, [amenityReservationsLookup, props.date, closureEvents])

  // when amenity id or date changes, we re-request the usage details
  useEffect(() => {
    if (!props.amenityId) {
      return
    }

    setIsLoadingDetails(true)
    setEvents([])
    loadAmenityDetails()
      .catch(err => window.alert(err.message))
      .finally(() => setIsLoadingDetails(false))
  }, [props.amenityId])

  /**
   * @param {CalendarEventConfig} ev
   */
  function eventToReservation(ev) {
    const startAt = moment(startOfWeek).add(columns.indexOf(ev.column), 'days').startOf('day').add(ev.start, 'minutes').toDate()
    const endAt = moment(startAt)
      .add(ev.end - ev.start, 'minutes')
      .toDate()

    return {startAt: startAt, endAt: endAt, amenityId: props.amenityId}
  }

  const handleUpdateForm = (newData, onUpdateEvent) => {
    // only change our box if the new form has a start and end time, otherwise we just keep previous settings
    if (!(newData && newData.startAt && newData.endAt)) {
      return
    }

    if (newData.id === DUMMY_EVENT_ID) {
      onUpdateEvent({id: newData.id, ...getColumnStartAndEndTimes(newData)})
    } else {
      // update our reservation event accordingly
      const e = events.find(e => e.id === newData.id)
      if (e) {
        Object.assign(e, getColumnStartAndEndTimes(newData))
        setEvents([...events])
      }
    }
  }

  const isCurrentWeek = moment() > startOfWeek && moment() < endOfWeek

  return (
    <div className={classes.container}>
      {isLoadingDetails ? (
        <div className={classes.loading}>
          <SimpleSpinner size={SimpleSpinner.SIZE_LARGE} />
        </div>
      ) : (
        <CalendarWithToolTip
          eventToObject={eventToReservation}
          columns={columns}
          events={events.concat(generalClosureEvents)}
          highlightedColumn={isCurrentWeek ? moment().format('ddd') : null}
          calendarStartTime={startAndEndTimes.start}
          calendarEndTime={startAndEndTimes.end}
          renderEventMenu={(obj, id, onUpdateEvent, onCloseEvent) => {
            return (
              <div className={classes.formContainer}>
                {isElevatorAmenity ? (
                  <CompactAmenityReservationEditor
                    initialValues={obj}
                    amenitiesFilter={onboardingElevatorAmenityFilter}
                    amenityReservationId={id !== DUMMY_EVENT_ID ? id : undefined}
                    onComplete={onCloseEvent ? onCloseEvent : () => {}}
                    onUpdateForm={d => handleUpdateForm(d, onUpdateEvent)}
                  />
                ) : (
                  <AmenityReservationEditor
                    amenityReservationId={id !== DUMMY_EVENT_ID ? id : undefined}
                    initialValues={obj}
                    amenitiesFilter={props.amenitiesFilter}
                    onComplete={onCloseEvent ? onCloseEvent : () => {}}
                    onUpdateForm={d => handleUpdateForm(d, onUpdateEvent)}
                  />
                )}
              </div>
            )
          }}
        />
      )}
    </div>
  )
}

export const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
  },
  loading: {
    textAlign: 'center',
  },
  formContainer: {
    maxWidth: 400,
  },
  pendingTag: {
    borderRadius: 12,
    backgroundColor: Colors.rxrWhiteColor,
    color: Colors.rxrBlackDarkerColor,
    textTransform: 'uppercase',
    fontSize: Typography.fontSizeExtraSmall,
    display: 'block',
    textAlign: 'center',
    width: '100%',
    marginBottom: Spacing.spaceExtraSmall,
  },
}))

AmenityWeekCalendar.propTypes = {
  date: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]), // start date of the week
  amenityId: PropTypes.string.isRequired,
}

/**
 * @param {{startAt: Date|string, endAt: Date|string}} r
 * @return {{column: string, start: number, end: number}}
 */
function getColumnStartAndEndTimes(r) {
  const startOfDay = moment(r.startAt).startOf('day')
  return {
    column: moment(r.startAt).format('ddd'),
    start: moment(r.startAt).diff(startOfDay, 'minutes'),
    end: moment(r.endAt).diff(startOfDay, 'minutes'),
  }
}

export default AmenityWeekCalendar
