import React from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@mui/styles'
import {spaceMedium} from '../../assets/styles/spacing'
import TextInput from '../TextInput'
import DateTimeInput from '../DateTimeInput'

export default function SingleClosureInput(props) {
  const classes = useStyles()

  return (
    <React.Fragment>
      <div className={classes.gutter}>
        <TextInput
          label="Reason for closure"
          isRequired={true}
          helperText={'50 character limit'}
          maxLength={50}
          onChange={newReason => props.onChange({...props.value, label: newReason})}
          value={props.value.label}
        />
      </div>
      <div className={classes.gutter}>
        <DateTimeInput
          label={'Start date and start time'}
          onChange={startDate => props.onChange({...props.value, startAt: startDate})}
          min={new Date()}
          value={props.value.startAt}
        />
      </div>
      <DateTimeInput
        label={'End date and end time'}
        onChange={endDate => props.onChange({...props.value, endAt: endDate})}
        value={props.value.endAt}
        disabled={!props.value.startAt}
        min={props.value.startAt}
      />
    </React.Fragment>
  )
}

const useStyles = makeStyles(theme => ({
  gutter: {
    marginRight: spaceMedium,
    width: '100%',
  },
}))

SingleClosureInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object.isRequired,
}
