import React, {useState, useEffect} from 'react'
import moment from 'moment'
import {makeStyles} from '@mui/styles'
import Grid from '@mui/material/Grid'
import {Typography as TypographyStyle} from './../../assets/styles'
import SearchResident from '../SearchResident'
import DateInput from '../DateInput'
import SelectInput from '../SelectInput'
import Constants from './GuestAuthorizationConstants'
import {isGuestDatePermanent} from '../../Utils/dateTimeUtil'
import TextInput from '../TextInput'
import MultilineTextInput from '../MultilineTextInput'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  heading: {
    ...TypographyStyle.H3Header,
    lineHeight: '22px',
    marginBottom: '18px',
    color: '#0C4067',
  },
}))

export default function GuestAuthorizationForm(props) {
  const classes = useStyles()

  // change rowSize if we're creating a new guest
  const rowsize = !props.id ? 8 : 6

  const handleChangeResident = value => {
    if (value) {
      props.updateForm({
        residentId: value.id,
        unitId: value.occupancy.unit.id,
      })
    } else {
      props.updateForm({
        residentId: null,
        unitId: null,
      })
    }
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item md={6} xs={12}>
          <TextInput
            label="First name"
            isRequired={true}
            placeholder=""
            maxLength={120}
            onChange={val => props.updateForm({firstName: val})}
            value={props.firstName}
            error={props.invalidItems.includes('firstName')}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextInput
            label="Last name"
            isRequired={true}
            placeholder=""
            maxLength={120}
            onChange={val => props.updateForm({lastName: val})}
            value={props.lastName}
            error={props.invalidItems.includes('lastName')}
          />
        </Grid>
        <Grid item xs={12}>
          <SearchResident
            value={props.residentId}
            isRequired={true}
            onSelectResident={handleChangeResident}
            error={props.invalidItems.includes('residentId') || props.invalidItems.includes('unitId')}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          {isGuestDatePermanent(props.guestRegistrationExpiry) ? (
            <TextInput label="Guest registration expires on" isRequired={true} isDisabled={true} value={'Permanent'} />
          ) : (
            <DateInput
              onChange={date => {
                if (moment(date).isValid())
                  props.updateForm({
                    guestRegistrationExpiry: moment(date).startOf('day').toDate(),
                  })
              }}
              min={moment().startOf('day').toDate()}
              label={'Guest registration expires on'}
              helperText={'MM/DD/YYYY'}
              value={props.guestRegistrationExpiry}
              isRequired={true}
              error={props.invalidItems.includes('guestRegistrationExpiry')}
            />
          )}
        </Grid>
        <Grid item md={6} xs={12}>
          <SelectInput
            onChange={val =>
              props.updateForm({
                meetingOption: val,
              })
            }
            label={'Announce option'}
            isRequired={true}
            options={Constants.MEETING_OPTIONS}
            value={props.meetingOption}
            error={props.invalidItems.includes('meetingOption')}
          />
        </Grid>
        <Grid item xs={12}>
          <MultilineTextInput
            label="Special instructions"
            placeholder=""
            maxLength={1000}
            rows={rowsize}
            rowsMax={rowsize}
            onChange={val => props.updateForm({specialInstructions: val})}
            value={props.specialInstructions}
          />
        </Grid>
      </Grid>
    </div>
  )
}
