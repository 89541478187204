import React from 'react'
import {Typography, List, ListItem} from '@mui/material'
import {makeStyles} from '@mui/styles'
import MultiResidentProfileImages from '../ResidentProfiles/MultiResidentProfileImages'
import {spaceMedium, spaceSmall} from '../../assets/styles/spacing'
import {fontSizeLarge} from '../../assets/styles/typography'
import {rxrGreyColor, rxrMediumGreyColor, rxrWhiteColor} from '../../assets/styles/color'

const useStyles = makeStyles(theme => ({
  attendeesList: {},
  attendeeName: {
    fontSize: fontSizeLarge,
    display: 'flex',
    alignItems: 'center',
    borderTop: `1px solid ${rxrGreyColor}`,
    '&:first-child': {
      borderTop: 'none',
    },
    paddingTop: spaceSmall,
    paddingBottom: spaceSmall,
    justifyContent: 'space-between',
  },
  attendeeTypography: {
    marginLeft: spaceSmall,
    marginBottom: 4,
  },
  simpleFlex: {
    display: 'flex',
    alignItems: 'center',
  },
  avatarContainer: {
    marginBottom: '10px',
    height: '34px',
    width: '34px',
    flexShrink: 0,
  },
}))

const AttendeeList = ({event, attendees}) => {
  const classes = useStyles()
  const headcount = event.headcount

  if (headcount === 0 || !attendees || !Array.isArray(attendees) || attendees.length === 0) {
    return <Typography>No attendees have RSVP'd</Typography>
  }

  return (
    <List className={classes.attendeesList}>
      {attendees.map((attendee, index) => {
        const residentIds = [attendee.residentId]
        for (let i = 1; i < attendee.partySize; i++) {
          residentIds.push('placeholder')
        }
        return (
          <ListItem key={index} className={classes.attendeeName}>
            <div className={classes.simpleFlex}>
              <MultiResidentProfileImages residentIds={residentIds} className={classes.avatarContainer} />
              <Typography className={classes.attendeeTypography}>{attendee.residentDisplayName}</Typography>
            </div>
            <div className={classes.simpleFlex}>
              {attendee.isWaitlisted && (
                <div
                  style={{
                    marginRight: spaceSmall,
                    backgroundColor: rxrMediumGreyColor,
                    paddingLeft: spaceSmall,
                    paddingRight: spaceSmall,
                    borderRadius: spaceSmall,
                    color: rxrWhiteColor,
                  }}
                >
                  <Typography>On Waitlist</Typography>
                </div>
              )}
              <Typography>{attendee?.resident?.occupancy?.unit?.number}</Typography>
            </div>
          </ListItem>
        )
      })}
    </List>
  )
}

export default AttendeeList
