import React, {useState, useEffect} from 'react'
import IconButton from '@mui/material/IconButton'
import {Colors, TextFieldBorders} from './../../assets/styles'
import {makeStyles} from '@mui/styles'
import {useDispatch, useSelector} from 'react-redux'
import Constant from '../Messages/MessageConstant'
import MultilineTextInput from '../MultilineTextInput'
import {matchPath, useLocation} from 'react-router-dom'
import Routes from '../../constants/RouteConstants'
import {saveDraftMessage} from '../../actions/messagesActions'
import RXRButton from '../RXRButton'
import FileHelper from '../../lib/FileHelper'
import RXRIcon from '../RXRIcon'
import {spaceExtraExtraSmall, spaceSmall, spaceMedium, spaceExtraSmall} from '../../assets/styles/spacing'
import {saveMessage, createImageMessage, createTextMessage} from './ChatContainerQueries'
import {getAuthedUserFromChatRoom} from '../../Utils/chatUtils'
import PropTypes from 'prop-types'
import {selectAuthedUserId} from '../../reducers/selectors'

const useStyles = makeStyles(theme => ({
  inputContainer: props => ({
    ...Colors.backgroundLightGrey,
    display: 'flex',
    padding: props.isMobile ? `${spaceExtraSmall}px` : `${spaceSmall}px ${spaceMedium}px`,
    width: '100%',
    alignItems: 'center',
  }),
  sendButton: {},
  uploadButton: props => ({
    width: props.isMobile ? 42 : 56,
    height: props.isMobile ? 42 : 56,
    margin: spaceExtraExtraSmall,
  }),
  messageTextField: {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      borderRadius: '8px',
      backgroundColor: Colors.rxrWhiteColor,
      '& fieldset': {
        ...TextFieldBorders.messageInputBorder,
      },
      '&:hover fieldset': {
        ...TextFieldBorders.messageInputBorder,
      },
      '&.Mui-disabled fieldset': {
        ...TextFieldBorders.messageInputBorder,
      },
      '&.Mui-focused fieldset': {
        ...TextFieldBorders.messageInputBorder,
      },
    },
    '& .MuiOutlinedInput-input': {
      color: Colors.rxrDarkGreyColor,
    },
  },
}))

function ChatContainerInput(props) {
  const isMobile = useSelector(state => state.App.isMobile)
  const classes = useStyles({isMobile})
  const [messageInput, setMessageInput] = useState('')
  const [viewingConversationId, setViewingConversationId] = useState(null)
  const currentLocation = useLocation()

  const conversationModel = useSelector(state => state.Messages.conversationsLookup[viewingConversationId])
  const authedStaffId = useSelector(selectAuthedUserId)
  const activeBuilding = useSelector(state => state.Buildings.buildingsLookup[state.Buildings.activeBuildingId])
  const groupPermissions = useSelector(state => state.GroupPermissions.permissionsObject)
  const draftMessagesLookup = useSelector(state => state.Messages.draftMessagesByConversationId)
  const staffLookup = useSelector(state => state.Staff.staffLookup)

  const dispatch = useDispatch()

  const authedChatUser = getAuthedUserFromChatRoom(conversationModel, authedStaffId, staffLookup)

  useEffect(() => {
    const viewConversationMatch = matchPath(currentLocation.pathname, {path: Routes.MESSAGES_VIEW_SINGLE})

    if (!viewConversationMatch) {
      // this shouldn't happen since we only render if we're viewing a conversation
      return
    }

    const newConversationId = viewConversationMatch.params.conversationId
    if (newConversationId === viewingConversationId) {
      // if the conversation hasn't changed, we skip
      return
    }

    setViewingConversationId(newConversationId)

    if (viewConversationMatch.state && viewConversationMatch.state.prefillMessage) {
      setMessageInput(viewConversationMatch.state.prefillMessage)
      // also immediately put this to our draft state
      saveDraftMessage(dispatch, newConversationId, viewConversationMatch.state.prefillMessage)
    } else if (draftMessagesLookup[newConversationId]) {
      setMessageInput(draftMessagesLookup[newConversationId])
    } else {
      setMessageInput('')
    }
  }, [currentLocation, viewingConversationId, draftMessagesLookup])

  const handleChangeInput = newVal => {
    saveDraftMessage(dispatch, viewingConversationId, newVal)
    setMessageInput(newVal)
  }

  // still only supporting one image at a time
  const sendImage = async imageData => {
    const message = await createImageMessage(conversationModel, authedChatUser, imageData)
    props.onMessageSending(message)
    const savedMessage = await saveMessage(dispatch, conversationModel, message)
    props.onMessageSent(savedMessage)
  }

  const sendText = async () => {
    if (messageInput.trim().length === 0) {
      return
    }

    const message = await createTextMessage(conversationModel, authedChatUser, messageInput)
    props.onMessageSending(message)
    handleChangeInput('')
    try {
      const savedMessage = await saveMessage(dispatch, conversationModel, message)
      props.onMessageSent(savedMessage)
    } catch (err) {
      console.error(err)
      window.alert(err.message)
      // put our input back how it was
      handleChangeInput(messageInput)
    }
  }

  const handleImage = e => {
    FileHelper.Instance()
      .handleSelectFilesInputEventUploadToS3(e, FileHelper.IMAGE_FILE_TYPES)
      .then(s3Object => {
        return sendImage(s3Object[0])
      })
      .catch(err => {
        console.error(err)
        window.alert(err.message)
      })
  }

  return (
    (<div className={classes.inputContainer}>
      <MultilineTextInput
        id="outlined-multiline-flexible"
        rowsMax={4}
        placeholder="Type a message"
        value={messageInput}
        onChange={handleChangeInput}
        className={classes.messageTextField}
        isDisabled={!activeBuilding.outOfOfficeStatus || !groupPermissions.messagesWrite} //outOfOfficeStatus = "false" when user is offline, outOfOfficeStatus="true" when user is online"
        onKeyDown={e => {
          if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault()
            sendText().then()
          }
        }}
      />
      <input
        name="image"
        onChange={handleImage}
        accept="image/*"
        style={{display: 'none'}}
        id="raised-button-file"
        multiple
        type="file"
        disabled={!activeBuilding.outOfOfficeStatus || !groupPermissions.messagesWrite}
      />
      <label htmlFor="raised-button-file">
        <IconButton
          aria-label="upload picture"
          component="span"
          disabled={!groupPermissions.messagesWrite}
          className={classes.uploadButton}
          size="large">
          <RXRIcon
            icon={RXRIcon.FILE_IMAGE}
            color={activeBuilding.outOfOfficeStatus && groupPermissions.messagesWrite ? Colors.rxrBlueColor : Colors.rxrGreyColor}
            size={RXRIcon.SIZE_EXTRA_LARGE}
          />
        </IconButton>
      </label>
      <RXRButton
        onClick={() => sendText()}
        className={classes.sendButton}
        disabled={!activeBuilding.outOfOfficeStatus || !groupPermissions.messagesWrite || messageInput.trim() === ''}
      >
        {Constant.MESSAGE_SEND_CONSTANT}
      </RXRButton>
    </div>)
  );
}

ChatContainerInput.propTypes = {
  onMessageSending: PropTypes.func.isRequired,
  onMessageSent: PropTypes.func.isRequired,
}

export default ChatContainerInput
