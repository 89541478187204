import React from 'react'
import {makeStyles} from '@mui/styles'
import {rxrLightGreyColor} from '../assets/styles/color'
import SimpleSpinner from './SimpleSpinner'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    zIndex: 9,
    backgroundColor: rxrLightGreyColor,
    opacity: 1,
  },
}))

function Loader({className, style}) {
  const classes = useStyles()
  return (
    <div style={style} className={[classes.root, className].join(' ')}>
      <SimpleSpinner size={SimpleSpinner.SIZE_X_LARGE} />
    </div>
  )
}

export default Loader
