import React, {Fragment, useState, useRef} from 'react'
import moment from 'moment'
import {makeStyles} from '@mui/styles'
import {borderRadiusMedium} from '../../assets/styles/border'
import {
  messageBubbleColor,
  messageBubbleColorPeer,
  messageBubbleColorMine,
  rxrBlackColor,
  rxrDarkGreyColor,
  rxrWhiteColor,
} from '../../assets/styles/color'
import PropTypes from 'prop-types'
import RXRIcon from '../RXRIcon'
import {IconButton, Menu, MenuItem} from '@mui/material'
import {iconButton} from '../../assets/styles/buttons'
import {iconButtonSizeSmall, spaceExtraExtraSmall, spaceExtraSmall, spaceSmall} from '../../assets/styles/spacing'
import * as queries from '../../lib/queries'
import {useSelector} from 'react-redux'
import MultilineTextInput from '../MultilineTextInput'
import {updateChatMessage} from './ChatContainerQueries'
import {Typography} from '../../assets/styles'
import {selectAuthedUserId} from '../../reducers/selectors'

const sharedBubbleStyles = {
  display: 'inline-block',
  alignItems: 'flex-start',
  borderRadius: borderRadiusMedium,
  paddingLeft: '16px',
  paddingRight: '16px',
  paddingTop: '10px',
  paddingBottom: '10px',
  fontSize: '16px',
  lineHeight: '19px',
  position: 'relative',
  color: rxrBlackColor,
}

const useStyles = makeStyles(theme => ({
  bubbleRight: {
    ...sharedBubbleStyles,
    backgroundColor: messageBubbleColorPeer,
  },
  bubbleRightMe: {
    ...sharedBubbleStyles,
    backgroundColor: messageBubbleColorMine,
  },
  bubbleLeft: {
    ...sharedBubbleStyles,
    backgroundColor: messageBubbleColor,
  },
  bubbleMetaText: {
    marginTop: spaceExtraExtraSmall,
    fontSize: Typography.fontSizeSmall,
    color: rxrDarkGreyColor,
  },
  messageText: {
    // this padding prevents the edit dots icon from overlapping
    paddingRight: spaceSmall,
    textAlign: 'left',
  },

  optionsContainer: {
    position: 'absolute',
    top: spaceExtraExtraSmall,
    right: spaceExtraExtraSmall,
  },

  optionsIcon: {
    ...iconButton,
    width: iconButtonSizeSmall,
    height: iconButtonSizeSmall,
  },

  editControls: {
    marginTop: spaceExtraExtraSmall,
  },
  editInput: {
    '& .MuiInputBase-root': {
      padding: spaceExtraSmall,
    },
  },
  line: {wordBreak: 'break-word'},
}))

function EditableTextChatMessage(props) {
  const classes = useStyles()
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [editedText, setEditedText] = useState('')
  const authedStaffId = useSelector(selectAuthedUserId)
  const modifyRef = useRef()
  const isStaffMessage = props.message.chatUser && props.message.chatUser.isStaffUser
  const isAuthor = isStaffMessage && props.message.chatUser.userId === authedStaffId

  // Cannot edit message if you're not the author, it's a resident message, is currently editing, or is a sending placeholder
  const canEdit = isStaffMessage && isAuthor && !isEditing && !props.isSendingMessage

  let cls = classes.bubbleLeft
  if (isAuthor) {
    cls = classes.bubbleRightMe
  } else if (isStaffMessage) {
    cls = classes.bubbleRight
  }

  const handleClickDelete = () => {
    setIsMenuOpen(false)
    setIsDeleting(true)
    queries
      .deleteMessage(props.message.id)
      .then(() => {
        props.onDelete(props.message)
      })
      .catch(err => {
        console.error(err)
        window.alert(err.message)
      })
      .finally(() => {
        setIsDeleting(false)
      })
  }

  const handleClickEdit = () => {
    setIsEditing(true)
    setIsMenuOpen(false)
    setEditedText(props.message.content)
  }

  const saveChanges = () => {
    // if no changes, treat it like a cancel
    if (editedText === props.message.content) {
      cancelChanges()
      return
    }

    updateChatMessage(props.message, editedText)
      .then(resp => {
        props.onEdit(resp)
        cancelChanges()
      })
      .catch(err => {
        console.error(err)
        window.alert(err.message)
      })
  }

  const cancelChanges = () => {
    setIsEditing(false)
    setEditedText('')
  }

  // if we're deleting, let's just pretend we're already deleted (return null)
  return isDeleting ? null : (
    <div className={cls} style={isEditing ? {width: '100%'} : undefined} data-id={props.message.id}>
      {canEdit && (
        <div ref={modifyRef} className={classes.optionsContainer}>
          <IconButton
            className={classes.optionsIcon}
            onClick={() => setIsMenuOpen(true)}
            size="large">
            <RXRIcon icon={RXRIcon.ELLIPSIS} color={rxrBlackColor} />
          </IconButton>
          <Menu open={isMenuOpen} anchorEl={modifyRef.current} onClose={() => setIsMenuOpen(false)}>
            <MenuItem onClick={handleClickEdit}>Edit</MenuItem>
            <MenuItem onClick={handleClickDelete}>Delete</MenuItem>
          </Menu>
        </div>
      )}
      {isEditing ? (
        <div>
          <MultilineTextInput
            value={editedText}
            onChange={setEditedText}
            onKeyDown={e => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault()
                if (editedText.trim() !== '') {
                  saveChanges()
                }
              }
            }}
            className={classes.editInput}
          />
          <div className={classes.editControls}>
            <IconButton className={classes.optionsIcon} onClick={saveChanges} size="large">
              <RXRIcon icon={RXRIcon.CHECK} color={rxrWhiteColor} />
            </IconButton>
            <IconButton className={classes.optionsIcon} onClick={cancelChanges} size="large">
              <RXRIcon icon={RXRIcon.CLOSE} color={rxrWhiteColor} />
            </IconButton>
          </div>
        </div>
      ) : (
        <Fragment>
          <div className={classes.messageText}>
            {props.message.content.split('\n').map((item, key) => {
              return (
                <Fragment key={key}>
                  <p className={classes.line}>{item}</p>
                </Fragment>
              )
            })}
          </div>
          <p className={classes.bubbleMetaText}>
            {props.isSendingMessage ? 'sending...' : moment(props.message.createdAt).format('MM/DD/YYYY, h:mma')}
          </p>
        </Fragment>
      )}
    </div>
  );
}

EditableTextChatMessage.propTypes = {
  message: PropTypes.object.isRequired,
  isSendingMessage: PropTypes.bool,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
}

export default EditableTextChatMessage
