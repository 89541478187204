import React from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@mui/styles'

const useStyles = makeStyles({
  outerContainer: {
    width: '100%',
    height: '10px',
    borderRadius: '5px',
    overflow: 'hidden',
  },
  progressBar: {
    height: '100%',
  },
})

function ProgressBar({progress, color}) {
  const classes = useStyles()

  return (
    <div className={classes.outerContainer} style={{border: `1px solid ${color}`}}>
      <div className={classes.progressBar} style={{width: `${progress * 100}%`, backgroundColor: color}} />
    </div>
  )
}

ProgressBar.propTypes = {
  progress: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
}

export default ProgressBar
