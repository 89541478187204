import React from 'react'
import SortableTable, {ColumnConfig} from '../SortableTable'
import {makeStyles} from '@mui/styles'
import {primaryButton, secondaryButton} from '../../assets/styles/buttons'
import {rxrGreenColor} from '../../assets/styles/color'
import {useDispatch, useSelector} from 'react-redux'
import DashboardToggle from './../DashboardToggle'
import ActionMenu from './ActionMenu'
import {setAmenityIsVisible} from '../../actions/amenitiesActions'
import {CORE_FEATURE_ELEVATORS} from '../../constants/ModelConstants'

const useStyles = makeStyles(theme => ({
  checkInButton: {
    ...primaryButton,
  },
  checkInButtonConfirm: {
    ...secondaryButton,
    borderColor: rxrGreenColor,
    color: rxrGreenColor,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  menuIcon: {
    paddingLeft: 15,
  },
}))

const NULL_MUTATE = value => (value ? value.toLowerCase() : '0')
const getAmenityType = isOnboardingOnly => `${isOnboardingOnly ? 'Elevator' : 'Non-elevator'}`

const AmenityManagerTable = function (props) {
  const classes = useStyles()
  const dispatch = useDispatch()

  const coreFeatures = useSelector(state => state.GroupPermissions.coreFeatures)
  const amenitiesLookup = useSelector(state => state.Amenities.amenitiesLookup)

  const handleToggle = (isSelected, amenityId) => {
    setAmenityIsVisible(dispatch, amenityId, isSelected).catch(err => {
      window.alert(err.message)
    })
  }

  const amenitiesToShow = Object.values(amenitiesLookup).filter(a => (coreFeatures[CORE_FEATURE_ELEVATORS] ? a : !a.isOnboardingOnly))

  const columns = [
    new ColumnConfig({
      title: 'Name',
      render: g => g.label,
      comparator: ColumnConfig.simpleComparatorGenerator('label'),
      isDefaultSort: true,
    }),

    // only show Type column if elevators are enabled (otherwise they're all just "Non-elevator")
    coreFeatures[CORE_FEATURE_ELEVATORS]
      ? new ColumnConfig({
          title: 'Type',
          render: g => getAmenityType(g.isOnboardingOnly),
          comparator: (a, b) => getAmenityType(a.isOnboardingOnly).localeCompare(getAmenityType(b.isOnboardingOnly)),
        })
      : null,

    new ColumnConfig({
      title: 'Active',
      render: a => <DashboardToggle value={a.isVisible} onChange={isSelected => handleToggle(isSelected, a.id)} />,
      comparator: (a, b) => b.isVisible - a.isVisible,
    }),

    new ColumnConfig({
      title: 'Availability',
      render: g => (g.needsApproval ? 'Needs approval' : "Doesn't need approval"),
      comparator: ColumnConfig.simpleComparatorGenerator('needsApproval', undefined, NULL_MUTATE),
    }),

    new ColumnConfig({
      title: 'Actions',
      render: a => (
        <div className={classes.menuIcon}>
          <ActionMenu amenity={a} />
        </div>
      ),
    }),
  ].filter(c => !!c)

  return <SortableTable data={amenitiesToShow} columns={columns} />
}

export default AmenityManagerTable
