import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@mui/styles'
import SimpleSpinner from './SimpleSpinner'
import FileHelper from '../lib/FileHelper'
import RXRIcon from './RXRIcon'
import {spaceExtraSmall, spaceSmall} from '../assets/styles/spacing'
import {Buttons} from '../assets/styles'
import {rxrRedColor} from '../assets/styles/color'

const useStyles = makeStyles(theme => ({
  container: {},
  attachFileCTA: {
    ...Buttons.secondaryButton,
  },
  attachFileText: {
    marginLeft: spaceExtraSmall,
    display: 'inline-block',
  },

  attachment: props => ({
    cursor: props.isDisabled ? 'auto' : 'pointer',
    display: 'inline-block',
    marginBottom: spaceSmall,

    '&:hover': !props.isDisabled && {
      color: rxrRedColor,
    },
  }),
}))

function GenericFileSelector(props) {
  const classes = useStyles(props)
  const [isLoading, setIsLoading] = useState(false)
  const filesArray = Array.isArray(props.value) ? props.value : []

  const handleFileSelect = e => {
    setIsLoading(true)
    FileHelper.Instance()
      .handleSelectFilesInputEventUploadToS3(e)
      .then(uploaded => {
        props.onChange([...filesArray, ...uploaded])
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => setIsLoading(false))
  }

  const handleRemoveAttachment = index => {
    let newFiles = [...filesArray]
    newFiles.splice(index, 1)
    props.onChange(newFiles)
  }

  return (
    <div className={classes.container}>
      {filesArray.map((file, idx) => {
        return (
          <div key={file.key}>
            <span className={classes.attachment} onClick={() => (props.isDisabled ? undefined : handleRemoveAttachment(idx))}>
              <RXRIcon icon={RXRIcon.TRASH} />
              <span className={classes.attachFileText}>{file.displayName || file.key}</span>
            </span>
          </div>
        )
      })}
      {filesArray.length < props.maxFiles && !props.isDisabled && (
        <React.Fragment>
          <input name="file" onChange={handleFileSelect} style={{display: 'none'}} id="upload-file-input" multiple type="file" />
          <label htmlFor="upload-file-input">
            <div className={classes.attachFileCTA}>
              {isLoading ? <SimpleSpinner /> : <RXRIcon icon={RXRIcon.ATTACHMENT} />}
              <span className={classes.attachFileText}>Attach file</span>
            </div>
          </label>
        </React.Fragment>
      )}
    </div>
  )
}

GenericFileSelector.propTypes = {
  value: PropTypes.array, // Array of S3 objects
  maxFiles: PropTypes.number.isRequired, // Count of max number of files to attach
  onChange: PropTypes.func.isRequired, // callback invoked with the selected files prop
  isDisabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  helperText: PropTypes.string,
}

export default GenericFileSelector
