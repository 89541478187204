import {Dialog, DialogActions, DialogTitle} from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import RXRButton from './RXRButton'

function ConfirmDeleteDialog(props) {
  return (
    <Dialog
      open={props.isOpen}
      onClose={props.onCancel}
      aria-labelledby="alert-delete-dialog-title"
      aria-describedby="alert-delete-dialog-description"
    >
      <DialogTitle>{props.prompt}</DialogTitle>
      <DialogActions>
        <RXRButton type={RXRButton.TYPE_SECONDARY} onClick={props.onCancel}>
          {typeof props.cancelButtonText === 'string' ? props.cancelButtonText : 'Cancel'}
        </RXRButton>
        <RXRButton type={RXRButton.TYPE_DESTRUCTIVE} onClick={props.onConfirmDelete}>
          {typeof props.deleteButtonText === 'string' ? props.deleteButtonText : 'Delete'}
        </RXRButton>
      </DialogActions>
    </Dialog>
  )
}

ConfirmDeleteDialog.propTypes = {
  prompt: PropTypes.string.isRequired,
  onConfirmDelete: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  cancelButtonText: PropTypes.string,
  deleteButtonText: PropTypes.string,
}

export default ConfirmDeleteDialog
