import React from 'react'
import {useSelector} from 'react-redux'
import {listNotificationSettingsByResident, createNotificationSetting, updateNotificationSetting} from '../../lib/queries'
import {
  NOTIFICATION_CATEGORY_RXO_UNREAD_RESIDENT_MESSAGE,
  NOTIFICATION_CATEGORY_RXO_DELAYED_CHAT_RESPONSE,
  NOTIFICATION_MEDIUM_EMAIL,
  NOTIFICATION_CATEGORY_RXO_STAFF_AMENITY_RESERVATION_REQUEST,
} from '../../constants/ModelConstants'

const useNotificationSettings = () => {
  const authedStaffModel = useSelector(state => state.Staff.authedStaffModel)
  const permissionsObject = useSelector(state => state.GroupPermissions.permissionsObject)

  const [originalNotifyEveryMessage, setOriginalNotifyEveryMessage] = React.useState(null)
  const [originalNotifyWithDelay, setOriginalNotifyWithDelay] = React.useState(null)
  const [originalNotifyAmenityReservationRequests, setOriginalNotifyAmenityReservationRequests] = React.useState(null)

  const [stagedNotifyEveryMessage, setStagedNotifyEveryMessage] = React.useState(null)
  const [stagedNotifyWithDelay, setStagedNotifyWithDelay] = React.useState(null)
  const [stagedNotifyAmenityReservationRequests, setStagedNotifyAmenityReservationRequests] = React.useState(null)

  const [originalNotifyEveryMessage_FullNotificationSetting, setOriginalNotifyEveryMessage_FullNotificationSetting] = React.useState(null)
  const [originalNotifyWithDelay_FullNotificationSetting, setOriginalNotifyWithDelay_FullNotificationSetting] = React.useState(null)
  const [
    originalNotifyAmenityReservationRequests_FullNotificationSetting,
    setOriginalNotifyAmenityReservationRequests_FullNotificationSetting,
  ] = React.useState(null)

  const [isLoadingNotificationSettings, setIsLoadingNotificationSettings] = React.useState(true)
  const [loadNotificationSettingsError, setLoadNotificationSettingsError] = React.useState(null)

  React.useEffect(() => {
    if (!authedStaffModel) return
    setIsLoadingNotificationSettings(true)
    listNotificationSettingsByResident(authedStaffModel.id)
      .then(notificationSettings => {
        refreshNotificationSettingsLocalStateVariables(notificationSettings)
      })
      .catch(err => {
        console.error('Error fetching notification settings:', err)
        setLoadNotificationSettingsError(`Failed to load notification settings. Error: ${err}`)
        setIsLoadingNotificationSettings(false)
      })
  }, [authedStaffModel])

  const refreshNotificationSettingsLocalStateVariables = notificationSettings => {
    const notifyEveryMessageSetting = notificationSettings.find(
      setting => setting.category === NOTIFICATION_CATEGORY_RXO_UNREAD_RESIDENT_MESSAGE,
    )
    const notifyWithDelaySetting = notificationSettings.find(
      setting => setting.category === NOTIFICATION_CATEGORY_RXO_DELAYED_CHAT_RESPONSE,
    )
    const notifyAmenityReservationRequestsSetting = notificationSettings.find(
      setting => setting.category === NOTIFICATION_CATEGORY_RXO_STAFF_AMENITY_RESERVATION_REQUEST,
    )

    setOriginalNotifyEveryMessage_FullNotificationSetting(notifyEveryMessageSetting)
    setOriginalNotifyWithDelay_FullNotificationSetting(notifyWithDelaySetting)
    setOriginalNotifyAmenityReservationRequests_FullNotificationSetting(notifyAmenityReservationRequestsSetting)

    setOriginalNotifyEveryMessage(notifyEveryMessageSetting ? !notifyEveryMessageSetting.isUnsubscribed : false)
    setOriginalNotifyWithDelay(notifyWithDelaySetting ? !notifyWithDelaySetting.isUnsubscribed : false)
    setOriginalNotifyAmenityReservationRequests(
      notifyAmenityReservationRequestsSetting ? !notifyAmenityReservationRequestsSetting.isUnsubscribed : false,
    )

    setStagedNotifyEveryMessage(notifyEveryMessageSetting ? !notifyEveryMessageSetting.isUnsubscribed : false)
    setStagedNotifyWithDelay(notifyWithDelaySetting ? !notifyWithDelaySetting.isUnsubscribed : false)
    setStagedNotifyAmenityReservationRequests(
      notifyAmenityReservationRequestsSetting ? !notifyAmenityReservationRequestsSetting.isUnsubscribed : false,
    )

    setIsLoadingNotificationSettings(false)
  }

  const cancelNotificationPreferences = () => {
    setStagedNotifyEveryMessage(originalNotifyEveryMessage)
    setStagedNotifyWithDelay(originalNotifyWithDelay)
    setStagedNotifyAmenityReservationRequests(originalNotifyAmenityReservationRequests)
  }

  const updateNotificationSettings = async () => {
    await createOrUpdateNotifyEveryMessageNotificationSettingAsNeeded()
    await createOrUpdateNotifyWithDelayNotificationSettingAsNeeded()
    await createOrUpdateNotifyAmenityReservationRequestsNotificationSettingAsNeeded()

    setIsLoadingNotificationSettings(true)
    const updatedNotificationSettings = await listNotificationSettingsByResident(authedStaffModel.id)
    try {
      refreshNotificationSettingsLocalStateVariables(updatedNotificationSettings)
    } catch (err) {
      console.error('Error fetching notification settings:', err)
      setLoadNotificationSettingsError(`Failed to load notification settings. Error: ${err}`)
      setIsLoadingNotificationSettings(false)
    }
  }

  const createOrUpdateNotifyEveryMessageNotificationSettingAsNeeded = async () => {
    if (stagedNotifyEveryMessage === originalNotifyEveryMessage) return

    if (originalNotifyEveryMessage_FullNotificationSetting) {
      await updateNotificationSettingWrapper(originalNotifyEveryMessage_FullNotificationSetting.id, stagedNotifyEveryMessage)
    } else {
      await createNotificationSettingWrapper(NOTIFICATION_CATEGORY_RXO_UNREAD_RESIDENT_MESSAGE, stagedNotifyEveryMessage)
    }
  }

  const createOrUpdateNotifyWithDelayNotificationSettingAsNeeded = async () => {
    if (stagedNotifyWithDelay === originalNotifyWithDelay) return

    if (originalNotifyWithDelay_FullNotificationSetting) {
      await updateNotificationSettingWrapper(originalNotifyWithDelay_FullNotificationSetting.id, stagedNotifyWithDelay)
    } else {
      await createNotificationSettingWrapper(NOTIFICATION_CATEGORY_RXO_DELAYED_CHAT_RESPONSE, stagedNotifyWithDelay)
    }
  }

  const createOrUpdateNotifyAmenityReservationRequestsNotificationSettingAsNeeded = async () => {
    if (stagedNotifyAmenityReservationRequests === originalNotifyAmenityReservationRequests) return

    if (originalNotifyAmenityReservationRequests_FullNotificationSetting) {
      await updateNotificationSettingWrapper(
        originalNotifyAmenityReservationRequests_FullNotificationSetting.id,
        stagedNotifyAmenityReservationRequests,
      )
    } else {
      await createNotificationSettingWrapper(
        NOTIFICATION_CATEGORY_RXO_STAFF_AMENITY_RESERVATION_REQUEST,
        stagedNotifyAmenityReservationRequests,
      )
    }
  }

  const updateNotificationSettingWrapper = async (notificationSettingId, stagedSetting) => {
    const updateNotificationSettingInput = {
      id: notificationSettingId,
      isUnsubscribed: !stagedSetting,
    }
    await updateNotificationSetting(updateNotificationSettingInput)
  }

  const createNotificationSettingWrapper = async (category, stagedSetting) => {
    const createNotificationSettingInput = {
      category: category,
      medium: NOTIFICATION_MEDIUM_EMAIL,
      residentId: authedStaffModel.id,
      isUnsubscribed: !stagedSetting,
    }
    await createNotificationSetting(createNotificationSettingInput)
  }

  const userHasMadeNotificationChanges =
    stagedNotifyEveryMessage !== originalNotifyEveryMessage ||
    stagedNotifyWithDelay !== originalNotifyWithDelay ||
    stagedNotifyAmenityReservationRequests !== originalNotifyAmenityReservationRequests

  return {
    originalNotifyEveryMessage,
    originalNotifyWithDelay,
    originalNotifyAmenityReservationRequests,
    stagedNotifyEveryMessage,
    stagedNotifyWithDelay,
    stagedNotifyAmenityReservationRequests,
    isLoadingNotificationSettings,
    setStagedNotifyEveryMessage,
    setStagedNotifyWithDelay,
    setStagedNotifyAmenityReservationRequests,
    loadNotificationSettingsError,
    updateNotificationSettings,
    userHasMadeNotificationChanges,
    cancelNotificationPreferences,
    permissionsObject,
  }
}

export {useNotificationSettings}
