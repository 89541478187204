import React from 'react'
import {connect, useSelector} from 'react-redux'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import {makeStyles} from '@mui/styles'
import KYRDataField from './KYRDataField'
import {KYRScreenLayout} from '../../assets/styles/layout'
import {rxrBlueColor, rxrSecondaryBlueGrayColor} from '../../assets/styles/color'
import {selectResidentsByUnit} from '../../Utils/residentUtils'
import moment from 'moment'
import {capitalizeFirstLetter, formatPhoneNumber} from '../../Utils/StringFormatter'
import TimelineEvent from './TimelineEvent'

const useStyles = makeStyles(theme => ({
  scrollContainer: {
    ...KYRScreenLayout,
    marginLeft: '40px',
  },
  infoTitle: {
    fontSize: 12,
    lineHeight: '18px',
    fontWeight: '400',
    fontStyle: 'normal',
    color: rxrSecondaryBlueGrayColor,
  },
  infoData: {
    fontSize: 16,
    lineHeight: '16px',
    fontWeight: '400',
    fontStyle: 'normal',
    color: rxrBlueColor,
    paddingTop: '4px',
  },
  ptePreferenceContainer: {
    marginLeft: '-23px',
  },
}))

const PACKAGE_MAP = {
  ['inUnit']: 'In unit',
  ['frontDesk']: 'Front desk',
}

function ResidentProfile(props) {
  const classes = useStyles()

  let resident = props.resident ? props.resident : {occupancy: {unit: {}}}
  let userProfile = {}
  if (resident && resident.userProfile) {
    userProfile = JSON.parse(resident.userProfile)
  }

  const residentsLookup = useSelector(state => state.Residents.residentsLookup)

  let roommates = []
  if (resident.occupancy && resident.occupancy.unit && resident.occupancy.unit.id) {
    roommates = selectResidentsByUnit(residentsLookup, resident.occupancy.unit.id)
      .map(r => r.displayName)
      .filter(r => r !== resident.displayName)
  }

  const renderPTE = () => (
    <Grid container direction="column" style={{paddingBottom: '24px'}}>
      <Grid item>
        <div className={classes.infoTitle}>PTE preferences</div>
      </Grid>
      <Grid item>
        {userProfile.maintenancePermission ||
        userProfile.cleaningPermission ||
        userProfile.deliveryPermission ||
        userProfile.thirdPartyPermission ? (
          <ul className={classes.ptePreferenceContainer}>
            {!!userProfile.maintenancePermission && <li className={classes.infoData}>Maintenance</li>}
            {!!userProfile.cleaningPermission && <li className={classes.infoData}>Cleaning</li>}
            {!!userProfile.deliveryPermission && <li className={classes.infoData}>Package delivery</li>}
            {!!userProfile.thirdPartyPermission && <li className={classes.infoData}>3rd party service providers</li>}
          </ul>
        ) : (
          <div className={classes.infoData}>--</div>
        )}
      </Grid>
    </Grid>
  )

  if (userProfile) {
    return (
      <div className={classes.scrollContainer}>
        <KYRDataField title="Name" data={resident.displayName} />
        <KYRDataField title="Goes by" data={userProfile.goesBy} />
        <KYRDataField title="Gender" data={userProfile.gender ? capitalizeFirstLetter(userProfile.gender) : ''} />
        <KYRDataField title="Birthday" data={userProfile.birthday ? moment(userProfile.birthday).format('MM/DD') : ''} />
        <KYRDataField title="Phone number" data={formatPhoneNumber(resident.phoneNumber)} />
        <KYRDataField title="Email" data={resident.email} />
        {/* <KYRDataField title='Pets' data={'None'} /> */}
        <KYRDataField title="Building" data={props.activeBuilding ? props.activeBuilding.displayName : ''} />
        <KYRDataField title="Unit" data={resident.occupancy.unit.number} />
        <KYRDataField title="Roommate" data={roommates.length > 0 ? roommates.join() : ''} />
        <KYRDataField title="Packages" data={PACKAGE_MAP[userProfile.packagePreference]} />
        {renderPTE()}
        <KYRDataField title="Cleaning preferences" data={userProfile.cleaningPreferences} />
        <KYRDataField title="Likes" data={userProfile.likes} />
        <KYRDataField title="Dislikes" data={userProfile.dislikes} />
        <KYRDataField title="Notes" data={userProfile.notes} />
        <TimelineEvent primaryRecordId={props.residentId} />
      </div>
    )
  } else {
    return <div>Resident profile data not found.</div>
  }
}

ResidentProfile.propTypes = {
  residentId: PropTypes.string.isRequired,
}

const mapStateToProps = (state, ownProps) => ({
  resident: state.Residents.residentsLookup[ownProps.residentId],
  activeBuilding: state.Buildings.buildingsLookup[state.Buildings.activeBuildingId],
})

export default connect(mapStateToProps)(ResidentProfile)
