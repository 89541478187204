import React, {useContext, useState} from 'react'
import PartnerForm from './PartnerForm'
import useFormChanged, {
  EmailValidator,
  NonEmptyStringValidator,
  S3ObjectValidator,
  URLValidator,
  PhoneNumberValidator,
  ConstructValidateDependency,
} from '../../hooks/useFormChanged'
import {Grid} from '@mui/material'
import RXRButton from '../../RXRButton'
import useListCreateViewPattern from '../../hooks/useListCreateViewPattern'
import RouteConstants from '../../../constants/RouteConstants'
import {PartnersContext} from './PartnersContext'
import SidePanel from '../../SidePanel'
import {useHistory} from 'react-router-dom'
import {createPartner, updatePartner, deletePartner} from '../../../lib/queries'
import {useSelector} from 'react-redux'
import {spaceMedium} from '../../../assets/styles/spacing'
import DiscardChangesDialog from '../../DiscardChangesDialog'
import {makeStyles} from '@mui/styles'
import ConfirmDeleteDialog from '../../ConfirmDeleteDialog'
import FileHelper from '../../../lib/FileHelper'
import PreviewPartner from './PreviewPartner'
import RXRIcon from '../../RXRIcon'

const EMPTY_FORM = {
  displayName: '',
  description: '',
  shortDescription: '',
  callToAction: '',
  landingUrl: '',
  email: '',
  phoneNumber: '',
  coverImage: null,
  logo: null,
}

const validationPropObject = {
  displayName: NonEmptyStringValidator,
  description: NonEmptyStringValidator,
  shortDescription: NonEmptyStringValidator,
  callToAction: NonEmptyStringValidator,
  landingUrl: ConstructValidateDependency(URLValidator, ['email', 'phoneNumber']),
  phoneNumber: ConstructValidateDependency(PhoneNumberValidator, ['landingUrl', 'email']),
  email: ConstructValidateDependency(EmailValidator, ['phoneNumber', 'landingUrl']),
  coverImage: S3ObjectValidator,
  logo: S3ObjectValidator,
}

const validateFunction = useFormChanged.PropLevelValidation(validationPropObject)

function CreateOrEditPartner(props) {
  const classes = useStyles()
  const {removePartner, partnersLookup, isLoading, createOrUpdatePartner} = useContext(PartnersContext)
  const [isSaving, setIsSaving] = useState(false)
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [showPreview, setShowPreview] = useState(false)

  const {
    /** @type {PartnerRecord} */
    form,
    formChanged,
    setForm,
    invalidItems,
    resetInitialForm,
    validateForm,
  } = useFormChanged(EMPTY_FORM, validateFunction)
  const history = useHistory()
  const activeBuildingId = useSelector(state => state.Buildings.activeBuildingId)

  const {isViewSingleMode, isCreateMode, focusedItemId} = useListCreateViewPattern(
    RouteConstants.SETTINGS_PARTNERS_CREATE,
    RouteConstants.SETTINGS_PARTNERS_VIEW_SINGLE,
    'partnerId',
    EMPTY_FORM,
    form,
    resetInitialForm,
    partnersLookup,
    p => p,
    isLoading,
  )

  const goBack = () => history.push(RouteConstants.SETTINGS_PARTNERS)

  const handleCreate = () => {
    if (!validateForm()) {
      return
    }

    setIsSaving(true)
    createPartner({
      ...form,
      buildingId: activeBuildingId,
      logo: FileHelper.formatS3ObjectForInput(form.logo),
      coverImage: FileHelper.formatS3ObjectForInput(form.coverImage),
    })
      .then(resp => {
        createOrUpdatePartner(resp)
        resetInitialForm(resp, goBack)
      })
      .catch(err => {
        window.alert(err.message)
      })
      .finally(() => {
        setIsSaving(false)
      })
  }

  const handleUpdate = () => {
    if (!validateForm()) {
      return
    }

    setIsSaving(true)
    updatePartner({
      ...form,
      logo: FileHelper.formatS3ObjectForInput(form.logo),
      coverImage: FileHelper.formatS3ObjectForInput(form.coverImage),
    })
      .then(() => {
        createOrUpdatePartner(form)
        resetInitialForm(form, goBack)
      })
      .catch(err => {
        window.alert(err.message)
      })
      .finally(() => {
        setIsSaving(false)
      })
  }

  const handleDelete = () => {
    if (!confirmDelete) {
      setConfirmDelete(true)
      return
    }

    deletePartner(form.id, activeBuildingId)
      .then(() => {
        removePartner(form.id)
        setConfirmDelete(false)
        goBack()
      })
      .catch(err => {
        window.alert(err.message)
        setIsSaving(false)
      })
  }

  const handlePreview = () => {
    if (!validateForm()) {
      return
    }

    setShowPreview(true)
  }

  return (
    <React.Fragment>
      <SidePanel
        isOpen={isViewSingleMode || isCreateMode}
        title={isCreateMode ? 'Create a partner' : `Edit ${form.displayName}`}
        onClose={() => {
          setShowPreview(false)
          history.push(RouteConstants.SETTINGS_PARTNERS)
        }}
      >
        {showPreview ? (
          <React.Fragment>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <RXRButton onClick={() => setShowPreview(false)} type={RXRButton.TYPE_TEXT_SECONDARY}>
                  <RXRIcon icon={RXRIcon.ARROW_LONG_LEFT} /> <span>Back to form</span>
                </RXRButton>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} style={{marginTop: spaceMedium}}>
                <PreviewPartner
                  shortDescription={form.shortDescription}
                  callToAction={form.callToAction}
                  coverImage={form.coverImage}
                  logo={form.logo}
                  landingUrl={form.landingUrl}
                  description={form.description}
                  displayName={form.displayName}
                  isFeatured={form.isFeatured}
                />
              </Grid>
            </Grid>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <PartnerForm partner={form} onChange={setForm} invalidItems={invalidItems} />

            <Grid container spacing={3} style={{marginTop: spaceMedium}}>
              <Grid item xs={12} md={6}>
                {isCreateMode ? (
                  <RXRButton onClick={handleCreate} disabled={!formChanged} isLoading={isSaving}>
                    Create new partner
                  </RXRButton>
                ) : (
                  <RXRButton onClick={handleUpdate} disabled={!formChanged} isLoading={isSaving}>
                    Save changes
                  </RXRButton>
                )}
                <RXRButton onClick={goBack} type={RXRButton.TYPE_TEXT} className={classes.secondButton}>
                  Cancel
                </RXRButton>
              </Grid>
              <Grid item xs={12} md={6} style={{textAlign: 'right'}}>
                <RXRButton onClick={handlePreview} type={RXRButton.TYPE_SECONDARY}>
                  Preview
                </RXRButton>
                {isViewSingleMode && (
                  <RXRButton onClick={handleDelete} isLoading={isSaving} type={RXRButton.TYPE_DESTRUCTIVE} className={classes.secondButton}>
                    Delete
                  </RXRButton>
                )}
              </Grid>
            </Grid>
          </React.Fragment>
        )}
      </SidePanel>
      <ConfirmDeleteDialog
        isOpen={confirmDelete}
        prompt={`Are you sure you'd like to delete this Partner. This change will take effect immediately and cannot be undone.`}
        onConfirmDelete={handleDelete}
        onCancel={() => setConfirmDelete(false)}
      />
      <DiscardChangesDialog hasChanges={formChanged} />
    </React.Fragment>
  )
}

const useStyles = makeStyles(theme => ({
  secondButton: {
    marginLeft: spaceMedium,
  },
}))

CreateOrEditPartner.propTypes = {}

export default CreateOrEditPartner
