import React from 'react'
import {makeStyles} from '@mui/styles'
import Divider from '@mui/material/Divider'
import {Fragment} from 'react'
import TimeLineCard from './TimeLineCard'
import {rxrDarkGreyColor, rxrBlueColor} from '../../assets/styles/color'
import PropTypes from 'prop-types'
import Loader from '../Loader'

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(2),
  },
  divider: {
    height: '16px',
    marginLeft: '32px',
    color: rxrBlueColor,
    backgroundColor: rxrBlueColor,
    width: '2px',
  },
  emptyStateRoot: {
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  emptyStateText: {
    fontSize: 14,
    fontWeight: 400,
    color: rxrDarkGreyColor,
    paddingBottom: '40px',
  },
}))

export default function Timeline({residentEventsData, navigationType, isEventDataLoading}) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      {residentEventsData.length ? (
        residentEventsData.map((eventData, index) => {
          return (
            <Fragment key={index}>
              <TimeLineCard key={index} eventData={eventData} navigationType={navigationType} />
              {residentEventsData.length - 1 !== index && <Divider orientation={'vertical'} className={classes.divider} />}
            </Fragment>
          )
        })
      ) : (
        <div className={classes.emptyStateRoot}>
          {isEventDataLoading ? <Loader /> : <div className={classes.emptyStateText}>No events match your filters</div>}
        </div>
      )}
    </div>
  )
}

Timeline.propTypes = {
  isEventDataLoading: PropTypes.bool,
  filterStateParent: PropTypes.array,
  residentEventsData: PropTypes.array,
  navigationType: PropTypes.string,
}
