import React from 'react'
import PropTypes from 'prop-types'
import {IconButton} from '@mui/material'
import {makeStyles} from '@mui/styles'
import GenericFilterMenu from '../GenericFilterMenu'
import RXRIcon from '../RXRIcon'
import {iconButton} from '../../assets/styles/buttons'
import {rxrBlueColor} from '../../assets/styles/color'
import {MAP_FILTER_LABEL_TO_COPY} from './KYRConstants'

const useStyles = makeStyles({
  filterIconButton: {
    ...iconButton,
    color: rxrBlueColor,
  },
})

const KYRFilter = ({selectedFilters, onChange}) => {
  const classes = useStyles()
  return (
    (<GenericFilterMenu onChange={onChange} filterLabelMap={MAP_FILTER_LABEL_TO_COPY} selectedFilters={selectedFilters}>
      <IconButton className={classes.filterIconButton} size="large">
        <RXRIcon icon={RXRIcon.SLIDERS} />
      </IconButton>
    </GenericFilterMenu>)
  );
}

KYRFilter.propTypes = {
  selectedFilters: PropTypes.array,
  onChange: PropTypes.func,
}

export default KYRFilter
