import React from 'react'
import {connect} from 'react-redux'
import {compose} from 'redux'
import Paper from '@mui/material/Paper'
import {makeStyles} from '@mui/styles'
import moment from 'moment'
import {withRouter} from 'react-router-dom'
import Routes from '../../constants/RouteConstants'
import {rxrTealColor, rxrBlueColor, rxrWarmBrown, rxrErrorColor, rxrSuccessColor} from '../../assets/styles/color'
import {shadowSoft} from '../../assets/styles/color'
import {EVENT_TYPE_TO_FILTER, INSIGHT_CATEGORY_TO_LABEL, NUDGE_TYPE_TO_ICON} from '../RXOAssistant/AssistantConstants'
import RXRIcon from '../RXRIcon'
import {spaceMedium} from '../../assets/styles/spacing'
import {iconButton} from '../../assets/styles/buttons'
import {IconButton} from '@mui/material'

const useStyles = makeStyles(theme => ({
  row1: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 16,
  },
  row2: {
    marginBottom: 18,
  },
  row3: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  iconsContainer: {
    float: 'right',
  },
  icon: {
    ...iconButton,
    marginLeft: spaceMedium,
  },
  cardContainer: props => ({
    borderRadius: 0,
    paddingTop: 14,
    paddingBottom: 11,
    paddingLeft: 16,
    paddingRight: 24,
    width: '100%',
    minHeight: 151,
    marginTop: 16,
    '&:focus': {
      outline: '0px auto transparent',
    },
    boxShadow: shadowSoft,
    borderLeft: `8px solid transparent`,
    ...(props.isHighPriority
      ? {
          borderLeft: `8px solid`,
          borderLeftColor: rxrErrorColor,
        }
      : {}),
  }),
  categoryTitleStyle: {
    fontSize: '16px',
    color: rxrTealColor,
    cursor: 'pointer',
    marginTop: '25px',
  },
  dateTimeStyle: {
    marginLeft: '15px',
    fontSize: '12px',
    color: rxrTealColor,
    letterSpacing: '1.3px',
  },
  lableStyle: {
    fontSize: '16px',
    color: rxrBlueColor,
  },
  remainingDays: {
    fontSize: '12px',
    color: rxrWarmBrown,
    textTransform: 'uppercase',
    letterSpacing: '1.3px',
  },
  checkIcon: {
    minWidth: '12px',
    cursor: 'pointer',
    marginRight: '32px',
  },
  closeIcon: {
    minWidth: '12px',
    cursor: 'pointer',
  },
  eventTypeContainer: {
    backgroundColor: 'rgba(46, 156, 202, 0.15)',
    borderRadius: '20px',
    height: 26,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 8,
    paddingRight: 8,
  },
  eventType: {fontSize: 12, textTransform: 'uppercase', color: '#0C4067', letterSpacing: '1.3px'},
}))

const KYRInsightCard = ({insightData, ...props}) => {
  const residentInfo = props.residentsLookup[insightData.residentId]
  const dateTimeObj = new Date(insightData.triggeredAt)
  let isHighPriority = insightData.isHighPriority
  const classes = useStyles({isHighPriority: isHighPriority})

  let remainingDays = moment(insightData.expiredAt).diff(new Date(), 'days')
  let daysLeftString = ''

  if (remainingDays > 1) {
    daysLeftString = `${remainingDays} days left`
  } else if (remainingDays > 0) {
    daysLeftString = `${remainingDays} day left`
  }
  const dateTime = `${moment(dateTimeObj).format('MM/DD').toUpperCase()}`

  const handleCardClick = residentId => {
    const params = {residentId: residentId}
    props.history.push(Routes.constructPath(Routes.PROFILE_VIEW_SINGLE, params))
  }

  const handleCheckClick = nudgeId => {
    const params = {buildingNudgeId: nudgeId}
    props.history.push(Routes.constructPath(Routes.RXO_ASSISTANT_LOG_ACTION_FROM_NUDGE, params))
  }

  return (
    (<Paper key={props.key} className={classes.cardContainer}>
      <div className={classes.row1}>
        <div>
          <RXRIcon size={14} icon={NUDGE_TYPE_TO_ICON[insightData.type] || NUDGE_TYPE_TO_ICON.DEFAULT} color={rxrBlueColor} />
          <span className={classes.dateTimeStyle}>{dateTime}</span>
        </div>
        <span className={classes.remainingDays}>{`${daysLeftString}`}</span>
      </div>
      <div className={classes.row2}>
        <span className={classes.categoryTitleStyle} onClick={() => handleCardClick(insightData.residentId)}>
          {residentInfo.displayName}
        </span>
        <span className={classes.lableStyle}>{insightData.label.replace(residentInfo.displayName, '')}</span>
      </div>
      <div className={classes.row3}>
        <div className={classes.eventTypeContainer}>
          <p className={classes.eventType}>
            {INSIGHT_CATEGORY_TO_LABEL[EVENT_TYPE_TO_FILTER[insightData.type] || EVENT_TYPE_TO_FILTER.DEFAULT]}
          </p>
        </div>
        <div className={classes.iconsContainer}>
          <IconButton
            className={classes.icon}
            onClick={() => handleCheckClick(insightData.id)}
            size="large">
            <RXRIcon icon={RXRIcon.CHECK} color={rxrSuccessColor} />
          </IconButton>
          <IconButton
            className={classes.icon}
            onClick={() => props.ignoreInsight(insightData.id)}
            size="large">
            <RXRIcon icon={RXRIcon.CLOSE} color={rxrTealColor} />
          </IconButton>
        </div>
      </div>
    </Paper>)
  );
}

const mapStateToProps = state => ({
  residentsLookup: state.Residents.residentsLookup,
})

export default compose(withRouter, connect(mapStateToProps))(KYRInsightCard)
