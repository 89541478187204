import React from 'react'
import {makeStyles} from '@mui/styles'
import {rxrWhiteColor, rxrSuccessColor} from '../../assets/styles/color'
import {useSelector} from 'react-redux'
import PropTypes from 'prop-types'
import ProfileImage from '../ProfileImage'

function ResidentProfileImage(props) {
  const classes = useStyles()
  const resident = useSelector(state => (props.residentId ? state.Residents.residentsLookup[props.residentId] : null))

  return (
    <ProfileImage
      className={props.className}
      displayName={resident && resident.displayName}
      s3Object={resident && resident.profileImage}
      colorId={props.residentId}
      onUploaded={props.onUploaded}
    >
      {!props.hideAppStatus && !!resident && !!resident.dateClaimed && <div className={classes.hasAppCircle} />}
    </ProfileImage>
  )
}

const useStyles = makeStyles(theme => ({
  hasAppCircle: {
    position: 'absolute',
    background: rxrSuccessColor,
    borderRadius: '50%',
    height: '20%',
    width: '20%',
    right: '5%',
    bottom: '5%',
    border: '2px solid',
    borderColor: rxrWhiteColor,
  },
}))

ResidentProfileImage.propTypes = {
  residentId: PropTypes.string,
  className: PropTypes.string, // styles to pass the container (such as, height, width, borders, etc
  hideAppStatus: PropTypes.bool, // hides the has app circle if true
  onUploaded: PropTypes.func,
}

export default ResidentProfileImage
