import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import useResidentLookup from '../hooks/useResidentLookup'
import {listPetProfiles} from '../../lib/queries'
import SimpleSpinner from '../SimpleSpinner'
import CustomCheckbox from '../CustomCheckbox'
import {H4HeaderWithoutBold} from '../../assets/styles/typography'
import {rxrBlueColor, rxrRedColor} from '../../assets/styles/color'
import {Typography} from '@mui/material'
import moment from 'moment'
import {EXTRA_NOTES_DIVIDER} from './servicesUtilities'
import DefaultSelectAddOns from './DefaultSelectAddOns'

/**
 * ---------------------------------------------------------------------------
 *  THIS IS COPIED FROM ../../../ui/components/VendorServices/serviceUtilities
 * @param {Array<PetProfileFragment>} petProfiles
 * @return {string}
 */
function getExtraNotesFromPetProfiles(petProfiles) {
  let retVal = ''
  try {
    retVal = petProfiles.reduce((retVal, p) => {
      const weightStr = p.weight ? `${p.weight}# ` : ''
      const ageStr = p.birthday ? `${moment().from(p.birthday, true)} old ` : ''
      const breedStr = p.breed ? p.breed : ''
      const petNotesStr = p.notes ? ` -- ${p.notes}` : ''

      const thisPetNotes = `${p.displayName} (${weightStr}${ageStr}${breedStr})${petNotesStr}`

      return `${retVal ? `${retVal}\n` : ''}${thisPetNotes}`
    }, '')
  } catch (err) {
    retVal = `Error generating pet profile notes: ${err.message}. Sorry for the inconvenience`
  }
  return `${retVal}${EXTRA_NOTES_DIVIDER}`
}

// ---------------------------------------------------------------------------

function SelectPetAddOns(props) {
  const [isLoadingPets, setIsLoadingPets] = useState(true)
  // we need to copy the selected pets into state because we won't have access to metaPetProfileIds
  const [selectedPets, setSelectedPets] = useState([]) // array of pet ids
  const [incrementalPriceOfExtraDog, setIncrementalPriceOfExtraDog] = useState(0)
  const [petProfiles, setPetProfiles] = useState([])
  const {getResident} = useResidentLookup()

  const maxDogs = props.service.addOnOptions.reduce((agr, a) => Math.max(agr, parseInt(a.label)), 0)

  useEffect(() => {
    listPetProfiles(getResident(props.residentId).occupancy.id)
      .catch(err => {
        window.alert(err.message)
      })
      .then(pets => {
        setPetProfiles(pets)
      })
      .finally(() => setIsLoadingPets(false))

    // the add on with "2 dogs" in the name shows the price for 1 extra dog
    let firstAddOnePrice = props.service.addOnOptions.find(a => parseInt(a.label) === 2)
    if (firstAddOnePrice) {
      setIncrementalPriceOfExtraDog(firstAddOnePrice.price)
    }
  }, [])

  useEffect(() => {
    if (props.appointment) {
      setSelectedPets(props.appointment.petProfiles.items.map(p => p.petProfile.id))
    }
  }, [props.appointment])

  const handleTogglePet = pet => {
    let indexOf = selectedPets.indexOf(pet.id)
    let nextPetsArr = indexOf < 0 ? [...selectedPets, pet.id] : [...selectedPets.slice(0, indexOf), ...selectedPets.slice(indexOf + 1)]

    // The first pet is free, so if they haven't selected at least 2 pets then there are no addons, so we'll keep nextAdOn as undefined
    let nextAddOn
    if (nextPetsArr.length > 1) {
      // if they're trying to select too many pets (more than our addons support), we exit without modifying state
      if (nextPetsArr.length > maxDogs) {
        return
      }

      // if they have, then we select the AddOn which matches that number of pets
      nextAddOn = props.service.addOnOptions.find(a => parseInt(a.label) === nextPetsArr.length)
    }

    setSelectedPets(nextPetsArr)
    props.updateForm({
      metaPetProfileIds: nextPetsArr,
      extraNotes: getExtraNotesFromPetProfiles(nextPetsArr.map(pId => petProfiles.find(p => p.id === pId))),
      addOns: nextAddOn
        ? [
            {
              label: nextAddOn.label,
              cost: nextAddOn.price,
            },
          ]
        : null,
    })
  }

  return (
    <React.Fragment>
      <Typography style={{...H4HeaderWithoutBold, color: props.hasError ? rxrRedColor : rxrBlueColor}}>Select pets</Typography>
      {isLoadingPets ? (
        <SimpleSpinner />
      ) : petProfiles.length > 0 ? (
        petProfiles.map(p => {
          const selectedPetsIndex = selectedPets.indexOf(p.id)
          const isSelected = selectedPetsIndex >= 0
          // we pretend they're all "firstPet" when the input is disabled as shorthand to hide the price label
          const isFirstPet = selectedPetsIndex === 0 || props.isDisabled
          return (
            <CustomCheckbox
              key={p.id}
              label={p.displayName + (selectedPets.length > 0 && !isFirstPet ? ` (+$${incrementalPriceOfExtraDog})` : ``)}
              onChange={() => handleTogglePet(p)}
              isChecked={isSelected}
              isDisabled={props.isDisabled || (!isSelected && selectedPets.length >= maxDogs)}
            />
          )
        })
      ) : (
        <Typography>This resident has no pets</Typography>
      )}
      <DefaultSelectAddOns
        isDisabled={props.isDisabled}
        residentId={props.residentId}
        updateForm={props.updateForm}
        appointment={props.appointment}
        addOns={(props.addOns || []).filter(addOn => {
          if (!props.service) {
            return true
          } else if (!props.service.addOnOptions) {
            return true
          } else if (!props.service.addOnOptions.map(addOnOption => addOnOption.label).includes(addOn.label)) {
            return true
          } else {
            return false
          }
        })}
        hasError={props.hasError}
        doNotShowTitle={true}
      />
      {props.hasError && <Typography style={{color: rxrRedColor}}>Must select at least 1 pet</Typography>}
    </React.Fragment>
  )
}

SelectPetAddOns.propTypes = {
  updateForm: PropTypes.func.isRequired,
  residentId: PropTypes.string.isRequired,
  service: PropTypes.any.isRequired,
  appointment: PropTypes.any,
  addOns: PropTypes.array,
  isDisabled: PropTypes.bool,
  hasError: PropTypes.bool,
}

export default SelectPetAddOns
