import React from 'react'
import moment from 'moment'
import {Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import PropTypes from 'prop-types'
import {bodyText, H4HeaderBlack, totalText} from '../../../assets/styles/typography'
import {spaceExtraLarge, spaceExtraSmall, spaceSmall} from '../../../assets/styles/spacing'
import {rxrBlackColor} from '../../../assets/styles/color'

const useStyles = makeStyles(theme => ({
  rowContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  totalContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: spaceSmall,
    borderTop: '1px solid black',
  },
  totalText: {
    ...totalText,
  },
  H4HeaderBlack: {
    ...H4HeaderBlack,
  },
  sectionContainer: {
    paddingBottom: spaceExtraLarge,
  },
  bodyText: {...bodyText},
  subtitleText: {
    ...bodyText,
    fontWeight: 'normal',
    fontStyle: 'italic',
  },
}))

export default function ChargesTable(props) {
  const classes = useStyles()

  return (
    <div className={classes.sectionContainer}>
      <Typography className={classes.H4HeaderBlack}>
        Charges <span className={classes.subtitleText}>(last 90 days)</span>
      </Typography>
      {props.charges && props.charges.length > 0 ? (
        <React.Fragment>
          {props.charges.map(charge => {
            return (
              <div key={charge.chargeId} className={classes.rowContainer}>
                <Typography className={classes.bodyText}>{charge.label}</Typography>
                <Typography className={classes.bodyText}>{`$${charge.amount.toFixed(2)}`}</Typography>
              </div>
            )
          })}
          <div className={classes.totalContainer}>
            <Typography className={classes.totalText}>Total charges last 90 days</Typography>
            <Typography className={classes.totalText}>
              $
              {`${props.charges
                .reduce((accum, element) => {
                  return accum + element.amount
                }, 0)
                .toFixed(2)}`}
            </Typography>
          </div>
        </React.Fragment>
      ) : (
        <Typography className={classes.bodyText}>Resident does not currently have any charges</Typography>
      )}
    </div>
  )
}

ChargesTable.propTypes = {
  charges: PropTypes.array,
}
