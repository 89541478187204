import React, {forwardRef} from 'react'
import {makeStyles} from '@mui/styles'
import PictureChatMessage from './PictureChatMessage'
import {Colors} from './../../assets/styles'
import PropTypes from 'prop-types'
import EditableTextChatMessage from './EditableTextChatMessage'
import StaffProfileImage from './StaffProfileImage'
import ResidentProfileImage from '../ResidentProfiles/ResidentProfileImage'
import {constructClassString} from '../../Utils/objectUtil'
import {fontSizeSmall} from '../../assets/styles/typography'
import {spaceSmall} from '../../assets/styles/spacing'

const PICTURE_DIMENSION = 30

const useStyles = makeStyles(theme => ({
  threadContainer: {
    textAlign: 'center',
    width: '100%',
    fontSize: '12px',
    ...Colors.darkGrey,
  },
  senderName: {
    fontWeight: 'bold',
  },
  chatMessageContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    '&.staff': {
      flexDirection: 'row-reverse',
    },
  },
  messageContent: {
    margin: `0 ${spaceSmall}px`,
    width: '100%',
  },
  avatarContainer: {
    height: PICTURE_DIMENSION,
    width: PICTURE_DIMENSION,
    flexShrink: 0,
  },
  author: {
    '& span': {
      fontSize: fontSizeSmall,
    },
  },
}))

const ChatMessage = forwardRef(function ChatMessage(props, ref) {
  const classes = useStyles()

  if (!props.message) {
    return null
  }

  const isStaffMessage = props.message.chatUser && props.message.chatUser.isStaffUser

  let messageRender
  if (props.message.hasAttachments) {
    // TODO: just supporting 1 image attachment at a time
    messageRender = <PictureChatMessage s3Object={props.message.attachments[0]} />
  } else {
    messageRender = (
      <EditableTextChatMessage
        message={props.message}
        isSendingMessage={props.isSendingMessage}
        onEdit={props.onEdit}
        onDelete={props.onDelete}
      />
    )
  }

  return (
    <div className={constructClassString({staff: isStaffMessage}, classes.chatMessageContainer, props.className)} ref={ref}>
      <div className={classes.avatarContainer}>
        {isStaffMessage ? (
          <StaffProfileImage staffId={props.message.chatUser.userId} />
        ) : (
          <ResidentProfileImage residentId={props.message.chatUser.userId} hideAppStatus={true} />
        )}
      </div>
      <div className={classes.messageContent}>
        <div className={classes.author}>
          {props.message.chatUser.displayName} <span>{isStaffMessage ? 'RXO' : 'Resident'}</span>
        </div>
        {messageRender}
      </div>
    </div>
  )
})

ChatMessage.propTypes = {
  message: PropTypes.object.isRequired,
  isSendingMessage: PropTypes.bool,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  className: PropTypes.string,
}

export default ChatMessage
