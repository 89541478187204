import React from 'react'
import {makeStyles} from '@mui/styles'
import {Colors} from '../../assets/styles'
import PropTypes from 'prop-types'
import {fontSizeExtraSmall, fontSizeMedium} from '../../assets/styles/typography'

export const VARIANT_COMPACT = 'compact'
export const VARIANT_DEFAULT = 'default'

function GenericTag(props) {
  const classes = useStyles()

  const containerClass = props.variant === VARIANT_COMPACT ? classes.containerCompact : classes.containerDefault

  return (
    <span
      className={[containerClass, props.className].join(' ')}
      style={{
        backgroundColor: props.background || Colors.rxrDarkGreyColor,
        color: props.foreground || Colors.rxrWhiteColor,
      }}
    >
      {props.text}
    </span>
  )
}

const commontStyles = {
  borderRadius: fontSizeMedium,
  fontSize: fontSizeExtraSmall,
  lineHeight: `${fontSizeExtraSmall}px`,
  display: 'inline-block',
}

const useStyles = makeStyles(theme => ({
  containerCompact: {
    ...commontStyles,
    padding: `2px 6px`,
  },
  containerDefault: {
    ...commontStyles,
    padding: `4px 10px`,
  },
}))

GenericTag.propTypes = {
  text: PropTypes.string.isRequired,
  variant: PropTypes.oneOf([VARIANT_DEFAULT, VARIANT_COMPACT]),
  background: PropTypes.string,
  foreground: PropTypes.string,
  className: PropTypes.string,
}

export default GenericTag
