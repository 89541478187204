import React, {useEffect, useState} from 'react'
import useDoOnceTimer from '../hooks/useDoOnceTimer'
import moment from 'moment'
import {makeStyles} from '@mui/styles'
import {Colors, Spacing, Typography} from '../../assets/styles'

const CURRENT_TIME_WIDTH = 66

function CurrentTimeLine(props) {
  const classes = calendarStyles()
  const [currentTimeStr, setCurrentTimeStr] = useState('')
  const [topPos, setTopPos] = useState(0)
  const {setTimer} = useDoOnceTimer()

  useEffect(() => {
    const updateTime = () => {
      const diffInMins = moment().diff(moment().startOf('day'), 'minutes')
      const newTop = props.onUpdatePosition(diffInMins)
      setTopPos(newTop)
      setCurrentTimeStr(moment().format('h:mm A'))
      setTimer('update-time-str', updateTime, 60000 - parseInt(moment().format('ss')) * 1000)
    }

    updateTime()
  }, [])

  return (
    <div className={classes.currentTime} style={{top: topPos}}>
      <span>{currentTimeStr}</span>
    </div>
  )
}

const calendarStyles = makeStyles(theme => ({
  currentTime: {
    position: 'absolute',
    width: `100%`,
    height: 2,
    backgroundColor: Colors.rxrOrangeColor,
    top: 0,
    overflow: 'visible',
    zIndex: 6,

    '& span': {
      display: 'inline-block',
      padding: `${Spacing.spaceExtraExtraSmall}px 0`,
      textAlign: 'center',
      fontSize: Typography.fontSizeSmall,
      backgroundColor: Colors.rxrOrangeColor,
      color: Colors.rxrWhiteColor,
      borderRadius: 4,
      position: 'relative',
      // half the current line component height
      top: `-12px`,
      width: CURRENT_TIME_WIDTH,
      left: -CURRENT_TIME_WIDTH,
    },
  },
}))

export default CurrentTimeLine
