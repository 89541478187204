import moment from 'moment'

/**
 * @param {string} idSeed -- probably the amenity id
 * @param {number} startTime
 * @param {number} endTime
 * @param {moment|Date} startOfDay
 * @param {Array<GeneralAvailability|*>} gAs -- these should all be for a single day, ordered by startTime ASC, and not overlapping
 * @returns {Array<{id: string, startAt: string, endAt: string}>}
 */
export function convertGeneralAvailabilityIntoClosureEvents(idSeed, startTime, endTime, startOfDay, gAs) {
  const retVal = []
  const dow = moment(startOfDay).format('d')

  if (gAs.length === 0) {
    // closed all day this day
    retVal.push({
      id: `${idSeed}-closed-all-day-${dow}`,
      startAt: moment(startOfDay)
        .add(startTime, 'minutes')
        .toISOString(),
      endAt: moment(startOfDay)
        .add(endTime, 'minutes')
        .toISOString(),
    })
  } else {
    // this is kind of hard to explain, but basically we imagine our availabilities sitting in the startTime/end box like this:
    // [_____[XXXXX]_____[XXXXXX]_______] (fig A)
    // and we want to create blocks for all the gaps between availabilities, like so:
    // [XXXXX]_____[XXXXX]______[XXXXXXX] (fig B)
    // so we initialize our earliestStart variable to startTime (the left most '[' character in fig A)
    // and we create a block from that time to the start of the first availability in fig A
    // once created, we increment our earliest start to be the end of the availability period just processed
    // and repeat
    let earliestStart = startTime
    gAs.forEach((a, i) => {
      if (a.startTime > earliestStart) {
        retVal.push({
          id: `${idSeed}-closed-${a.id}-start`,
          startAt: moment(startOfDay)
            .add(earliestStart, 'minutes')
            .toISOString(),
          endAt: moment(startOfDay)
            .add(a.startTime, 'minutes')
            .toISOString(),
        })
      }
      // we increment our earliest start to be the end of the availability period just processed
      earliestStart = a.endTime

      // if this is the last availability block for this day of week, and it doesn't extend to the end of our endTime period
      // then we need to create one last closure from its end time to the end of grid
      if (i === gAs.length - 1 && a.endTime < endTime) {
        retVal.push({
          id: `${idSeed}-closed-${a.id}-end`,
          startAt: moment(startOfDay)
            .add(earliestStart, 'minutes')
            .toISOString(),
          endAt: moment(startOfDay)
            .add(endTime, 'minutes')
            .toISOString(),
        })
      }
    })
  }

  return retVal
}

/**
 * @param {Date|string} date
 * @returns {number}
 */
function getMinutesSinceMidnight(date) {
  return moment(date).diff(moment(date).startOf('day'), 'minutes')
}

/**
 * @param {Date} date
 * @param {number} generalAvailabilityStartTime
 * @return {number}
 */
const getMinutesFromStartOfAvailability = (date, generalAvailabilityStartTime) => {
  let startOfDay = moment(date)
    .startOf('day')
    .add(generalAvailabilityStartTime, 'minutes')
  return moment(date).diff(startOfDay, 'minutes')
}

/**
 * @param {{start: Date, end:Date}} range
 * @param {number} generalAvailabilityStartTime
 * @param {number} reservationIncrement
 * @returns {{start: Moment, end: Moment}}
 */
const shiftRangeToStartOfNextIncrement = (range, generalAvailabilityStartTime, reservationIncrement) => {
  let addiditionToReservationStartTime =
    (reservationIncrement - getMinutesFromStartOfAvailability(range.start, generalAvailabilityStartTime)) % reservationIncrement
  if (addiditionToReservationStartTime !== 0) {
    addiditionToReservationStartTime = addiditionToReservationStartTime + reservationIncrement
  }
  return {
    start: moment(range.start)
      .add(addiditionToReservationStartTime, 'm')
      .local(),
    end: moment(range.end).local(),
  }
}

/**
 * This function divides a list of ranges into smaller chunks based on blockSize
 * @param {Array<{start: Date, end: Date}>} amenityAvailability
 * @param {Array<{startTime: number, endTime: number, dayOfWeek: number}>} generalAvailability
 * @param {number} reservationIncrement
 * @param {number} blockSize
 * @return {Array<{start: Date, end: Date}>}
 */
export function chunkAvailabilityTimeRanges(amenityAvailability, generalAvailability, reservationIncrement, blockSize) {
  let timeSeries = []
  amenityAvailability.forEach(range => {
    const rangeStartMinutes = getMinutesSinceMidnight(range.start)
    const todayGA = generalAvailability.find(
      gA => gA.dayOfWeek === moment(range.start).day() && gA.startTime <= rangeStartMinutes && gA.endTime > rangeStartMinutes,
    )

    const {start, end} = shiftRangeToStartOfNextIncrement(range, todayGA ? todayGA.startTime : 0, reservationIncrement)

    while (
      moment(start)
        .add(blockSize, 'minutes')
        .isSameOrBefore(end)
    ) {
      let thisStart = moment(start)

      // add reservationIncrement minutes to the starting point
      timeSeries.push({
        start: thisStart.toDate(),
        end: start.add(blockSize, 'minutes').toDate(),
      })
    }
  })

  return timeSeries
}

/**
 * This creates options to be used in a select input
 * @param {Array<{start: Date, end: Date}>} amenityAvailability
 * @param {Array<{startTime: number, endTime: number, dayOfWeek: number}>} generalAvailability
 * @param {number} reservationIncrement
 * @param {number} minDuration
 * @return {Array<{value: string, label: string, canStart: bool}>}
 */
export function convertAvailabilityToTimeRangeOptions(amenityAvailability, generalAvailability, reservationIncrement, minDuration) {
  let timeSeries = []
  amenityAvailability.forEach((range, i) => {
    const rangeStartMinutes = getMinutesSinceMidnight(range.start)
    const todayGA = generalAvailability.find(
      gA => gA.dayOfWeek === moment(range.start).day() && gA.startTime <= rangeStartMinutes && gA.endTime > rangeStartMinutes,
    )

    // we add extra time to our start time to ensure we're starting at a reservationIncrement boundary
    const {start, end} = shiftRangeToStartOfNextIncrement(range, todayGA ? todayGA.startTime : 0, reservationIncrement)

    while (!start.isAfter(end)) {
      let thisTime = moment(start)
      // add reservationIncrement minutes to the starting point
      timeSeries.push({
        value: thisTime.toISOString(), // select values are strings, but we cast to date when saving
        label: thisTime.format('hh:mm A'),
        canStart: !moment(thisTime)
          .add('m', minDuration)
          .isAfter(end),
      })
      start.add(reservationIncrement, 'm')
    }

    if (amenityAvailability.length - 1 > i) {
      // add blank object for separation
      // if value is blank string then it will be treated as a disabled option. Follow SelectInput.js for more info.
      timeSeries.push({
        value: '',
        label: '----',
        canStart: true, // we mark it as true so it appears in the startTime select list
      })
    }
  })

  return timeSeries
}

/**
 * This function takes a closure object and returns an array of closure representing the input closure split by day
 * @param {{startAt: string, endAt: string, label: string}} closure
 * @return {Array<{startAt: string, endAt: string, label: string}>}
 */
export function splitClosureByDay(closure) {
  let startAt = moment(closure.startAt)
  let endAt = moment(closure.endAt)

  let retVal = []
  while (startAt.isBefore(endAt)) {
    retVal.push({
      ...closure,
      startAt: startAt.toISOString(),
      endAt: moment.min([startAt.clone().endOf('day'), endAt]).toISOString(),
    })
    startAt = startAt
      .clone()
      .add(1, 'day')
      .startOf('day')
  }
  return retVal
}

/**
 * @param {Array<*>} closures
 * @return {Array<*>}
 */
export function splitAndFlattenClosuresByDay(closures) {
  if (!closures || closures.length === 0) {
    return []
  }
  return closures.reduce((agr, c) => agr.concat(splitClosureByDay(c)), [])
}
