// TODO: There's no way for a staff user to "join" a ChatRoom as a participant.
//  When we eventually enable the participantId auth rules, they'll need some way to join chats before they can read/send messages

import React, {useEffect, useRef, useState} from 'react'
import ChatContainerHeader from './ChatContainerHeader'
import Loader from '../Loader'
import ChatMessage from './ChatMessage'
import Alert from '@mui/material/Alert'
import RXRIcon from '../RXRIcon'
import {Colors, Spacing, TextFieldBorders} from '../../assets/styles'
import Constant from './MessageConstant'
import ChatContainerInput from './ChatContainerInput'
import {makeStyles} from '@mui/styles'
import {spaceMedium, spaceSmall} from '../../assets/styles/spacing'
import Pollable from '../../lib/Pollable'
import {loadMessagesByConversation, iterateMessagesByConversation} from './ChatContainerQueries'
import {formatChatMessages} from '../../Utils/chatUtils'
import {useSelector} from 'react-redux'
import PropTypes from 'prop-types'
import RXRButton from '../RXRButton'
import {constructClassString} from '../../Utils/objectUtil'
import ResidentForecasterCard from '../ResidentSentiment/Forecaster/ResidentForecasterCard'

function ChatContainer(props) {
  const classes = useStyles()

  // initialize with no function
  const [isLoadingOlderMessages, setIsLoadingOlderMessages] = useState(false)
  const [hasOlderMessages, setHasOlderMessages] = useState(true)
  const pollable = useRef(new Pollable('Messages', 5000, undefined))
  const olderMessageIterator = useRef(undefined)
  const targetMessageRef = useRef()
  const scrollBottomRef = useRef()
  const [sendingMessage, setSendingMessage] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [messages, setMessages] = useState([])
  const staffLookup = useSelector(state => state.Staff.staffLookup)

  const activeBuilding = useSelector(state => state.Buildings.buildingsLookup[state.Buildings.activeBuildingId])
  const conversation = useSelector(state => (props.conversationId ? state.Messages.conversationsLookup[props.conversationId] : null))
  const residentsInChat = conversation?.chatUsers?.filter(u => !u.isStaffUser)
  const singleResidentId = residentsInChat.length === 1 ? residentsInChat[0].id : null
  const [isLookingForSpecificMessage, setIsLookingForSpecificMessage] = useState(false)

  const scrollToBottom = () => {
    setTimeout(() => {
      if (scrollBottomRef.current) {
        scrollBottomRef.current.scrollIntoView({
          behavior: 'auto',
        })
      }
    }, 400)
  }

  const scrollToTargetMessage = () => {
    setTimeout(() => {
      if (targetMessageRef.current) {
        targetMessageRef.current.scrollIntoView({
          behavior: 'auto',
        })
      }
    }, 400)
  }

  // this function will use the iterative query we have stored to fetch progressively older messages
  const loadOlderMessages = async reset => {
    if (!reset && (isLoadingOlderMessages || !hasOlderMessages)) {
      return
    }

    setIsLoadingOlderMessages(true)
    const res = formatChatMessages(conversation, await olderMessageIterator.current.next(), staffLookup)
    if (res.length > 0) {
      // our sort direction is DESCENDING
      setMessages(reset ? res : [...messages, ...res])
    }

    if (olderMessageIterator.current.hasCompleted) {
      setHasOlderMessages(false)
    }

    setIsLoadingOlderMessages(false)

    return res
  }

  useEffect(() => {
    if (isLookingForSpecificMessage) {
      if (messages.find(m => m.id === props.targetMessageId)) {
        setIsLookingForSpecificMessage(false)
        scrollToTargetMessage()
      } else if (!isLoadingOlderMessages) {
        // keep loading older messages until we find the one we need
        // an edge case where the message is not found or does not exist will be handled by the loadOlderMessages function
        // which will exit once it has no more messages to load
        loadOlderMessages().then()
      }
    } else {
      // do nothing
    }
  }, [isLookingForSpecificMessage, isLoadingOlderMessages, messages])

  // when new messages are found, we update our polling function so it can detect new messages considering the latest messages data
  useEffect(() => {
    pollable.current.updateQueryFunction(async () => {
      await loadMessagesByConversation(conversation.id, conversation.isOnboardingChat)
        .then(res => formatChatMessages(conversation, res, staffLookup))
        .then(res => {
          if (res.length > 0) {
            const mostRecent = res[0]
            const previousMostRecent = messages[0]

            if (!previousMostRecent) {
              setMessages(res)
              scrollToBottom()
            } else if (mostRecent.id !== previousMostRecent.id) {
              // add all the new messages to our messages array
              const upToIndex = res.findIndex(m => m.id === previousMostRecent.id)
              setMessages([...res.slice(0, upToIndex >= 0 ? upToIndex : messages.length), ...messages])
              scrollToBottom()
            }
          }
        })
        .catch(err => console.error(err))
    })
  }, [messages])

  useEffect(() => {
    // stop polling and show a loading screen
    pollable.current.stopPolling()
    setIsLoading(true)

    // set our new iterator query to use this conversation id
    olderMessageIterator.current = iterateMessagesByConversation(conversation.id, conversation.isOnboardingChat)

    // reset our message state variables
    setMessages([]) // this will cause our polling query to update as well
    setHasOlderMessages(true)
    setIsLoadingOlderMessages(false)

    // load our initial batch of messages
    loadOlderMessages(true)
      .then(() => {
        // once we have our initial query back, we resume polling
        pollable.current.startPolling(true)
        // if we don't have a target message, scroll to the bottom,
        // if we do, we have another useEffect that will handle loading & scrolling to the target message
        if (!props.targetMessageId) {
          scrollToBottom()
        }
      })
      .finally(() => {
        setIsLoading(false)
      })

    return () => {
      pollable.current.stopPolling()
    }
  }, [props.conversationId])

  useEffect(() => {
    setIsLookingForSpecificMessage(!!props.targetMessageId)
  }, [props.targetMessageId])

  if (!conversation) {
    return null
  }

  /**
   * @param {GenericChatMessage} newMessage
   */
  const handleMessageSent = newMessage => {
    // add the message to our list
    setMessages(m => [newMessage, ...m])
    setSendingMessage(null)
    scrollToBottom()
  }

  const handleSendingMessage = (newMessage = null) => {
    // add the place holder message to our list
    // TODO: this can only handle 1 sending message at a time :/
    setSendingMessage(newMessage)
    if (newMessage) {
      scrollToBottom()
    }
  }

  const handleEditMessage = editedMessage => {
    // we swap the message with the new one
    setMessages(m => m.map(m => (m.id === editedMessage.id ? editedMessage : m)))
  }

  const handleDeleteMessage = deletedMessage => {
    // remove the message from our list
    setMessages(m => m.filter(m => m.id !== deletedMessage.id))
  }

  return (
    <div className={classes.root}>
      <ChatContainerHeader conversationId={props.conversationId} />
      <div className={classes.chatContainerStyle}>
        {isLoading ? (
          <Loader />
        ) : (
          <React.Fragment>
            {hasOlderMessages && (
              <div className={classes.loadMoreButton}>
                <RXRButton onClick={() => loadOlderMessages()} isLoading={isLoadingOlderMessages} type={RXRButton.TYPE_TEXT}>
                  Load older messages
                </RXRButton>
              </div>
            )}
            {/* messages are stored DESC, but we want to print ASC */}
            {[...messages].reverse().map(
              /** @type {GenericChatMessage} message */ message => {
                return (
                  <ChatMessage
                    key={message.id}
                    message={message}
                    onEdit={handleEditMessage}
                    onDelete={handleDeleteMessage}
                    className={constructClassString(
                      {
                        [classes.staffBubble]: !!message.chatUser.isStaffUser,
                        [classes.targetMessage]: message.id === props.targetMessageId,
                      },
                      classes.bubbleContainer,
                    )}
                    ref={message.id === props.targetMessageId ? targetMessageRef : null}
                  />
                )
              },
            )}
          </React.Fragment>
        )}
        {sendingMessage ? (
          <ChatMessage
            message={sendingMessage}
            isSendingMessage={true}
            className={[classes.staffBubble, classes.bubbleContainer].join(' ')}
          />
        ) : null}
        {!activeBuilding.outOfOfficeStatus && (
          <div className={classes.OOOContainer}>
            <Alert
              icon={<RXRIcon icon={RXRIcon.INFO} color={Colors.rxrDarkGreyColor} />}
              className={classes.OOOAlert}
              variant="outlined"
              severity="info"
            >
              {Constant.OOO_MESSAGE}
            </Alert>
          </div>
        )}
        <div style={{float: 'left', clear: 'both'}} ref={scrollBottomRef} />
      </div>
      <ResidentForecasterCard className={classes.forecaster} residentId={singleResidentId} />
      <ChatContainerInput onMessageSent={handleMessageSent} onMessageSending={handleSendingMessage} />
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    backgroundColor: Colors.rxrWhiteColor,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    position: 'relative',
  },
  chatContainerStyle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    overflowY: 'scroll',
    paddingBottom: spaceMedium,
    height: '100%',
    flexGrow: 1,
  },
  OOOContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    alignItems: 'flex-end',
  },
  OOOAlert: {
    backgroundColor: Colors.rxrWhiteColor,
    color: Colors.rxrDarkGreyColor,
    ...TextFieldBorders.textfieldInputBorder,
    margin: '24px 24px 0px 24px',
    width: '100%',
  },

  bubbleContainer: {
    maxWidth: '75%',
    margin: spaceMedium,
    marginBottom: 0,
  },
  staffBubble: {
    textAlign: 'right',
    marginLeft: '25%',
  },
  targetMessage: {
    backgroundColor: Colors.rxrSuccess25Color,
    padding: Spacing.spaceExtraSmall,
    borderRadius: 4,
  },
  lastSeenText: {
    marginTop: '12px',
    fontSize: '12px',
    color: Colors.rxrDarkGreyColor,
  },
  loadMoreButton: {
    padding: spaceSmall,
    margin: '0 auto',
  },
  forecaster: {
    margin: Spacing.spaceSmall,
    flexShrink: 0,
  },
}))

ChatContainer.propTypes = {
  conversationId: PropTypes.string.isRequired,
  targetMessageId: PropTypes.string,
}

export default ChatContainer
