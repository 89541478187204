import React from 'react'
import moment from 'moment'
import {makeStyles} from '@mui/styles'
import Grid from '@mui/material/Grid'
import {Typography as TypographyStyle} from '../../assets/styles'
import SearchResident from '../SearchResident'
import SelectInput from '../SelectInput'
import Constants from './DeliveryConstants'
import TextInput from '../TextInput'
import MultilineTextInput from '../MultilineTextInput'
import PropTypes from 'prop-types'
import {Typography} from '@mui/material'
import {Colors} from '../../assets/styles'

const NOTES_CHARACTER_LIMIT = 200

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  heading: {
    ...TypographyStyle.H3Header,
    lineHeight: '22px',
    marginBottom: '18px',
    color: '#0C4067',
  },
  priorityGrid: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    textAlign: 'center',
  },
  priorityHeading: {
    ...TypographyStyle.BodyHeader,
    paddingLeft: 10,
  },
  loggedBy: {
    ...TypographyStyle.BodyHeader,
    color: Colors.rxrBlackColor,
    textAlign: 'left',
  },
}))

export default function OutgoingDeliveryForm(props) {
  const classes = useStyles()
  const isCreatingNew = !props.id
  const isShipped = !!props.shippedAt

  const rowsize = isCreatingNew ? 6 : 4

  const onChangeResident = value => {
    if (value) {
      props.updateForm({
        residentId: value.id,
        unitId: value.occupancy.unit.id,
      })
    } else {
      props.updateForm({
        residentId: null,
        unitId: null,
      })
    }
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <SearchResident
            value={props.residentId}
            isRequired={true}
            disabled={!isCreatingNew}
            onSelectResident={onChangeResident}
            error={props.invalidItems.includes('residentId') || props.invalidItems.includes('unitId')}
          />
        </Grid>
        <Grid item mg={6} xs={12}>
          <SelectInput
            onChange={val =>
              props.updateForm({
                carrier: val,
              })
            }
            label={'Carrier'}
            options={Constants.CARRIER_OPTIONS}
            value={props.carrier}
            error={props.invalidItems.includes('carrier')}
            disabled={isShipped}
          />
        </Grid>
        <Grid item mg={6} xs={12}>
          <SelectInput
            onChange={val =>
              props.updateForm({
                packageType: val,
              })
            }
            label={'Package type'}
            isRequired={true}
            options={Constants.PACKAGE_OPTIONS}
            value={props.packageType}
            disabled={isShipped}
            error={props.invalidItems.includes('packageType')}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            label="Tracking number"
            placeholder=""
            maxLength={120}
            onChange={val => props.updateForm({trackingNumber: val})}
            value={props.trackingNumber}
          />
        </Grid>
        <Grid item xs={12}>
          <MultilineTextInput
            label="Notes for resident"
            placeholder=""
            helperText={`The resident will see these notes. ${NOTES_CHARACTER_LIMIT} character limit.`}
            maxLength={NOTES_CHARACTER_LIMIT}
            rows={rowsize}
            rowsMax={rowsize}
            onChange={val => props.updateForm({notes: val})}
            value={props.notes}
          />
        </Grid>

        {!isCreatingNew && (
          <React.Fragment>
            <Grid item mg={6} xs={12}>
              <TextInput
                label="Logged in on"
                placeholder=""
                value={props.loggedOn ? new moment(props.loggedOn).format(Constants.PACKAGE_TABLE_DATETIME_FORMAT) : ''}
                isDisabled
              />
            </Grid>
            {isShipped && (
              <Grid item mg={6} xs={12}>
                <TextInput
                  label={'Picked up on'}
                  placeholder=""
                  value={props.shippedAt ? new moment(props.shippedAt).format(Constants.PACKAGE_TABLE_DATETIME_FORMAT) : ''}
                  isDisabled
                />
              </Grid>
            )}
          </React.Fragment>
        )}

        {props.staffId && props.staffLookup && props.staffLookup[props.staffId] && props.staffLookup[props.staffId].displayName ? (
          <Grid item xs={12} className={classes.priorityGrid}>
            <Typography className={classes.loggedBy}>Logged by: {props.staffLookup[props.staffId].displayName}</Typography>
          </Grid>
        ) : null}
      </Grid>
    </div>
  )
}

OutgoingDeliveryForm.propTypes = {
  invalidItems: PropTypes.array,
}
