import React from 'react'
import PetsList from '../../ResidentProfiles/Pets/PetsList'
import {makeStyles} from '@mui/styles'
import {spaceLarge, spaceMedium} from '../../../assets/styles/spacing'
import {ONBOARDING_STATE_AVAILABLE, ONBOARDING_STATE_IN_PROGRESS, ONBOARDING_STATE_COMPLETED} from '../../../constants/ModelConstants'
import MarkTaskCompletedCTAs from '../FormCTAs/MarkTaskCompletedCTAs'
import {FormComponentPropTypes} from '../onboardingTypes'
import useSharedStyles from '../useSharedStyles'
import {constructClassString} from '../../../Utils/objectUtil'

const useStyles = makeStyles(theme => ({
  bodyContainer: {
    paddingBottom: spaceMedium,
  },
  petsListContainer: {
    padding: spaceLarge,
    maxWidth: '800px',
  },
}))

function PetProfileCreation(props) {
  const sharedClasses = useSharedStyles()
  const classes = useStyles()
  const occupancyId = props.task.occupancyId

  const onSaved = pets => {
    if (pets.length > 0 && props.submission.state === ONBOARDING_STATE_AVAILABLE) {
      props.onUpdateSubmission({...props.submission, state: ONBOARDING_STATE_IN_PROGRESS})
    }
  }

  return (
    <React.Fragment>
      <div className={constructClassString(sharedClasses.bodyContainer, classes.bodyContainer)}>
        <PetsList className={classes.petsListContainer} occupancyId={occupancyId} onSaved={onSaved} />{' '}
      </div>
      {props.submission.state !== ONBOARDING_STATE_COMPLETED && (
        <MarkTaskCompletedCTAs onMarkCompleted={() => props.onStateChange(ONBOARDING_STATE_COMPLETED)} />
      )}
    </React.Fragment>
  )
}

PetProfileCreation.propTypes = FormComponentPropTypes

export default PetProfileCreation
