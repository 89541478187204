import React, {Fragment, useEffect} from 'react'
import {MenuItem, Fade, Grid, Menu} from '@mui/material'
import {makeStyles} from '@mui/styles'
import PropTypes from 'prop-types'
import RXRButton from './RXRButton'
import CustomCheckbox from './CustomCheckbox'

const useStyles = makeStyles({
  menu: {
    '& .MuiMenu-paper': {},
    '& .MuiMenu-list': {
      padding: '13px 0px 5px 0px',
    },
  },
  menuItem: {
    height: '32px',
  },
  button: {
    fontWeight: 'normal',
    fontSize: '12px',
  },
})

const GenericFilterMenu = ({children, onChange, filterLabelMap, selectedFilters}) => {
  const ALL_FILTERS = Object.keys(filterLabelMap)

  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)
  // start off with every item selected unless otherwise indicated
  const [filterState, setFilterState] = React.useState(ALL_FILTERS)

  useEffect(() => {
    if (!Array.isArray(selectedFilters)) {
      return
    }
    setFilterState(selectedFilters)
  }, [selectedFilters])

  const handleFilterIconClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleFilterMenuClose = () => {
    setAnchorEl(null)
    onChange(filterState)
  }

  const handleFilterChange = (filterName, isChecked) => {
    if (filterState.includes(filterName)) {
      setFilterState(filterState.filter(f => f !== filterName))
    } else {
      setFilterState([...filterState, filterName])
    }
  }

  const handleSelectAll = () => {
    setFilterState(ALL_FILTERS)
  }

  const handleClearAll = () => {
    setFilterState([])
  }

  return (
    <Fragment>
      <span style={{cursor: 'pointer'}} onClick={handleFilterIconClick}>
        {children}
      </span>
      <Menu
        className={classes.menu}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleFilterMenuClose}
        TransitionComponent={Fade}
      >
        {ALL_FILTERS.map((filterName, index) => {
          const isChecked = filterState.includes(filterName)
          return (
            <MenuItem className={classes.menuItem} key={index}>
              <CustomCheckbox
                key={index}
                isChecked={isChecked}
                onChange={() => handleFilterChange(filterName, !isChecked)}
                name={filterName}
                label={filterLabelMap[filterName]}
              />
            </MenuItem>
          )
        })}
        <Grid container style={{textAlign: 'center'}}>
          <Grid item sm={6}>
            <RXRButton type={RXRButton.TYPE_TEXT} className={classes.button} onClick={handleSelectAll}>
              Select all
            </RXRButton>
          </Grid>
          <Grid item sm={6}>
            <RXRButton type={RXRButton.TYPE_TEXT} className={classes.button} onClick={handleClearAll}>
              Clear
            </RXRButton>
          </Grid>
        </Grid>
      </Menu>
    </Fragment>
  )
}

GenericFilterMenu.propTypes = {
  filterLabelMap: PropTypes.object.isRequired, // they keys of this object will be the filter constants
  onChange: PropTypes.func.isRequired,
  selectedFilters: PropTypes.array, // optional to initialize the state
  minimumSelected: PropTypes.number,
}

export default GenericFilterMenu
