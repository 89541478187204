/**
 * @param {number} preTaxAmount
 * @param {{taxRate: string}} building
 * @return {{
 *   taxRate: number,
 *   preTax: number,
 *   tax: number,
 *   total: number
 * }}
 */
export function calculateTaxAndTotal(preTaxAmount, building) {
  let taxRate = parseFloat(building.taxRate) / 100
  preTaxAmount = parseFloat(preTaxAmount.toFixed(2))
  let taxAmount = parseFloat((preTaxAmount * taxRate).toFixed(2))

  return {
    taxRate: taxRate,
    preTax: preTaxAmount,
    tax: taxAmount,
    total: parseFloat((preTaxAmount + taxAmount).toFixed(2))
  }
}
