import React from 'react'
import GenericServicesPage from './GenericServicesPage'
import {VendorServiceTypePersonalTraining} from './ServicesConstants'
import Routes from '../../constants/RouteConstants'
import {useSelector} from 'react-redux'

function PersonalTrainingPage() {
  const isMobile = useSelector(state => state.App.isMobile)
  return (
    <GenericServicesPage
      serviceType={VendorServiceTypePersonalTraining}
      title={'Personal Training'}
      createNewCTA={isMobile ? 'Book a session' : 'Book a training session'}
      baseRoute={Routes.PERSONALTRAINING}
      createNewRoute={Routes.PERSONALTRAINING_CREATE}
      viewSingleRoute={Routes.PERSONALTRAINING_VIEW_SINGLE}
    />
  )
}

export default PersonalTrainingPage
