import ActionTypes from './types'
import * as queries from '../lib/queries'

export const setBuildingsAll = (dispatch, buildings) => {
  dispatch({
    type: ActionTypes.BUILDINGS_UPDATE_ALL,
    payload: buildings,
  })
}

/**
 * @param {function} dispatch
 * @param {string} buildingId
 * @param {string} status
 * @param {string?} message
 * @return {Promise<*>}
 */
export const setBuildingOOOStatus = async (dispatch, buildingId, status, message) => {
  let input = {id: buildingId, outOfOfficeStatus: status, outOfOfficeMessage: message || null}

  // assume it worked, we'll revert below if it doesn't
  dispatch({
    type: ActionTypes.BUILDINGS_SET_BUILDING_OOO,
    payload: {
      buildingId: buildingId,
      status: status,
      message: message,
    },
  })

  try {
    return await queries.updateBuildingOOOStatus(input)
  } catch (err) {
    // revert it
    dispatch({
      type: ActionTypes.BUILDINGS_SET_BUILDING_OOO,
      payload: {
        buildingId: buildingId,
        status: !status,
        message: null,
      },
    })
  }
}

/**
 * @param {function} dispatch
 * @param {*} resident
 * @return {Promise<void>}
 */
export const setUpdateResident = async (dispatch, resident) => {
  let updatedResident = await queries.updateResidentProfile(resident)

  dispatch({
    type: ActionTypes.RESIDENT_UPDATE_SINGLE,
    payload: updatedResident,
  })
}

/**
 * @param {function} dispatch
 * @param {string} nextBuildingId
 * @param {string?} previousBuildingId
 * @returns {Promise<void>}
 */
export const setBuildingViewing = async (dispatch, nextBuildingId, previousBuildingId) => {
  // only dispatch a building set viewing if the building as changed (otherwise, just reload data)
  // because some reducers will reset to initial state on building change, so if we're not actually changing buildings
  // don't want to trigger it
  if (nextBuildingId !== previousBuildingId) {
    dispatch({
      type: ActionTypes.BUILDINGS_SET_VIEWING,
      payload: nextBuildingId,
    })
  }

  // first we'll load all the residents and units
  // TODO: I'd like to break this into 3 flat queries for units, residents, and occupancies, but getting residents and occupancies without going via units is impossible
  let [units, residents, occupancies] = await queries.loadUnitsAndResidentsAndOnboardingOccupanciesForBuilding(nextBuildingId)

  dispatch({
    type: ActionTypes.RESIDENTS_UPDATE_ALL,
    payload: residents,
  })

  dispatch({
    type: ActionTypes.UNITS_UPDATE_ALL,
    payload: units,
  })

  dispatch({
    type: ActionTypes.OCCUPANCIES_UPDATE_ALL,
    payload: occupancies,
  })

  const chatRooms = await queries.loadChatRoomsByBuilding(nextBuildingId)
  let chatParticipants = []
  for (const chatRoom of chatRooms) {
    if (chatRoom.chatParticipants && Array.isArray(chatRoom.chatParticipants)) {
      chatParticipants = chatParticipants.concat(chatRoom.chatParticipants)
    }
  }

  dispatch({
    type: ActionTypes.CHAT_PARTICIPANTS_UPDATE_ALL,
    payload: chatParticipants,
  })
}
