import ActionTypes from './types'
import {listBuildingStaffForBuilding} from '../lib/queries'
import {updateGroupUser, getStaffModel} from '../lib/queries'

/**
 * @param {function} dispatch
 * @param {*} staffModel
 */
export const setAuthenticatedStaffUser = (dispatch, staffModel) => {
  dispatch({
    type: ActionTypes.STAFF_SET_AUTHED_USER,
    payload: staffModel,
  })
}

/**
 * @param {function} dispatch
 * @param {*} updateGroupUserInput
 */
export const updateAuthenticatedStaffUser = async (dispatch, updateGroupUserInput) => {
  await updateGroupUser(updateGroupUserInput)
  const updatedStaff = await getStaffModel(updateGroupUserInput.staffId)

  dispatch({
    type: ActionTypes.STAFF_UPDATE_STAFF,
    payload: updatedStaff,
  })
}

/**
 * @param {function} dispatch
 * @param {string} buildingId
 * @return {Promise<*>}
 */
export async function loadBuildingStaffForBuilding(dispatch, buildingId) {
  let response = await listBuildingStaffForBuilding(buildingId)

  dispatch({
    type: ActionTypes.STAFF_SET_STAFF_LOOKUP,
    payload: response,
  })

  return response
}
