import React, {forwardRef} from 'react'
import PropTypes from 'prop-types'
import {Grid, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import {Spacing, Typography as TypographyStyle, Colors} from '../assets/styles'
import {constructClassString} from '../Utils/objectUtil'
import {useSelector} from 'react-redux'

const PageHeader = forwardRef(function PageHeader(props, ref) {
  const isMobile = useSelector(state => state.App.isMobile)
  const classes = useStyles({isMobile})

  return (
    <div className={constructClassString(classes.appBar, props.className)} ref={ref}>
      <Grid container className={classes.headerContainer}>
        <Grid item xs={props.rightControlComponent ? 6 : 12}>
          <Typography variant="h3" noWrap className={classes.headerThree}>
            RXO Home<sup>&trade;</sup>
          </Typography>
          {!isMobile && (
            <Typography variant="h1" noWrap className={classes.headerOne}>
              {props.title}
            </Typography>
          )}
        </Grid>
        {props.rightControlComponent && (
          <Grid item xs={6} className={classes.rightComponent}>
            {props.rightControlComponent}
          </Grid>
        )}
      </Grid>
      {props.children && <div className={classes.childrenContainer}>{props.children}</div>}
    </div>
  )
})

PageHeader.MIN_HEIGHT_MOBILE = 40
PageHeader.MIN_HEIGHT = 116

const useStyles = makeStyles(theme => ({
  appBar: props => ({
    minHeight: props.isMobile ? PageHeader.MIN_HEIGHT_MOBILE : PageHeader.MIN_HEIGHT,
    flexShrink: 0,
    position: 'relative',
    // boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)',
    backgroundColor: Colors.rxrWhiteColor,
    borderBottom: '1px solid ' + Colors.rxrGreyColor,
  }),
  headerContainer: props => ({
    padding: props.isMobile ? Spacing.spaceSmall : Spacing.spaceMedium,
  }),
  childrenContainer: props => ({
    paddingLeft: props.isMobile ? Spacing.spaceSmall : Spacing.spaceMedium,
    paddingRight: props.isMobile ? Spacing.spaceSmall : Spacing.spaceMedium,
  }),
  headerOne: props =>
    props.isMobile
      ? {
          ...TypographyStyle.AppBarHeader,
          fontSize: TypographyStyle.fontSizeLarge,
        }
      : TypographyStyle.AppBarHeader,
  headerThree: props =>
    props.isMobile
      ? {
          ...TypographyStyle.TrademarkText,
          fontSize: TypographyStyle.fontSizeSmall,
        }
      : TypographyStyle.TrademarkText,
  rightComponent: {
    textAlign: 'right',
  },
}))

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  rightControlComponent: PropTypes.any,
  hideDivider: PropTypes.bool,
  className: PropTypes.string,
}

export default PageHeader
