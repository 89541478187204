import React, {useEffect, useState} from 'react'
import FileHelper from '../../lib/FileHelper'
import PropTypes from 'prop-types'
import {makeStyles} from '@mui/styles'
import {spaceMedium} from '../../assets/styles/spacing'
import {rxrMonotoneLightGreyColor} from '../../assets/styles/color'
import SimpleSpinner from '../SimpleSpinner'

function PictureChatMessage(props) {
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(true)
  const [imageUri, setImageUri] = useState(null)

  useEffect(() => {
    if (props.s3Object) {
      FileHelper.Instance()
        .getFileUrlFromS3Object(props.s3Object)
        .then(imageUri => {
          setImageUri(imageUri)
        })
        .catch(err => {
          console.error('image fetching error: ', err)
        })
        .finally(() => {
          setIsLoading(false)
        })
    } else {
      setIsLoading(false)
    }
  }, [])

  return isLoading ? (
    <div className={classes.loadingContainer}>
      <SimpleSpinner size={SimpleSpinner.SIZE_LARGE} />
    </div>
  ) : imageUri ? (
    <img className={classes.image} src={imageUri} />
  ) : null
}

const useStyles = makeStyles(theme => ({
  loadingContainer: {
    padding: spaceMedium,
  },
  image: {
    borderRadius: spaceMedium,
    border: `1px solid ${rxrMonotoneLightGreyColor}`,
    width: '100%',
    height: 'auto',
  },
}))

PictureChatMessage.propTypes = {
  className: PropTypes.string,
  s3Object: PropTypes.any,
}

export default PictureChatMessage
