import React, {useState} from 'react'
import RXRButton from '../../RXRButton'
import {stylesRaw} from '../useSharedStyles'
import PropTypes from 'prop-types'
import {makeStyles} from '@mui/styles'
import {constructClassString} from '../../../Utils/objectUtil'
import {spaceSmall} from '../../../assets/styles/spacing'
import DiscardChangesDialog from '../../DiscardChangesDialog'

function AcceptOrEditFormCTAs(props) {
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)

  const handleClick = async func => {
    setIsLoading(true)
    try {
      await func()
    } catch (err) {
      window.alert(err.message)
      console.error(err)
    }
    setIsLoading(false)
  }

  return (
    <div className={classes.container}>
      {typeof props.onEdit === 'function' && (
        <RXRButton
          onClick={() => handleClick(props.onEdit)}
          className={constructClassString(classes.CTA, classes.editCTA)}
          isLoading={isLoading}
          disabled={props.isDisabled}
          type={RXRButton.TYPE_SECONDARY}
        >
          Edit
        </RXRButton>
      )}
      <RXRButton onClick={() => handleClick(props.onAccept)} className={classes.CTA} isLoading={isLoading} disabled={props.isDisabled}>
        Accept
      </RXRButton>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  CTA: {
    ...stylesRaw.CTA,
  },
  editCTA: {
    ...stylesRaw.CTA,
    marginRight: spaceSmall,
    width: 120,
    flexGrow: 0,
  },
  container: {
    ...stylesRaw.ctaContainer,
    display: 'flex',
    alignItems: 'center',
  },
}))

AcceptOrEditFormCTAs.propTypes = {
  onAccept: PropTypes.func.isRequired,
  onEdit: PropTypes.func,
  isDisabled: PropTypes.bool,
}

export default AcceptOrEditFormCTAs
