import React, {useEffect, useRef, useState} from 'react'
import Pollable from '../lib/Pollable'
import {getAppContext} from '../lib/queries'
import moment from 'moment'
import {makeStyles} from '@mui/styles'
import {spaceMedium, spaceSmall} from '../assets/styles/spacing'
import {rxrGreenColor, rxrRedColor, rxrWhiteColor} from '../assets/styles/color'
import RXRIcon from './RXRIcon'
import {fontSizeLarge} from '../assets/styles/typography'
import pJson from '../../package.json'

// extra what product version we're on
const CURRENT_VERSION = pJson.version

// 10 minute delay
const VERSION_CHECK_DELAY = 600000

// 3 minutes before the update is forced
const FORCE_UPDATE_TIMEOUT = 180000

/**
 * This is copied from rexResidentContext and is used to determine if the latestDashboardVersion is greater than our version
 * @param {string} thisVersion
 * @param {string} minVersion
 * @return {boolean}
 */
function isVersionGreaterThanOrEqualToMin(thisVersion, minVersion) {
  let thisVersionNumbers = thisVersion.split('.').map(s => parseInt(s))
  let minVersionNumbers = minVersion.split('.').map(s => parseInt(s))

  for (let i = 0; i < minVersionNumbers.length; i++) {
    let currentMinNumber = minVersionNumbers[i]
    let currentVersionNumber = thisVersionNumbers[i]

    // if this current min number is greater than the corresponding number on the passed value
    // then we return false
    if (currentMinNumber > currentVersionNumber) {
      return false
    }

    // if it's less than it, we return true
    if (currentMinNumber < currentVersionNumber) {
      return true
    }
  }

  // if we get all the way through, then the numbers are equal
  return true
}

function VersionChecker() {
  const classes = useStyles()
  const [forceUpdate, setForceUpdate] = useState(null)
  const [, setRefreshControl] = useState(false)
  const [failCount, setFailCount] = useState(0)
  const [appear, setAppear] = useState(false)
  const pollable = useRef(new Pollable('VersionChecker', VERSION_CHECK_DELAY, checkForNewVersion))
  const [minDashboardVersion, setMinDashboardVersion] = useState()

  // on mount, start polling the version number
  useEffect(() => {
    pollable.current.startPolling(true)
  }, [])

  // if there's a new version detected or we've failed at least 3 version check requests, we need to force an update
  useEffect(() => {
    if ((!minDashboardVersion || isVersionGreaterThanOrEqualToMin(CURRENT_VERSION, minDashboardVersion)) && failCount < 3) {
      return
    }

    pollable.current.stopPolling()
    setForceUpdate(Date.now() + FORCE_UPDATE_TIMEOUT)
    setTimeout(() => {
      window.location.reload()
    }, FORCE_UPDATE_TIMEOUT + 1000)
    setInterval(() => {
      // this allows our banner to animate up
      setAppear(true)
      setRefreshControl(prev => !prev)
    }, 1000)
  }, [minDashboardVersion, failCount])

  // this function checks for new versions using the requestContext api hook
  async function checkForNewVersion() {
    try {
      const resp = await getAppContext()
      setMinDashboardVersion(resp.latestDashboardVersion)
    } catch (err) {
      console.error(err)
      setFailCount(p => p + 1)
    }
  }

  // if there's no forceUpdate, then nothing to render
  if (!forceUpdate) {
    return null
  }

  // if we get to here, it means we definitely have a forceUpdate value and need to show a message

  const minutesRemaining = moment(forceUpdate).diff(moment(), 'minutes', true)
  let timeRemainingStr
  if (minutesRemaining < 0) {
    timeRemainingStr = '0:00'
  } else {
    let seconds = Math.round((minutesRemaining % 1) * 60)
    timeRemainingStr = `${parseInt(minutesRemaining)}:${seconds < 10 ? '0' : ''}${seconds}`
  }

  // if there's no minDashboardVersion, it means we need to show the error message (fail count > N)
  return minDashboardVersion ? (
    <div className={`${classes.updateBanner} ${appear ? '' : classes.offFrame}`}>
      <RXRIcon icon={RXRIcon.CHECK} color={rxrWhiteColor} className={classes.icon} size={RXRIcon.SIZE_EXTRA_LARGE} />
      <div>
        <h3>Version {minDashboardVersion} is now available!</h3>
        <span onClick={() => window.location.reload()}>Refresh the page</span> to get the latest features. This page will refresh
        automatically in {timeRemainingStr}, please save your work.
      </div>
      <strong>{timeRemainingStr}</strong>
    </div>
  ) : (
    <div className={`${classes.errorBanner} ${appear ? '' : classes.offFrame}`}>
      <RXRIcon icon={RXRIcon.ERROR} color={rxrWhiteColor} className={classes.icon} size={RXRIcon.SIZE_EXTRA_LARGE} />
      <div>
        We've lost connection to the backend and you may need to login again. This page will{' '}
        <span onClick={() => window.location.reload()}>refresh</span> automatically in {timeRemainingStr}, please save your work.
      </div>
      <strong>{timeRemainingStr}</strong>
    </div>
  )
}

const sharedBannerStyles = {
  position: 'fixed',
  width: '90%',
  padding: `${spaceSmall}px ${spaceMedium}px`,
  bottom: 0,
  left: '5%',
  borderRadius: `8px 8px 0px 0px`,
  color: rxrWhiteColor,
  transition: 'all 0.3s',
  boxShadow: `0px 0px 20px 4px rgb(0 0 0 / 25%)`,
  zIndex: 10000000,
  display: 'flex',
  alignItems: 'center',

  '& div': {
    flex: 1,
  },
  '& h3': {
    fontSize: fontSizeLarge,
  },
  '& strong': {
    display: 'inline-block',
    marginLeft: spaceMedium,
    fontSize: 24,
  },

  '& span': {
    cursor: 'pointer',
    textDecoration: 'underline',
    textUnderlineOffset: '3px',
  },
}

const useStyles = makeStyles(theme => ({
  updateBanner: {
    ...sharedBannerStyles,
    backgroundColor: rxrGreenColor,
  },

  errorBanner: {
    ...sharedBannerStyles,
    backgroundColor: rxrRedColor,
  },

  offFrame: {
    bottom: '-200px',
  },

  icon: {
    marginRight: spaceMedium,
  },
}))

export default VersionChecker
