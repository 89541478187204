import React, {useEffect, useState, useRef} from 'react'
import PropTypes from 'prop-types'
import {useLocation, matchPath, useHistory} from 'react-router-dom'
import {IconButton, List, ListItem, ListItemIcon, ListItemText, Typography, Menu, MenuItem} from '@mui/material'
import {makeStyles} from '@mui/styles'
import {Typography as TypographyStyle} from '../assets/styles'
import {useDispatch, useSelector} from 'react-redux'
import {setBuildingViewing} from '../actions/buildingsActions'
import {setDisabledFeatures} from '../actions/groupPermissionsActions'
import Routes from '../constants/RouteConstants'
import AppAuth from '../lib/AppAuth'
import {setAuthenticatedUser} from '../actions/authActions'
import {
  rxrBlackDarkerColor,
  rxrBlueColor,
  rxrRedColor,
  rxrSoftHighlightedBlue,
  rxrTeal25Color,
  rxrTealColor,
  rxrWhiteColor,
} from '../assets/styles/color'
import SimpleSpinner from './SimpleSpinner'
import {closeNavPanel, toggleNavPanelOpenStatus} from '../actions/appActions'
import {getVendorServiceTypeIcon, VendorServiceTypeLabels} from './Services/ServicesConstants'
import RXRIcon from './RXRIcon'
import {spaceExtraSmall, spaceSmall} from '../assets/styles/spacing'
import {fontSizeExtraSmall} from '../assets/styles/typography'
import RXOHomeLogo from '../assets/images/RXOHomeIconGradient.png'
import {appointmentShouldBeHighlightedAsNeedingReschedule} from './Services/VendorServiceConstants'
import {checkForOnboardingUnitsRequiringReview} from '../reducers/selectors'
import {
  CORE_FEATURE_CLEANINGS,
  CORE_FEATURE_DATA_COLLECTION,
  CORE_FEATURE_DOG_WALKING,
  CORE_FEATURE_ELEVATORS,
  CORE_FEATURE_MASSAGE,
  CORE_FEATURE_PACKAGE_DELIVERY,
  CORE_FEATURE_PERSONAL_TRAINING,
} from '../constants/ModelConstants'
import StaffProfileImage from './Messages/StaffProfileImage'

import pJson from '../../package.json'

const tailSize = 12
const iconSize = 20
const iconContainer = 48

const navItemBasics = {
  padding: 0,
  borderRadius: 6,
  color: rxrWhiteColor,
  cursor: 'pointer',

  marginTop: 6,

  '&:hover': {
    backgroundColor: rxrSoftHighlightedBlue,
  },
}

const subMenuTail = {
  height: 0,
  width: 0,
  borderStyle: 'solid',
  borderColor: 'transparent',
  borderWidth: tailSize,
  borderLeftWidth: 0,
  borderRightColor: rxrSoftHighlightedBlue,
  position: 'absolute',
  left: -tailSize,
}

const scrollBarStyles = {
  '&::-webkit-scrollbar': {
    width: spaceExtraSmall,
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: rxrTeal25Color,
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: rxrBlackDarkerColor,
    borderRadius: '4px',
  },
}

const notificationIndicatorNumberBasic = {
  textAlign: 'center',
  lineHeight: `24px`,
  width: 24,
  height: 24,
  backgroundColor: rxrRedColor,
  position: 'absolute',
  zIndex: 2,
}

// magic number of the height of the nav bar when closed
export const MobileNavClosedHeight = 48

const useStyles = makeStyles(theme => ({
  navContainer: props =>
    props.isMobile
      ? {
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          zIndex: 100,
          backgroundColor: rxrBlueColor,
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          height: props.isMobileNavOpen ? '100%' : MobileNavClosedHeight,
        }
      : {
          position: 'relative',
          zIndex: 100,
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          backgroundColor: rxrBlueColor,
          padding: tailSize,
          overflow: 'hidden',
          transition: `width 0.2s`,
          flexShrink: 0,
        },
  logoContainer: {
    '& img': {
      width: iconContainer - 16,
      padding: `8px 3px`,
      backgroundColor: rxrWhiteColor,
      borderRadius: 5,
    },
  },
  menuItems: {
    flexGrow: 1,
    paddingTop: 0,
    paddingBottom: 0,
    overflowY: 'auto',
    ...scrollBarStyles,
  },
  bottomMenuItems: props => ({
    display: props.isMobileNavOpen ? 'block' : 'none',
    justifySelf: 'flex-end',
  }),
  notificationIndicator: {
    width: 8,
    height: 8,
    backgroundColor: rxrRedColor,
    borderRadius: '50%',
    position: 'absolute',
    top: 12,
    left: 28,
    zIndex: 2,
  },
  notificationIndicatorNumber: props =>
    props.isMobileNavOpen
      ? {
          ...notificationIndicatorNumberBasic,
          borderRadius: '50%',
          top: 12,
          right: 4,
        }
      : {
          ...notificationIndicatorNumberBasic,
          borderRadius: 6,
          top: 0,
          right: 0,
        },
  badgeNumber: {
    width: 26,
    height: 26,
    backgroundColor: rxrTealColor,
    borderRadius: '50%',
    textAlign: 'center',
    marginLeft: tailSize / 2,
    lineHeight: '27px',
    fontSize: '12px',
    fontWeight: 'bold',
  },
  childNavItemNotificationIndicator: {
    width: 8,
    height: 8,
    backgroundColor: rxrRedColor,
    borderRadius: '50%',
    textAlign: 'center',
    marginLeft: tailSize,
  },
  navIcon: {
    color: rxrWhiteColor,
    paddingRight: spaceSmall,
  },

  profileImage: {
    height: '30px',
    width: '30px',
    marginLeft: '9px',
    marginTop: '8.5px',
  },

  navItemActive: {
    ...navItemBasics,
    backgroundColor: `${rxrTealColor} !important`,
  },

  navItemHover: {
    ...navItemBasics,
    backgroundColor: rxrSoftHighlightedBlue,
  },

  navItem: {
    ...navItemBasics,
  },

  hamburgerMenu: props =>
    props.isMobile
      ? {
          ...navItemBasics,
          marginTop: 0,
        }
      : navItemBasics,

  navItemIcon: {
    minWidth: 0,
    width: iconContainer,
    height: iconContainer,

    '& .MuiButtonBase-root': {
      width: '100%',
    },
  },

  navItemText: {
    marginLeft: 6,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },

  versionNumber: {
    color: rxrWhiteColor,
    fontSize: fontSizeExtraSmall,
    position: 'absolute',
    bottom: 0,
    right: '4px',
    opacity: 0,
    transition: 'opacity 0.2s',

    '&:hover': {
      opacity: 1,
    },
  },

  subMenuContainer: {
    maxHeight: '50vh',
  },

  subMenuInnerList: {
    overflowY: 'auto',
    maxHeight: '50vh',
    ...scrollBarStyles,
  },

  subMenuList: {
    ...navItemBasics,
    backgroundColor: rxrSoftHighlightedBlue,
  },

  subMenuTail: {
    ...subMenuTail,
    top: tailSize,
  },

  subMenuTailBottom: {
    ...subMenuTail,
    bottom: tailSize,
  },

  subMenuItem: {
    backgroundColor: 'transparent',
    padding: `${tailSize}px ${tailSize * 2}px`,

    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}))

/*
Some routes cannot be viewed when you change buildings. for example, if you're viewing a specific conversation then change buildings,
you can't possibly still be viewing that conversation. This object's purpose is to map every specific route to its nearest general route
that we should route to when changing buildings.
 */
const buildingChangeBackMap = {
  [Routes.MESSAGES_VIEW_SINGLE]: Routes.MESSAGES,
  [Routes.PROFILE_VIEW_SINGLE]: Routes.PROFILE,
  [Routes.GUESTAUTHORIZATION_VIEW_SINGLE]: Routes.GUESTAUTHORIZATION,
  [Routes.DELIVERIES_IN_VIEW_SINGLE]: Routes.DELIVERIES_IN,
  [Routes.DELIVERIES_OUT_VIEW_SINGLE]: Routes.DELIVERIES_OUT,
  [Routes.AMENITIES_RESERVATIONS_VIEW_SINGLE]: Routes.AMENITIES_RESERVATIONS,
  [Routes.AMENITIES_EDIT]: Routes.AMENITIES_MANAGER,
  [Routes.SERVICE_REQUESTS_VIEW]: Routes.SERVICE_REQUESTS,

  // TODO: The below routes are feature dependent, so some buildings may have them and some may not. Eventually we should
  //  be able to determine if the new building has the feature and if so, we can keep them on this route. But for now, since we
  //  can't be sure, we'll just send them to the command center.
  [Routes.CLEANING]: Routes.COMMAND_CENTER,
  [Routes.DOGWALKING]: Routes.COMMAND_CENTER,
  [Routes.MASSAGE]: Routes.COMMAND_CENTER,
  [Routes.PERSONALTRAINING]: Routes.COMMAND_CENTER,
  [Routes.ONBOARDING]: Routes.COMMAND_CENTER,
  [Routes.RESIDENT_SENTIMENT]: Routes.COMMAND_CENTER,
}

function ResponsiveDrawer(props) {
  const isMobile = useSelector(state => state.App.isMobile)
  const isNavOpen = useSelector(state => state.App.isNavPanelOpen)
  const classes = useStyles({isMobile, isMobileNavOpen: isNavOpen})
  const currentLocation = useLocation()
  const [isChangingBuildings, setIsChangingBuildings] = useState(false)
  const [currentNavMenuOpen, setCurrentNavMenuOpen] = useState(null)

  const history = useHistory()
  const dispatch = useDispatch()
  const buildingsLookup = useSelector(state => state.Buildings.buildingsLookup)
  const activeBuilding = useSelector(state => state.Buildings.buildingsLookup[state.Buildings.activeBuildingId])
  const permissionsObject = useSelector(state => state.GroupPermissions.permissionsObject)
  const unreadMessagesCount = useSelector(state => state.Messages.unreadCount)
  const nudgesCount = useSelector(state => Object.keys(state.Nudges.nudgesLookup).length)
  const reservationsRequiringApprovalCount = useSelector(state => state.Amenities.reservationsRequiringApprovalCount)
  const coreFeatures = useSelector(state => state.GroupPermissions.coreFeatures)
  const navPanelWidth = useSelector(state => state.App.navPanelWidth)
  const servicesLookup = useSelector(state => state.Appointments.servicesLookup)
  const hasOnboardingUnitsRequiringReview = useSelector(state => checkForOnboardingUnitsRequiringReview(state))
  const appointmentsLookup = useSelector(state => state.Appointments.appointmentsLookup)
  const navBarServicesObj = createNavBarServicesObj(servicesLookup, appointmentsLookup)
  const authedStaffModel = useSelector(state => state.Staff.authedStaffModel)

  const navContainerRef = useRef(null)

  // TODO: feels like this should be dont automatically in the reducer whenever ActionTypes.BUILDINGS_SET_VIEWING action is received
  //  but not clear how to get the building object -- maybe a re-structure is needed?
  //Resetting disabled features on building change
  useEffect(() => {
    setDisabledFeatures(dispatch, activeBuilding.disabledFeatures)
  }, [activeBuilding])

  const buildingSwitchPseudoObject = {
    path: 'building-switcher-path',
    childNav: Object.values(buildingsLookup)
      .sort((a, b) => (a.displayName > b.displayName ? 1 : -1))
      .map(b => ({name: b.displayName, path: () => handleChangeBuilding(b.id), buildingId: b.id})),
    icon: RXRIcon.BUILDINGS,
    showInNav: false, // keep it our of the main menu area
  }

  const handleLogOut = async () => {
    setCurrentNavMenuOpen(null)
    await AppAuth.Instance().logOut()
    await setAuthenticatedUser(dispatch, null)
    history.push(Routes.ROOT)
    window.location.reload()
  }

  const accountMenuNavItem = {
    name: authedStaffModel.displayName,
    path: 'logout-menu',
    icon: RXRIcon.SETTINGS,
    childNav: [
      {
        name: 'Profile and Preferences',
        path: Routes.STAFF_PROFILE,
      },
      {
        name: 'Log out',
        path: handleLogOut,
      },
    ],
  }

  // NOTE: if badgeNumber is truthy, a red indicator will show.
  // If badgeNumber is a number, it will show count. If badgeNumber is a boolean, it will just show the red dot

  const drawerNavigationData = [
    {
      name: 'Command Center',
      icon: RXRIcon.COMMAND_CENTER,
      path: Routes.COMMAND_CENTER,
      showInNav: true,
    },
    {
      name: 'Messages',
      icon: RXRIcon.MESSAGES,
      path: Routes.MESSAGES,
      showInNav: permissionsObject.messagesRead,
      badgeNumber: permissionsObject.messagesWrite ? unreadMessagesCount : 0,
    },
    {
      name: 'Resident Insights',
      icon: RXRIcon.PROFILE,
      path: Routes.RESIDENT_INSIGHTS,
      showInNav: true,
      badgeNumber: nudgesCount > 0,
      childNav: [
        {name: 'Resident Profiles', path: Routes.PROFILE},
        {name: 'RXO Assistant', path: Routes.RXO_ASSISTANT, badgeNumber: nudgesCount > 0},
        {name: 'Resident Sentiment', path: Routes.RESIDENT_SENTIMENT, showInNav: coreFeatures[CORE_FEATURE_DATA_COLLECTION]},
      ],
    },
    {
      name: 'Deliveries',
      icon: RXRIcon.DELIVERIES,
      path: Routes.DELIVERIES,
      showInNav: coreFeatures[CORE_FEATURE_PACKAGE_DELIVERY],
      childNav: [
        {name: 'Incoming', path: Routes.DELIVERIES_IN},
        {name: 'Outgoing', path: Routes.DELIVERIES_OUT},
      ],
    },
    {
      name: 'Maintenance',
      icon: RXRIcon.MAINTENANCE,
      path: Routes.SERVICE_REQUESTS,
      showInNav: true,
    },
    navBarServicesObj,
    {
      name: 'Amenities',
      icon: RXRIcon.AMENITIES,
      path: Routes.AMENITIES,
      showInNav: true,
      badgeNumber: reservationsRequiringApprovalCount,
      childNav: [
        {name: 'Amenities Calendar', path: Routes.AMENITIES_CALENDAR_VIEW},
        {name: 'Elevator Calendar', path: Routes.ELEVATOR_CALENDAR_VIEW, showInNav: coreFeatures[CORE_FEATURE_ELEVATORS]},
        {name: 'Reservations', path: Routes.AMENITIES_RESERVATIONS, badgeNumber: reservationsRequiringApprovalCount},
        {name: 'Amenities Manager', path: Routes.AMENITIES_MANAGER},
      ],
    },
    {
      name: 'Guest Authorization',
      icon: RXRIcon.GUEST_AUTH,
      path: Routes.GUESTAUTHORIZATION,
      showInNav: true,
    },
    {
      name: 'Bulletin',
      icon: RXRIcon.EVENTS,
      path: Routes.BULLETIN,
      showInNav: true,
      childNav: [
        {name: 'Events', path: Routes.EVENTS},
        {name: 'Announcements', path: Routes.ANNOUNCEMENTS},
      ],
    },
    {
      name: 'Onboarding',
      icon: RXRIcon.ONBOARDING,
      path: Routes.ONBOARDING,
      badgeNumber: hasOnboardingUnitsRequiringReview,
      showInNav: !!activeBuilding.onboardingSchema,
    },
    {
      name: 'Settings',
      icon: RXRIcon.SETTINGS,
      path: Routes.SETTINGS,
      showInNav: permissionsObject.settingsControl,
      childNav: [
        {
          name: 'Dashboard Users',
          path: Routes.SETTINGS_DASHBOARD_USERS,
        },
        {
          name: 'Partners',
          path: Routes.SETTINGS_PARTNERS,
        },
        {
          name: 'Promotions',
          path: Routes.SETTINGS_PROMOTIONS,
          showInNav:
            coreFeatures[CORE_FEATURE_CLEANINGS] ||
            coreFeatures[CORE_FEATURE_PERSONAL_TRAINING] ||
            coreFeatures[CORE_FEATURE_MASSAGE] ||
            coreFeatures[CORE_FEATURE_DOG_WALKING],
        },
      ],
    },

    // this is a little hacky, basically pretending the building switcher and log out menu are Nav Items so they plays nice with the Menu
    buildingSwitchPseudoObject,
    accountMenuNavItem,
  ]

  /**
   * @param {{path:string, childNav?:Array<*>}} navItem
   * @param {Node?} element
   */
  const handleClickNavItem = (navItem, element) => {
    if (navItem.childNav && navItem.childNav.length > 0) {
      if (currentNavMenuOpen && currentNavMenuOpen.path === navItem.path) {
        setCurrentNavMenuOpen(null)
      } else {
        setCurrentNavMenuOpen({path: navItem.path, element: element})
      }

      return
    }

    setCurrentNavMenuOpen(null)

    // this type check is to accommodate the pseudo nav item building selector
    if (typeof navItem.path === 'function') {
      return navItem.path()
    }

    // we're already on this path, return
    if (navItem.path === currentLocation.pathname) {
      return
    }

    history.push(navItem.path)
    if (isMobile) {
      closeNavPanel(dispatch)
    }
  }

  const handleChangeBuilding = buildingId => {
    setIsChangingBuildings(true)
    setCurrentNavMenuOpen(null)

    let matchingPathKey = Object.keys(buildingChangeBackMap).find(r => matchPath(currentLocation.pathname, {path: r}))
    // if the path we're currently on is a known route key in the buildingChangeBackMap,
    if (matchingPathKey) {
      // we navigate to its more general page
      history.push(buildingChangeBackMap[matchingPathKey])
    }

    setBuildingViewing(dispatch, buildingId, activeBuilding ? activeBuilding.id : undefined).then(() => {
      setIsChangingBuildings(false)
    })
  }

  return (
    <div className={classes.navContainer} ref={navContainerRef} style={isMobile ? undefined : {width: navPanelWidth}}>
      <ListItem onClick={() => toggleNavPanelOpenStatus(dispatch, isNavOpen)} className={classes.hamburgerMenu}>
        <ListItemIcon className={classes.navItemIcon}>
          <IconButton color="primary" component="span" className={classes.logoContainer} size="large">
            <img alt="RXO Home logo" src={RXOHomeLogo} />
          </IconButton>
        </ListItemIcon>
        {(isNavOpen || isMobile) && (
          <ListItemText className={classes.navItemText}>
            <Typography style={TypographyStyle.H4HeaderWithoutBold}>
              {isMobile
                ? // First we check to see if the currentLocation.pathname is a key in the drawerNavigationData object
                  drawerNavigationData.find(n => currentLocation.pathname.includes(n.path))?.name
                  ? drawerNavigationData.find(n => currentLocation.pathname.includes(n.path))?.name
                  : // Then we check to see if the currentLocation.pathname is a key in the childNav object of the drawerNavigationData object
                    drawerNavigationData
                      .find(n => n.childNav?.find(c => currentLocation.pathname.includes(c.path)))
                      ?.childNav.find(c => currentLocation.pathname.includes(c.path))?.name
                : 'RXO Home Dashboard'}
            </Typography>
          </ListItemText>
        )}
      </ListItem>
      <List className={classes.menuItems} style={isMobile && !isNavOpen ? {display: 'none'} : undefined}>
        {drawerNavigationData
          .filter(navItem => navItem.showInNav)
          .map(navItem => {
            const isRouteActive = matchPath(currentLocation.pathname, {path: navItem.path})

            const renderableChildren = Array.isArray(navItem.childNav)
              ? navItem.childNav.filter(c => typeof c.showInNav !== 'boolean' || c.showInNav)
              : []
            const hasChildNav = renderableChildren.length > 0

            return (
              <ListItem
                key={navItem.path}
                onClick={ev => handleClickNavItem(navItem, ev.currentTarget)}
                className={
                  isRouteActive
                    ? classes.navItemActive
                    : currentNavMenuOpen && currentNavMenuOpen.path === navItem.path
                    ? classes.navItemHover
                    : classes.navItem
                }
              >
                {!!navItem.badgeNumber &&
                  (typeof navItem.badgeNumber === 'boolean' ? (
                    <div className={classes.notificationIndicator} />
                  ) : (
                    <div className={classes.notificationIndicatorNumber}>{navItem.badgeNumber}</div>
                  ))}
                <ListItemIcon className={classes.navItemIcon}>
                  <IconButton color="primary" component="span" size="large">
                    <RXRIcon icon={navItem.icon} color={rxrWhiteColor} size={iconSize} />
                  </IconButton>
                </ListItemIcon>
                {isNavOpen && (
                  <ListItemText className={classes.navItemText}>
                    <Typography style={isRouteActive ? TypographyStyle.H4Header : TypographyStyle.H4HeaderWithoutBold}>
                      {navItem.name}
                    </Typography>
                  </ListItemText>
                )}
                {isNavOpen && hasChildNav && <RXRIcon icon={RXRIcon.ARROW_RIGHT} className={classes.navIcon} size={RXRIcon.SIZE_SMALL} />}
              </ListItem>
            )
          })}
      </List>
      <List className={classes.bottomMenuItems}>
        <ListItem
          onClick={ev => (isChangingBuildings ? null : handleClickNavItem(buildingSwitchPseudoObject, ev.currentTarget))}
          className={
            currentNavMenuOpen && currentNavMenuOpen.path === buildingSwitchPseudoObject.path ? classes.navItemHover : classes.navItem
          }
        >
          <ListItemIcon className={classes.navItemIcon}>
            <IconButton color="primary" aria-label="Switch buildings" component="span" size="large">
              {isChangingBuildings ? <SimpleSpinner /> : <RXRIcon icon={RXRIcon.BUILDINGS} color={rxrWhiteColor} size={iconSize} />}
            </IconButton>
          </ListItemIcon>
          {isNavOpen && (
            <ListItemText className={classes.navItemText}>
              <Typography style={TypographyStyle.H4HeaderWithoutBold}>{activeBuilding.displayName.replace('Building', '')}</Typography>
            </ListItemText>
          )}
          {isNavOpen && <RXRIcon icon={RXRIcon.ARROW_RIGHT} className={classes.navIcon} size={RXRIcon.SIZE_SMALL} />}
        </ListItem>

        <ListItem onClick={ev => handleClickNavItem(accountMenuNavItem, ev.currentTarget)} className={classes.navItem}>
          <ListItemIcon className={classes.navItemIcon}>
            <StaffProfileImage staffId={authedStaffModel.id} className={classes.profileImage} />
          </ListItemIcon>
          {isNavOpen && (
            <ListItemText className={classes.navItemText}>
              <Typography style={TypographyStyle.H4HeaderWithoutBold}>{accountMenuNavItem.name}</Typography>
            </ListItemText>
          )}
          {isNavOpen && <RXRIcon icon={RXRIcon.ARROW_RIGHT} className={classes.navIcon} size={RXRIcon.SIZE_SMALL} />}
        </ListItem>
      </List>
      <Typography className={classes.versionNumber}>{pJson.version}</Typography>
      {navContainerRef.current && (
        <Menu
          onClose={ev => {
            ev.stopPropagation()
            setCurrentNavMenuOpen(null)
          }}
          keepMounted={true}
          open={!!currentNavMenuOpen}
          anchorEl={currentNavMenuOpen ? currentNavMenuOpen.element : null}
          classes={{
            // root: classes.subMenuContainer, - doesn't work until Material UI 5. For now hacking with an inner wrapper instead
            // once we're ready to switch, remove wrapper with class "subMenuInnerList"
            list: classes.subMenuList,
          }}
          PaperProps={{
            style: {
              boxShadow: 'none',
              overflow: 'visible',
              marginLeft: isMobile ? 'auto' : navContainerRef.current.offsetWidth,
              right: isMobile ? 0 : 'auto',
              backgroundColor: 'transparent',
            },
          }}
          anchorReference={'anchorPosition'}
          anchorPosition={{
            top: currentNavMenuOpen
              ? currentNavMenuOpen.element.parentElement.offsetTop + // how far down the menu items container is on the nav panel
                currentNavMenuOpen.element.offsetTop - // how far down the open nav item is within the list of all nav items
                currentNavMenuOpen.element.parentElement.scrollTop - // how far scrolled down the menu items list is
                tailSize / 2 // to center our tail with the nav item
              : 0,
            left: '0px', // it wants this to be a number, but it doesn't work right
          }}
        >
          {!!currentNavMenuOpen && <div className={classes.subMenuTail} />}
          {!!currentNavMenuOpen && (
            <div className={classes.subMenuInnerList}>
              {drawerNavigationData
                .find(n => n.path === currentNavMenuOpen.path)
                .childNav.filter(c => typeof c.showInNav !== 'boolean' || c.showInNav)
                .map((child, index) => {
                  if (currentNavMenuOpen.path === buildingSwitchPseudoObject.path) {
                    // this hackyness is related to the buildingSwitchPseudoObject hackyness
                    const isRouteActive = child.buildingId
                      ? child.buildingId === activeBuilding.id
                      : matchPath(currentLocation.pathname, {path: child.path})

                    return (
                      <MenuItem
                        className={classes.subMenuItem}
                        onClick={() => handleClickNavItem(child)}
                        key={`${currentNavMenuOpen.path}-${index}`}
                      >
                        <Typography style={isRouteActive ? TypographyStyle.H4Header : TypographyStyle.H4HeaderWithoutBold}>
                          {child.name}
                        </Typography>
                        {!!child.badgeNumber &&
                          (typeof child.badgeNumber === 'boolean' ? (
                            <div className={classes.childNavItemNotificationIndicator} />
                          ) : (
                            <div className={classes.badgeNumber}>{child.badgeNumber}</div>
                          ))}
                      </MenuItem>
                    )
                  } else {
                    // this hackyness is related to the log out menu
                    return (
                      <MenuItem
                        className={classes.subMenuItem}
                        onClick={() => handleClickNavItem(child)}
                        key={`${currentNavMenuOpen.path}-${index}`}
                      >
                        <Typography style={TypographyStyle.H4HeaderWithoutBold}>{child.name}</Typography>
                        {!!child.badgeNumber &&
                          (typeof child.badgeNumber === 'boolean' ? (
                            <div className={classes.childNavItemNotificationIndicator} />
                          ) : (
                            <div className={classes.badgeNumber}>{child.badgeNumber}</div>
                          ))}
                      </MenuItem>
                    )
                  }
                })}
            </div>
          )}
        </Menu>
      )}
    </div>
  )
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  container: PropTypes.instanceOf(typeof Element === 'undefined' ? Object : Element),
}

//this helper function is used to determine how many services are provided by the building, and create an object
//named navBarServicesObj that will adjust the nav panel accordingly
function createNavBarServicesObj(servicesLookup, appointmentsLookup) {
  if (!servicesLookup)
    return {
      showInNav: false,
      usesIconComponent: true,
    }

  //first, we will create a set that lists all of the services provided by the building
  const servicesProvided = [...new Set(Object.values(servicesLookup).map(service => service.type))]

  //next, we will create an object that will later be inserted into the drawerNavigationData array
  const navBarServicesObj = {
    showInNav: servicesProvided.length > 0,
    usesIconComponent: true,
  }

  //next, we will use the size of servicesProvided set to determine what we add to the navBarServicesObj object

  const appointmentsNeedingReschedule = Object.values(appointmentsLookup).filter(appointmentShouldBeHighlightedAsNeedingReschedule)

  //if the size of the set is one, we do not need to have a childNav
  if (servicesProvided.length === 1) {
    const item = servicesProvided[0]
    navBarServicesObj.name = VendorServiceTypeLabels[item]
    navBarServicesObj.path = Routes[item.toUpperCase()]
    navBarServicesObj.icon = getVendorServiceTypeIcon(item)
  }
  //else if the size of the set is greater than one, we will have a childNav
  else if (servicesProvided.length > 1) {
    navBarServicesObj.name = 'Services'
    navBarServicesObj.path = Routes.SERVICES
    // pass with no input to use the default icon
    navBarServicesObj.icon = getVendorServiceTypeIcon()
    navBarServicesObj.childNav = servicesProvided.map(item => ({
      name: VendorServiceTypeLabels[item],
      path: Routes[item.toUpperCase()],
      badgeNumber: appointmentsNeedingReschedule.filter(a => servicesLookup[a.vendorServiceId]?.type === item).length,
    }))
  }

  navBarServicesObj.badgeNumber = appointmentsNeedingReschedule.length
  return navBarServicesObj
}

export default ResponsiveDrawer
