import React from 'react'
import ReactDOM from 'react-dom/client'
import * as Sentry from '@sentry/react'
import {Integrations} from '@sentry/tracing'
import {ExtraErrorData} from '@sentry/integrations'
import App from './App'
import * as serviceWorker from './serviceWorker'
import {BrowserRouter} from 'react-router-dom'
import {Provider} from 'react-redux'
import {createStore} from 'redux'
import rootReducer from './reducers'
import config from './amplifyconfiguration.json'
import pjson from '../package.json'
import {ErrorBoundary} from 'react-error-boundary'
import ErrorBoundaryFallback from './components/ErrorBoundaryFallback'
import Amplify from 'aws-amplify'

Amplify.configure(config)

const ENV = config.aws_content_delivery_bucket.split('-').slice(-1)[0]
const PROD_SITE = 'https://rxohome.rxr.io'

if (ENV === 'prod' && window.location.origin.includes('rex-staff-backend')) {
  if (window.confirm('The RXO Home dashboard is only available at rxohome.rxr.io. Click confirm to be redirected')) {
    window.location = PROD_SITE
  }

  document.getElementById(
    'root',
  ).innerHTML = `<h1>Cannot render dashboard. <a href="${PROD_SITE}">Click here</a> to go to latest version</h1>`
  throw new Error('USE NEW SITE')
}

Sentry.init({
  dsn: 'https://9d12aec983ef464b96888d713a0855a4@o407746.ingest.sentry.io/5841617',
  integrations: [new ExtraErrorData({depth: 10}), new Integrations.BrowserTracing()],
  normalizeDepth: 11, // @see https://github.com/getsentry/sentry-javascript/issues/1964
  environment: ENV,
  release: 'rxo-dashboard@' + pjson.version,
  beforeSend(event, hint) {
    const error = hint.originalException
    if (error) {
      let errorMessage = error.message || ''

      // Convert error message to lower case for ease of comparison
      errorMessage = errorMessage.toLowerCase()

      if (
        errorMessage.includes('network error') ||
        errorMessage.includes('not authenticated') ||
        errorMessage.includes('no current user') ||
        errorMessage.includes('request failed with status code 401') ||
        errorMessage.includes('no time remaining') ||
        errorMessage.includes(`unprocessable entity:422, {"message":"selected time slot no longer available"}`) ||
        errorMessage.includes('non-error exception captured with keys: code, message, name')
      ) {
        return null // Drop the event
      }
    }
    return event
  },
})

const store = createStore(rootReducer)

ReactDOM.createRoot(document.getElementById('root')).render(
  <BrowserRouter>
    <Provider store={store}>
      <ErrorBoundary FallbackComponent={ErrorBoundaryFallback} onReset={() => window.location.reload()}>
        <App />
      </ErrorBoundary>
    </Provider>
  </BrowserRouter>,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
