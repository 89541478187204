import React, {useState} from 'react'
import {FormComponentPropTypes} from '../onboardingTypes'
import RejectOrAcceptWithFileAttacher from '../FormCTAs/RejectOrAcceptWithFileAttacher'
import SelectInput from '../../SelectInput'
import SyncedFiles from '../SyncedFiles'
import {getSyncedAndNotSyncedFilesFromSubmission} from '../../../Utils/onboardingUtils'
import useSharedStyles from '../useSharedStyles'
import {makeStyles} from '@mui/styles'
import {spaceMedium} from '../../../assets/styles/spacing'

function WelcomeLetter(props) {
  const sharedClasses = useSharedStyles()
  const classes = useStyles()
  const {syncedAttachments, notSyncedAttachments} = getSyncedAndNotSyncedFilesFromSubmission(props.submission)
  const [confirmed, setConfirmed] = useState(syncedAttachments.length > 0)

  const handleSyncedAttachments = newSyncedAttachmentsArray => {
    props.onUpdateSubmission({
      ...props.submission,
      attachments: [...notSyncedAttachments, ...newSyncedAttachmentsArray],
    })
  }

  return (
    <React.Fragment>
      <div className={sharedClasses.bodyContainer}>
        <div>Residents need to sign in the portal. Staff can upload a hand-signed letter on a resident's behalf, if needed.</div>
        <div className={classes.confirmationContainer}>
          <SelectInput
            onChange={() => setConfirmed(true)}
            label={'Letter uploaded and signed'}
            isRequired={true}
            disabled={confirmed && syncedAttachments.length > 0}
            options={[{label: 'Yes, letter is uploaded and signed.', value: true}]}
            value={confirmed}
          />
        </div>
        <SyncedFiles value={syncedAttachments} onChange={handleSyncedAttachments} />
      </div>
      <RejectOrAcceptWithFileAttacher
        onEdit={() => setConfirmed(false)}
        isDisabled={!confirmed}
        submission={props.submission}
        onUpdateSubmission={props.onUpdateSubmission}
      />
    </React.Fragment>
  )
}

const useStyles = makeStyles(theme => ({
  confirmationContainer: {
    marginTop: spaceMedium,
    marginBottom: spaceMedium,
  },
}))

WelcomeLetter.propTypes = FormComponentPropTypes

export default WelcomeLetter
