import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@mui/styles'
import moment from 'moment'
import {rxrDarkGreyColor, rxrGreyColor, rxrTealColor, rxrWhiteColor} from '../assets/styles/color'
import {IconButton} from '@mui/material'
import RXRIcon from './RXRIcon'
import {iconButton} from '../assets/styles/buttons'
import {spaceSmall} from '../assets/styles/spacing'
import {MobileDatePicker, PickersDay} from '@mui/x-date-pickers'

const sharedDayStyles = {
  background: rxrTealColor,
  color: rxrWhiteColor,
  border: '1px solid ' + rxrTealColor,
}

const hoveredDayStyles = {
  ...sharedDayStyles,
  background: '#81cbeb',
}

const RXR_CLASS_IS_IN_RANGE = 'rxr-is-in-range'
const RXR_CLASS_IS_SELECTED = 'rxr-is-selected'

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    maxWidth: 400,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
  },

  arrowDivider: {
    margin: `0 ${spaceSmall}px`,
  },

  dateInput: {
    width: '100%',
  },

  icon: {
    ...iconButton,
    height: RXRIcon.BUTTON_SIZE_LARGE,
    width: RXRIcon.BUTTON_SIZE_LARGE,
  },

  dayItem: {
    [`&:hover.${RXR_CLASS_IS_IN_RANGE}`]: {
      background: '#81cbeb',
    },
    [`&.${RXR_CLASS_IS_SELECTED}`]: {
      background: rxrTealColor,
    },
  },
}))

const FOCUSED_INPUT_START = 'startDate'
const FOCUSED_INPUT_END = 'endDate'

function DateRangeInput(props) {
  const classes = useStyles()
  const [focusedInput, setFocusedInput] = useState(null)

  /**
   * @param {{startDate:Date, endDate: Date}} dates
   */
  const handleDatesChanged = dates => {
    // we place our start date to be 00:00:00 of that date
    let startDate = dates.startDate ? moment(dates.startDate).startOf('day').toDate() : null
    // we place our end date to be 23:59:59 of that date
    let endDate = dates.endDate ? moment(dates.endDate).endOf('day').toDate() : null

    setFocusedInput(startDate && !endDate ? FOCUSED_INPUT_END : null)

    props.onChange(startDate, endDate)
  }

  const shouldShowClearButton = props.startDate || props.endDate

  const minDate = props.minDate ? moment(props.minDate).toDate() : null
  const maxDate = props.maxDate ? moment(props.maxDate).toDate() : null

  const slots = {
    day: p => {
      const mDate = moment(p.day)
      const rxrClasses = []
      const isInRange =
        props.startDate && props.endDate
          ? mDate.isBetween(props.startDate, props.endDate, 'day', '[]')
          : (props.endDate && mDate.isSameOrBefore(props.endDate)) || (props.startDate && mDate.isSameOrAfter(props.startDate)) || false

      if (isInRange) {
        rxrClasses.push(RXR_CLASS_IS_IN_RANGE)

        if (props.startDate && props.endDate) {
          rxrClasses.push(RXR_CLASS_IS_SELECTED)
        }
      }
      return <CustomDayComp {...p} rxrClass={rxrClasses.join(' ')} />
    },
  }

  return (
    <div className={classes.container}>
      <MobileDatePicker
        open={focusedInput === FOCUSED_INPUT_START}
        onOpen={() => setFocusedInput(FOCUSED_INPUT_START)}
        onClose={() => setFocusedInput(null)}
        className={classes.dateInput}
        minDate={minDate}
        maxDate={props.endDate || maxDate}
        label={'Start Date'}
        value={props.startDate}
        slots={slots}
        onChange={date => handleDatesChanged({startDate: date, endDate: props.endDate})}
      />
      <RXRIcon className={classes.arrowDivider} icon={RXRIcon.ARROW_LONG_RIGHT} />
      <MobileDatePicker
        open={focusedInput === FOCUSED_INPUT_END}
        onOpen={() => setFocusedInput(FOCUSED_INPUT_END)}
        onClose={() => setFocusedInput(null)}
        className={classes.dateInput}
        minDate={props.startDate || minDate}
        maxDate={maxDate}
        label={'End Date'}
        value={props.endDate}
        slots={slots}
        onChange={date => handleDatesChanged({startDate: props.startDate, endDate: date})}
      />
      {shouldShowClearButton ? (
        <IconButton className={classes.icon} onClick={() => handleDatesChanged({})} size="large">
          <RXRIcon icon={RXRIcon.CLOSE} size={RXRIcon.SIZE_LARGE} />
        </IconButton>
      ) : (
        <IconButton
          className={classes.icon}
          onClick={() => setFocusedInput(!props.startDate ? FOCUSED_INPUT_START : FOCUSED_INPUT_END)}
          size="large"
        >
          <RXRIcon icon={RXRIcon.CALENDAR} size={RXRIcon.SIZE_LARGE} color={rxrDarkGreyColor} />
        </IconButton>
      )}
    </div>
  )
}

function CustomDayComp({rxrClass, ...props}) {
  const classes = useStyles()
  return <PickersDay {...props} className={[classes.dayItem, rxrClass || ''].join(' ')} />
}

DateRangeInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
}

export default DateRangeInput
