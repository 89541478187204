import React, {useState} from 'react'
import AuthPage from './AuthPage'
import {useHistory} from 'react-router-dom'
import {STATE_IDLE, STATE_SENDING, STATE_SENT} from './authConstants'
import RXRButton from '../RXRButton'
import Routes from '../../constants/RouteConstants'
import ChangePasswordInputs from './ChangePasswordInputs'
import {makeStyles} from '@mui/styles'
import {heading, inputRow} from './authStyles'
import {spaceExtraLarge, spaceMedium} from '../../assets/styles/spacing'

function CreatePassword(props) {
  const classes = useStyles()
  const history = useHistory()
  const [sendState, setSendState] = useState(STATE_IDLE)

  return (
    <AuthPage isLoading={sendState === STATE_SENDING}>
      {sendState === STATE_SENT ? (
        <React.Fragment>
          <p className={classes.heading}>You're all set!</p>
          <p className={classes.confirmationText}>You have successfully created a password. You can now log in to RXO Home.</p>
          <RXRButton
            className={classes.backButton}
            onClick={() => {
              sessionStorage.setItem('showAccountPreferences', 'true')
              history.push(Routes.LOGIN)
            }}
          >
            Return to log-in
          </RXRButton>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <p className={classes.heading}>Create a Password for your RXO Home account</p>
          <ChangePasswordInputs
            ctaLabel={'Create Password'}
            onStateChange={setSendState}
            errorHelperText={'Please reach out to your admin to resend.'}
          />
        </React.Fragment>
      )}
    </AuthPage>
  )
}

const useStyles = makeStyles(theme => ({
  heading: heading,
  backButton: {
    marginTop: spaceMedium,
  },
  confirmationText: {
    ...inputRow,
    margin: `${spaceExtraLarge}px auto`,
  },
}))

export default CreatePassword
