import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import useSharedStyles from './useSharedStyles'
import {useSelector} from 'react-redux'
import TaskStateIcon from './TaskStateIcon'
import {ONBOARDING_STATE_AVAILABLE} from '../../constants/ModelConstants'
import {ONBOARDING_TASK_KEYS} from '../../constants/OnboardingConstants'
import TaskStatusComponent from './TaskStatusComponent'
import SimpleSpinner from '../SimpleSpinner'
import {matchPath, useHistory, useLocation} from 'react-router-dom'
import Routes from '../../constants/RouteConstants'
import {selectResidentsByOccupancyId} from '../../Utils/residentUtils'

// Task components
import BuildingRules from './Tasks/BuildingRules'
import PersonalPreferences from './Tasks/PersonalPreferences'
import PetProfileCreation from './Tasks/PetProfileCreation'
import MeetYourPropertyStaff from './Tasks/MeetYourPropertyStaff'
import DownloadRXOHome from './Tasks/DownloadRXOHome'
import MovingServices from './Tasks/MovingServices'
import FurnitureRental from './Tasks/FurnitureRental'
import CleaningServices from './Tasks/CleaningServices'
import SmartLock from './Tasks/SmartLock'
import SecurityDepositAndRent_LinkOut from './Tasks/SecurityDepositAndRent_LinkOut'
import SecurityDepositAndRent_Check from './Tasks/SecurityDepositAndRent_Check'
import SecurityDepositAndRent_Hybrid from './Tasks/SecurityDepositAndRent_Hybrid'
import {spaceLarge} from '../../assets/styles/spacing'
import ElevatorReservation from './Tasks/ElevatorReservation'
import UtilitiesSetup from './Tasks/UtilitiesSetup'
import PetScreening from './Tasks/PetScreening'
import MovingInfo_SelfMoveNoWaiver from './Tasks/MovingInfo_SelfMoveNoWaiver'
import MovingInfo_SelfMoveWithWaiver from './Tasks/MovingInfo_SelfMoveWithWaiver'
import RentersInsurance from './Tasks/RentersInsurance'
import RentersInsurance_475C from './Tasks/RentersInsurance_475C'
import RentersInsurance_Willough from './Tasks/RentersInsurance_Willough'
import KeyPickup from './Tasks/KeyPickup'
import KeyPickup_ReadOnly from './Tasks/KeyPickup_ReadOnly'
import CobuLinkout from './Tasks/CobuLinkout'
import WelcomeLetter from './Tasks/WelcomeLetter'

// TODO: Maybe we move this to a different file so it can be reused if needed...
const TASK_KEY_TO_COMPONENT_MAP = {
  [ONBOARDING_TASK_KEYS.BUILDING_RULES]: BuildingRules,
  [ONBOARDING_TASK_KEYS.PERSONAL_PREFERENCES]: PersonalPreferences,
  [ONBOARDING_TASK_KEYS.CLEANING_SERVICES]: CleaningServices,
  [ONBOARDING_TASK_KEYS.PET_PROFILE_CREATION]: PetProfileCreation,
  [ONBOARDING_TASK_KEYS.MEET_YOUR_PROPERTY_STAFF]: MeetYourPropertyStaff,
  [ONBOARDING_TASK_KEYS.DOWNLOAD_RXO_HOME]: DownloadRXOHome,
  [ONBOARDING_TASK_KEYS.MOVING_SERVICES]: MovingServices,
  [ONBOARDING_TASK_KEYS.FURNITURE_RENTAL]: FurnitureRental,
  [ONBOARDING_TASK_KEYS.SMART_LOCK]: SmartLock,
  [ONBOARDING_TASK_KEYS.SECURITY_DEPOSIT_AND_RENT_LINK_OUT]: SecurityDepositAndRent_LinkOut,
  [ONBOARDING_TASK_KEYS.SECURITY_DEPOSIT_AND_RENT_CHECK]: SecurityDepositAndRent_Check,
  [ONBOARDING_TASK_KEYS.SECURITY_DEPOSIT_AND_RENT_HYBRID]: SecurityDepositAndRent_Hybrid,
  [ONBOARDING_TASK_KEYS.ELEVATOR_RESERVATION]: ElevatorReservation,
  [ONBOARDING_TASK_KEYS.ELEVATOR_RESERVATION_MULTIPLE]: ElevatorReservation,
  [ONBOARDING_TASK_KEYS.UTILITIES_SETUP]: UtilitiesSetup,
  [ONBOARDING_TASK_KEYS.PET_SCREENING]: PetScreening,
  [ONBOARDING_TASK_KEYS.PET_SCREENING_LINK_OUT_REQUIRED]: PetScreening,
  [ONBOARDING_TASK_KEYS.MOVING_INFO_SELF_MOVE_NO_WAIVER]: MovingInfo_SelfMoveNoWaiver,
  [ONBOARDING_TASK_KEYS.MOVING_INFO_SELF_MOVE_WITH_WAIVER]: MovingInfo_SelfMoveWithWaiver,
  [ONBOARDING_TASK_KEYS.RENTERS_INSURANCE]: RentersInsurance,
  [ONBOARDING_TASK_KEYS.RENTERS_INSURANCE_475C]: RentersInsurance_475C,
  [ONBOARDING_TASK_KEYS.RENTERS_INSURANCE_WILLOUGH]: RentersInsurance_Willough,
  [ONBOARDING_TASK_KEYS.RENTERS_INSURANCE_ASW]: RentersInsurance_475C,
  [ONBOARDING_TASK_KEYS.RENTERS_INSURANCE_AS1]: RentersInsurance_475C,
  [ONBOARDING_TASK_KEYS.KEY_PICKUP]: KeyPickup,
  [ONBOARDING_TASK_KEYS.KEY_PICKUP_READ_ONLY]: KeyPickup_ReadOnly,
  [ONBOARDING_TASK_KEYS.COBU_LINKOUT]: CobuLinkout,
  [ONBOARDING_TASK_KEYS.WELCOME_LETTER]: WelcomeLetter,
}

// This is handy for our engineering team to quick see in the console if any task keys are missing corresponding components
const missingComponents = Object.values(ONBOARDING_TASK_KEYS).filter(key => !TASK_KEY_TO_COMPONENT_MAP[key])
if (missingComponents.length > 0) {
  console.warn(`The following task keys are missing components: ${missingComponents.join(', ')}`)
}

function ListSubmissionsForTaskIndex(props) {
  const classes = useSharedStyles()
  const [isSaving, setIsSaving] = useState(false)
  const history = useHistory()
  const currentLocation = useLocation()
  const residentsLookup = useSelector(state => state.Residents.residentsLookup)
  const residents = selectResidentsByOccupancyId(residentsLookup, props.task.occupancyId)
  const currentLoggedInStaffId = useSelector(state => state.Staff.authedStaffModel.id)

  // we only show the index view if
  const needsIndexView = residents.length > 1 && props.task.supportsMultipleSubmissions

  let residentId = null
  const viewSubmissionMatch = matchPath(currentLocation.pathname, {path: Routes.ONBOARDING_TASK_SUBMISSION_DETAILS})
  if (viewSubmissionMatch) {
    residentId = viewSubmissionMatch.params.residentId
  }

  const taskForm = props.task.form ? props.task.form : {}
  // copying to a variable for easier access
  const submissions = taskForm.submissions || []

  useEffect(() => {
    if (residents.length === 0) {
      console.warn('No residents are onboarding for this task.')
      return
    }

    // if this view doesn't support an index view, and we haven't selected a resident,
    if (!needsIndexView && !residentId) {
      // we go to the first submission (if available), else we go to the lease holder -- or first resident as a fallback
      let residentId =
        submissions.length > 0
          ? submissions[0].residentId
          : (residents.find(r => r.referenceId === r.secondaryReferenceId) || residents[0]).id
      goToSubmission(residentId)
    }
  }, [residents, needsIndexView, submissions])

  /**
   * @param {string} residentId
   */
  const goToSubmission = residentId => {
    // we just select the primary lease holder (or first resident as fallback)
    history.push(
      Routes.constructPath(Routes.ONBOARDING_TASK_SUBMISSION_DETAILS, {
        occupancyId: props.task.occupancyId,
        taskId: props.task.id,
        residentId: residentId,
      }),
    )
  }

  // -----------------------------------------------------
  // if we have a residentId, then we're prepared to show the specific Task component
  if (residentId) {
    /**
     * @param {OnboardingTaskSubmission} newSubmission
     */
    const handleSubmissionChanged = async newSubmission => {
      // TODO: How can we tell if the submission changes are enough to change our task state
      //  If they are, we need to change newState accordingly
      //  In the meantime we'll just assume it's the same state passed by the submission
      const newState = newSubmission.state

      newSubmission.stateModifiedTimestamp = new Date()
      newSubmission.stateModifiedBy = currentLoggedInStaffId

      setIsSaving(true)

      try {
        await props.onTaskUpdate(newState, {
          ...taskForm,
          submissions: [...submissions.filter(s => s.residentId !== newSubmission.residentId), newSubmission],
        })
      } catch (err) {
        console.error(err)
        window.alert(err.message)
      }

      setIsSaving(false)
    }

    const handleCancelSubmission = () => {
      if (needsIndexView) {
        history.push(Routes.constructPath(Routes.ONBOARDING_TASK_DETAILS, {occupancyId: props.task.occupancyId, taskId: props.task.id}))
      } else {
        history.push(Routes.constructPath(Routes.ONBOARDING_VIEW_OCCUPANCY_DETAILS, {occupancyId: props.task.occupancyId}))
      }
    }

    const TaskComponent = TASK_KEY_TO_COMPONENT_MAP[props.task.key]
    // if we can't find the submission, we'll initialize the submission to the known resident Id and Available
    const thisSubmission = submissions.find(s => s.residentId === residentId) || {state: ONBOARDING_STATE_AVAILABLE, residentId: residentId}
    return (
      <React.Fragment>
        {isSaving ? (
          <SimpleSpinner />
        ) : (
          <TaskStatusComponent
            state={thisSubmission.state || props.task.state}
            modifiedByDate={thisSubmission.stateModifiedTimestamp}
            modifiedById={thisSubmission.stateModifiedBy}
          />
        )}

        <TaskComponent
          submission={thisSubmission}
          onUpdateSubmission={handleSubmissionChanged}
          onCancel={handleCancelSubmission}
          task={props.task}
        />
      </React.Fragment>
    )
  }

  // -----------------------------------------------------

  // if we don't have a resident Id, we show the list (index) view so the user can pick
  // NOTE: if we know this task doesn't have an index view, we just show null
  // the useEffect will run shortly and properly route us to the single submission view
  return !needsIndexView ? null : (
    <div className={classes.bodyContainer}>
      <p style={{marginBottom: spaceLarge, marginTop: spaceLarge}}>
        This task allows multiple submissions. Please select a resident to see their individual submission.
      </p>

      {residents.map(r => {
        const thisSubmission = submissions.find(s => s.residentId === r.id)
        return (
          <div key={r.id} onClick={() => goToSubmission(r.id)} className={classes.taskItemCheckContainer}>
            <TaskStateIcon
              state={(thisSubmission && thisSubmission.state) || ONBOARDING_STATE_AVAILABLE}
              importance={props.task.importance}
              className={classes.taskItemCheckIcon}
            />
            <span className={classes.taskItemCheckLabel}>
              {props.task.label} - {r.displayName}
            </span>
          </div>
        )
      })}
    </div>
  )
}

ListSubmissionsForTaskIndex.propTypes = {
  task: PropTypes.object.isRequired,
  onTaskUpdate: PropTypes.func.isRequired, // (newState: string, newForm: OnboardingTaskForm) => {}
}

export default ListSubmissionsForTaskIndex
