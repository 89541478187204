import React, {useEffect} from 'react'
import SlidingPane from 'react-sliding-pane'
import PropTypes from 'prop-types'
import {rxrSidePanelBackgroundCoor, rxrBlackColor, rxrBlueColor, rxrDarkGreyColor} from '../assets/styles/color'
import {Grid, Typography, IconButton} from '@mui/material'
import {H3Header} from '../assets/styles/typography'
import {makeStyles} from '@mui/styles'
import RXRIcon from './RXRIcon'
import {iconButton} from '../assets/styles/buttons'
import BreadCrumbs from './BreadCrumbs'
import {spaceSmall, spaceMedium} from '../assets/styles/spacing'
import {useSelector} from 'react-redux'

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: rxrSidePanelBackgroundCoor,
    zIndex: 10,

    '& .slide-pane__content': {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  closeIconButton: {
    ...iconButton,
    color: rxrDarkGreyColor,
    '&:hover': {
      color: rxrBlackColor,
    },
  },
  closeIconContainer: {
    textAlign: 'right',
  },
  breadCrumbs: {
    position: 'relative',
    zIndex: 2,
    marginBottom: spaceSmall,
  },
  header: {
    ...H3Header,
    color: rxrBlueColor,
    marginBottom: spaceMedium,
  },
}))

function SidePanel(props) {
  const isMobile = useSelector(state => state.App.isMobile)
  const classes = useStyles({isMobile})

  useEffect(() => {
    if (isMobile) {
      const style = document.createElement('style')
      style.innerHTML = `
        .ReactModalPortal {
          z-index: 200 !important;
        }
      `
      document.head.appendChild(style)

      return () => {
        document.head.removeChild(style)
      }
    }
  }, [isMobile])

  return (
    <SlidingPane
      width={isMobile ? '100%' : '50%'}
      hideHeader={true}
      isOpen={props.isOpen || false}
      onRequestClose={props.onClose || (() => {})}
      className={classes.container}
    >
      {props.breadcrumbs ? <BreadCrumbs className={classes.breadCrumbs} paths={props.breadcrumbs} /> : null}
      <Grid spacing={3} container>
        <Grid item xs={10}>
          {!!props.title && <Typography className={classes.header}>{props.title}</Typography>}
        </Grid>
        <Grid item xs={2} className={classes.closeIconContainer}>
          <IconButton className={classes.closeIconButton} onClick={props.onClose} size="large">
            <RXRIcon icon={RXRIcon.CLOSE} />
          </IconButton>
        </Grid>
      </Grid>
      {props.children ? (
        props.children
      ) : (
        <div>{/* The sliding pane requires children so if there's no selected pane we just show an empty div */}</div>
      )}
    </SlidingPane>
  )
}

SidePanel.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  breadcrumbs: PropTypes.arrayOf(PropTypes.object), // Array<{path: string, label: string}>
}

export default SidePanel
