import React, {useCallback, useEffect, useRef, useState} from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@mui/styles'
import moment from 'moment'
import {Colors, Spacing, Typography} from '../../assets/styles'
import CalendarEvent from './CalendarEvent'
import CurrentTimeLine from './CurrentTimeLine'

// ---------------------------------------------------------------------
// Logic constants

const DEFAULT_START_TIME = 0
const DEFAULT_END_TIME = 1440
const DEFAULT_INCREMENT = 15
const START_OF_DAY = moment().startOf('day')

// ---------------------------------------------------------------------
// CSS constants

const LABEL_HEIGHT = 30
const CELL_BLOCK_HEIGHT = 35
const DEFAULT_CELL_BLOCK_WIDTH = 100
const ROW_LABEL_WIDTH = DEFAULT_CELL_BLOCK_WIDTH

// ---------------------------------------------------------------------

/**
 * @param {CalendarEventConfig} t1
 * @param {CalendarEventConfig} t2
 * @returns {boolean}
 */
function overlaps(t1, t2) {
  return (
    // t1 start falls between t2 start/end
    // OR t2 start falls between t1 start/end
    (t1.start >= t2.start && t1.start < t2.end) || (t2.start >= t1.start && t2.start < t1.end)
  )
}

function Calendar(props) {
  const [eventsByColumn, setEventsByColumn] = useState({})
  const [blockWidth, setBlockWidth] = useState(DEFAULT_CELL_BLOCK_WIDTH)
  const classes = calendarStyles()
  const [gridAndColumnsHeight, setGridAndColumnsHeight] = useState(0)

  // define our settings using props or defaults
  const startTime = props.startTime || DEFAULT_START_TIME
  const endTime = props.endTime || DEFAULT_END_TIME
  const increment = props.increment || DEFAULT_INCREMENT
  const rawTimeRange = endTime - startTime
  const timeRange = rawTimeRange - (rawTimeRange % increment)

  // construct an array of every time split into blocks of `increment` size
  // we add 1 to the array size so that the end time is included
  /** @type {Array<number>} */
  const rowTimes = [...new Array(timeRange / increment + 1)].map((e, i) => startTime + i * increment)

  /**
   * @param {number} time
   * @param {boolean?} roundToIncrement
   * @return {number}
   */
  const getTopFromTime = (time, roundToIncrement) => {
    // we clamp the time between our start and end times so that the blocks don't overflow the calendar grid
    let rawDiff = Math.min(endTime - startTime, Math.max(0, time - startTime)) / increment
    return (roundToIncrement ? Math.round(rawDiff) : rawDiff) * CELL_BLOCK_HEIGHT
  }

  /**
   * @param {CalendarEventConfig} event
   * @return {{top: number, height: number}}
   */
  const getPositionAndSizeForEvent = event => {
    const top = getTopFromTime(event.start, true)
    const end = getTopFromTime(event.end, true)
    return {
      top: top,
      height: end - top,
    }
  }

  useEffect(() => {
    // group our events array by column
    const grouped = props.events.reduce((agr, e) => {
      if (!agr[e.column]) {
        agr[e.column] = []
      }
      // initialize both to 0, these will be used below
      e._overlapsAfter = 0
      e._overlapsBefore = 0

      agr[e.column].push(e)
      return agr
    }, {})

    Object.entries(grouped).forEach(([col, arr]) => {
      // first we sort our array so the earlier events are first
      arr.sort((a, b) => (a.start < b.start ? -1 : a.start > b.start ? 1 : 0))

      // then we calculate overlaps
      for (let i = 0; i < arr.length; i++) {
        const e1 = arr[i]
        // start this loop at i + 1 since we only need to look forward
        for (let j = i + 1; j < arr.length; j++) {
          const e2 = arr[j]
          if (overlaps(e1, e2)) {
            e1._overlapsAfter++
            e2._overlapsBefore++
          } else {
            // our array is sorted, if this one doesn't overlap, none of the others will
            break
          }
        }
      }
    })

    setEventsByColumn(grouped)
  }, [props.events])

  /**
   * @param {string} col
   * @param {number} time
   */
  const handleClickEmptyBlock = (col, time) => {
    if (typeof props.onCreateEvent === 'function') {
      props.onCreateEvent({
        column: col,
        start: time,
        end: time + 2 * increment,
      })
    }
  }

  /**
   * This will snap to the grid
   * @param {CalendarEventConfig} e
   * @param {{x?: number, y?: number, top?: number, bottom?: number}} pos
   */
  const handleMoveEvent = (e, pos) => {
    if (props.disableEventDragging) {
      return
    }

    const newData = {}
    // determine what column we're now in
    if (pos.x) {
      const columnsMoved = Math.round(pos.x / blockWidth)
      const newColumn = props.columns[props.columns.indexOf(e.column) + columnsMoved]
      if (newColumn) {
        newData.column = newColumn
      }
    }

    // determine new start and end times after move
    if (pos.y) {
      const rowsMoved = Math.round(pos.y / CELL_BLOCK_HEIGHT)
      const timeDiff = rowsMoved * increment
      newData.start = e.start + timeDiff
      newData.end = e.end + timeDiff
    }

    // determine new start time after expand up
    if (pos.top) {
      const rowsExpanded = Math.round(pos.top / CELL_BLOCK_HEIGHT)
      newData.start = e.start + rowsExpanded * increment
    }

    // determine new end time after expand down
    if (pos.bottom) {
      const rowsExpanded = Math.round(pos.bottom / CELL_BLOCK_HEIGHT)
      newData.end = e.end + rowsExpanded * increment
    }

    props.onChangeEvent({...e, ...newData})
  }

  const onColumnRef = useCallback(node => {
    if (node && blockWidth === DEFAULT_CELL_BLOCK_WIDTH) {
      // TODO: What if screen size changes???
      setBlockWidth(node.clientWidth)
    }
  }, [])

  return (
    <div className={classes.container}>
      <div className={classes.rowLabels} style={{height: gridAndColumnsHeight}}>
        {rowTimes.map(t => (
          <div key={t} className={classes.rowLabel}>
            {moment(START_OF_DAY).add(t, 'minutes').format('h:mm a')}
          </div>
        ))}
      </div>
      <div
        className={classes.columnsAndGridContainer}
        // 9 is half the label font size so that our label sits in the middle of the gutter
        ref={node => (node && gridAndColumnsHeight === 0 ? setGridAndColumnsHeight(node.clientHeight + 9) : null)}
      >
        <div className={classes.columnLabelsContainer}>
          {props.columns.map((columnLabel, i) => {
            // we only want to grab the first one to determine their width
            const refProps = i === 0 ? {ref: onColumnRef} : {}
            return (
              <div key={`${columnLabel}-${columnLabel}`} className={classes.columnLabel} {...refProps}>
                {columnLabel}
              </div>
            )
          })}
        </div>
        <div className={classes.gridContainer}>
          <CurrentTimeLine onUpdatePosition={getTopFromTime} />
          {props.columns.map(columnLabel => (
            <div key={columnLabel} className={classes.column}>
              {rowTimes.slice(0, rowTimes.length - 1).map(t => (
                <div
                  key={`${columnLabel}-${t}`}
                  className={[classes.cellBlock, props.highlightedColumn === columnLabel ? 'highlighted' : ''].join(' ')}
                  onClick={() => handleClickEmptyBlock(columnLabel, t)}
                />
              ))}
              {eventsByColumn[columnLabel]
                ? eventsByColumn[columnLabel].map(e => (
                    <CalendarEvent
                      ref={r => {
                        if (typeof props.onRenderFocusedEvent === 'function' && e.id === props.focusedEventId) {
                          props.onRenderFocusedEvent(r)
                        }
                      }}
                      isFocused={props.focusedEventId === e.id}
                      key={e.id}
                      onClick={() => props.onClickEvent(e)}
                      onMove={pos => handleMoveEvent(e, pos)}
                      style={getPositionAndSizeForEvent(e)}
                      event={props.disableEventDragging ? {...e, isDragDisabled: true} : e}
                    />
                  ))
                : null}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

const calendarStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  rowLabels: {
    flexGrow: 0,
    flexShrink: 0,
    width: ROW_LABEL_WIDTH,
    textAlign: 'right',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingTop: `${LABEL_HEIGHT - 9}px`,
    paddingRight: Spacing.spaceSmall,
  },
  rowLabel: {
    fontSize: Typography.fontSizeSmall,
  },
  gridContainer: {
    cursor: 'pointer',
    position: 'relative',
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
  },
  columnsAndGridContainer: {
    width: '100%',
  },
  column: {
    width: '100%',
    position: 'relative',
  },
  columnLabelsContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  columnLabel: {
    flex: 1,
    minWidth: DEFAULT_CELL_BLOCK_WIDTH,
    height: LABEL_HEIGHT,
    textAlign: 'center',
    fontSize: Typography.fontSizeMediumLarge,
    fontWeight: 'bold',
  },
  cellBlock: {
    minWidth: DEFAULT_CELL_BLOCK_WIDTH,
    width: 'auto',
    height: CELL_BLOCK_HEIGHT,
    backgroundColor: Colors.rxrWhiteColor,
    border: `1px solid ${Colors.rxrGreyColor}`,
    '&.highlighted': {
      backgroundColor: Colors.rxrTeal15Color,
    },
  },
}))

Calendar.propTypes = {
  startTime: PropTypes.number, // number in mins since midnight
  endTime: PropTypes.number, // number in mins since midnight,
  increment: PropTypes.number, // step size in mins
  columns: PropTypes.arrayOf(PropTypes.string).isRequired, // array of column labels
  highlightedColumn: PropTypes.string,
  focusedEventId: PropTypes.string,
  onRenderFocusedEvent: PropTypes.func,
  events: PropTypes.array, // array of CalendarEventConfig
  onClickEvent: PropTypes.func, // passes 2 args: event and ref
  onChangeEvent: PropTypes.func, // passes 2 args: event and ref
  onCreateEvent: PropTypes.func, // passes 1 arg: event (does not pass ref because it does not create an element)
  disableEventDragging: PropTypes.bool,
}

export default Calendar
