import React, {useState} from 'react'
import RXRButton from '../../RXRButton'
import {stylesRaw} from '../useSharedStyles'
import PropTypes from 'prop-types'
import {makeStyles} from '@mui/styles'
import {constructClassString} from '../../../Utils/objectUtil'
import {spaceSmall} from '../../../assets/styles/spacing'
import DiscardChangesDialog from '../../DiscardChangesDialog'

function SaveOrEditFormCTAs(props) {
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)

  const handleClick = async func => {
    setIsLoading(true)
    try {
      await func()
    } catch (err) {
      window.alert(err.message)
      console.error(err)
    }
    setIsLoading(false)
  }

  return (
    <div className={classes.container}>
      {!!props.hasChanges && typeof props.onCancel === 'function' && (
        <RXRButton
          onClick={() => handleClick(props.onCancel)}
          className={constructClassString(classes.CTA, classes.cancelCTA)}
          isLoading={isLoading}
          type={RXRButton.TYPE_SECONDARY}
        >
          Cancel
        </RXRButton>
      )}
      <RXRButton
        onClick={() => handleClick(props.onSave)}
        disabled={!props.isEdit && !props.hasChanges}
        className={classes.CTA}
        isLoading={isLoading}
      >
        {props.isEdit ? 'Edit' : 'Save'}
      </RXRButton>
      <DiscardChangesDialog hasChanges={props.hasChanges} />
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  CTA: {
    ...stylesRaw.CTA,
  },
  cancelCTA: {
    ...stylesRaw.CTA,
    marginRight: spaceSmall,
    width: 120,
    flexGrow: 0,
  },
  container: {
    ...stylesRaw.ctaContainer,
    display: 'flex',
    alignItems: 'center',
  },
}))

SaveOrEditFormCTAs.propTypes = {
  onSave: PropTypes.func.isRequired,
  isEdit: PropTypes.bool,
  hasChanges: PropTypes.bool,
  onCancel: PropTypes.func,
}

export default SaveOrEditFormCTAs
