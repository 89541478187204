import React from 'react'
import {Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import {spaceExtraExtraSmall, spaceMedium, spaceSmall} from '../../assets/styles/spacing'
import {
  rxrDarkGreyColor,
  rxrMediumLightGreyColor,
  rxrWhiteColor,
  rxrGreenColor,
  rxrOrangeColor,
  rxrRedColor,
} from '../../assets/styles/color'
import {fontSizeLarge, fontSizeMedium, fontSizeSmall, heavyFontWeight, lightFontWeight} from '../../assets/styles/typography'
import ArcChart from './ArcChart'
import {getChangeStyle} from './utils'
import SentimentCard from './SentimentCard'
import NPSScoreBreakdownTable from './NPSScoreBreakdownTable'
import NPSScoreBreakdownChart from './NPSScoreBreakdownChart'
import PropTypes from 'prop-types'
import {renderNotAvailableIfValIsNaN, renderFloatWithTwoDecimalsIfValIsNotNaN} from './residentSentimentUtilities'

const useStyles = makeStyles(theme => ({
  npsScoreSummaryCards: {
    display: 'flex',
  },
  cardLarge: {
    width: '350px',
    height: '304px',
    borderRadius: '10px',
    border: `1px solid ${rxrMediumLightGreyColor}`,
    background: rxrWhiteColor,
    padding: spaceSmall,
  },
  cardStack: {
    marginLeft: spaceMedium,
    '& > div': {
      marginBottom: spaceMedium,
    },
    '& > div:last-child': {
      marginBottom: 0,
    },
  },
  cardTitle: {
    fontSize: fontSizeLarge,
    fontWeight: heavyFontWeight,
    alignSelf: 'flex-start',
  },
  arcChartContainer: {
    display: 'flex',
    alignItems: 'center',
  },

  npsChangeText: {
    marginLeft: spaceMedium,
  },

  npsScoreChangeValue: {
    fontSize: fontSizeMedium,
    fontWeight: lightFontWeight,
  },

  npsScoreChangeDescription: {
    fontSize: fontSizeMedium,
    fontWeight: lightFontWeight,
    color: rxrDarkGreyColor,
    marginLeft: spaceExtraExtraSmall,
  },
}))

function NPSScoreSummaryCards(props) {
  const npsResponses = props.npsResponses
  const npsScore = props.npsScore
  const npsScoreChange = props.npsScoreChange
  const npsScorePercentagePromoters = props.npsScorePercentagePromoters
  const npsScorePercentagePromotersChangeFromLastMonth = props.npsScorePercentagePromotersChangeFromLastMonth
  const npsScorePercentagePassives = props.npsScorePercentagePassives
  const npsScorePercentagePassivesChangeFromLastMonth = props.npsScorePercentagePassivesChangeFromLastMonth
  const npsScorePercentageDetractors = props.npsScorePercentageDetractors
  const npsScorePercentageDetractorsChangeFromLastMonth = props.npsScorePercentageDetractorsChangeFromLastMonth
  const messageSentiment = props.messageSentiment
  const messageSentimentChangeFromLastMonth = props.messageSentimentChangeFromLastMonth
  const serviceRating = props.serviceRating
  const serviceRatingChangeFromLastMonth = props.serviceRatingChangeFromLastMonth
  const amenitiesRating = props.amenitiesRating
  const amenitiesRatingChangeFromLastMonth = props.amenitiesRatingChangeFromLastMonth
  const maintenanceRating = props.maintenanceRating
  const maintenanceRatingChangeFromLastMonth = props.maintenanceRatingChangeFromLastMonth

  const classes = useStyles({
    npsScorePercentagePromoters,
    npsScorePercentagePassives,
    npsScorePercentageDetractors,
  })

  // Determine the color and sign of the npsScoreChange
  let {color, sign} = getChangeStyle(npsScoreChange)

  return (
    <div className={classes.npsScoreSummaryCards}>
      <div className={classes.cardLarge}>
        <Typography className={classes.cardTitle}>NPS score</Typography>
        <div className={classes.arcChartContainer}>
          <ArcChart
            score={typeof npsScore === 'number' ? npsScore.toFixed(0) : null}
            height={100}
            width={100}
            arcColor={npsScore > 30 ? rxrGreenColor : npsScore < -30 ? rxrRedColor : rxrOrangeColor}
          />
          <div className={classes.npsChangeText}>
            <Typography className={classes.npsScoreChangeValue} style={{color: color}}>
              {sign}
              {renderFloatWithTwoDecimalsIfValIsNotNaN(npsScoreChange)}
              <span className={classes.npsScoreChangeDescription}>from last month</span>
            </Typography>
            <Typography className={classes.npsScoreChangeValue}>{renderNotAvailableIfValIsNaN(npsResponses)} responses</Typography>
          </div>
        </div>
        <NPSScoreBreakdownChart
          npsScorePercentagePromoters={npsScorePercentagePromoters}
          npsScorePercentagePassives={npsScorePercentagePassives}
          npsScorePercentageDetractors={npsScorePercentageDetractors}
        />
        <NPSScoreBreakdownTable
          npsScorePercentagePromoters={npsScorePercentagePromoters}
          npsScorePercentagePromotersChangeFromLastMonth={npsScorePercentagePromotersChangeFromLastMonth}
          npsScorePercentagePassives={npsScorePercentagePassives}
          npsScorePercentagePassivesChangeFromLastMonth={npsScorePercentagePassivesChangeFromLastMonth}
          npsScorePercentageDetractors={npsScorePercentageDetractors}
          npsScorePercentageDetractorsChangeFromLastMonth={npsScorePercentageDetractorsChangeFromLastMonth}
        />
      </div>

      <div className={classes.cardStack}>
        <SentimentCard
          title={'Message sentiment'}
          value={renderFloatWithTwoDecimalsIfValIsNotNaN(messageSentiment)}
          denominator={1}
          hideDenominator={isNaN(messageSentiment)}
          change={renderFloatWithTwoDecimalsIfValIsNotNaN(messageSentimentChangeFromLastMonth)}
          changeDescription={'from last month'}
        />
        <SentimentCard
          title={'Service rating'}
          value={renderFloatWithTwoDecimalsIfValIsNotNaN(serviceRating)}
          denominator={1}
          hideDenominator={isNaN(serviceRating)}
          change={renderFloatWithTwoDecimalsIfValIsNotNaN(serviceRatingChangeFromLastMonth)}
          changeDescription={'from last month'}
        />
      </div>
      <div className={classes.cardStack}>
        <SentimentCard
          title={'Amenities rating'}
          value={renderFloatWithTwoDecimalsIfValIsNotNaN(amenitiesRating)}
          denominator={1}
          hideDenominator={isNaN(amenitiesRating)}
          change={renderFloatWithTwoDecimalsIfValIsNotNaN(amenitiesRatingChangeFromLastMonth)}
          changeDescription={'from last month'}
        />
        <SentimentCard
          title={'Maintenance rating'}
          value={renderFloatWithTwoDecimalsIfValIsNotNaN(maintenanceRating)}
          denominator={1}
          hideDenominator={isNaN(maintenanceRating)}
          change={renderFloatWithTwoDecimalsIfValIsNotNaN(maintenanceRatingChangeFromLastMonth)}
          changeDescription={'from last month'}
        />
      </div>
    </div>
  )
}

NPSScoreSummaryCards.propTypes = {
  npsResponses: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['NaN'])]).isRequired,
  npsScore: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['NaN'])]).isRequired,
  npsScoreChange: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['NaN'])]).isRequired,
  npsScorePercentagePromoters: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['NaN'])]).isRequired,
  npsScorePercentagePromotersChangeFromLastMonth: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['NaN'])]).isRequired,
  npsScorePercentagePassives: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['NaN'])]).isRequired,
  npsScorePercentagePassivesChangeFromLastMonth: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['NaN'])]).isRequired,
  npsScorePercentageDetractors: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['NaN'])]).isRequired,
  npsScorePercentageDetractorsChangeFromLastMonth: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['NaN'])]).isRequired,
  messageSentiment: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['NaN'])]).isRequired,
  messageSentimentChangeFromLastMonth: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['NaN'])]).isRequired,
  serviceRating: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['NaN'])]).isRequired,
  serviceRatingChangeFromLastMonth: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['NaN'])]).isRequired,
  amenitiesRating: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['NaN'])]).isRequired,
  amenitiesRatingChangeFromLastMonth: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['NaN'])]).isRequired,
  maintenanceRating: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['NaN'])]).isRequired,
  maintenanceRatingChangeFromLastMonth: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['NaN'])]).isRequired,
}

export default NPSScoreSummaryCards
