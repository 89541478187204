import React, {useState, useEffect} from 'react'
import {Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import Grid from '@mui/material/Grid'
import Constant from './EventConstant'
import {Typography as TypographyStyle, Colors} from './../../assets/styles'
import DateTimeInput from '../DateTimeInput'
import TextInput from '../TextInput'
import MultilineTextInput from '../MultilineTextInput'
import ImageGallerySelector from '../ImageGallerySelector'
import {spaceMedium, spaceSmall} from '../../assets/styles/spacing'
import DashboardToggle from '../DashboardToggle'
import NumberInput from '../NumberInput'
import {useSelector} from 'react-redux'
import {CORE_FEATURE_KORMAN} from '../../constants/ModelConstants'
import {rxrGreyColor} from '../../assets/styles/color'
import {getSocialEventRegistrants} from '../../lib/queries'
import AttendeeList from './AttendeeList'
import SimpleSpinner from '../SimpleSpinner'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  formRow: {
    marginBottom: spaceMedium,
  },
  heading: {
    ...TypographyStyle.CaptionHeader,
    lineHeight: '19px',
    color: Colors.rxrRedColor,
  },
  viewRsvpDetails: {
    marginTop: spaceMedium,
    borderTop: `3px solid ${rxrGreyColor}`,
  },
  rsvpDetailsHeader: {
    marginTop: spaceMedium,
    fontSize: TypographyStyle.fontSizeLarge,
    fontWeight: TypographyStyle.heavyFontWeight,
  },
}))

const DESCRIPTION_CHARACTER_LIMIT = 5000

export default function EventForm(props) {
  const classes = useStyles()
  const coreFeatures = useSelector(state => state.GroupPermissions.coreFeatures)
  // we only show RSVP to Korman properties for now
  const showRSVP = !coreFeatures[CORE_FEATURE_KORMAN]
  const [socialEventRegistrants, setSocialEventRegistrants] = useState([])
  const [isLoadingSocialEventRegistrants, setIsLoadingSocialEventRegistrants] = useState(false)
  const residentsLookup = useSelector(state => state.Residents.residentsLookup)

  useEffect(() => {
    if (props.id && props.rsvpRequired) {
      setIsLoadingSocialEventRegistrants(true)
      getSocialEventRegistrants({eventId: props.id})
        .then(res => {
          if (res && res.registrants && Array.isArray(res.registrants) && res.registrants.length > 0) {
            let updatedSocialEventRegistrants = res.registrants.map(registrant => {
              return {
                ...registrant,
                residentInitials: registrant.residentDisplayName
                  .split(' ')
                  .map(name => name[0])
                  .join(''),
                resident: residentsLookup[registrant.residentId],
              }
            })
            setSocialEventRegistrants(updatedSocialEventRegistrants)

            if (props.allowWaitlist && props.capacity) {
              const numAttendees = updatedSocialEventRegistrants.reduce((acc, registrant) => acc + registrant.partySize, 0)
              if (numAttendees > props.capacity) {
                updatedSocialEventRegistrants.sort((a, b) => {
                  if (a.createdAt < b.createdAt) return -1
                  if (a.createdAt > b.createdAt) return 1
                  return 0
                })
                let indexOfLastConfirmedGuest = 0
                let guestCount = 0
                for (const idx in updatedSocialEventRegistrants) {
                  const registrant = updatedSocialEventRegistrants[idx]
                  if (guestCount + registrant.partySize > props.capacity) {
                    indexOfLastConfirmedGuest = idx - 1
                    break
                  }
                  guestCount += registrant.partySize
                }
                updatedSocialEventRegistrants = updatedSocialEventRegistrants.map((registrant, idx) => {
                  if (idx > indexOfLastConfirmedGuest) {
                    registrant.isWaitlisted = true
                  }
                  return registrant
                })
                setSocialEventRegistrants(updatedSocialEventRegistrants)
              }
            }
          }
        })
        .then(() => {
          setIsLoadingSocialEventRegistrants(false)
        })
    }
  }, [props.id, props.rsvpRequired])

  const isCreateMode = !props.id

  return (
    <div className={classes.root}>
      {isCreateMode && (
        <Grid container spacing={3} className={classes.formRow}>
          <Grid item xs={12}>
            <Typography className={classes.heading}>{Constant.ALL_FIELDS_REQUIRED}</Typography>
          </Grid>
        </Grid>
      )}
      <Grid container spacing={3} className={classes.formRow}>
        <Grid item xs={12}>
          <TextInput
            label="Event name"
            placeholder=""
            maxLength={120}
            onChange={val => props.updateForm({name: val})}
            value={props.name}
            isRequired={true}
            error={props.invalidItems.includes('name')}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3} className={classes.formRow}>
        <Grid item xs={12}>
          <TextInput
            label="Event byline"
            placeholder=""
            helperText="A brief sentence describing the event"
            maxLength={120}
            onChange={val => props.updateForm({byline: val})}
            value={props.byline}
            isRequired={true}
            error={props.invalidItems.includes('byline')}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3} className={classes.formRow}>
        <Grid item xs={12}>
          <MultilineTextInput
            label="Event description"
            placeholder=""
            helperText="A full description of the event"
            rows={4}
            maxLength={DESCRIPTION_CHARACTER_LIMIT}
            onChange={val => props.updateForm({description: val})}
            value={props.description}
            isRequired={true}
            error={props.invalidItems.includes('description')}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3} className={classes.formRow}>
        <Grid item xs={12}>
          <TextInput
            label="Location"
            placeholder=""
            helperText="Location of the event"
            maxLength={120}
            onChange={val => props.updateForm({location: val})}
            value={props.location}
            isRequired={true}
            error={props.invalidItems.includes('location')}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3} className={classes.formRow}>
        <Grid item md={6} xs={12}>
          <DateTimeInput
            label="Start date"
            value={props.startDate}
            min={new Date()}
            onChange={val => props.updateForm({startDate: val})}
            error={props.invalidItems.includes('startDate')}
            isRequired={true}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <DateTimeInput
            label="End date"
            value={props.endDate}
            min={props.startDate}
            onChange={val => props.updateForm({endDate: val})}
            disabled={!props.startDate}
            error={props.invalidItems.includes('endDate')}
            isRequired={true}
          />
        </Grid>
      </Grid>
      {showRSVP && (
        <Grid container spacing={3} className={classes.formRow}>
          <Grid item md={6} xs={12}>
            <DashboardToggle
              label={'Require RSVP'}
              value={props.rsvpRequired}
              isDisabled={!isCreateMode}
              onChange={isSelected => props.updateForm({rsvpRequired: isSelected})}
            />
          </Grid>
          {props.rsvpRequired ? (
            <Grid container item spacing={3} md={6} xs={12}>
              <Grid item>
                <DashboardToggle
                  label={`Cap RSVP number`}
                  value={props.capRsvpRequired}
                  isDisabled={!isCreateMode}
                  onChange={isSelected => {
                    if (!isSelected) {
                      props.updateForm({capacity: null})
                    }
                    props.updateForm({capRsvpRequired: isSelected})
                  }}
                />
              </Grid>
              <Grid item>
                <NumberInput
                  label="Max RSVP Number"
                  onChange={val => props.updateForm({capacity: val})}
                  value={props.capacity}
                  integerOnly={true}
                  isDisabled={!props.capRsvpRequired || !isCreateMode}
                  isRequired={props.capRsvpRequired}
                  error={props.invalidItems.includes('capacity')}
                />
              </Grid>
              <Grid item>
                <DashboardToggle
                  label={`Allow residents view who else RSVPd`}
                  value={props.allowResidentsToViewRsvps}
                  onChange={isSelected => props.updateForm({allowResidentsToViewRsvps: isSelected})}
                  isDisabled={!isCreateMode}
                />
              </Grid>
              <Grid item>
                <DashboardToggle
                  label={`Allow guests`}
                  value={props.allowResidentGuestRsvps}
                  onChange={isSelected => props.updateForm({allowResidentGuestRsvps: isSelected})}
                  isDisabled={!isCreateMode}
                />
              </Grid>
              <Grid item>
                <DashboardToggle
                  label={`Allow waitlist`}
                  value={props.allowWaitlist}
                  onChange={isSelected => props.updateForm({allowWaitlist: isSelected})}
                  isDisabled={!isCreateMode}
                />
              </Grid>
            </Grid>
          ) : null}
        </Grid>
      )}

      <Grid container spacing={3} className={classes.formRow} style={{marginTop: 20}}>
        <Grid item xs={12}>
          <ImageGallerySelector
            helperText={
              'The ideal dimensions are 375 x 200 pixels, in landscape mode. The resident will be able to click on your image to view it expanded.'
            }
            maxPhotos={1}
            value={props.image ? [props.image] : []}
            onChange={v => props.updateForm({image: v[0]})}
          />
        </Grid>
      </Grid>

      {props.rsvpRequired && (
        <div className={classes.viewRsvpDetails}>
          <Typography className={classes.rsvpDetailsHeader}>RSVP list</Typography>
          {isLoadingSocialEventRegistrants ? (
            <div style={{marginTop: spaceSmall}}>
              <SimpleSpinner />
            </div>
          ) : (
            <AttendeeList event={{eventName: props.name, headcount: socialEventRegistrants.length}} attendees={socialEventRegistrants} />
          )}
        </div>
      )}
    </div>
  )
}
