import React from 'react'
import moment from 'moment'
import {Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import Grid from '@mui/material/Grid'
import {Colors, Typography as TypographyStyle} from '../../assets/styles'
import SearchResident from '../SearchResident'
import SelectInput from '../SelectInput'
import Constants from './DeliveryConstants'
import TextInput from '../TextInput'
import MultilineTextInput from '../MultilineTextInput'
import CustomCheckbox from '../CustomCheckbox'
import PropTypes from 'prop-types'

const NOTES_CHARACTER_LIMIT = 200

const useStyles = makeStyles(theme => ({
  container: {
    flexGrow: 1,
    marginBottom: 22,
  },
  heading: {
    ...TypographyStyle.H3Header,
    lineHeight: '22px',
    marginBottom: '18px',
    color: '#0C4067',
  },
  priorityGrid: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    textAlign: 'center',
  },
  priorityHeading: {
    ...TypographyStyle.BodyHeader,
    textAlign: 'left',
  },
  priorityHeadingDisabled: {
    ...TypographyStyle.BodyHeader,
    color: Colors.darkGrey,
    textAlign: 'left',
  },
  loggedBy: {
    ...TypographyStyle.BodyHeader,
    color: Colors.rxrBlackColor,
    textAlign: 'left',
  },
}))

export default function IncomingDeliveriesForm(props) {
  const classes = useStyles()

  const isCreatingNewDelivery = !props.id
  const isEditable = !props.receivedAt

  const rowsize = isCreatingNewDelivery ? 6 : 4

  const onChangeResident = value => {
    if (value) {
      props.updateForm({
        residentId: value.id,
        unitId: value.occupancy.unit.id,
      })
    } else {
      props.updateForm({
        residentId: null,
        unitId: null,
      })
    }
  }

  return (
    <div className={classes.container}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <SearchResident
            disabled={!isCreatingNewDelivery}
            value={props.residentId}
            isRequired={true}
            onSelectResident={onChangeResident}
            error={props.invalidItems.includes('residentId') || props.invalidItems.includes('unitId')}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <SelectInput
            onChange={val =>
              props.updateForm({
                carrier: val,
              })
            }
            label={'Carrier'}
            isRequired={true}
            options={Constants.CARRIER_OPTIONS}
            value={props.carrier}
            disabled={!isEditable}
            error={props.invalidItems.includes('carrier')}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <SelectInput
            onChange={val =>
              props.updateForm({
                packageType: val,
              })
            }
            label={'Package type'}
            isRequired={true}
            options={Constants.PACKAGE_OPTIONS}
            value={props.packageType}
            disabled={!isCreatingNewDelivery}
            error={props.invalidItems.includes('packageType')}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            label="Tracking number"
            placeholder=""
            maxLength={120}
            onChange={val => props.updateForm({trackingNumber: val})}
            value={props.trackingNumber}
            isDisabled={!isEditable}
          />
        </Grid>

        {!isCreatingNewDelivery && (
          <React.Fragment>
            <Grid item md={6} xs={12}>
              <TextInput
                label="Arrived on"
                placeholder=""
                isDisabled
                value={props.arrivedOn ? new moment(props.arrivedOn).format(Constants.PACKAGE_TABLE_DATETIME_FORMAT) : ''}
              />
            </Grid>
            {!isEditable && (
              <Grid item md={6} xs={12}>
                <TextInput
                  label={
                    props.deliveredTo === 'unit'
                      ? 'Marked Delivered to unit by'
                      : props.deliveredTo === 'person'
                      ? 'Picked up at front desk by'
                      : 'Delivered on'
                  }
                  placeholder=""
                  isDisabled
                  value={`${props.recipientName ? props.recipientName + ', ' : ''} ${
                    props.deliveredOn ? new moment(props.deliveredOn).format(Constants.PACKAGE_TABLE_DATETIME_FORMAT) : ''
                  }`}
                />
              </Grid>
            )}
          </React.Fragment>
        )}
        {props.staffId && props.staffLookup && props.staffLookup[props.staffId] && props.staffLookup[props.staffId].displayName ? (
          <Grid item xs={12} className={classes.priorityGrid}>
            <Typography className={classes.loggedBy}>Logged by: {props.staffLookup[props.staffId].displayName}</Typography>
          </Grid>
        ) : null}

        <Grid item xs={12}>
          <MultilineTextInput
            label="Additional details"
            placeholder=""
            helperText={`The resident will see these notes. ${NOTES_CHARACTER_LIMIT} character limit.`}
            rows={rowsize}
            rowsMax={rowsize}
            maxLength={NOTES_CHARACTER_LIMIT}
            onChange={val => props.updateForm({notes: val})}
            value={props.notes}
            isDisabled={!isEditable}
          />
        </Grid>
        <Grid item xs={12} className={classes.priorityGrid}>
          <CustomCheckbox
            isChecked={props.priority === 'HIGH'}
            onChange={checked =>
              props.updateForm({
                priority: checked ? 'HIGH' : 'LOW',
              })
            }
            label={'High priority?'}
            isDisabled={!isEditable}
          />
        </Grid>
      </Grid>
    </div>
  )
}

IncomingDeliveriesForm.propTypes = {
  invalidItems: PropTypes.array,
}
