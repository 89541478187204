import {Auth, Storage} from 'aws-amplify'
import QueryHelper from './QueryHelper'
import FileHelper from './FileHelper'
import config from '../amplifyconfiguration.json'

Auth.configure(config)

class AuthedUser {
  /**
   * @param {string} id
   * @param {Array<string>} groups
   */
  constructor(id, groups) {
    if (!id || !groups) {
      throw new Error('Missing required param(s)')
    }

    this.id = id
    this.groups = groups

    // TODO: This for legacy support, remove ASAP
    this.attributes = {sub: id}
  }
}

class AppAuth {
  static #instance
  /**
   * @constructor
   */
  constructor() {
    if (AppAuth.#instance) {
      throw new Error('AppAuth is a Singleton')
    }

    if (!config) {
      throw new Error('Initialization of AppAuth requires a config')
    }

    // usernames get converted into phone numbers using the rexRedirect API gateway
    this.__baseEndpoint = config.aws_cloud_logic_custom.find(o => o.name === 'rexRedirectApi').endpoint
    if (!this.__baseEndpoint) {
      throw new Error('Missing auth confirmation endpoint')
    }
    // using the staffLookup hook
    this.__usernameEndpoint = this.__baseEndpoint + '/staffLookup'
    this.__requestPasswordResetEndpoint = this.__baseEndpoint + '/requestPasswordReset'
    this.__submitPasswordResetEndpoint = this.__baseEndpoint + '/submitPasswordReset'
    this.__validatePasswordResetTokenEndpoint = this.__baseEndpoint + '/validatePasswordResetToken'

    QueryHelper.Instance(config)
    FileHelper.Instance(Storage, config)
    AppAuth.#instance = this
  }

  /**
   * @returns {Promise<AuthedUser|null>}
   */
  async getAuthenticatedUser() {
    try {
      const user = await Auth.currentAuthenticatedUser()
      if (!user || user.expired) {
        return null
      }
      return new AuthedUser(user.attributes.sub, user.signInUserSession.accessToken.payload['cognito:groups'])
    } catch (error) {
      return null
    }
  }

  /**
   * Will return the staff id matching that username and password, or throws an exception if login fails
   * @param {string} username
   * @param {string} password
   * @throws
   * @returns {Promise<AuthedUser>}
   */
  async logIn(username, password) {
    try {
      const response = await fetch(this.__usernameEndpoint, {
        method: 'post',
        body: JSON.stringify({
          username: username,
        }),
      })

      const respData = await response.json()
      const result = await Auth.signIn(respData.phoneNumber, password)

      if (result) {
        const retVal = new AuthedUser(result.attributes.sub, result.signInUserSession.accessToken.payload['cognito:groups'])
        if (!retVal.groups.some(g => ['staff', 'group2', 'group3', 'group4'].includes(g))) {
          await Auth.signOut()
          throw new Error('Not a staff user')
        }

        // success!
        return retVal
      }
    } catch (err) {
      console.error(err)
      throw new Error('Invalid credentials')
    }
  }

  /**
   * @returns {Promise<void>}
   */
  async logOut() {
    await Auth.signOut()
  }

  /**
   * @returns {Promise<string>}
   */
  async getJWT() {
    try {
      const sesh = await Auth.currentSession()

      if (!sesh) {
        return undefined
      }

      return sesh.getIdToken().getJwtToken()
    } catch (err) {
      console.error(err)
      return undefined
    }
  }

  /**
   * @param {string} email
   * @return {Promise<boolean>}
   */
  async sendPasswordResetEmail(email) {
    const resp = await fetch(this.__requestPasswordResetEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: email,
      }),
    })

    if (resp.status !== 200) {
      throw new Error('Failed to send password reset email')
    }
    return true
  }

  /**
   * @param {string} staffId
   * @param {string} token
   * @param {string} password
   * @return {Promise<boolean>}
   */
  async submitNewPassword(staffId, token, password) {
    const resp = await fetch(this.__submitPasswordResetEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        staffId: staffId,
        token: token,
        password: password,
      }),
    })

    if (resp.status !== 200) {
      throw new Error('Failed to submit new password')
    }
    return true
  }

  /**
   * @param {string} staffId
   * @param {string} token
   * @returns {Promise<{id: string, userName: string, displayName: string}>}
   */
  async validatePasswordResetToken(staffId, token) {
    const resp = await fetch(this.__validatePasswordResetTokenEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        staffId: staffId,
        token: token,
      }),
    })

    if (resp.status !== 200) {
      throw new Error('Invalid or expired token')
    }

    return await resp.json()
  }

  // ---------------------------------------------------------------
  // STATIC FUNCTIONS:

  /**
   * @returns {AppAuth}
   */
  static Instance() {
    if (!AppAuth.#instance) {
      new AppAuth()
    }
    return AppAuth.#instance
  }
}

export default AppAuth
