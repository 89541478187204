export default {
  TITLE: 'Deliveries',
  ENTER_SEARCH_KEYWORDS_CONSTANT: 'Search by resident, unit number, or barcode...',
  ENTER_SEARCH_KEYWORDS_OUTGOING_CONSTANT: 'Search by resident, unit number, or carrier...',
  NO_INCOMING_PACKAGE_MESSAGE: 'There are currently no incoming packages. Select “Log new package" to create a new package.',
  NO_PACKAGE_DELIVERED_MESSAGE: 'No packages have been delivered. Deliver a package from the Delivered packages tab.',
  NO_OUTGOING_PACKAGE_MESSAGE: 'There are currently no outgoing packages. Select “Log new package" to create a new package.',
  NO_PACKAGE_PICKEDUP_MESSAGE: 'No packages have been picked up. You can mark a package Picked Up on the Outgoing packages tab.',
  ADD_BUTTON: 'Log new package',
  ADD_BUTTON_MOBILE: 'Log new',
  INCOMING_PACKAGES: 'Incoming packages',
  DELIVERED_PACKAGES: 'Package archive',
  BTN_DELETE_PACKAGE: 'Delete this package',
  BTN_SAVE: 'Save',
  BTN_CANCEL: 'Cancel',
  BTN_UNMARK_DELIVERED: 'Unmark as delivered',
  BTN_UNMARK_PICKEDUP: 'Unmark as picked up',
  BTN_DISCARD_CHANGES: 'Discard changes',
  BTN_SAVE_LOG_ANOTHER: 'Save and log another',
  CARRIER_OPTIONS: [
    {value: 'AMAZON', label: 'Amazon'},
    {value: 'DHL', label: 'DHL'},
    {value: 'FEDEX', label: 'FedEx'},
    {value: 'FLORIST', label: 'Florist'},
    {value: 'LASER_SHIP', label: 'LaserShip'},
    {value: 'OTHER', label: 'Other'},
    {value: 'UPS', label: 'UPS'},
    {value: 'USPS', label: 'USPS'},
  ],
  PACKAGE_OPTIONS: [
    {value: 'DRYCLEANING', label: 'Dry Cleaning'},
    {value: 'ENVELOPE', label: 'Envelope'},
    {value: 'FURNITURE', label: 'Furniture'},
    {value: 'GROCERY', label: 'Grocery'},
    {value: 'OTHER', label: 'Other'},
    {value: 'PACKAGE', label: 'Package'},
    {value: 'TAKEOUT', label: 'Take out'},
  ],
  PRIORITY_OPTIONS: [{value: 'LOW', label: 'LOW'}, {value: 'HIGH', label: 'HIGH'}],
  DELETE_PACKAGE_DISCARD_TITLE: 'Discard package entry',
  DELETE_PACKAGE_DISCARD_MESSAGE: 'Are you sure you want to discard this package?',
  UNMARK_PACKAGE_DISCARD_TITLE: 'Unmark package as delivered',
  UNMARK_PACKAGE_PICKEDUP_DISCARD_TITLE: 'Unmark package as picked up',
  UNMARK_PACKAGE_PICKEDUP_DISCARD_MESSAGE: 'Are you sure you want to move this package back into the outgoing packages tab?',
  UNMARK_PACKAGE_DISCARD_MESSAGE: 'Are you sure you want to move this package back into the incoming packages tab?',
  UNDELETE_PACKAGE_DISCARD_MESSAGE: 'Are you sure you want to move this package back into the outgoing packages tab?',
  OUTGOING_PACKAGES: 'Outgoing packages',
  PICKEDUP_PACKAGES: 'Package archive',
  PACKAGE_TABLE_DATETIME_FORMAT: 'h:mma, MM/DD/YY',
  TAB_INCOMING_PACKAGES: 0,
  TAB_DELIVERED_PACKAGES: 1,
  BTN_UNDELETE_DELIVERED: 'Undelete package',
  UNDELETE_PACKAGE_DISCARD_TITLE: 'Undelete package',
  UNDELETE_NOTIFICATION_TEXT: 'The resident will get a notification.',
}
