import React, {useState, useRef, useEffect} from 'react'
import {PageContainer} from '../../assets/styles/layout'
import {Grid, MenuItem, Menu, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import {Typography as TypographyStyle} from '../../assets/styles'
import StaffNotes from './StaffNotes/StaffNotes'
import {spaceMedium, spaceSmall} from '../../assets/styles/spacing'
import {shadowHard, shadowSoft, rxrWhiteColor, rxrSidePanelBackgroundCoor} from '../../assets/styles/color'
import moment from 'moment'
import RXRIcon from '../RXRIcon'
import MetricCard from './MetricCard'
import NudgesSummary from './NudgesSummary'
import OnboardingSummary from './OnboardingSummary'
import {borderRadiusMedium} from '../../assets/styles/border'
import MetricDataAPI from '../../lib/MetricData/MetricDataAPI'
import {useSelector} from 'react-redux'
import PageHeader from '../PageHeader'
import {
  CORE_FEATURE_CLEANINGS,
  CORE_FEATURE_DOG_WALKING,
  CORE_FEATURE_MASSAGE,
  CORE_FEATURE_PERSONAL_TRAINING,
} from '../../constants/ModelConstants'

/**
 * @param {number} days
 * @returns {string}
 */
function getLabelForDaysBack(days) {
  return `Last ${days} days`
}

const dateOptions = [7, 14, 30, 90]

function CommandCenter() {
  const dateRef = useRef()
  const classes = useStyles()
  const coreFeatures = useSelector(state => state.GroupPermissions.coreFeatures)
  const [isDateSelectorOpen, setIsDateSelectorOpen] = useState(false)
  const [selectedDaysBack, setSelectedDaysBack] = useState(30)
  const activeBuildingId = useSelector(state => state.Buildings.activeBuildingId)
  const activeBuilding = useSelector(state => state.Buildings.buildingsLookup[state.Buildings.activeBuildingId])
  const [isLoadingData, setIsLoadingData] = useState(true)

  const [
    /** @type CommandCenterMetricDataPayload */
    metricData,
    setMetricData,
  ] = useState(undefined)

  const fetchMetricData = async () => {
    if (!activeBuilding) {
      return
    }

    setIsLoadingData(true)

    try {
      setMetricData(
        await MetricDataAPI.fetchCommandCenterData(activeBuilding.yardiId, moment().subtract(selectedDaysBack, 'days').toDate()),
      )
    } catch (err) {
      console.error(err)
      window.alert(err.message)
    }

    setIsLoadingData(false)
  }

  useEffect(() => {
    fetchMetricData().then()
  }, [selectedDaysBack, activeBuildingId])

  /**
   * @param {string} metricName
   * @returns {undefined|number}
   */
  const getMetricFromMetricData = metricName => {
    return isLoadingData || !metricData ? undefined : metricData[metricName]
  }

  /**
   * @param {string} metricName
   * @returns {undefined|number}
   */
  const getDeltaFromMetricData = metricName => {
    return isLoadingData || !metricData ? undefined : metricData[`${metricName}Delta`]
  }

  const getRevenueFromMetricData = metricName => {
    return isLoadingData || !metricData ? undefined : metricData[`${metricName}Revenue`]
  }

  return (
    <div className={classes.commandCenterPage}>
      <PageHeader title={'Command Center'} className={classes.titleContainer} />
      <div className={classes.innerPage}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            {/*<Grid container className={classes.container}>*/}
            {/*  <Grid item xs={12} md={4}>*/}
            {/*    <MetricCard*/}
            {/*      metric={getMetricFromMetricData('sentiment')}*/}
            {/*      label={'Resident\nSatisfaction'}*/}
            {/*      icon={RXRIcon.STAR}*/}
            {/*      delta={getDeltaFromMetricData('sentiment')}*/}
            {/*    />*/}
            {/*  </Grid>*/}
            {/*  <Grid item xs={12} md={4}>*/}
            {/*    <MetricCard*/}
            {/*      metric={getMetricFromMetricData('engagement')}*/}
            {/*      label={'Resident\nEngagement'}*/}
            {/*      icon={RXRIcon.SMILE_BIG}*/}
            {/*      delta={getDeltaFromMetricData('engagement')}*/}
            {/*    />*/}
            {/*  </Grid>*/}
            {/*  <Grid item xs={12} md={4}>*/}
            {/*    <MetricCard*/}
            {/*      metric={getMetricFromMetricData('nudges')}*/}
            {/*      label={'RXO\nAssistant'}*/}
            {/*      icon={RXRIcon.RXO_ASSISTANT}*/}
            {/*      delta={getDeltaFromMetricData('nudges')}*/}
            {/*    />*/}
            {/*  </Grid>*/}
            {/*</Grid>*/}
            <NudgesSummary className={classes.container} />
            {!!activeBuilding && !!activeBuilding.onboardingSchema && <OnboardingSummary className={classes.container} />}
            <div className={classes.dateSelectContainer}>
              <span>Viewing below data for: </span>
              <span
                className={classes.dateSelector}
                onClick={e => {
                  e.stopPropagation()
                  setIsDateSelectorOpen(!isDateSelectorOpen)
                }}
                ref={dateRef}
              >
                {getLabelForDaysBack(selectedDaysBack)}
                {'\n'}
                <RXRIcon icon={isDateSelectorOpen ? RXRIcon.ARROW_UP : RXRIcon.ARROW_DOWN} size={RXRIcon.SIZE_SMALL} />
                <Menu className={classes.dateSelectorMenu} open={!!isDateSelectorOpen} anchorEl={dateRef.current}>
                  {dateOptions.map(o => {
                    return (
                      <MenuItem
                        key={o}
                        value={o}
                        style={selectedDaysBack === o ? TypographyStyle.MenuItemSelected : TypographyStyle.MenuItemNotSelected}
                        onClick={() => setSelectedDaysBack(o)}
                      >
                        {getLabelForDaysBack(o)}
                      </MenuItem>
                    )
                  })}
                </Menu>
              </span>
            </div>
            <div className={classes.container}>
              <Grid container spacing={3} justifyContent={'flex-start'}>
                {coreFeatures[CORE_FEATURE_CLEANINGS] && (
                  <Grid item xs={12} md={6} lg={3}>
                    <MetricCard
                      label={'Cleanings'}
                      icon={RXRIcon.CLEANING}
                      metric={getMetricFromMetricData('cleanings')}
                      delta={getDeltaFromMetricData('cleanings')}
                      revenue={getRevenueFromMetricData('cleanings')}
                    />
                  </Grid>
                )}
                {coreFeatures[CORE_FEATURE_DOG_WALKING] && (
                  <Grid item xs={12} md={6} lg={3}>
                    <MetricCard
                      label={'Dog Walking'}
                      icon={RXRIcon.DOG_WALKING}
                      metric={getMetricFromMetricData('dogWalking')}
                      delta={getDeltaFromMetricData('dogWalking')}
                      revenue={getRevenueFromMetricData('dogWalking')}
                    />
                  </Grid>
                )}
                {coreFeatures[CORE_FEATURE_MASSAGE] && (
                  <Grid item xs={12} md={6} lg={3}>
                    <MetricCard
                      label={'Massage'}
                      icon={RXRIcon.MASSAGE}
                      metric={getMetricFromMetricData('massage')}
                      delta={getDeltaFromMetricData('massage')}
                      revenue={getRevenueFromMetricData('massage')}
                    />
                  </Grid>
                )}
                {coreFeatures[CORE_FEATURE_PERSONAL_TRAINING] && (
                  <Grid item xs={12} md={6} lg={3}>
                    <MetricCard
                      label={'Training'}
                      icon={RXRIcon.PERSONAL_TRAINING}
                      metric={getMetricFromMetricData('personalTraining')}
                      delta={getDeltaFromMetricData('personalTraining')}
                      revenue={getRevenueFromMetricData('personalTraining')}
                    />
                  </Grid>
                )}
                <Grid item xs={12} md={6} lg={3}>
                  <MetricCard
                    label={'Maintenance'}
                    icon={RXRIcon.MAINTENANCE}
                    metric={getMetricFromMetricData('maintenance')}
                    delta={getDeltaFromMetricData('maintenance')}
                    revenue={getRevenueFromMetricData('maintenance')}
                  />
                </Grid>
              </Grid>
            </div>
            <div className={classes.container}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={3}>
                  <MetricCard
                    label={'Deliveries'}
                    icon={RXRIcon.DELIVERIES}
                    metric={getMetricFromMetricData('incomingDeliveries')}
                    delta={getDeltaFromMetricData('incomingDeliveries')}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <MetricCard
                    label={'Guests'}
                    icon={RXRIcon.GUEST_AUTH}
                    metric={getMetricFromMetricData('guests')}
                    delta={getDeltaFromMetricData('guests')}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <MetricCard
                    label={'Amenities'}
                    icon={RXRIcon.AMENITIES}
                    metric={getMetricFromMetricData('amenities')}
                    delta={getDeltaFromMetricData('amenities')}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <MetricCard
                    label={'Events'}
                    icon={RXRIcon.EVENTS}
                    metric={getMetricFromMetricData('events')}
                    delta={getDeltaFromMetricData('events')}
                  />
                </Grid>
              </Grid>
            </div>
          </Grid>

          <Grid item xs={12} md={4}>
            <div className={`${classes.container} ${classes.notesContainer}`}>
              <StaffNotes />
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  commandCenterPage: {
    ...PageContainer,
    height: 'auto',
    backgroundColor: rxrSidePanelBackgroundCoor,
  },
  titleContainer: {
    borderBottom: 'none',
    backgroundColor: 'transparent',
  },

  innerPage: {
    padding: spaceMedium,
    paddingTop: 0,
    height: '100%',
  },

  container: {
    backgroundColor: rxrWhiteColor,
    overflow: 'hidden',
    borderRadius: borderRadiusMedium,
    padding: spaceMedium,
    boxShadow: shadowSoft,
    marginBottom: spaceMedium,
  },

  notesContainer: {
    padding: spaceMedium,
    maxHeight: '80vh',
    overflowY: 'auto',
  },

  dateSelectContainer: {
    marginBottom: spaceSmall,
  },

  dateSelector: {
    position: 'relative',
    textDecoration: 'underline',
    cursor: 'pointer',
  },

  dateSelectorMenu: {},
}))

export default CommandCenter
