import ActionTypes from '../actions/types'

const initialState = {
  residentsLookup: {}, // these objects have the props defined in ResidentFragment @see modelFragments.js
}

// copied from user reducer in mobile app -----------------------------------------------------------
/**
 * @param {string|*} userProfile
 * @return {UserProfileData|{}}
 */
export function formatUserProfile(userProfile) {
  if (typeof userProfile === 'string') {
    try {
      return JSON.parse(userProfile || '{}')
    } catch (err) {
      // do nothing, must be malformed
      return {}
    }
  } else if (!!userProfile && typeof userProfile === 'object') {
    return userProfile
  } else {
    return {}
  }
}
// ---------------------------------------------------------------------------------------------------

export function formatResidentObject(r) {
  const userProfileObj = formatUserProfile(r.userProfile)
  return {
    ...r,
    userProfileObj: userProfileObj,
    displayName: `${r.displayName}${userProfileObj.goesBy ? ` '${userProfileObj.goesBy.trim()}'` : ''}`,
    canonicalDisplayName: r.displayName,
  }
}

// --------------------------------------------------------------------------------
// State permutation functions:

function reduceResidentsListToObj(state, residentsList) {
  let byId = {}
  residentsList.forEach(r => {
    byId[r.id] = formatResidentObject(r)
  })

  return {...state, residentsLookup: byId}
}

function updateResident(state, resident) {
  const newLookup = {...state.residentsLookup}
  // overwrite changes only this is because Occupancy prop is merged into the resident on initial sync
  // and so will not appear in the updated resident object
  newLookup[resident.id] = {...newLookup[resident.id], ...formatResidentObject(resident)}
  return {...state, residentsLookup: newLookup}
}

// --------------------------------------------------------------------------------

export default function (state = initialState, action) {
  switch (action.type) {
    case ActionTypes.RESIDENTS_UPDATE_ALL:
      return reduceResidentsListToObj(state, action.payload)

    case ActionTypes.RESIDENT_UPDATE_SINGLE:
      return updateResident(state, action.payload)

    default:
      return state
  }
}
