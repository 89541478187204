import React, {Fragment, useEffect, useState} from 'react'
import {PageContainer} from '../../assets/styles/layout'
import PageHeader from '../PageHeader'
import {RXRButton} from '../RXRButton'
import Routes from '../../constants/RouteConstants'
import LoadingStateIconAndMessage from '../LoadingStateIconAndMessage'
import EmptyStateIconAndMessage from '../EmptyStateIconAndMessage'
import DiscardChangesDialog from '../DiscardChangesDialog'
import SidePanel from '../SidePanel'
import ServiceRequestsTable from './ServiceRequestsTable'
import ServiceRequestForm from './ServiceRequestForm'
import {Grid} from '@mui/material'
import ConfirmDeleteDialog from '../ConfirmDeleteDialog'
import useBuildingDataRefresh from '../hooks/useBuildingDataRefresh'
import {listServiceRequests, createNewServiceRequest, cancelExistingServiceRequest} from '../../lib/queries'
import useFormChanged, {NonEmptyValidator} from '../hooks/useFormChanged'
import useListCreateViewPattern from '../hooks/useListCreateViewPattern'
import {makeStyles} from '@mui/styles'
import {spaceMedium} from '../../assets/styles/spacing'
import {rxrTealColor} from '../../assets/styles/color'
import {useHistory} from 'react-router-dom'
import ClearableInput from '../ClearableInput'
import {AntTab, AntTabs} from '../AntTabs'
import RXRIcon from '../RXRIcon'
import useResidentLookup from '../hooks/useResidentLookup'
import {useSelector} from 'react-redux'

const useStyles = makeStyles(theme => ({
  cancelButton: {
    marginLeft: spaceMedium,
  },

  maintenanceIcon: {
    color: rxrTealColor,
    fontSize: 48,
  },
}))

const EMPTY_FORM_STATE = {
  residentId: null,
  description: '',
  category: '',
  hasPermissionToEnter: false,
  accessNotes: '',
  attachments: [],
  yardiServiceRequestId: '',
}

function serviceRequestIsActive(sr) {
  return !['Canceled', 'Completed'].includes(sr.currentStatus)
}

const TAB_ACTIVE = 0
const TAB_PASSED = 1

function ServiceRequestsPage(props) {
  const isMobile = useSelector(state => state.App.isMobile)
  const classes = useStyles({isMobile})

  const history = useHistory()
  const [isSaving, setIsSaving] = useState(false)
  const [serviceRequests, setServiceRequests] = useState([])
  const [cancelServiceRequestDialog, setCancelServiceRequestDialog] = useState(null)
  const [filterTerm, setFilterTerm] = useState('')
  const [tab, setTab] = useState(TAB_ACTIVE)
  const {form, formChanged, setForm, validateForm, invalidItems, resetInitialForm} = useFormChanged(
    EMPTY_FORM_STATE,
    useFormChanged.PropLevelValidation({
      residentId: NonEmptyValidator,
      description: NonEmptyValidator,
      category: NonEmptyValidator,
    }),
  )
  const {getResident} = useResidentLookup()

  const {activeBuildingId, isLoadingData: isLoading} = useBuildingDataRefresh(loadServiceRequests)

  const {isCreateMode, focusedItemId} = useListCreateViewPattern(
    Routes.SERVICE_REQUESTS_CREATE,
    Routes.SERVICE_REQUESTS_VIEW,
    'serviceRequestId',
    EMPTY_FORM_STATE,
    form,
    resetInitialForm,
    serviceRequests,
    item => ({
      id: item.id,
      residentId: item.serviceRequestResidentId,
      description: item.fullDescription,
      category: item.category,
      hasPermissionToEnter: item.hasPermissionToEnter,
      accessNotes: item.accessNotes,
      attachments: item.attachments,
      yardiServiceRequestId: item.yardiServiceRequestId,
    }),
    isLoading,
  )

  async function loadServiceRequests() {
    let requests = await listServiceRequests(activeBuildingId)
    setServiceRequests(requests)
  }

  const resetForm = () => {
    resetInitialForm(EMPTY_FORM_STATE, hideSlidingPanel)
  }

  const hideSlidingPanel = () => {
    setCancelServiceRequestDialog(null)
    history.push(Routes.SERVICE_REQUESTS)
  }

  async function handleCancelServiceRequest(serviceRequestId) {
    if (!cancelServiceRequestDialog || cancelServiceRequestDialog !== serviceRequestId) {
      return setCancelServiceRequestDialog(serviceRequestId)
    }

    setIsSaving(true)

    try {
      await cancelExistingServiceRequest(serviceRequestId)
      await loadServiceRequests()
      resetForm()
    } catch (err) {
      console.error(err)
      alert('Sorry, we could not process this request. ' + err.message)
    }

    setIsSaving(false)
  }

  async function handleSubmit() {
    if (form.id) {
      // can't edit a service request, can only create a new one or cancel an existing one
      return
    }

    // make sure the form is valid
    if (!validateForm()) {
      return
    }

    setIsSaving(true)

    const input = {
      residentId: form.residentId,
      category: form.category,
      description: form.description,
      accessNotes: form.accessNotes,
      hasPermissionToEnter: form.hasPermissionToEnter || false,
    }

    try {
      await createNewServiceRequest(input)
      await loadServiceRequests()
      resetForm()
    } catch (err) {
      console.error(err)
      alert(`Sorry, we could not process this request. ${err.message}. Please re-enter the maintenance information and try again.`)
    }

    setIsSaving(false)
  }

  function filterServiceRequests() {
    const filterLowerCase = filterTerm.toLowerCase()
    return serviceRequests.filter(serviceRequest => {
      const resident = getResident(serviceRequest.serviceRequestResidentId)
      return (
        (tab === TAB_ACTIVE) === serviceRequestIsActive(serviceRequest) &&
        (!filterLowerCase ||
          resident.occupancy.unit.number.toLowerCase().includes(filterLowerCase) ||
          resident.displayName.toLowerCase().includes(filterLowerCase) ||
          serviceRequest.yardiServiceRequestId.includes(filterLowerCase) ||
          (serviceRequest.category && serviceRequest.category.toLowerCase().includes(filterLowerCase)))
      )
    })
  }

  const focusedItem = focusedItemId ? serviceRequests.find(s => s.id === focusedItemId) : null

  return (
    <div style={PageContainer}>
      <PageHeader
        title={'Maintenance Requests'}
        rightControlComponent={
          <RXRButton
            type={isMobile ? RXRButton.TYPE_DEFAULT : RXRButton.TYPE_LARGE}
            onClick={() => history.push(Routes.SERVICE_REQUESTS_CREATE)}
          >
            Create work order
          </RXRButton>
        }
      >
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6} md={6}>
            <ClearableInput value={filterTerm} onChange={setFilterTerm} placeholder={'Search by resident, unit, or category'} />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item lg={6} md={6}>
            <AntTabs value={tab} onChange={(e, t) => setTab(t)}>
              <AntTab label={'Active'} />
              <AntTab label={'Previous'} />
            </AntTabs>
          </Grid>
        </Grid>
      </PageHeader>

      <div style={{height: '100%'}}>
        {isLoading ? (
          <LoadingStateIconAndMessage message={'Loading maintenance requests...'} />
        ) : serviceRequests.length === 0 ? (
          <EmptyStateIconAndMessage message={'There are no maintenance requests to view'} icon={RXRIcon.MAINTENANCE} />
        ) : (
          <ServiceRequestsTable requests={filterServiceRequests()} />
        )}
      </div>

      <DiscardChangesDialog hasChanges={formChanged} />

      <SidePanel
        isOpen={!!focusedItemId || isCreateMode}
        onClose={hideSlidingPanel}
        title={isCreateMode ? 'Create new maintenance request' : 'View maintenance request'}
      >
        <Fragment>
          <ServiceRequestForm {...form} updateForm={setForm} invalidItems={invalidItems} />
          <Grid container spacing={3} style={{marginTop: 20}}>
            <Grid item>
              {isCreateMode ? (
                <RXRButton onClick={handleSubmit} isLoading={isSaving}>
                  Submit maintenance request
                </RXRButton>
              ) : focusedItem && serviceRequestIsActive(focusedItem) ? (
                <RXRButton type={RXRButton.TYPE_DESTRUCTIVE} onClick={() => handleCancelServiceRequest(focusedItemId)} isLoading={isSaving}>
                  Cancel maintenance request
                </RXRButton>
              ) : null}
              <RXRButton type={RXRButton.TYPE_TEXT} className={classes.cancelButton} isLoading={isSaving} onClick={hideSlidingPanel}>
                Cancel
              </RXRButton>
            </Grid>
          </Grid>
        </Fragment>
      </SidePanel>

      <ConfirmDeleteDialog
        prompt={'Are you sure you want to cancel this Service Request?'}
        isOpen={!!cancelServiceRequestDialog && !isSaving}
        onConfirmDelete={() => handleCancelServiceRequest(cancelServiceRequestDialog)}
        onCancel={() => setCancelServiceRequestDialog(null)}
        deleteButtonText={'Cancel maintenance request'}
        cancelButtonText={'No'}
      />
    </div>
  )
}

export default ServiceRequestsPage
