import React, {useEffect, useState} from 'react'
import {Typography} from '@mui/material'
import {Typography as TypographyStyle} from '../../assets/styles'
import {rxrBlueColor} from '../../assets/styles/color'
import PropTypes from 'prop-types'
import {getDiscountsForResident} from '../../lib/queries'
import {useSelector} from 'react-redux'
import VendorServiceDiscount from '../../lib/VendorServiceDiscount'
import CustomCheckbox from '../CustomCheckbox'

function SelectVendorServiceDiscounts(props) {
  const [discountsLookup, setDiscountsLookup] = useState({})
  const activeBuildingId = useSelector(state => state.Buildings.activeBuildingId)

  useEffect(() => {
    if (!props.residentId || !activeBuildingId || !props.service) {
      setDiscountsLookup({})
      return
    }

    const today = new Date()
    getDiscountsForResident(props.residentId, activeBuildingId).then(loadedDiscounts => {
      setDiscountsLookup(
        VendorServiceDiscount.filterDiscountsForServiceType(loadedDiscounts, props.service.type)
          // only includes ones that aren't expired yet OR have already been selected
          .filter(d => d.expiredAt >= today || props.selectedDiscountIds.includes(d.id))
          // only includes ones that haven't been used OR have been used by this appointment
          .filter(d => !d.vendorAppointmentId || d.vendorAppointmentId === props.editingAppointmentId)
          .reduce((agr, d) => {
            agr[d.id] = d
            return agr
          }, {}),
      )
    })
  }, [props.residentId, activeBuildingId, props.service])

  /**
   * @param {VendorServiceDiscount} discount
   */
  const handToggleDiscount = discount => {
    const nextDiscountIds = [...(props.selectedDiscountIds || [])]
    const indexOf = nextDiscountIds.indexOf(discount.id)
    if (indexOf >= 0) {
      nextDiscountIds.splice(indexOf, 1)
    } else {
      nextDiscountIds.push(discount.id)
    }
    props.onChange(nextDiscountIds.map(id => discountsLookup[id]))
  }

  const discounts = Object.values(discountsLookup)

  return (
    <React.Fragment>
      <Typography style={{...TypographyStyle.H4HeaderWithoutBold, color: rxrBlueColor}}>Promotions and Credits:</Typography>
      {discounts.length > 0 ? (
        discounts.map(d => {
          return (
            <div key={d.id}>
              <CustomCheckbox
                isChecked={props.selectedDiscountIds.includes(d.id)}
                onChange={() => handToggleDiscount(d)}
                label={VendorServiceDiscount.toShortLabel(d)}
                ellipsis={true}
              />
            </div>
          )
        })
      ) : (
        <div>Not discounts available</div>
      )}
    </React.Fragment>
  )
}

SelectVendorServiceDiscounts.propTypes = {
  residentId: PropTypes.string.isRequired,
  service: PropTypes.object,
  editingAppointmentId: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  selectedDiscountIds: PropTypes.array,
}

export default SelectVendorServiceDiscounts
