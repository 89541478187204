import React from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {Grid} from '@mui/material'
import {makeStyles} from '@mui/styles'
import {getResidentEventsByResident} from '../../lib/queries'
import Timeline from '../KnowYourResident/Timeline'
import KYRFilter from '../KnowYourResident/KYRFilter'
import {KYRScreenLayoutDashboard} from '../../assets/styles/layout'
import {MAP_PRIMARY_RECORD_TO_FILTER_LABEL} from '../KnowYourResident/KYRConstants'

const useStyles = makeStyles(theme => ({
  rightIconButton: {
    textAlign: 'right',
    marginRight: 12,
  },
  timelineContainer: {
    ...KYRScreenLayoutDashboard,
  },
}))

function ProfileTimeline(props) {
  const classes = useStyles()
  const [residentEventsData, setResidentEventsData] = React.useState([])
  const [isEventDataLoading, setIsEventDataLoading] = React.useState(true)

  React.useEffect(() => {
    getResidentEventsByResident(props.residentId).then(residentEventsData => {
      setResidentEventsData(residentEventsData)
      setIsEventDataLoading(false)
    })
  }, [])

  const filteredAndSortedEvents =
    props.filterState.length > 0
      ? residentEventsData.filter(event => props.filterState.includes(MAP_PRIMARY_RECORD_TO_FILTER_LABEL[event.primaryRecord]))
      : []

  filteredAndSortedEvents.sort((a, b) => {
    // sort descending
    return a.createdAt === b.createdAt ? 0 : a.createdAt < b.createdAt ? 1 : -1
  })

  return (
    (<div>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <div className={classes.rightIconButton}>
            <KYRFilter onChange={props.setFilterState} selectedFilters={props.filterState} />
          </div>
        </Grid>
      </Grid>
      <div className={classes.timelineContainer}>
        <Timeline
          isEventDataLoading={isEventDataLoading}
          filterStateParent={props.filterState}
          residentEventsData={filteredAndSortedEvents}
          navigationType={'default'}
        />
      </div>
    </div>)
  );
}

ProfileTimeline.propTypes = {
  residentId: PropTypes.string.isRequired,
  setFilterState: PropTypes.func.isRequired,
  filterState: PropTypes.func.isRequired,
}

export default ProfileTimeline
