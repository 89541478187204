import React, {useEffect, useState} from 'react'
import SortableTable, {ColumnConfig} from '../SortableTable'
import PropTypes from 'prop-types'
import moment from 'moment'
import Routes from '../../constants/RouteConstants'
import {makeStyles} from '@mui/styles'
import {withRouter} from 'react-router-dom'
import {secondaryButton} from '../../assets/styles/buttons'
import {rxrBlackColor, rxrGreenColor, rxrGreyColor, rxrRedColor} from '../../assets/styles/color'
import {connect} from 'react-redux'
import {compose} from 'redux'
import {markedDelivered, markDelivered_Multiple} from '../../actions/deliveryActions'
import Constants from './DeliveryConstants'
import HighlightText from '../HighlightText'
import WhoPickedUpModal from './WhoPickedUpModal'
import {RXRButton} from '../RXRButton'
import useResidentLookup from '../hooks/useResidentLookup'
import RXRIcon from '../RXRIcon'
import {spaceExtraLarge, spaceMedium} from '../../assets/styles/spacing'
import {Typography} from '@mui/material'
import TableMultiSelectToolBar from '../TableMultiSelectToolBar'
import {pluralizeWord} from '../../Utils/StringFormatter'

const useStyles = makeStyles(theme => ({
  checkInButton: {
    marginRight: 24,
  },
  checkInButtonConfirm: {
    ...secondaryButton,
    borderColor: rxrGreenColor,
    color: rxrGreenColor,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  lineThrough: {
    textDecoration: 'line-through',
  },

  priorityDot: {
    position: 'relative',

    '&::before': {
      content: '""',
      position: 'absolute',
      top: '6px',
      left: '-15px',
      width: '7px',
      height: '7px',
      backgroundColor: rxrRedColor,
      borderRadius: '50%',
    },
  },
  multiSelectCopy: {
    color: rxrBlackColor,
  },
  multiSelectCTAs: {
    marginLeft: 'auto',
  },
}))

const IncomingPackagesTable = function (props) {
  const classes = useStyles()

  const [showPickedUpModal, setShowPickedUpModal] = useState(false)
  const [selectedPackage, setSelectedPackage] = useState(null)
  const [selectedPackageUnit, setSelectedPackageUnit] = useState(null)
  const {getResident} = useResidentLookup()
  const [multiSelectSelectedArray, setMultiSelectSelectedArray] = useState([])
  const [isHandlingBulkPickup, setIsHandlingBulkPickup] = useState(false)

  const handleClickDelivery = delivery => {
    props.history.push(Routes.constructPath(Routes.DELIVERIES_IN_VIEW_SINGLE, {deliveryId: delivery.id}))
  }

  const NULL_MUTATE = value => (value ? value.toLowerCase() : '0')

  useEffect(() => {
    if (!showPickedUpModal) {
      setSelectedPackageUnit(null)
      setSelectedPackage(null)
    }
  }, [showPickedUpModal])

  const columns = [
    new ColumnConfig({
      title: 'Unit',
      renderPrimitive: g => getResident(g.residentId).occupancy.unit.number,
      render: (g, col) => (
        <div className={g.priority === 'HIGH' ? classes.priorityDot : ''}>
          <HighlightText className={g.isDeleted ? classes.lineThrough : null} search={props.filterTerm}>
            {col.renderPrimitive(g)}
          </HighlightText>
        </div>
      ),
      comparator: ColumnConfig.residentUnitComparatorGenerator(getResident),
    }),

    new ColumnConfig({
      title: 'Resident',
      renderPrimitive: g => getResident(g.residentId).displayName,
      render: (g, col) => (
        <HighlightText className={g.isDeleted ? classes.lineThrough : null} search={props.filterTerm}>
          {col.renderPrimitive(g)}
        </HighlightText>
      ),
      comparator: ColumnConfig.residentNameComparatorGenerator(getResident),
    }),

    new ColumnConfig({
      title: 'Type',
      renderPrimitive: g => {
        let matching = Constants.PACKAGE_OPTIONS.find(o => o.value === g.packageType)
        return matching ? matching.label : 'Unknown'
      },
      render: (g, col) => (
        <HighlightText className={g.isDeleted ? classes.lineThrough : null} search={props.filterTerm}>
          {col.renderPrimitive(g)}
        </HighlightText>
      ),
      comparator: ColumnConfig.simpleComparatorGenerator('packageType'),
    }),

    new ColumnConfig({
      title: 'Carrier',
      renderPrimitive: g => {
        let matching = Constants.CARRIER_OPTIONS.find(o => o.value === g.carrier)
        return matching ? matching.label : '--'
      },
      render: (g, col) => (
        <HighlightText className={g.isDeleted ? classes.lineThrough : null} search={props.filterTerm}>
          {col.renderPrimitive(g)}
        </HighlightText>
      ),
      comparator: ColumnConfig.simpleComparatorGenerator('carrier', undefined, NULL_MUTATE),
    }),

    new ColumnConfig({
      title: 'Tracking number',
      renderPrimitive: g => g.trackingNumber || '--',
      render: (g, col) => (
        <HighlightText className={g.isDeleted ? classes.lineThrough : null} search={props.filterTerm}>
          {col.renderPrimitive(g)}
        </HighlightText>
      ),
      comparator: ColumnConfig.simpleComparatorGenerator('trackingNumber', undefined, NULL_MUTATE),
    }),

    new ColumnConfig({
      title: 'Arrived on',
      renderPrimitive: g => moment(g.createdAt).format(Constants.PACKAGE_TABLE_DATETIME_FORMAT),
      render: (g, col) => <span className={g.isDeleted ? classes.lineThrough : null}>{col.renderPrimitive(g)}</span>,
      comparator: (a, b) => moment(a.createdAt) - moment(b.createdAt),
      isDefaultSort: true,
      sortingDirection: 'desc',
    }),

    new ColumnConfig({
      title: 'PTE',
      renderPrimitive: g => (g.PTE ? 'Yes' : 'No'),
      render: g => (g.PTE ? <RXRIcon icon={RXRIcon.CHECK} /> : ''),
      comparator: (a, b) => b.PTE - a.PTE,
    }),

    new ColumnConfig({
      title: (
        <React.Fragment key="singleItemCTAs">
          <span style={{display: 'inline-block', marginRight: '60px'}}>Doorstep</span>
          <span>Front desk</span>
        </React.Fragment>
      ),
      render: g => {
        let recentlyDeliveredObj = props.recentlyMarkedDeliveryIds.find(obj => obj.id === g.id)
        let wasDeliveredToDoorstep = recentlyDeliveredObj && recentlyDeliveredObj.deliveredTo === 'unit'
        let wasDeliveredToPerson = recentlyDeliveredObj && recentlyDeliveredObj.deliveredTo === 'person'

        return (
          <React.Fragment>
            <div style={{display: 'flex', alignItems: 'center'}}>
              {wasDeliveredToDoorstep ? (
                <div className={classes.checkInButtonConfirm} style={{marginRight: '24px'}} onClick={e => e.stopPropagation()}>
                  <RXRIcon icon={RXRIcon.CHECK} size={RXRIcon.SIZE_LARGE} />
                </div>
              ) : (
                <RXRButton
                  className={classes.checkInButton}
                  disabled={wasDeliveredToPerson || g.isDeleted}
                  onClick={e => {
                    e.stopPropagation()
                    props.dispatchMarkedDelivered(g.id, props.staffId, null, props.staffName).then()
                  }}
                  style={{marginRight: '24px'}}
                >
                  Delivered
                </RXRButton>
              )}
              {wasDeliveredToPerson ? (
                <div className={classes.checkInButtonConfirm} onClick={e => e.stopPropagation()}>
                  <RXRIcon icon={RXRIcon.CHECK} size={RXRIcon.SIZE_LARGE} />
                </div>
              ) : (
                <RXRButton
                  className={classes.checkInButton}
                  disabled={wasDeliveredToDoorstep || g.isDeleted}
                  onClick={e => {
                    e.stopPropagation()
                    setSelectedPackage(g.id)
                    setSelectedPackageUnit(g.unitId)
                    setShowPickedUpModal(true)
                  }}
                >
                  Picked&nbsp;up
                </RXRButton>
              )}
            </div>
          </React.Fragment>
        )
      },
    }),
  ]

  let handleModalClose = () => {
    setShowPickedUpModal(false)
  }

  let handleModalSubmit = recipientName => {
    props.dispatchMarkedDelivered(selectedPackage, props.staffId, recipientName).then()
    setShowPickedUpModal(false)
  }

  const handleBulkMarkDelivered = () => {
    props
      .dispatchMarkDelivered_Multiple(
        multiSelectSelectedArray.map(d => d.id),
        props.staffId,
        undefined,
        props.staffName,
      )
      .then(() => setMultiSelectSelectedArray([]))
  }

  const handleBulkModalSubmit = recipientName => {
    props
      .dispatchMarkDelivered_Multiple(
        multiSelectSelectedArray.map(d => d.id),
        props.staffId,
        recipientName,
      )
      .then(() => setMultiSelectSelectedArray([]))

    setIsHandlingBulkPickup(false)
    setShowPickedUpModal(false)
  }

  const numberOfPackagesSelected = multiSelectSelectedArray.length
  return (
    <React.Fragment>
      <SortableTable
        data={props.incomingPackages}
        columns={columns}
        downloadFileName={`Incoming Packages`}
        onClickRow={handleClickDelivery}
        rowHighlightCondition={g => {
          return !!props.recentlyMarkedDeliveryIds.find(obj => obj.id === g.id) || multiSelectSelectedArray.map(g => g.id).includes(g.id)
        }}
        hasMultiSelect={true}
        multiSelectSelectedArray={multiSelectSelectedArray}
        setMultiSelectSelectedArray={setMultiSelectSelectedArray}
      />
      {numberOfPackagesSelected > 0 ? (
        <TableMultiSelectToolBar>
          <Typography className={classes.multiSelectCopy}>{pluralizeWord('Package', numberOfPackagesSelected, true)} Selected</Typography>
          <div className={classes.multiSelectCTAs}>
            <RXRButton
              className={classes.checkInButton}
              onClick={e => {
                e.stopPropagation()
                handleBulkMarkDelivered()
              }}
              style={{marginRight: '24px'}}
            >
              Delivered
            </RXRButton>
            <RXRButton
              className={classes.checkInButton}
              onClick={e => {
                e.stopPropagation()
                if (multiSelectSelectedArray.length === 0) {
                  return
                }
                const first = multiSelectSelectedArray[0]
                if (multiSelectSelectedArray.every(d => d.unitId === first.unitId)) {
                  setIsHandlingBulkPickup(true)
                  setSelectedPackageUnit(first.unitId)
                  setShowPickedUpModal(true)
                } else {
                  window.alert('Cannot mark bulk picked up, not all selected deliveries are for the same unit')
                }
              }}
            >
              Picked&nbsp;up
            </RXRButton>
          </div>
        </TableMultiSelectToolBar>
      ) : null}
      <WhoPickedUpModal
        isOpen={showPickedUpModal}
        handleClose={handleModalClose}
        handleSubmit={
          isHandlingBulkPickup ? recipientName => handleBulkModalSubmit(recipientName) : recipientName => handleModalSubmit(recipientName)
        }
        selectedPackageUnit={selectedPackageUnit}
      />
    </React.Fragment>
  )
}

IncomingPackagesTable.propTypes = {
  incomingPackages: PropTypes.array.isRequired,
}

const mapStateToProps = state => ({
  recentlyMarkedDeliveryIds: state.Delivery.recentlyMarkedDeliveryIds,
  staffId: state.Staff.authedStaffModel.id,
  staffName: state.Staff.authedStaffModel.displayName,
})

const mapDispatchToProps = dispatch => ({
  dispatchMarkedDelivered: (deliveryId, staffId, recipientName, staffName) =>
    markedDelivered(dispatch, deliveryId, staffId, recipientName, staffName),
  dispatchMarkDelivered_Multiple: (deliveryId, staffId, recipientName, staffName) =>
    markDelivered_Multiple(dispatch, deliveryId, staffId, recipientName, staffName),
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(IncomingPackagesTable)
