import React, {useState, useEffect} from 'react'
import {matchPath, useHistory, useLocation} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import Routes from '../../constants/RouteConstants'
import {Grid} from '@mui/material'
import Constant from './AmenitiesConstants'
import PageHeader from '../PageHeader'
import Loader from '../Loader'
import {loadAmenitiesForBuilding} from '../../actions/amenitiesActions'
import RXRButton from '../RXRButton'
import {PageContainer} from '../../assets/styles/layout'
import {AntTab, AntTabs} from '../AntTabs'
import AllAmenitiesCalendarView from './AllAmenitiesCalendarView'
import IndividualAmenityCalendarView from './IndividualAmenityCalendarView'
import {rxrContainerColor} from '../../assets/styles/color'
import {makeStyles} from '@mui/styles'
import SidePanel from '../SidePanel'
import CompactAmenityReservationEditor from './CompactAmenityReservationEditor'
import {onboardingElevatorAmenityFilter} from '../../Utils/amenityUtils'

const TAB_ALL = 0
const TAB_SINGLE = 1
const tabRoutes = [Routes.ELEVATOR_CALENDAR_VIEW, Routes.ELEVATOR_CALENDAR_VIEW_INDIVIDUAL]

function ElevatorCalendarPage(props) {
  const isMobile = useSelector(state => state.App.isMobile)
  const classes = useStyles({isMobile})
  const [isLoadingAmenityData, setIsLoadingAmenityData] = useState(true)

  const activeBuildingId = useSelector(state => state.Buildings.activeBuildingId)
  const history = useHistory()
  const currentLocation = useLocation()
  const dispatch = useDispatch()
  let focusedItemId = null
  let isCreateMode = false
  let createMatch = matchPath(currentLocation.pathname, {path: Routes.ELEVATOR_RESERVATIONS_CREATE})
  let viewSingleMatch = matchPath(currentLocation.pathname, {path: Routes.AMENITIES_RESERVATIONS_VIEW_SINGLE})
  if (createMatch && createMatch.isExact) {
    isCreateMode = true
  } else if (viewSingleMatch && viewSingleMatch.isExact) {
    isCreateMode = false
    focusedItemId = viewSingleMatch.params['reservationId']
  }
  const individualMatch = matchPath(currentLocation.pathname, Routes.ELEVATOR_CALENDAR_VIEW_INDIVIDUAL)
  const tab = individualMatch ? TAB_SINGLE : TAB_ALL

  useEffect(() => {
    loadAmenitiesForBuilding(dispatch, activeBuildingId).then(() => setIsLoadingAmenityData(false))
  }, [])

  return (
    <div style={PageContainer}>
      <PageHeader
        title={Constant.ELEVATOR_RESERVATION_CALENDAR}
        rightControlComponent={
          <RXRButton
            type={isMobile ? RXRButton.TYPE_DEFAULT : RXRButton.TYPE_LARGE}
            onClick={() => history.push(Routes.ELEVATOR_RESERVATIONS_CREATE)}
          >
            {Constant.BTN_ADD}
          </RXRButton>
        }
      >
        <Grid container>
          <Grid item xs={12} lg={6} md={6}>
            <AntTabs value={tab} onChange={(e, t) => history.push(tabRoutes[t])}>
              <AntTab label={'All elevators'} />
              <AntTab label={'Individual elevator'} />
            </AntTabs>
          </Grid>
        </Grid>
      </PageHeader>

      <div className={classes.mainContainer}>
        {isLoadingAmenityData ? (
          <Loader />
        ) : tab === TAB_ALL ? (
          <AllAmenitiesCalendarView amenitiesFilter={onboardingElevatorAmenityFilter} />
        ) : (
          <IndividualAmenityCalendarView
            amenitiesFilter={onboardingElevatorAmenityFilter}
            individualAmenityRoute={Routes.ELEVATOR_CALENDAR_VIEW_INDIVIDUAL_AMENITY}
          />
        )}
      </div>
      <SidePanel
        isOpen={!!(focusedItemId || isCreateMode)}
        onClose={() => history.push(Routes.ELEVATOR_CALENDAR_VIEW)}
        title={focusedItemId ? 'Reservation request details' : 'Make a reservation'}
      >
        <CompactAmenityReservationEditor
          onComplete={() => history.push(Routes.ELEVATOR_CALENDAR_VIEW)}
          amenitiesFilter={onboardingElevatorAmenityFilter}
          amenityReservationId={focusedItemId}
        />
      </SidePanel>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  mainContainer: {
    backgroundColor: rxrContainerColor,
    flex: 1,
    width: '100%',
    overflowX: 'auto',
  },
}))

export default ElevatorCalendarPage
