import React from 'react'
import {makeStyles} from '@mui/styles'
import Fab from '@mui/material/Fab'
import {rxrWhiteColor, rxrMidBlue, shadowSoft} from '../../assets/styles/color'
import {useSelector} from 'react-redux'

import PropTypes from 'prop-types'
import RXRIcon from '../RXRIcon'

const useStyles = makeStyles({
  root: props => ({
    backgroundColor: rxrMidBlue,
    color: rxrWhiteColor,
    width: props.isMobile ? '25px' : '53px',
    height: props.isMobile ? '25px' : '53px',
    minHeight: props.isMobile ? '25px' : '53px',
    '&:hover': {
      backgroundColor: '#95C6DB',
      boxShadow: shadowSoft,
      '@media (hover: none)': {
        boxShadow: shadowSoft,
      },
    },
    '&:focus': {
      outline: '0px auto transparent',
    },
    boxShadow: shadowSoft,
    '&:focus-visible': {
      boxShadow: shadowSoft,
    },
    '&:active': {
      boxShadow: shadowSoft,
    },
    '&:disabled': {
      boxShadow: shadowSoft,
    },
  }),
})

const FloatingBubbleButton = ({onBubbleClick, isOpen}) => {
  const isMobile = useSelector(state => state.App.isMobile)
  const classes = useStyles({isMobile})

  return (
    <Fab className={classes.root} onClick={() => onBubbleClick()}>
      {!isOpen ? <RXRIcon icon={RXRIcon.PLUS} color={rxrWhiteColor} /> : <RXRIcon icon={RXRIcon.ARROW_DOWN} color={rxrWhiteColor} />}
    </Fab>
  )
}

FloatingBubbleButton.propTypes = {
  onBubbleClick: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
}

export default FloatingBubbleButton
