import React from 'react'
import PropTypes from 'prop-types'
import {Grid} from '@mui/material'
import {makeStyles} from '@mui/styles'
import {Typography as TypographyStyle} from './../../assets/styles'
import {rxrMidBlue, rxrTealColor, rxrWhiteColor} from '../../assets/styles/color'
import HeaderButtonComponent from './HeaderButtonComponent'
import {connect} from 'react-redux'
import ResidentProfileImage from '../ResidentProfiles/ResidentProfileImage'
import ResidentStatusTag from '../UITags/ResidentStatusTag'
import {spaceExtraSmall} from '../../assets/styles/spacing'
import {useSelector} from 'react-redux'

/**
 *  <Header residentName = {'Darshan Jain'} residentUnit = {'401H'} />
 */

const useStyles = makeStyles(theme => ({
  headerContainer: props => ({
    width: props.isMobile ? '370px' : '400px',
    backgroundColor: rxrMidBlue,
    minHeight: '154px',
    borderRadius: '10px 10px 0px 0px',
    boxShadow: '0px 1px 18px rgba(151, 151, 151, 0.2)',
    justifyContent: 'space-around',
    marginBottom: 40,
  }),
  gridContainer: {
    position: 'absolute',
    top: '28px',
    alignItems: 'center',
  },
  residentNameStyle: {
    ...TypographyStyle.KYCHeaderText,
    marginBottom: spaceExtraSmall,
  },
  residentUnitStyle: {
    ...TypographyStyle.KYCHeaderSubText,
    marginBottom: spaceExtraSmall,
  },
  profileImage: {
    backgroundColor: rxrTealColor,
    height: '75px',
    width: '75px',
    border: '2px solid',
    borderColor: rxrWhiteColor,
  },
}))
function Header(props) {
  const isMobile = useSelector(state => state.App.isMobile)
  const classes = useStyles({isMobile})

  let resident = props.resident

  if (!resident) {
    return null
  }

  return (
    <div className={classes.headerContainer}>
      <Grid container className={classes.gridContainer}>
        <Grid item xs={3} style={{marginLeft: '60px'}}>
          <ResidentProfileImage residentId={resident.id} hideAppStatus={true} className={classes.profileImage} />
        </Grid>
        <Grid item xs={6}>
          <div className={classes.residentNameStyle}>{resident.displayName}</div>
          <div className={classes.residentUnitStyle}>{'Unit ' + resident.occupancy.unit.number}</div>
          <ResidentStatusTag residentId={resident.id} />
        </Grid>
        <HeaderButtonComponent residentId={resident.id} />
      </Grid>
    </div>
  )
}

Header.propTypes = {
  residentId: PropTypes.string.isRequired,
}

const mapStateToProps = (state, ownProps) => ({
  resident: state.Residents.residentsLookup[ownProps.residentId],
})

export default connect(mapStateToProps)(Header)
