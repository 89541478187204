import {API} from 'aws-amplify'
import gql from 'graphql-tag'
import GQLResponse from './GQLResponse'
import {QueryIterator} from './QueryIterator'

// these are copied from dep/backend-helpers/GraphQLHelper.js
const DEFAULT_PARAMS = {
  nextToken: null,
  limit: 10000,
}
const DEFAULT_MAXIMUM_ITERATIONS = 20
const DEFAULT_MAXIMUM_RESULTS = 1000000

/**
 * @param {*} resp
 * @throws {Error}
 */
function handleQueryException(resp) {
  if (Array.isArray(resp.errors) && resp.errors.length > 0) {
    throw new Error(resp.errors[0].message)
  } else {
    throw resp
  }
}

class QueryHelper {
  static #instance

  constructor(config) {
    if (QueryHelper.#instance) {
      throw new Error('Query Helper is a singleton')
    }

    if (!config) {
      throw new Error('config is required to instantiate QueryHelper')
    }

    API.configure(config)
    QueryHelper.#instance = this
  }

  /**
   * @param {string} mutation
   * @param {*} params
   * @returns {Promise<*>}
   */
  async executeMutation(mutation, params) {
    return this.queryOnce(mutation, params)
  }

  /**
   * @param {string} query
   * @param {*?} params
   * @returns {Promise<*>}
   */
  async queryOnce(query, params) {
    try {
      let response = await API.graphql(graphqlOperation(this._formatQueryString(query), params))
      return new GQLResponse(response).payload
    } catch (resp) {
      handleQueryException(resp)
    }
  }

  /**
   * @param {string} query
   * @param {*?} params
   * @param {function?} listPayloadExtractor
   * @returns {QueryIterator}
   */
  iterativeQuery(query, params, listPayloadExtractor) {
    const that = this
    return new QueryIterator(async function () {
      if (this.hasCompleted) {
        return []
      }

      try {
        let response = await API.graphql(graphqlOperation(that._formatQueryString(query), {...params, nextToken: this.nextToken}))
        const resp = new GQLResponse(response, listPayloadExtractor)
        this.nextToken = resp.nextToken

        if (!this.nextToken) {
          this.hasCompleted = true
        }

        return resp.payload
      } catch (resp) {
        handleQueryException(resp)
      }
    })
  }

  /**
   * Will exhaustively search all records until we've gathered maximumResults, executed maximumIterations, or scanned all records
   * @param {string} query
   * @param {*?} params
   * @param {number?} maximumResults
   * @param {number?} maximumIterations
   * @param {function?} listPayloadExtractor
   * @returns {Promise<Array<*>>}
   */
  async queryAll(
    query,
    params,
    maximumResults = DEFAULT_MAXIMUM_RESULTS,
    maximumIterations = DEFAULT_MAXIMUM_ITERATIONS,
    listPayloadExtractor,
  ) {
    let result = []

    // merge this params with our defaults
    params = Object.assign({}, DEFAULT_PARAMS, params)

    try {
      do {
        // execute our query
        let responseRaw = await API.graphql(graphqlOperation(this._formatQueryString(query), params))
        let response = new GQLResponse(responseRaw, listPayloadExtractor)

        // store our items into our results array
        if (response.hasItems()) {
          result = result.concat(response.payload)
        }

        // TODO: In order for nextToken to work, it must be listed as an params to the query definition
        //  we should find some way to check for this before blindly trying to repeat a query which may not
        //  actually be capable to follow nextTokens

        // set our next token
        // eslint-disable-next-line require-atomic-updates
        params.nextToken = response.nextToken

        // decrement our depth counter
        maximumIterations--

        // repeat for as long as there's a next token && we haven't exceeded the max depth && we haven't reached our result count
      } while (params.nextToken && maximumIterations > 0 && result.length < maximumResults)
    } catch (resp) {
      handleQueryException(resp)
    }
    return result.slice(0, maximumResults)
  }

  /**
   * @param {string} query
   * @returns {any}
   * @private
   */
  _formatQueryString(query) {
    if (typeof query === 'string') {
      return gql`
        ${query}
      `
    } else {
      return query
    }
  }

  // ---------------------------------------------------------------
  // STATIC FUNCTIONS:

  /**
   * @param {*?} config
   * @returns {QueryHelper}
   */
  static Instance(config) {
    if (!QueryHelper.#instance) {
      QueryHelper.#instance = new QueryHelper(config)
    }
    return QueryHelper.#instance
  }
}

export default QueryHelper

function graphqlOperation(query, variables) {
  return {query, variables}
}
