import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import {PropTypeS3Object} from '../../../constants/complexPropTypes'
import {makeStyles} from '@mui/styles'
import RXRIcon from '../../RXRIcon'
import {spaceExtraLarge, spaceExtraSmall, spaceMedium, spaceSmall} from '../../../assets/styles/spacing'
import {rxrBlueColor, nearWhiteColor, rxrWhiteColor, rxrDarkGreyColor} from '../../../assets/styles/color'
import FileHelper from '../../../lib/FileHelper'
import RXRButton from '../../RXRButton'
import {fontSizeLarge} from '../../../assets/styles/typography'
import NavBarMock from '../../../assets/images/app_nav_bar_mock.png'
import FeaturedServiceMock from '../../../assets/images/app_home_screen_mock_featured_service_plug.png'

function PreviewPartner(props) {
  const classes = useStyles()
  const [logoUrl, setLogoUrl] = useState('')
  const [coverImageUrl, setCoverImageUrl] = useState('')

  useEffect(() => {
    FileHelper.Instance().getFileUrlFromS3Object(props.logo).then(setLogoUrl)
  }, [props.logo])

  useEffect(() => {
    FileHelper.Instance().getFileUrlFromS3Object(props.coverImage).then(setCoverImageUrl)
  }, [props.coverImage])

  return (
    <div className={classes.container}>
      {props.isFeatured ? (
        <div className={classes.featuredCardPage} style={{backgroundImage: `url(${FeaturedServiceMock})`}}>
          <div className={classes.featuredCard}>
            <div className={classes.coverImage} style={{backgroundImage: `url(${coverImageUrl})`}} />
            <div className={classes.featuredContent}>
              <div className={classes.partnerName} style={{marginBottom: spaceExtraSmall}}>
                <img src={logoUrl} alt={`${props.displayName} logo`} className={classes.logo} />
                <h2>{props.displayName}</h2>
              </div>
              <p className={classes.partnerDescription}>{props.shortDescription}</p>
            </div>
          </div>
        </div>
      ) : (
        <p className={classes.notFeaturedText}>
          This partner is not featured on the home screen so there is no home screen mock to view.
          <br /> <br />
          To see the home screen featured card, please enable "Is Featured" setting for this partner.
        </p>
      )}

      <div className={classes.partnerPage}>
        <div className={classes.header}>
          <RXRIcon icon={RXRIcon.ARROW_LEFT} className={classes.backIcon} />
          <h2>{props.displayName}</h2>
        </div>

        <div className={classes.partnerCard}>
          <div className={classes.coverImage} style={{backgroundImage: `url(${coverImageUrl})`}} />

          <div className={classes.partnerContent}>
            <div className={classes.partnerName}>
              <img src={logoUrl} alt={`${props.displayName} logo`} className={classes.logo} />
              <h2>{props.displayName}</h2>
            </div>
            <p className={classes.partnerDescription}>{props.description}</p>
            <div style={{textAlign: 'center'}}>
              <RXRButton onClick={() => window.open(props.landingUrl, '_blank')} className={classes.button}>
                {props.callToAction}
              </RXRButton>
            </div>
          </div>
        </div>
        <img className={classes.bottomNavBar} src={NavBarMock} />
      </div>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    maxWidth: 340,
  },

  featuredCardPage: {
    position: 'relative',
    width: '100%',
    aspectRatio: 0.47893,
    backgroundSize: 'cover',
    backgroundPosition: 'center bottom',
    marginBottom: spaceExtraLarge,
    border: `1px solid ${rxrDarkGreyColor}`,
    borderRadius: 4,
  },

  featuredCard: {
    width: '80%',
    overflow: 'hidden',
    borderRadius: 8,
    boxShadow: '2px 0px 10px 0px rgba(0,0,0,0.1)',
    position: 'absolute',
    top: '27%',
    left: '6%',
  },

  partnerPage: {
    position: 'relative',
    width: '100%',
    aspectRatio: '0.5',
    backgroundColor: nearWhiteColor,
    overflowY: 'auto',
    border: `1px solid ${rxrDarkGreyColor}`,
    borderRadius: 4,
    overflow: 'hidden',
  },

  header: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    padding: spaceSmall,
    backgroundColor: rxrBlueColor,

    '& h2': {
      color: rxrWhiteColor,
      margin: '0 auto',
      fontSize: fontSizeLarge,
    },
  },
  backIcon: {
    position: 'absolute',
    top: 16,
    left: spaceSmall,
    color: rxrWhiteColor,
  },

  partnerCard: {
    margin: spaceMedium,
    backgroundColor: rxrWhiteColor,
    boxShadow: '2px 0px 10px 0px rgba(0,0,0,0.1)',
    borderRadius: 8,
    overflow: 'hidden',
  },

  coverImage: {
    aspectRatio: 1.55,
    width: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },

  partnerContent: {
    backgroundColor: rxrWhiteColor,
    padding: spaceMedium,
  },

  featuredContent: {
    backgroundColor: rxrWhiteColor,
    padding: spaceExtraSmall,
  },

  partnerName: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: spaceMedium,

    '& h2': {
      margin: 0,
      fontSize: fontSizeLarge,
      color: rxrBlueColor,
    },

    '& img': {
      width: 24,
      height: 24,
      marginRight: spaceSmall,
    },
  },

  partnerDescription: {
    color: rxrBlueColor,
    lineHeight: 1.15,
  },

  bottomNavBar: {
    width: '100%',
    height: 'auto',
    position: 'absolute',
    bottom: 0,
    left: 0,
  },

  button: {
    marginTop: spaceMedium,
    borderRadius: 32,
  },

  notFeaturedText: {
    color: rxrDarkGreyColor,
    fontStyle: 'italic',
    margin: spaceMedium,
    marginBottom: spaceExtraLarge,
  },
}))

PreviewPartner.propTypes = {
  logo: PropTypeS3Object,
  coverImage: PropTypeS3Object,
  displayName: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  landingUrl: PropTypes.string.isRequired,
  shortDescription: PropTypes.string.isRequired,
  callToAction: PropTypes.string.isRequired,
  isFeatured: PropTypes.bool,
}

export default PreviewPartner
