import React from 'react'
import PropTypes from 'prop-types'
import {Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import {spaceMedium} from '../assets/styles/spacing'
import {rxrBlueColor} from '../assets/styles/color'
import RXRIcon from './RXRIcon'

const useStyles = makeStyles(theme => ({
  mainDiv: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    width: '100%',
    height: 'calc(100% - 200px)', // we want it shifted slightly above center
    minHeight: 200,
    padding: spaceMedium,
  },

  text: {
    marginTop: '20px',
  },
}))

function EmptyStateIconAndMessage(props) {
  const classes = useStyles()

  return (
    <div className={classes.mainDiv}>
      <div style={{textAlign: 'center'}}>
        {typeof props.icon === 'string' ? (
          <RXRIcon icon={props.icon} style={{height: props.iconHeight || 100, width: props.iconWidth || 100, color: rxrBlueColor}} />
        ) : (
          props.icon
        )}
        <Typography className={classes.text}>{props.message}</Typography>
      </div>
    </div>
  )
}

EmptyStateIconAndMessage.propTypes = {
  message: PropTypes.string.isRequired,
  icon: PropTypes.any.isRequired,
  iconHeight: PropTypes.number,
  iconWidth: PropTypes.number,
}

export default EmptyStateIconAndMessage
