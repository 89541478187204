import {withStyles} from '@mui/styles'
import {Tab, Tabs} from '@mui/material'
import {rxrTealColor, rxrDarkGreyColor, rxrGreyColor} from '../assets/styles/color'
import React from 'react'

export const AntTab = withStyles(theme => ({
  root: {
    textAlign: 'left',
    marginTop: 10,
    paddingBottom: 10,
    marginLeft: 18,
    textTransform: 'none',
    minWidth: 72,
    fontSize: 18,
    color: rxrDarkGreyColor,
    fontWeight: 700,
    '&:hover': {
      color: rxrTealColor,
      opacity: 1,
    },
    '&:focus': {
      color: rxrTealColor,
      outline: 'none',
    },
  },
  selected: {color: rxrTealColor, fontWeight: 700},
}))(props => <Tab disableRipple {...props} />)

export const AntTabs = withStyles({
  indicator: {
    backgroundColor: rxrTealColor,
    borderBottom: '4px solid ' + rxrTealColor,
  },
})(props => <Tabs {...props} variant="scrollable" scrollButtons="auto" />)
