import React, {useState, useEffect} from 'react'
import FloatingBubbleButton from './FloatingBubbleButton'
import PropTypes from 'prop-types'
import Routes from '../../constants/RouteConstants'
import {useLocation, matchPath} from 'react-router-dom'
import {makeStyles} from '@mui/styles'
import {setIsWidgetOpen} from '../../actions/kyrWidgetActions'
import KYRNavigator from './KYRNavigator'
import {ROOT_SCREEN} from './KYRConstants'
import {useDispatch, useSelector} from 'react-redux'
import {spaceExtraSmall, spaceSmall} from '../../assets/styles/spacing'

const BUBBLE_LOCATION_INITIAL_LOCATION = 'initial location'
const BUBBLE_LOCATION_MESSAGE_CHAT_OPEN = 'messages chat open'
const BUBBLE_LOCATION_SLIDING_PANE_OPEN = 'sliding pane open'

const specialBubbleLocationRoutes = {
  [Routes.AMENITIES_RESERVATIONS_VIEW_SINGLE]: BUBBLE_LOCATION_SLIDING_PANE_OPEN,
  [Routes.ANNOUNCEMENTS_CREATE]: BUBBLE_LOCATION_SLIDING_PANE_OPEN,
  [Routes.ANNOUNCEMENTS_VIEW_SINGLE]: BUBBLE_LOCATION_SLIDING_PANE_OPEN,
  [Routes.DELIVERIES_IN_CREATE]: BUBBLE_LOCATION_SLIDING_PANE_OPEN,
  [Routes.DELIVERIES_IN_VIEW_SINGLE]: BUBBLE_LOCATION_SLIDING_PANE_OPEN,
  [Routes.DELIVERIES_OUT_CREATE]: BUBBLE_LOCATION_SLIDING_PANE_OPEN,
  [Routes.DELIVERIES_OUT_VIEW_SINGLE]: BUBBLE_LOCATION_SLIDING_PANE_OPEN,
  [Routes.EVENTS_CREATE]: BUBBLE_LOCATION_SLIDING_PANE_OPEN,
  [Routes.EVENTS_VIEW_SINGLE]: BUBBLE_LOCATION_SLIDING_PANE_OPEN,
  [Routes.GUESTAUTHORIZATION_CREATE]: BUBBLE_LOCATION_SLIDING_PANE_OPEN,
  [Routes.GUESTAUTHORIZATION_VIEW_SINGLE]: BUBBLE_LOCATION_SLIDING_PANE_OPEN,
  [Routes.CLEANING_CREATE]: BUBBLE_LOCATION_SLIDING_PANE_OPEN,
  [Routes.CLEANING_VIEW_SINGLE]: BUBBLE_LOCATION_SLIDING_PANE_OPEN,
  [Routes.DOGWALKING_CREATE]: BUBBLE_LOCATION_SLIDING_PANE_OPEN,
  [Routes.DOGWALKING_VIEW_SINGLE]: BUBBLE_LOCATION_SLIDING_PANE_OPEN,
  [Routes.PERSONALTRAINING_CREATE]: BUBBLE_LOCATION_SLIDING_PANE_OPEN,
  [Routes.PERSONALTRAINING_VIEW_SINGLE]: BUBBLE_LOCATION_SLIDING_PANE_OPEN,
  [Routes.MASSAGE_CREATE]: BUBBLE_LOCATION_SLIDING_PANE_OPEN,
  [Routes.MASSAGE_VIEW_SINGLE]: BUBBLE_LOCATION_SLIDING_PANE_OPEN,
  [Routes.MESSAGES_VIEW_SINGLE]: BUBBLE_LOCATION_MESSAGE_CHAT_OPEN,
  [Routes.RXO_ASSISTANT_LOG_ACTION_FROM_NUDGE]: BUBBLE_LOCATION_SLIDING_PANE_OPEN,
  [Routes.RXO_ASSISTANT_LOG_NEW_ACTION]: BUBBLE_LOCATION_SLIDING_PANE_OPEN,
  [Routes.RXO_ASSISTANT_VIEW_LOG_ACTION]: BUBBLE_LOCATION_SLIDING_PANE_OPEN,
  [Routes.PROFILE_VIEW_RENT_BREAKDOWN]: BUBBLE_LOCATION_SLIDING_PANE_OPEN,
  [Routes.PROFILE_VIEW_DISCOUNTS_BREAKDOWN]: BUBBLE_LOCATION_SLIDING_PANE_OPEN,
  [Routes.PROFILE_VIEW_DISCOUNTS_CREATE]: BUBBLE_LOCATION_SLIDING_PANE_OPEN,
  [Routes.ONBOARDING_VIEW_OCCUPANCY_DETAILS]: BUBBLE_LOCATION_SLIDING_PANE_OPEN,
  [Routes.SETTINGS_PARTNERS_CREATE]: BUBBLE_LOCATION_SLIDING_PANE_OPEN,
  [Routes.SETTINGS_PARTNERS_VIEW_SINGLE]: BUBBLE_LOCATION_SLIDING_PANE_OPEN,
  [Routes.SETTINGS_DASHBOARD_USERS_CREATE]: BUBBLE_LOCATION_SLIDING_PANE_OPEN,
  [Routes.SETTINGS_DASHBOARD_USERS_VIEW_SINGLE]: BUBBLE_LOCATION_SLIDING_PANE_OPEN,
  [Routes.SETTINGS_PROMOTIONS_CREATE]: BUBBLE_LOCATION_SLIDING_PANE_OPEN,
  [Routes.SETTINGS_PROMOTIONS_VIEW_SINGLE]: BUBBLE_LOCATION_SLIDING_PANE_OPEN,
}

const messageChatListWidth = 400
const bubbleButtonWidth = 53
const bubbleMargin = 24

const useStyles = makeStyles(theme => ({
  floatingContainer: props => {
    const commonStyles = {
      position: 'absolute',
      zIndex: 101,
      transitionDuration: '0.5s',
    }

    if (props.isMobile) {
      return {
        ...commonStyles,
        top: `${spaceSmall}px`,
        right: `${spaceSmall}px`,
      }
    } else {
      return {
        ...commonStyles,
        bottom: `${bubbleMargin}px`,
        ...(props.bubbleLocation === BUBBLE_LOCATION_INITIAL_LOCATION
          ? {
              left: `calc(100vw - ${bubbleButtonWidth + bubbleMargin}px)`,
            }
          : props.bubbleLocation === BUBBLE_LOCATION_MESSAGE_CHAT_OPEN
          ? {
              left: `${props.navPanelWidth + messageChatListWidth - bubbleButtonWidth - bubbleMargin}px`,
            }
          : props.bubbleLocation === BUBBLE_LOCATION_SLIDING_PANE_OPEN
          ? {
              left: '45.6%',
            }
          : {}),
      }
    }
  },
  floatingNavigator: props => {
    return props.isMobile
      ? {
          position: 'absolute',
          top: '32px',
          right: '0px',
          width: '320px',
        }
      : {
          position: 'absolute',
          bottom: '69px',
          left: '-267px',
          width: '320px',
        }
  },
}))

const KYRWidgetContainer = props => {
  const [bubbleLocation, setBubbleLocation] = useState(BUBBLE_LOCATION_INITIAL_LOCATION)
  const currentLocation = useLocation()
  const dispatch = useDispatch()
  const navPanelWidth = useSelector(state => state.App.navPanelWidth)
  const isMobile = useSelector(state => state.App.isMobile)
  const current = useSelector(state => state.KYRWidget.current)
  const isWidgetOpen = useSelector(state => state.KYRWidget.isWidgetOpen)

  const handleBubbleClick = () => {
    // if the widget isn't open yet, and we're currently on the root screen
    if (!isWidgetOpen && current && current.name === ROOT_SCREEN) {
      // we'll try and guess the residentId based on which page we're on
      // TODO: How can we determine this?
    }

    // toggle the widget
    setIsWidgetOpen(dispatch, !isWidgetOpen)
  }

  useEffect(() => {
    let viewSingleMatch
    let newBubbleLocation = BUBBLE_LOCATION_INITIAL_LOCATION
    Object.entries(specialBubbleLocationRoutes).some(([route, bubbleLocation]) => {
      viewSingleMatch = matchPath(currentLocation.pathname, {path: route})
      if (viewSingleMatch) {
        newBubbleLocation = bubbleLocation
        return true
      }
    })
    setBubbleLocation(newBubbleLocation)
  }, [currentLocation])

  const classes = useStyles({bubbleLocation, navPanelWidth, isMobile})

  return (
    <div className={classes.floatingContainer}>
      {isWidgetOpen && (
        <div className={classes.floatingNavigator}>
          <KYRNavigator />
        </div>
      )}
      <FloatingBubbleButton isOpen={isWidgetOpen} onBubbleClick={handleBubbleClick} />
    </div>
  )
}

KYRWidgetContainer.propTypes = {
  residentId: PropTypes.string,
}

export default KYRWidgetContainer
