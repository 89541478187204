import React from 'react'
import {Grid, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import {rxrWhiteColor, rxrGreyColor} from '../assets/styles/color'
import PropTypes from 'prop-types'
import {constructClassString} from '../Utils/objectUtil'

const useStyles = makeStyles(theme => ({
  toggleButtonsContainer: {
    height: '30px',
    borderRadius: '15px',
    backgroundColor: rxrWhiteColor,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  toggleButtons: {
    marginLeft: '3px',
    marginRight: '3px',
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    borderRadius: '15px',
    backgroundColor: rxrWhiteColor,
  },
  toggleButtonText: {
    display: 'block',
    textAlign: 'center',
    width: '100%',
    fontSize: 'inherit',
    height: '24px',
    lineHeight: '24px',
    zIndex: 2,
    cursor: 'pointer',
    borderRadius: '15px',
  },
  selectedButtonHighlight: {
    height: '24px',
    position: 'absolute',
    borderRadius: '15px',
    top: 0,
    left: 0,
    backgroundColor: rxrGreyColor,
    transition: 'left 0.2s ease-in-out',
  },
}))

function ToggleButtons(props) {
  const classes = useStyles()

  const buttonLabels = props.buttonLabels
  const selected = props.selected
  const inverseColors = props.inverseColors
  const change = props.onChange

  return (
    <div
      className={constructClassString({}, classes.toggleButtonsContainer, props.className)}
      style={inverseColors ? {backgroundColor: rxrGreyColor} : null}
    >
      <div className={classes.toggleButtons} style={inverseColors ? {backgroundColor: rxrGreyColor} : null}>
        <div
          className={classes.selectedButtonHighlight}
          style={{
            ...{left: `${parseInt((selected * 100) / buttonLabels.length)}%`, width: `${parseInt(100 / buttonLabels.length)}%`},
            ...(inverseColors ? {backgroundColor: rxrWhiteColor} : null),
          }}
        />
        {buttonLabels.map((label, index) => (
          <span key={label} onClick={() => change(index)} className={classes.toggleButtonText}>
            {label}
          </span>
        ))}
      </div>
    </div>
  )
}

ToggleButtons.propTypes = {
  selected: PropTypes.number,
  onChange: PropTypes.func,
  buttonLabels: PropTypes.arrayOf(PropTypes.string),
  inverseColors: PropTypes.bool,
  className: PropTypes.string,
}

export default ToggleButtons
