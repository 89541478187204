import React, {useCallback, useEffect, useRef, useState} from 'react'
import {makeStyles} from '@mui/styles'
import ClearableInput from '../ClearableInput'
import ChatListItem from './ChatListItem'
import {Typography, Colors, Spacing} from '../../assets/styles'
import {useSelector, useDispatch} from 'react-redux'
import {setMessagesFilterString} from '../../actions/messagesActions'
import PropTypes from 'prop-types'
import Constant from '../Messages/MessageConstant'
import {isResidentActive} from '../../Utils/residentUtils'
import {CONVERSATION_CLOSED} from '../../constants/ModelConstants'
import useResidentLookup from '../hooks/useResidentLookup'
import {matchPath, useLocation} from 'react-router-dom'
import Routes from '../../constants/RouteConstants'
import {
  chatMessageToGenericChatMessage,
  FILTER_CURRENT,
  FILTER_NONE,
  FILTER_ONBOARDING,
  getResidentUsersFromChatRoom,
  messageToGenericChatMessage,
} from '../../Utils/chatUtils'
import SimpleSpinner from '../SimpleSpinner'
import {iterateSearchMessages} from '../../lib/queries'
import useDoOnceTimer from '../hooks/useDoOnceTimer'
const CONTENT_SEARCH_TIMER = 'content-search-timer'

const useStyles = makeStyles({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    overflowX: 'hidden',
    backgroundColor: Colors.rxrWhiteColor,
  },
  searchBarContainer: {
    flexShrink: 0,
    padding: '10px 16px',
    borderBottom: '1px solid ' + Colors.rxrGreyColor,
  },
  searchResult: {
    height: Spacing.spaceExtraLarge,
    backgroundColor: Colors.rxrTeal15Color,
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottom: '1px solid ' + Colors.rxrGreyColor,
  },

  title: {
    fontSize: 12,
    fontFamily: 'Proxima Nova',
    fontStyle: 'normal',
    fontWeight: 'bold',
    color: Colors.rxrBlackColor,
    lineHeight: '15px',
    marginLeft: '16px',
  },
  listContent: {
    flex: 1,
  },
  searchDivider: {
    textAlign: 'center',
    fontSize: Typography.fontSizeSmall,
    padding: Spacing.spaceExtraSmall,
    backgroundColor: Colors.rxrLightGreyColor,
    borderBottom: '1px solid ' + Colors.rxrGreyColor,
  },
})

function ChatsList(props) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const currentLocation = useLocation()
  const {getResident} = useResidentLookup()
  const searchTerm = useSelector(state => state.Messages.filterString)
  const searchTermFormatted = useSelector(state => state.Messages.filterString.toLowerCase().trim())
  const conversationsLookup = useSelector(state => state.Messages.conversationsLookup)
  const contentSearch = useRef({search: '', iterator: null})
  const [isContentSearching, setIsContentSearching] = useState(false)
  const [showMoreResults, setShowMoreResults] = useState(false)
  const [contentResults, setContentResults] = useState(/** @type {Array<GenericChatMessage>} */ [])
  const activeBuildingId = useSelector(state => state.Buildings.activeBuildingId)
  const {setTimer, cancelTimer} = useDoOnceTimer()

  // filter our conversation items by the search term if present
  let filteredConversationItems = Object.values(conversationsLookup).filter(item => {
    // convert our chat users back into resident objects
    let residents = getResidentUsersFromChatRoom(item).map(u => getResident(u.userId))
    // at least 1 of the resident users must still be active and there must be a "lastMessage" (at least 1 message in chat)
    if (!residents.some(isResidentActive)) {
      return false
    }

    return (
      (props.filter === FILTER_NONE ||
        (props.filter === FILTER_ONBOARDING && item.isOnboardingChat) ||
        (props.filter === FILTER_CURRENT && !item.isOnboardingChat)) &&
      // there's no search term OR
      (!searchTermFormatted ||
        residents.some(
          r =>
            // the resident's name contains this term
            r.displayName.toLowerCase().includes(searchTermFormatted) ||
            // OR the unit number contains this term
            r.occupancy.unit.number.toLowerCase().includes(searchTermFormatted),
        ))
    )
  })

  filteredConversationItems.sort((a, b) => {
    // we treat open and in progress the same
    const aIsActive = a.status !== CONVERSATION_CLOSED
    const bIsActive = b.status !== CONVERSATION_CLOSED
    const aLastMessageAt = a.lastMessage ? a.lastMessage.createdAt : 0
    const bLastMessageAt = b.lastMessage ? b.lastMessage.createdAt : 0

    if (aIsActive === bIsActive) {
      return a.hasUnread === b.hasUnread
        ? aLastMessageAt === bLastMessageAt
          ? 0
          : aLastMessageAt < bLastMessageAt
          ? 1
          : -1
        : a.hasUnread
        ? -1
        : 1
    } else if (aIsActive) {
      return -1
    } else {
      return 1
    }
  })

  function performContentSearch() {
    setIsContentSearching(true)
    if (!contentSearch.current.iterator) {
      // TODO: this search is case sensitive, but we're passing only a lower cased term. Need to resolve
      contentSearch.current.iterator = iterateSearchMessages(activeBuildingId, contentSearch.current.search)
    }

    contentSearch.current.iterator
      .next()
      .then(results => {
        setContentResults(prev =>
          Object.values(
            [
              ...prev,
              ...(results.messages || []).map(messageToGenericChatMessage),
              ...(results.chatMessages || []).map(chatMessageToGenericChatMessage),
            ].reduce((acc, message) => {
              // this removes duplicates by conversation
              // TODO: Idk if this is a good idea though since it might hide the specific message a staff user is looking for
              if (!acc[message.chatRoomId]) {
                acc[message.chatRoomId] = message
              } else {
                // we take the newer message
                acc[message.chatRoomId] = acc[message.chatRoomId].createdAt > message.createdAt ? acc[message.chatRoomId] : message
              }
              return acc
            }, {}),
          ),
        )
        setShowMoreResults(!contentSearch.current.iterator.hasCompleted)
      })
      .catch(e => {
        console.error(e)
      })
      .finally(() => {
        setIsContentSearching(false)
      })
  }

  useEffect(() => {
    // if the term has not changed since our lister iterator, we bail (this could happen if they enter a space/delete a space)
    if (contentSearch.current.search === searchTermFormatted) {
      return
    }

    delete contentSearch.current.iterator
    contentSearch.current.search = searchTermFormatted
    setContentResults([])

    // must type at least 3 characters to search content
    if (searchTermFormatted.length < 3) {
      cancelTimer(CONTENT_SEARCH_TIMER)
      setShowMoreResults(false)
      setIsContentSearching(false)
      return
    }

    setShowMoreResults(false)
    setIsContentSearching(true)
    setTimer(CONTENT_SEARCH_TIMER, performContentSearch, 1000)
  }, [searchTermFormatted])

  useEffect(() => {}, [contentResults])

  const updateMessagesFilter = searchValue => {
    setMessagesFilterString(dispatch, searchValue)
  }

  const viewConversationMatch = matchPath(currentLocation.pathname, {path: Routes.MESSAGES_VIEW_SINGLE})
  const viewingId = viewConversationMatch ? viewConversationMatch.params.conversationId : null

  // we filter the content results so they align with the tabe we're on
  const filteredContentResults = contentResults.filter(message => {
    const chatRoom = conversationsLookup[message.chatRoomId]
    if (!chatRoom) {
      return false
    }

    return (
      props.filter === FILTER_NONE ||
      (props.filter === FILTER_ONBOARDING && chatRoom.isOnboardingChat) ||
      (props.filter === FILTER_CURRENT && !chatRoom.isOnboardingChat)
    )
  })

  return (
    <div className={classes.root}>
      <div className={classes.searchBarContainer}>
        <ClearableInput placeholder={Constant.MESSAGE_ENTER_SEARCH_KEYWORDS_CONSTANT} onChange={updateMessagesFilter} value={searchTerm} />
      </div>

      <div className={classes.listContent}>
        {filteredConversationItems.map(convo => (
          <ChatListItem
            key={`${convo.id}-${convo.lastMessageId}-${convo.status}`}
            conversationId={convo.id}
            isHighlighted={convo.id === viewingId}
          />
        ))}
        {filteredContentResults.length > 0 && <div className={classes.searchDivider}>Message results</div>}
        {filteredContentResults.map(message => (
          <ChatListItem
            key={message.id}
            conversationId={message.chatRoomId}
            messageId={message.id}
            isHighlighted={message.chatRoomId === viewingId}
            messageTextOverride={message.content}
          />
        ))}
        {isContentSearching && (
          <div className={classes.searchResult}>
            <SimpleSpinner /> <span style={{marginLeft: Spacing.spaceSmall}}>Searching messages</span>
          </div>
        )}
        {!isContentSearching && showMoreResults && (
          <div className={classes.searchResult} style={{cursor: 'pointer'}} onClick={performContentSearch}>
            Show more results
          </div>
        )}
      </div>
    </div>
  )
}

ChatsList.propTypes = {
  filter: PropTypes.string,
}

export default ChatsList
