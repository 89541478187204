import React from 'react'
import {Switch, FormControlLabel} from '@mui/material'
import {spaceMedium} from '../../assets/styles/spacing'
import SimpleSpinner from '../SimpleSpinner'

function NotificationSettings({
  stagedNotifyEveryMessage,
  stagedNotifyWithDelay,
  stagedNotifyAmenityReservationRequests,
  isLoadingNotificationSettings,
  setStagedNotifyEveryMessage,
  setStagedNotifyWithDelay,
  setStagedNotifyAmenityReservationRequests,
  loadNotificationSettingsError,
  permissionsObject,
}) {
  if (isLoadingNotificationSettings) {
    return <SimpleSpinner size={SimpleSpinner.SIZE_DEFAULT} />
  } else if (loadNotificationSettingsError) {
    return <div>{loadNotificationSettingsError}</div>
  }

  return (
    <React.Fragment>
      {permissionsObject.messagesWrite && (
        <React.Fragment>
          <div style={{marginTop: -spaceMedium}}>
            <FormControlLabel
              control={
                <Switch
                  checked={stagedNotifyEveryMessage}
                  onChange={() => setStagedNotifyEveryMessage(!stagedNotifyEveryMessage)}
                  color="primary"
                />
              }
              label="Notify for every new RXO Chat message"
            />
          </div>
          <div>
            <FormControlLabel
              control={
                <Switch checked={stagedNotifyWithDelay} onChange={() => setStagedNotifyWithDelay(!stagedNotifyWithDelay)} color="primary" />
              }
              label="Notify for RXO Chat message with 36 hour delay"
            />
          </div>
        </React.Fragment>
      )}
      <div style={{marginTop: !permissionsObject.messagesWrite ? -spaceMedium : undefined}}>
        <FormControlLabel
          control={
            <Switch
              checked={stagedNotifyAmenityReservationRequests}
              onChange={() => setStagedNotifyAmenityReservationRequests(!stagedNotifyAmenityReservationRequests)}
              color="primary"
            />
          }
          label="Notify for amenity reservation requests"
        />
      </div>
    </React.Fragment>
  )
}

export default NotificationSettings
