import AppAuth from '../AppAuth'
import {
  constructCommandCenterMetricDataPayloadFromResponses,
  constructFeedbackDetailsPayloadFromResponse,
  constructResidentFeedbackSummaryAndTimeSeriesPayload,
} from './metricDataUtilities'
import moment from 'moment'
import {mapFeedbackTypeToCategory} from '../../components/ResidentSentiment/DetailCards/CategoryRatingDetails'
import {capitalizeFirstLetter} from '../../Utils/StringFormatter'
import config from '../../amplifyconfiguration.json'

// TODO: this should be defined by an environment variable or something methinks
// check for prod, set accordingly
const domain = window.location.origin.includes('rxohome') ? 'https://residentanalytics.rxr.io' : 'https://residentanalytics-qa.rxr.io'

class MetricDataAPI {
  /**
   * @param {string} buildingYardiId
   * @return {Promise<ResidentFeedbackSummaryAndTimeSeriesPayload>}
   */
  static async fetchResidentFeedbackSummaryAndTimeSeriesData(buildingYardiId) {
    let fetchResult
    let resp

    try {
      fetchResult = await MetricDataAPI.fetch('GET', '/app/satisfaction', {
        building: buildingYardiId,
        asOfDate: moment().format('YYYY-MM-DD'),
      })
      resp = fetchResult
    } catch (err) {
      console.error(err)
      resp = {}
    }

    return constructResidentFeedbackSummaryAndTimeSeriesPayload(resp)
  }

  /**
   * @param {string} buildingYardiId
   * @param {Date|string} asOfDate
   * @return {Promise<FeedbackDetailsPayload>}
   */
  static async fetchNPSScoreDetails(buildingYardiId, asOfDate) {
    let fetchResult
    let resp

    try {
      fetchResult = await MetricDataAPI.fetch('GET', '/app/nps_details', {
        building: buildingYardiId,
        asOfDate: moment(asOfDate).format('YYYY-MM-DD'),
      })
    } catch (err) {
      console.error(err)
      resp = {
        total: 'N/A',
        average: 'N/A',
        items: [],
      }
    }

    resp = {
      total: fetchResult?.agg?.count || 'N/A',
      average: fetchResult?.agg?.rating || 'N/A',
      items: (fetchResult?.detail || []).map(rating => {
        return {
          label: `${rating?.displayName}, Unit #${rating?.unit_number}`,
          value: rating?.rating,
          createdAt: moment(rating?.dt).format('YYYY-MM-DD'),
          description: rating?.feedback,
          denominator: 10,
        }
      }),
    }

    return constructFeedbackDetailsPayloadFromResponse(resp)
  }

  /**
   * @param {string} buildingYardiId
   * @param {Date|string} asOfDate
   * @return {Promise<FeedbackDetailsPayload>}
   */
  static async fetchSentimentDetails(buildingYardiId, asOfDate) {
    let fetchResult
    let resp

    try {
      fetchResult = await MetricDataAPI.fetch('GET', '/app/sentiment_messages', {
        building: buildingYardiId,
        asOfDate: moment(asOfDate).format('YYYY-MM-DD'),
      })
    } catch (err) {
      console.error(err)
      resp = {
        total: 'N/A',
        average: 'N/A',
        bottom: [],
        top: [],
      }
    }

    resp = {
      total: fetchResult?.agg?.count || 'N/A',
      average: fetchResult?.agg?.sentiment || 'N/A',
      top: (fetchResult?.top || [])
        .filter(message => message.sentiment >= 0)
        .map(message => {
          return {
            label: `${message?.displayName}, Unit #${message?.unit_number}`,
            value: message?.sentiment.toFixed(2),
            createdAt: moment(message?.dt).format('YYYY-MM-DD'),
            description: message?.body,
          }
        }),
      bottom: (fetchResult?.bottom || [])
        .filter(message => message.sentiment < 0)
        .map(message => {
          return {
            label: `${message?.displayName}, Unit #${message?.unit_number}`,
            value: message?.sentiment.toFixed(2),
            createdAt: moment(message?.dt).format('YYYY-MM-DD'),
            description: message?.body,
          }
        }),
    }

    return resp
  }

  /**
   * @param {string} buildingYardiId
   * @param {Date|string} asOfDate
   * @return {Promise<FeedbackDetailsPayload>}
   */
  static async fetchFeedbackCategoryDetails(buildingYardiId, asOfDate) {
    let fetchResult
    let resp

    try {
      fetchResult = await MetricDataAPI.fetch('GET', '/app/service_maintenance_amenity_rating_details', {
        building: buildingYardiId,
        asOfDate: moment(asOfDate).format('YYYY-MM-DD'),
      })
    } catch (err) {
      console.error(err)
      resp = {
        total: 'N/A',
        average: 'N/A',
        items: [],
      }
    }

    resp = {
      total: fetchResult?.agg?.count || 'N/A',
      average: fetchResult?.agg?.rating || 'N/A',
      items: (fetchResult?.detail || []).map(rating => {
        return {
          label: `${rating?.displayName}, Unit #${rating?.unit_number}`,
          secondaryLabel: capitalizeFirstLetter(mapFeedbackTypeToCategory[rating?.type]),
          value: rating?.rating,
          createdAt: moment(rating?.dt).format('YYYY-MM-DD'),
          description: rating?.feedback,
          denominator: 5,
          type: rating?.type,
        }
      }),
    }

    return constructFeedbackDetailsPayloadFromResponse(resp)
  }

  /**
   * @param {string} buildingId
   * @param {Date} asOfDate
   * @returns {Promise<CommandCenterMetricDataPayload>}
   */
  static async fetchCommandCenterData(buildingId, asOfDate) {
    const resp = await MetricDataAPI.fetch('GET', '/app/rxohome/commandcenter', {
      building: buildingId,
      start_date: moment(asOfDate).format('YYYY-MM-DD'),
      end_date: moment().format('YYYY-MM-DD'), // Yoann says use the same date for now?
    })

    return constructCommandCenterMetricDataPayloadFromResponses(resp)
  }

  // -------------------------------------------------------------------------------------------------

  /**
   * @param {*} params
   * @return {string}
   */
  static getQueryStringFromParams(params) {
    return !params
      ? ''
      : Object.entries(params)
          .map(tuple => {
            if (Array.isArray(tuple[1])) {
              return tuple[1].map(value => encodeURI(`${tuple[0]}=${value}`), '').join('&')
            }
            return encodeURI(`${tuple[0]}=${tuple[1]}`)
          })
          .join('&')
  }

  /**
   * @return {Promise<string>}
   */
  static async getJWT() {
    let jwt = await AppAuth.Instance().getJWT()
    if (!jwt) {
      throw new Error('Not authenticated')
    }

    return jwt
  }

  /**
   * @param {string} method
   * @param {string} path
   * @param {*?} params
   * @return {Promise<*>}
   */
  static async fetch(method, path, params) {
    if (!path) {
      throw new Error('Missing path')
    }

    const token = await MetricDataAPI.getJWT()

    if (method === 'GET') {
      // we pass params as the query string for Get requests
      const queryParams = MetricDataAPI.getQueryStringFromParams(params)
      // move the params into our path
      path = `${path}?${queryParams}`
      // delete the params
      params = undefined
    }

    // attach a leading slash in case the path doesn't have it
    const res = await fetch(`${domain}${path[0] === '/' ? '' : '/'}${path}`, {
      method: method,
      headers: {
        Authorization: 'Bearer ' + token,
        Region: config.aws_cognito_region,
        Pool: config.aws_user_pools_id,
        App: config.aws_user_pools_web_client_id,
      },
      body: params,
    })
    return await res.json()
  }
}

export default MetricDataAPI
