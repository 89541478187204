import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import {listPetProfiles} from '../../../lib/queries'
import SimpleSpinner from '../../SimpleSpinner'
import PetForm from './PetForm'
import {makeStyles} from '@mui/styles'
import {rxrBlueColor} from '../../../assets/styles/color'
import {spaceLarge, spaceMedium} from '../../../assets/styles/spacing'
import {fontSizeLarge} from '../../../assets/styles/typography'

function PetsList(props) {
  const classes = useStyles()
  const [pets, setPets] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [showNewForm, setShowNewForm] = useState(false)

  useEffect(() => {
    if (!props.occupancyId) {
      setPets([])
      return
    }

    setIsLoading(true)
    listPetProfiles(props.occupancyId)
      .then(p => {
        setPets(p)
        setShowNewForm(p.length === 0)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [props.occupancyId])

  const handleSaved = (newPet, isNew) => {
    let newPetsArr
    if (isNew) {
      newPetsArr = [...pets, newPet]
      setShowNewForm(false)
    } else {
      newPetsArr = pets.map(p => (p.id === newPet.id ? newPet : p))
    }

    setPets(newPetsArr)

    if (typeof props.onSaved === 'function') {
      // pass the new array of pets to the onSaved handler
      props.onSaved(newPetsArr)
    }
  }

  const handleRemoved = removedPet => {
    const nextPets = pets.filter(p => p.id !== removedPet.id)
    setPets(nextPets)
    if (nextPets.length === 0) {
      setShowNewForm(true)
    }
  }

  return (
    <div className={props.className}>
      {isLoading ? (
        <SimpleSpinner />
      ) : (
        <React.Fragment>
          {pets.map(p => (
            <PetForm
              key={p.id}
              pet={p}
              occupancyId={props.occupancyId}
              onSaved={handleSaved}
              className={classes.petForm}
              onRemoved={handleRemoved}
            />
          ))}

          {showNewForm && <PetForm occupancyId={props.occupancyId} onSaved={handleSaved} />}

          {!showNewForm && (
            <div className={classes.addAnotherButton} onClick={() => setShowNewForm(true)}>
              + Add another pet
            </div>
          )}
        </React.Fragment>
      )}
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  petForm: {
    marginBottom: spaceLarge,
  },
  addAnotherButton: {
    color: rxrBlueColor,
    textDecoration: 'underline',
    marginTop: spaceLarge,
    fontSize: fontSizeLarge,
    cursor: 'pointer',
  },
}))

PetsList.propTypes = {
  className: PropTypes.string,
  occupancyId: PropTypes.string.isRequired,
  onSaved: PropTypes.func,
}

export default PetsList
