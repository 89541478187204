import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {IconButton} from '@mui/material'
import moment from 'moment'
import RXRIcon from './RXRIcon'
import {Colors, Spacing} from '../assets/styles'
import {DatePicker} from '@mui/x-date-pickers'

// we're copying the styles form DateInputWeek
import {useStyles} from './DateInputWeek'

function DateInputWithArrows(props) {
  const classes = useStyles()
  const [isOpen, setIsOpen] = useState(false)

  return (
    <React.Fragment>
      <div className={classes.calendarLabelContainer}>
        <IconButton
          className={classes.iconButtonRoot}
          onClick={() => props.onChange(moment(props.date).subtract(1, 'day').toDate())}
          size="large"
        >
          <RXRIcon icon={RXRIcon.ARROW_LEFT} />
        </IconButton>

        <div className={classes.dateTitleContainer}>
          <p className={classes.dateRangeTitle}> {moment(props.date).format('MMM D')}</p>
          <IconButton
            style={{marginLeft: Spacing.spaceSmall}}
            className={classes.iconButtonRoot}
            onClick={() => setIsOpen(true)}
            size="large"
          >
            <RXRIcon icon={RXRIcon.CALENDAR} color={Colors.rxrBlackColor} size={RXRIcon.SIZE_LARGE} />
          </IconButton>
        </div>
        <IconButton
          className={classes.iconButtonRoot}
          onClick={() => props.onChange(moment(props.date).add(1, 'day').toDate())}
          size="large"
        >
          <RXRIcon icon={RXRIcon.ARROW_RIGHT} />
        </IconButton>
      </div>
      <div style={{display: 'none'}}>
        <DatePicker
          value={props.date}
          open={isOpen}
          onClose={() => setIsOpen(false)}
          variant="dialog"
          disableToolbar
          format="MM/dd/yyyy"
          minDate={props.min}
          maxDate={props.max}
          onChange={props.onChange}
          okLabel={'Select'}
          cancelLabel={'Cancel'}
          disabled={props.disabled}
        />
      </div>
    </React.Fragment>
  )
}

DateInputWithArrows.propTypes = {
  onChange: PropTypes.func.isRequired,
  date: PropTypes.instanceOf(Date).isRequired,
  min: PropTypes.instanceOf(Date),
  max: PropTypes.instanceOf(Date),
  disabled: PropTypes.bool,
}

export default DateInputWithArrows
