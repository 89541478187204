import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {Typography, Grid} from '@mui/material'
import {Typography as TypographyStyle} from '../../assets/styles'
import {makeStyles} from '@mui/styles'
import {spaceSmall} from '../../assets/styles/spacing'
import {rxrBlueColor} from '../../assets/styles/color'
import CustomCheckbox from '../CustomCheckbox'
import TextInput from '../TextInput'
import NumberInput from '../NumberInput'
import RXRButton from '../RXRButton'

const useStyles = makeStyles(theme => ({
  row: {
    marginTop: spaceSmall,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}))

function DefaultSelectAddOns(props) {
  const classes = useStyles()
  const [customAddOnText, setCustomAddOnText] = useState('')
  const [customAddOnPrice, setCustomAddOnPrice] = useState(0)

  const handleAddOption = (option, isSelected) => {
    if (isSelected) {
      props.updateForm({addOns: [...(props.addOns || []), {label: option.label, cost: option.price}]})
    } else {
      props.updateForm({addOns: props.addOns.filter(a => a.label !== option.label)})
    }
  }

  // This block of code combines our service addOnOptions with any custom options created by RXOs so that they can be selected and deselected after creation
  let addOnOptionsPlusCustomAddOns = []
  if (props.service && props.service.addOnOptions) {
    addOnOptionsPlusCustomAddOns = [...props.service.addOnOptions]
  }
  if (props.addOns) {
    props.addOns.forEach(addOn => {
      if (
        // Prevent duplicates
        !addOnOptionsPlusCustomAddOns.map(addOnOption => addOnOption.label).includes(addOn.label)
      ) {
        addOnOptionsPlusCustomAddOns.push({...addOn, price: addOn.cost})
      }
    })
  }

  return (
    <React.Fragment>
      {props.doNotShowTitle ? null : (
        <Typography style={{...TypographyStyle.H4HeaderWithoutBold, color: rxrBlueColor}}>Add on options:</Typography>
      )}
      {addOnOptionsPlusCustomAddOns.map((option, index) => {
        const isSelected = props.addOns && props.addOns.some(a => a.label === option.label)
        return (
          <div key={`${option.label}-${index}`}>
            <CustomCheckbox
              isChecked={isSelected}
              onChange={() => handleAddOption(option, !isSelected)}
              label={`${option.label} ($${option.price})`}
            />
          </div>
        )
      })}
      <Grid container spacing={3} className={classes.row}>
        <Grid item md={3} s={6}>
          <TextInput label="Create new Add on" isRequired={false} onChange={setCustomAddOnText} value={customAddOnText} />
        </Grid>
        <Grid item md={3} s={6}>
          <NumberInput
            label="Price"
            min={0}
            onChange={setCustomAddOnPrice}
            value={customAddOnPrice}
            integerOnly={true}
            showDollarSign={true}
          />
        </Grid>
        <Grid item md={6} s={6}>
          <RXRButton
            htmlType={'submit'}
            disabled={!customAddOnText || !customAddOnPrice}
            onClick={() => {
              handleAddOption({label: customAddOnText, price: customAddOnPrice}, true)
              setCustomAddOnText('')
              setCustomAddOnPrice(0)
            }}
          >
            Save
          </RXRButton>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

DefaultSelectAddOns.propTypes = {
  updateForm: PropTypes.func.isRequired,
  residentId: PropTypes.string.isRequired,
  service: PropTypes.any.isRequired,
  appointment: PropTypes.any,
  addOns: PropTypes.array,
  isDisabled: PropTypes.bool,
  hasError: PropTypes.bool,
  doNotShowTitle: PropTypes.bool,
}

export default DefaultSelectAddOns
