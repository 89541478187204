import React from 'react'
import {makeStyles} from '@mui/styles'
import {rxrGreyColor, rxrWhiteColor, shadowHard, shadowSoft} from '../assets/styles/color'
import {spaceExtraLarge, spaceMedium} from '../assets/styles/spacing'
import PropTypes from 'prop-types'

const useStyles = makeStyles(theme => ({
  multiSelectToolBarContainer: {
    position: 'fixed',
    bottom: 80,
    left: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  multiSelectToolBar: {
    boxShadow: `${shadowSoft}, ${shadowHard}`,
    width: '600px',
    height: '80px',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: rxrWhiteColor,
    border: `1px solid ${rxrGreyColor}`,
    borderRadius: '5px',
    paddingTop: spaceMedium,
    paddingLeft: spaceExtraLarge,
    paddingRight: spaceExtraLarge,
    paddingBottom: spaceMedium,
  },
}))

function TableMultiSelectToolBar(props) {
  const classes = useStyles()

  return (
    <div className={classes.multiSelectToolBarContainer}>
      <div className={classes.multiSelectToolBar}>{props.children}</div>
    </div>
  )
}

TableMultiSelectToolBar.propTypes = {
  children: PropTypes.any,
}

export default TableMultiSelectToolBar
