import React from 'react'
import {Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import {spaceLarge, spaceSmall} from '../../assets/styles/spacing'
import {rxrBlackColor, rxrDarkGreyColor, rxrMediumGreyColor, rxrMediumLightGreyColor, rxrWhiteColor} from '../../assets/styles/color'
import {
  fontSizeExtraLarge,
  fontSizeLarge,
  fontSizeMedium,
  heavyFontWeight,
  lightFontWeight,
  mediumFontWeight,
} from '../../assets/styles/typography'
import {getChangeStyle} from './utils'
import PropTypes from 'prop-types'

const useStyles = makeStyles(theme => ({
  cardTitle: {
    fontSize: fontSizeLarge,
    fontWeight: heavyFontWeight,
    alignSelf: 'flex-start',
  },

  cardSmall: {
    width: '350px',
    height: '140px',
    borderRadius: '10px',
    border: `1px solid ${rxrMediumLightGreyColor}`,
    background: rxrWhiteColor,
    padding: spaceSmall,
    display: 'flex',
    flexDirection: 'column',
  },

  smallCardColumnContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexGrow: 1,
  },
  leftColumn: {
    color: rxrBlackColor,
    fontSize: fontSizeExtraLarge,
    fontWeight: mediumFontWeight,
    paddingRight: spaceLarge,

    '& span': {
      color: rxrMediumGreyColor,
    },
  },
  rightColumnRow1: {
    fontSize: fontSizeMedium,
    fontWeight: lightFontWeight,
  },
  rightColumnRow2: {
    fontSize: fontSizeMedium,
    fontWeight: lightFontWeight,
    color: rxrDarkGreyColor,
  },
}))

const DEFAULT_DENOMINATOR = 1

function SentimentCard(props) {
  const title = props.title
  const value = props.value
  const change = props.change
  const changeDescription = props.changeDescription
  const hideDenominator = props.hideDenominator
  const denominator = props.denominator || DEFAULT_DENOMINATOR

  const classes = useStyles()

  return (
    <div className={classes.cardSmall}>
      <Typography className={classes.cardTitle}>{title}</Typography>
      <div className={classes.smallCardColumnContainer}>
        <Typography className={classes.leftColumn}>
          {value}
          {hideDenominator ? null : <span>/{denominator}</span>}
        </Typography>
        <div>
          <Typography className={classes.rightColumnRow1} style={getChangeStyle(change)}>
            {getChangeStyle(change).sign}
            {change}
          </Typography>
          <Typography className={classes.rightColumnRow2}>{changeDescription}</Typography>
        </div>
      </div>
    </div>
  )
}

SentimentCard.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  denominator: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  hideDenominator: PropTypes.bool,
  change: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  changeDescription: PropTypes.string.isRequired,
}

export default SentimentCard
