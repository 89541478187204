import React, {useState, useEffect} from 'react'
import {Grid} from '@mui/material'
import {makeStyles} from '@mui/styles'
import SearchResident from '../SearchResident'
import {useDispatch, useSelector} from 'react-redux'
import {loadVendorServicesForBuilding} from '../../actions/vendorAppointmentsActions'
import {getVendorServiceAvailability} from '../../lib/queries'
import SelectInput from '../SelectInput'
import DateInput from '../DateInput'
import moment from 'moment'
import {timeRangesToStartTimes} from '../../Utils/dateTimeUtil'
import MultilineTextInput from '../MultilineTextInput'
import {VendorServiceTypeCleaning, VendorServiceTypeDogwalking, VendorServiceTypeMassage} from './ServicesConstants'
import PropTypes from 'prop-types'
import TextInput from '../TextInput'
import {
  VENDOR_SERVICE_CANCELLED,
  VENDOR_SERVICE_COMPLETED,
  VENDOR_SERVICE_COMPLETED_PAID,
  VENDOR_SERVICE_NEEDS_RESCHEDULE,
} from './VendorServiceConstants'
import useBuildingDataRefresh from '../hooks/useBuildingDataRefresh'
import {spaceMedium, spaceSmall} from '../../assets/styles/spacing'
import useResidentLookup from '../hooks/useResidentLookup'
import DefaultSelectAddOns from './DefaultSelectAddOns'
import FrequencyBuilder from './FrequencyBuilder'
import SelectVendorServiceDiscounts from './SelectVendorServiceDiscounts'
import SelectPaymentMethodsForResident from './SelectPaymentMethodsForResident'
import CostBreakdown from './CostBreakdown'
import NumberInput from '../NumberInput'
import {H4HeaderWithoutBold} from '../../assets/styles/typography'
import {rxrRedColor, rxrWhiteColor} from '../../assets/styles/color'
import {useCalendarMonthAvailablity} from '../../Utils/useCalendarMonthAvailablity'
import {statusIsPast} from './servicesUtilities'

const useStyles = makeStyles(theme => ({
  row: {marginBottom: spaceMedium},
  warning: {
    padding: spaceSmall,
    border: `1px solid ${rxrRedColor}`,
    borderRadius: 4,
    ...H4HeaderWithoutBold,
    color: rxrRedColor,
    marginBottom: spaceMedium,
    backgroundColor: rxrWhiteColor,
  },
}))

const NOTES_CHARACTER_LIMIT = 500
const rowSize = 6

function formatSelectDate(d, durationInMinutes) {
  return `${moment(d).format('h:mma')} - ${moment(d).add(durationInMinutes, 'minutes').format('h:mma')}`
}

function VendorAppointmentForm(props) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [selectedDate, setSelectedDate] = useState(null)
  const [availabilityResponse, setAvailabilityResponse] = useState(null)
  const [isLoadingCalendar, setIsLoadingCalendar] = useState(false)
  const {getResident} = useResidentLookup()

  const servicesLookup = useSelector(state => state.Appointments.servicesLookup)
  const selectedAppointment = useSelector(state => (props.id ? state.Appointments.appointmentsLookup[props.id] : undefined))
  const {activeBuildingId, isLoadingData: isLoadingServices} = useBuildingDataRefresh(async () =>
    loadVendorServicesForBuilding(dispatch, activeBuildingId),
  )

  const {handleMonthChange, shouldDisableDate, loadAvailabilityForSelectedDate, clearCurrentAvailabilityInfo} = useCalendarMonthAvailablity(
    startDate => getVendorServiceAvailability(props.vendorServiceId, startDate),
  )

  const selectedService = servicesLookup[props.vendorServiceId]
  const isAppointmentPast =
    selectedAppointment &&
    [VENDOR_SERVICE_COMPLETED, VENDOR_SERVICE_COMPLETED_PAID, VENDOR_SERVICE_CANCELLED].includes(selectedAppointment.status)

  const handleTenantAndUnit = value => {
    // if resident ever changes, we need to reset some values
    props.updateForm({
      paymentMethodId: null,
      vendorServiceId: null,
      addOns: null,
      discounts: [],
      residentId: value ? value.id : null,
    })
    setSelectedDate(null)
    clearCurrentAvailabilityInfo()
  }

  useEffect(() => {
    if (!props.vendorServiceId || !selectedDate) {
      return
    }
    setSelectedDate(null)
    clearCurrentAvailabilityInfo()
  }, [props.vendorServiceId])

  const handleChangeDate = date => {
    if (!date || !moment(date).isValid()) {
      return
    }

    setAvailabilityResponse(null)
    setSelectedDate(date)
    props.updateForm({
      startAt: null,
      endAt: null,
    })
    handleMonthChange(date).then(() => {})
    setAvailabilityResponse(loadAvailabilityForSelectedDate(date))
  }

  useEffect(() => {
    if (props.vendorServiceId && selectedDate) {
      loadMonthData(selectedDate, true)
    }
  }, [selectedDate, props.vendorServiceId])

  const loadMonthData = (d, force) => {
    if (!!props.id && statusIsPast(props.status)) {
      return
    }

    setIsLoadingCalendar(true)
    handleMonthChange(d, force)
      .then(() => setAvailabilityResponse(loadAvailabilityForSelectedDate(d)))
      .finally(() => setIsLoadingCalendar(false))
  }

  const handleSelectStart = dateStr => {
    const date = new Date(dateStr)
    props.updateForm({
      startAt: date,
      endAt: moment(date).add(availabilityResponse.duration, 'minutes').toDate(),
    })
  }

  const startTimes =
    availabilityResponse && availabilityResponse.availability && availabilityResponse.duration
      ? timeRangesToStartTimes(availabilityResponse.availability, availabilityResponse.duration)
      : []
  const wasCostChanged = props.changedItems.includes('cost') || props.changedItems.includes('addOns')
  const isPaid = selectedAppointment && selectedAppointment.payment && selectedAppointment.payment.status !== 'DECLINED'
  const isCancelled = selectedAppointment && selectedAppointment.status === VENDOR_SERVICE_CANCELLED

  const AddOnsComponent = props.AddOnsComponent || DefaultSelectAddOns

  return (
    <React.Fragment>
      <Grid container spacing={3} className={classes.row}>
        <Grid item xs={12}>
          <SearchResident
            disabled={!!props.id}
            value={props.residentId}
            isRequired={true}
            onSelectResident={handleTenantAndUnit}
            error={props.invalidItems.includes('residentId')}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3} className={classes.row}>
        <Grid item xs={12}>
          <SelectInput
            label={'Package'}
            disabled={!props.residentId || !!props.id}
            onChange={val => props.updateForm({vendorServiceId: val})}
            value={props.vendorServiceId}
            options={filterServicesAvailableToResident(props.serviceType, getResident(props.residentId), Object.values(servicesLookup)).map(
              s => ({
                // we're including the subtype for transparency. Kinda hacky, not very human friendly, but it'll work
                label: `${s.label} - ${s.subType} ($${s.price})`,
                value: s.id,
              }),
            )}
            error={props.invalidItems.includes('vendorServiceId')}
            isLoading={isLoadingServices}
          />
        </Grid>
      </Grid>

      {props.status === VENDOR_SERVICE_NEEDS_RESCHEDULE && (
        <div className={classes.warning}>
          This appointment could not be booked due to a scheduling conflict. It must be rebooked for a different date and time or it will
          not be serviced.
        </div>
      )}

      <Grid container spacing={3} className={classes.row}>
        <Grid item md={6} xs={12}>
          <DateInput
            disabled={!props.vendorServiceId || isAppointmentPast}
            isRequired={true}
            label={'Date'}
            helperText={'MM/DD/YYYY'}
            onChange={handleChangeDate}
            onMonthChange={loadMonthData}
            shouldDisableDate={shouldDisableDate}
            // when viewing an existing appointment, we use that date
            value={selectedDate ? selectedDate : selectedAppointment ? selectedAppointment.startAt : null}
            error={props.invalidItems.includes('startAt')}
            isLoading={isLoadingCalendar}
          />
        </Grid>

        <Grid item md={6} xs={12}>
          {selectedAppointment && !selectedDate ? (
            <TextInput
              label={'Start time'}
              isDisabled={true}
              value={formatSelectDate(
                selectedAppointment.startAt,
                moment(selectedAppointment.endAt).diff(selectedAppointment.startAt, 'minutes'),
              )}
            />
          ) : (
            <SelectInput
              label={'Start time'}
              disabled={!selectedDate}
              isRequired={true}
              onChange={handleSelectStart}
              value={props.startAt ? props.startAt.toISOString() : null}
              options={startTimes.map(d => ({
                value: d.toISOString(),
                label: formatSelectDate(d, availabilityResponse.duration),
              }))}
              error={props.invalidItems.includes('startAt') || props.invalidItems.includes('endAt')}
              isLoading={isLoadingCalendar}
            />
          )}
        </Grid>
      </Grid>

      <Grid container spacing={3} className={classes.row}>
        <Grid item md={6} xs={12}>
          <FrequencyBuilder
            frequency={props.frequency}
            startingDate={props.startAt}
            onChange={f => props.updateForm({frequency: f})}
            isDisabled={!!props.id || !props.startAt}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <SelectPaymentMethodsForResident
            paymentMethodId={props.paymentMethodId}
            onSelect={v => props.updateForm({paymentMethodId: v})}
            isDisabled={isPaid}
            residentId={props.residentId}
            hasError={props.invalidItems.includes('paymentMethodId')}
          />
        </Grid>
      </Grid>

      {selectedService && (
        <Grid container spacing={3} className={classes.row}>
          <Grid item xs={12}>
            <AddOnsComponent
              isDisabled={!!props.id}
              service={selectedService}
              residentId={props.residentId}
              updateForm={props.updateForm}
              appointment={selectedAppointment}
              addOns={props.addOns}
              hasError={props.invalidItems.includes('addOns')}
            />
          </Grid>
        </Grid>
      )}

      {selectedService && (
        <Grid container spacing={3} className={classes.row}>
          <Grid item xs={12}>
            <SelectVendorServiceDiscounts
              service={selectedService}
              residentId={props.residentId}
              onChange={d => props.updateForm({discounts: d})}
              selectedDiscountIds={props.discounts.map(d => d.id)}
              editingAppointmentId={props.id}
            />
          </Grid>
        </Grid>
      )}

      {!isPaid && isCancelled && (
        <Grid container spacing={3} className={classes.row}>
          <Grid item xs={12}>
            <NumberInput
              label={'Cancellation Fee'}
              value={wasCostChanged ? props.cost : selectedAppointment.cost}
              onChange={v => {
                props.updateForm({cost: v})
              }}
              min={0}
              showDollarSign={true}
              helperText={
                wasCostChanged
                  ? 'You must save changes before you can charge the resident'
                  : 'Modify price for cancelled appointment? -- Price does not include add ons or discounts'
              }
            />
          </Grid>
        </Grid>
      )}

      {selectedService && (
        <Grid container spacing={3} className={classes.row}>
          <Grid item xs={12}>
            <CostBreakdown
              service={selectedService}
              priceOverride={selectedAppointment ? selectedAppointment.cost : undefined}
              addOns={props.addOns}
              discounts={props.discounts}
            />
          </Grid>
        </Grid>
      )}

      <Grid container spacing={3} className={classes.row}>
        <Grid item xs={12}>
          <MultilineTextInput
            label="Notes"
            placeholder=""
            helperText={`Special instructions from the resident to the vendor`}
            rows={rowSize}
            rowsMax={rowSize}
            maxLength={NOTES_CHARACTER_LIMIT}
            onChange={val => props.updateForm({notes: val})}
            value={props.notes}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

// TODO: this could be moved to a redux select if it's ever needed on another page
function filterServicesAvailableToResident(serviceType, residentObj, allServices) {
  if (!residentObj || !allServices) {
    return []
  }

  switch (serviceType) {
    case VendorServiceTypeCleaning:
      // should also centralize this subType logic somewhere maybe
      // eslint-disable-next-line no-case-declarations
      let cleaningSubType = residentObj.occupancy.unit.bedrooms + 'bedrooms'
      return allServices.filter(s => s.type === VendorServiceTypeCleaning && s.subType === cleaningSubType)

    case VendorServiceTypeDogwalking:
    case VendorServiceTypeMassage:
    default:
      return allServices.filter(s => s.type === serviceType)
  }
}

VendorAppointmentForm.propTypes = {
  updateForm: PropTypes.func.isRequired,
  invalidItems: PropTypes.array,
  changedItems: PropTypes.array,
  serviceType: PropTypes.string,
  AddOnsComponent: PropTypes.func,

  id: PropTypes.string,
  residentId: PropTypes.string,
  vendorServiceId: PropTypes.string,
  startAt: PropTypes.any,
  endAt: PropTypes.any,
  instructions: PropTypes.string,
  paymentMethodId: PropTypes.string,
  frequency: PropTypes.string,
  addOns: PropTypes.array,
}

export default VendorAppointmentForm
