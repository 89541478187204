import React from 'react'
import SortableTable, {ColumnConfig} from '../SortableTable'
import PropTypes from 'prop-types'
import moment from 'moment'
import Routes from '../../constants/RouteConstants'
import {makeStyles} from '@mui/styles'
import {withRouter} from 'react-router-dom'
import {secondaryButton} from '../../assets/styles/buttons'
import {rxrGreenColor} from '../../assets/styles/color'
import {isGuestDatePermanent} from '../../Utils/dateTimeUtil'
import {connect, useSelector} from 'react-redux'
import {compose} from 'redux'
import {checkInGuest} from '../../actions/guestAuthActions'
import Constants from './GuestAuthorizationConstants'
import HighlightText from '../HighlightText'
import RXRButton from '../RXRButton'
import useResidentLookup from '../hooks/useResidentLookup'
import RXRIcon from '../RXRIcon'

const useStyles = makeStyles(theme => ({
  checkInButtonConfirm: {
    ...secondaryButton,
    borderColor: rxrGreenColor,
    color: rxrGreenColor,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 80,
  },
  checkInButton: props => ({
    width: props.isMobile ? '100px' : undefined,
  }),
}))

function getGuestFullName(guest, filterTerm) {
  return filterTerm ? (
    <HighlightText search={filterTerm}>{`${guest.firstName} ${guest.lastName}`}</HighlightText>
  ) : (
    `${guest.firstName} ${guest.lastName}`
  )
}

function guestNameComparator(a, b) {
  const aName = getGuestFullName(a).toLowerCase()
  const bName = getGuestFullName(b).toLowerCase()

  return aName < bName ? -1 : aName > bName ? 1 : 0
}

const ActiveGuestsTable = function (props) {
  const isMobile = useSelector(state => state.App.isMobile)
  const classes = useStyles({isMobile})

  const handleClickGuest = guest => {
    props.history.push(Routes.constructPath(Routes.GUESTAUTHORIZATION_VIEW_SINGLE, {guestId: guest.id}))
  }
  const {getResident} = useResidentLookup()

  const columns = [
    new ColumnConfig({
      title: 'Guest name',
      render: guest => getGuestFullName(guest, props.filterTerm),
      comparator: guestNameComparator,
    }),

    new ColumnConfig({
      title: 'Resident',
      render: g => <HighlightText search={props.filterTerm}>{getResident(g.residentId).displayName}</HighlightText>,
      comparator: ColumnConfig.residentNameComparatorGenerator(getResident, guestNameComparator),
    }),

    new ColumnConfig({
      title: 'Unit',
      render: g => <HighlightText search={props.filterTerm}>{getResident(g.residentId).occupancy.unit.number}</HighlightText>,
      comparator: ColumnConfig.residentUnitComparatorGenerator(getResident, guestNameComparator),
    }),

    new ColumnConfig({
      title: 'Expires on',
      render: g => {
        if (isGuestDatePermanent(g.registrationExpiry)) {
          return 'Permanent'
        }
        return moment(g.registrationExpiry).format('l')
      },
      comparator: (a, b) => {
        const aIsPermanent = isGuestDatePermanent(a.registrationExpiry)
        const bIsPermanent = isGuestDatePermanent(b.registrationExpiry)
        // if they're equal, we go off the expiry date
        if (aIsPermanent === bIsPermanent) {
          return a.registrationExpiry < b.registrationExpiry
            ? -1
            : a.registrationExpiry > b.registrationExpiry
            ? 1
            : guestNameComparator(a, b)
        } else {
          // otherwise, we want to treat permanent as strictly greater
          return aIsPermanent ? 1 : -1
        }
      },
      isDefaultSort: true,
    }),

    new ColumnConfig({
      title: 'Announce option',
      render: g => {
        let matching = Constants.MEETING_OPTIONS.find(o => o.value === g.meetingOption)
        return matching.label || 'Unknown'
      },
    }),

    new ColumnConfig({
      title: 'Check in',
      render: g => {
        return props.recentlyCheckedInGuestIds.includes(g.id) ? (
          <div className={classes.checkInButtonConfirm} onClick={e => e.stopPropagation()}>
            <RXRIcon icon={RXRIcon.CHECK} size={RXRIcon.SIZE_LARGE} />
          </div>
        ) : (
          <RXRButton
            onClick={e => {
              e.stopPropagation()
              props.dispatchCheckInGuest(g, getResident(g.residentId)).then()
            }}
            className={classes.checkInButton}
          >
            Check in
          </RXRButton>
        )
      },
    }),
  ]

  return (
    <SortableTable
      data={props.activeGuests}
      columns={columns}
      onClickRow={handleClickGuest}
      downloadFileName={'Active Guests'}
      rowHighlightCondition={g => g.id === props.highlightGuestById || props.recentlyCheckedInGuestIds.includes(g.id)}
    />
  )
}

ActiveGuestsTable.propTypes = {
  activeGuests: PropTypes.array.isRequired,
  highlightGuestById: PropTypes.string,
}

const mapStateToProps = state => ({
  recentlyCheckedInGuestIds: state.GuestAuth.recentlyCheckedInGuestIds,
})

const mapDispatchToProps = dispatch => ({
  dispatchCheckInGuest: (guestModel, residentModel) => checkInGuest(dispatch, guestModel, residentModel),
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(ActiveGuestsTable)
