export const STATE_IDLE = 'idle'
export const STATE_SENDING = 'sending'
export const STATE_SENT = 'sent'
export const STATE_ERROR = 'error'

/**
 * @param {string} password
 * @return {boolean}
 */
export function passwordIsAcceptable(password) {
  return password.length >= 8 && /\d/.test(password);
}
