import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import MessageCard from './MessageCard'
import {makeStyles} from '@mui/styles'
import {matchPath, useHistory, useLocation} from 'react-router-dom'
import Routes from '../../constants/RouteConstants'
import {spaceMedium} from '../../assets/styles/spacing'
import {MESSAGES_POLL_TIME_MS} from '../../constants/AppConstants'

// how long a a single message card should appear on the page
const MESSAGE_PREVIEW_DURATION = 5000

// how many pixels multi messages should shift (x and y) when stacking
const SHIFT_AMOUNT = 12

function NewMessages() {
  const classes = useStyles()
  const activeBuildingId = useSelector(state => state.Buildings.activeBuildingId)
  const newMessagesFromLastSync = useSelector(state => state.Messages.newMessagesFromLastSync)
  const [newMessages, setNewMessages] = useState([])
  const [messageTimeouts, setMessageTimeouts] = useState([])
  const history = useHistory()
  const currentLocation = useLocation()
  const isOnMessagesPage = !!matchPath(currentLocation.pathname, {path: Routes.MESSAGES})
  const popupsHidden = useSelector(state => state.App.hidePopups)

  const handleRemoveMessage = m => {
    setNewMessages(prevMessages =>
      prevMessages.filter(obj => {
        if (obj.message.id === m.id) {
          clearTimeout(obj.timer)
          return false
        }
        return true
      }),
    )
  }

  const handleNavigateToMessage = m => {
    history.push(Routes.constructPath(Routes.MESSAGES_VIEW_SINGLE, {conversationId: m.chatRoomId}))
  }

  // this clears all timers and wipes the newMessages state -- effectively an unmount
  const resetData = () => {
    messageTimeouts.forEach(time => clearTimeout(time))
    newMessages.forEach(obj => clearTimeout(obj.timer))
    setNewMessages([])
  }

  // if the building changes, we clear the newMessages array (and dequeue any new message times)
  useEffect(() => {
    resetData()
  }, [activeBuildingId])

  // whenever new messages are detected
  useEffect(() => {
    if (newMessagesFromLastSync.length === 0) {
      // if there are no new messages, we just return
      return
    }

    // each message should be spaced such that they can all be shown (with delay) before the next sync runs
    const waitTime = MESSAGES_POLL_TIME_MS / newMessagesFromLastSync.length

    // now we need to one-at-a-time add our new messages so they appear staggered
    setMessageTimeouts(
      newMessagesFromLastSync.map((m, index) =>
        setTimeout(() => {
          setNewMessages(prevMessages => {
            // merge to our list
            return [
              ...prevMessages,
              {
                timer: setTimeout(() => handleRemoveMessage(m), MESSAGE_PREVIEW_DURATION),
                message: m,
              },
            ]
          })
          // we wait a blink before showing the next one
        }, index * waitTime),
      ),
    )
  }, [newMessagesFromLastSync])

  // whenever we determine we're on the messages page, we reset our new messages count
  useEffect(() => {
    if (isOnMessagesPage) {
      resetData()
    }
  }, [isOnMessagesPage])

  return newMessages.length === 0 || isOnMessagesPage || popupsHidden ? null : (
    <div className={classes.container}>
      {newMessages.map((obj, index) => {
        return (
          <div
            key={obj.message.id}
            style={{
              transition: 'all 0.5s',
              position: 'absolute',
              bottom: `${index * SHIFT_AMOUNT}px`,
              left: `${index * SHIFT_AMOUNT}px`,
            }}
          >
            <MessageCard
              message={obj.message}
              onClose={() => handleRemoveMessage(obj.message)}
              onOpen={() => handleNavigateToMessage(obj.message)}
            />
          </div>
        )
      })}
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    zIndex: 200,
    position: 'absolute',
    bottom: spaceMedium,
    left: spaceMedium,
  },
}))

export default NewMessages
