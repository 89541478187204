import React, {useEffect, useRef, useState} from 'react'
import {makeStyles} from '@mui/styles'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {compose} from 'redux'
import {withRouter} from 'react-router-dom'
import {Typography} from '@mui/material'
import SimpleSpinner from './SimpleSpinner'
import AddPhotoIcon from '../assets/images/Icon/AddPhoto.svg'
import {
  rxrRedColor,
  rxrDarkGreyColor,
  rxrGreyColor,
  rxrBlackColor,
  rxrWhiteColor,
  shadowSoft,
  rxrLightGreyColor,
} from '../assets/styles/color'
import {pluralizeWord} from '../Utils/StringFormatter'
import FileHelper from '../lib/FileHelper'
import RXRIcon from './RXRIcon'
import {v4 as uuid} from 'uuid'

const dimension = 100
const defaultBorderColor = rxrGreyColor

const sharedImageStyles = {
  width: dimension,
  height: dimension,
  borderRadius: 4,
  backgroundColor: rxrLightGreyColor,
  textAlign: 'center',
  margin: 4,
  position: 'relative',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  border: `1px solid ${defaultBorderColor}`,
}

const useStyles = makeStyles(theme => ({
  mainContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    alignContent: 'flex-start',
  },
  imgContainer: {
    ...sharedImageStyles,
    marginLeft: 10,
    borderColor: props => {
      return props.isRequired && props.error ? rxrRedColor : defaultBorderColor
    },
  },
  emptyImgContainer: {
    ...sharedImageStyles,
    borderColor: props => {
      return props.isRequired && props.error ? rxrRedColor : defaultBorderColor
    },
    cursor: 'pointer',
  },
  closeIcon: {
    cursor: 'pointer',
    zIndex: 2,
    position: 'absolute',
    top: 0,
    right: 0,
    backgroundColor: rxrWhiteColor,
    borderRadius: '50%',
    width: 20,
    textAlign: 'center',
    lineHeight: '20px',
    padding: 0,
    height: 20,
    transition: 'all 0.2s',
    '&:hover': {
      width: 26,
      height: 26,
      lineHeight: '26px',
    },
  },
  addPhotoText: {
    fontSize: 12,
    color: props => {
      return props.isRequired && props.error ? rxrRedColor : rxrDarkGreyColor
    },
    marginTop: 5,
  },
  renderedImage: {
    height: '100%',
    width: '100%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
}))

function ImageGallerySelector(props) {
  const idRef = useRef(uuid())
  const classes = useStyles(props)
  const imagesArray = Array.isArray(props.value) ? props.value : []

  const [isLoading, setLoading] = useState(false)

  // load any images already passed to this component
  useEffect(() => {
    let imagesToLoad = imagesArray.filter(i => !i.fileUrl)

    if (imagesToLoad.length === 0 || isLoading) {
      return
    }
    setLoading(true)

    Promise.all(
      imagesToLoad.map(async i => {
        i.fileUrl = await FileHelper.Instance().getFileUrlFromS3Object(i)
        return i
      }),
    ).then(() => {
      setLoading(false)
    })
  }, [imagesArray, isLoading, setLoading])

  /**
   * @param {*} e
   */
  const handleImages = e => {
    setLoading(true)
    FileHelper.Instance()
      .handleSelectFilesInputEventUploadToS3(e, props.fileTypes || FileHelper.IMAGE_FILE_TYPES)
      .then(uploaded => {
        props.onChange([...imagesArray, ...uploaded])
      })
      .catch(err => {
        window.alert(err.message)
      })
      .finally(() => setLoading(false))
  }

  const deleteImage = index => {
    let newImages = [...imagesArray]
    newImages.splice(index, 1)
    props.onChange(newImages)
  }

  const defaultHelperText = `${props.isRequired ? 'Attach at least 1 photo.* ' : ''}Select up to ${pluralizeWord(
    'image',
    props.maxPhotos,
    true,
  )}`

  return (
    <div>
      <div className={classes.mainContainer}>
        {imagesArray.map((image, idx) => {
          return image.fileUrl ? (
            <div key={image.fileUrl} className={classes.imgContainer}>
              <div className={classes.closeIcon} onClick={() => deleteImage(idx)}>
                <RXRIcon icon={RXRIcon.CLOSE} color={rxrBlackColor} size={RXRIcon.SIZE_SMALL} />
              </div>
              <a
                href={image.fileUrl}
                rel="noopener noreferrer"
                target="_blank"
                style={{backgroundImage: `url(${image.fileUrl})`}}
                className={classes.renderedImage}
              ></a>
            </div>
          ) : (
            <div key={idx} className={classes.emptyImgContainer}>
              <SimpleSpinner size={SimpleSpinner.SIZE_LARGE} />
            </div>
          )
        })}
        {imagesArray.length < props.maxPhotos && (
          <React.Fragment>
            <input
              name="image"
              onChange={handleImages}
              accept="image/*"
              style={{display: 'none'}}
              id={idRef.current}
              multiple
              type="file"
            />
            <label htmlFor={idRef.current}>
              <div className={classes.emptyImgContainer}>
                {isLoading ? <SimpleSpinner size={SimpleSpinner.SIZE_LARGE} /> : <img alt="Add Photo icon" src={AddPhotoIcon} />}
              </div>
            </label>
          </React.Fragment>
        )}
      </div>
      <Typography className={classes.addPhotoText}>{props.helperText ? props.helperText : defaultHelperText}</Typography>
    </div>
  )
}

const mapStateToProps = state => ({
  activeBuildingId: state.Buildings.activeBuildingId,
})

ImageGallerySelector.propTypes = {
  maxPhotos: PropTypes.number.isRequired, // Count of max number of photos
  value: PropTypes.array.isRequired, // Array of S3 objects (images)
  onChange: PropTypes.func.isRequired, // callback invoked with the selected image values prop
  isRequired: PropTypes.bool,
  helperText: PropTypes.string,
  fileTypes: PropTypes.arrayOf(PropTypes.string),
  error: PropTypes.bool,
}

export default compose(withRouter, connect(mapStateToProps))(ImageGallerySelector)
