import {Dialog, DialogActions, DialogTitle, Typography} from '@mui/material'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import {Typography as TypographyStyle, Colors} from '../assets/styles'
import React, {useEffect, useRef, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {makeStyles} from '@mui/styles'
import PropTypes from 'prop-types'
import RXRButton from './RXRButton'
import RXRIcon from './RXRIcon'

const CopyConstants = {
  BTN_CANCEL: 'Cancel',
  BTN_DISCARD: 'Discard',
  DISCARD_CHANGES: 'Discard changes',
  DISCARD_MESSAGE: 'Are you sure you want to discard your changes?',
}

const useStyles = makeStyles(theme => ({
  dialog: {
    maxWidth: '286px',
    '& .MuiDialogTitle-root': {
      padding: '24px 24px 8px 24px',
    },
    '& .MuiDialogActions-root': {
      padding: '0px 8px 16px 16px',
      justifyContent: 'flex-start',
    },
  },
  dialogHeading: {
    ...TypographyStyle.BodyHeader,
    lineHeight: '19px',
    marginBottom: '8px',
  },
}))

/*
This component has 2 major use cases it supports:
1. Passive use
  - if `hasChanges` prop is True, any app navigations will block and prompt the dialog to open
2. Active use
  - if `isOpen` prop is True, the dialog will appear and block app navigations until closed
 */

function DiscardChangesDialog(props) {
  const classes = useStyles()

  const history = useHistory()
  const [navTransaction, setNavTransaction] = useState(null)
  const unblockFunction = useRef(null)

  useEffect(() => {
    /*
      TODO: Could extend this to check for native browser navigation with a system dialog, but fine for now
     */

    const unblock = history.block(trx => {
      // if we have changes
      if (props.hasChanges || props.isOpen) {
        // we record the navigation attempt (trx) and cancel the navigation
        setNavTransaction(trx)
        return false
      }

      // otherwise, return true to allow it
      return true
    })

    // store a reference to our unblock function so we can call it from the confirm hook
    unblockFunction.current = unblock

    return () => {
      // if unmounting, always unblock history
      unblock()
    }
  }, [props.hasChanges, props.isOpen])

  function handleCancel() {
    // unset our navigation transaction
    setNavTransaction(null)

    // notify parents of cancel click
    if (typeof props.onCancel === 'function') {
      props.onCancel()
    }
  }

  function handleConfirm() {
    // first, unblock history navigations
    if (unblockFunction.current) {
      unblockFunction.current()
    }

    // then, notify our parents that we discarded so they can cleanup as needed
    if (typeof props.onDiscard === 'function') {
      props.onDiscard()
    }

    if (navTransaction) {
      // last, retry with the same transaction
      history.push(navTransaction)
      // unset our navigation transaction
      setNavTransaction(null)
    }
  }

  const icon = props.discardIcon || RXRIcon.TRASH

  return (
    <Dialog
      open={props.isOpen || !!navTransaction}
      onClose={handleCancel}
      aria-labelledby="alert-back-dialog-title"
      aria-describedby="alert-back-dialog-description"
      classes={{paper: classes.dialog}}
    >
      <DialogTitle>
        {typeof icon === 'string' ? <RXRIcon icon={icon} size={RXRIcon.SIZE_LARGE} color={Colors.rxrDarkGreyColor} /> : icon}
      </DialogTitle>
      <DialogContent>
        <Typography className={classes.dialogHeading}>{props.discardTitle || CopyConstants.DISCARD_CHANGES}</Typography>
        <DialogContentText>{props.discardMessage || CopyConstants.DISCARD_MESSAGE}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <RXRButton type={RXRButton.TYPE_TEXT} onClick={handleConfirm} isLoading={props.isLoading}>
          {props.discardButton || CopyConstants.BTN_DISCARD}
        </RXRButton>
        <RXRButton type={RXRButton.TYPE_TEXT_SECONDARY} onClick={handleCancel} isLoading={props.isLoading}>
          {CopyConstants.BTN_CANCEL}
        </RXRButton>
      </DialogActions>
    </Dialog>
  )
}

DiscardChangesDialog.propTypes = {
  onDiscard: PropTypes.func,
  onCancel: PropTypes.func,
  hasChanges: PropTypes.bool,
  isOpen: PropTypes.bool,
  discardIcon: PropTypes.any,
  discardTitle: PropTypes.string,
  discardMessage: PropTypes.string,
  discardButton: PropTypes.string,
  isLoading: PropTypes.bool,
}

export default DiscardChangesDialog
