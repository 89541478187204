import React, {useState, forwardRef} from 'react'
import {Autocomplete} from '@mui/material'
import {createFilterOptions} from '@mui/material/Autocomplete'
import TextInput from './TextInput'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import PropTypes from 'prop-types'
import {makeStyles} from '@mui/styles'
import {Colors, Spacing} from '../assets/styles'

const useStyles = makeStyles(theme => ({
  itemContainer: {
    padding: `${Spacing.spaceExtraSmall}px ${Spacing.spaceSmall}px`,
    borderBottom: `1px solid ${Colors.rxrMonotoneLightGreyColor}`,
    cursor: 'pointer',
    transition: 'background-color 0.2s',

    '&:last-child': {
      borderBottom: 'none',
    },

    '&:hover, &:focus': {
      backgroundColor: Colors.nearWhiteColor,
    },
  },
  item: {
    ...Colors.black,
    fontSize: '16px',
    lineHeight: '19px',
  },
  '@global': {
    '.MuiAutocomplete-option[data-focus="true"]': {
      backgroundColor: Colors.rxrMonotoneLightGreyColor,
    },
  },
  option: {
    paddingBottom: 0,
  },
  listbox: {
    paddingTop: 0,
    paddingBottom: 0,
  },
}))

const PrefixComplete = forwardRef(function PrefixComplete(props, ref) {
  const classes = useStyles()
  const filterOptions = createFilterOptions({
    limit: 5,
  })
  const [inputVal, setInputVal] = useState('')

  // if o is not provided, we return an empty string
  // this means implementing components don't have to null check inside their getOptionLabel functions
  const getOptionLabelSafe = o => {
    if (!o) {
      return ''
    }

    if (typeof props.getOptionLabel !== 'function') {
      return o
    }

    return props.getOptionLabel(o)
  }

  // if it's disabled we just return a basic textInput
  if (props.isDisabled) {
    return (
      <TextInput
        className={props.textFieldStyle}
        label={props.label}
        isRequired={props.isRequired}
        isDisabled={true}
        value={getOptionLabelSafe(props.value)}
      />
    )
  }

  return (
    <Autocomplete
      ref={ref}
      options={props.options}
      classes={{option: classes.option, listbox: classes.listbox}}
      getOptionLabel={getOptionLabelSafe}
      filterOptions={filterOptions}
      // -------------------------------------
      // these two props let us control the clearOnSelect behavior
      inputValue={props.clearOnSelect ? inputVal : undefined}
      onInputChange={(e, s) => setInputVal(s)}
      // -------------------------------------
      value={props.value}
      onChange={(event, newValue) => {
        props.onChange(newValue)
        if (props.clearOnSelect) {
          setInputVal('')
        }
      }}
      popupIcon={null}
      renderInput={params => {
        return (
          <TextInput
            inputProps={params.inputProps}
            InputProps={params.InputProps}
            isRequired={props.isRequired}
            label={props.label}
            placeholder={props.placeholder ? props.placeholder : ''}
            className={props.textFieldStyle}
            error={props.error}
          />
        )
      }}
      renderOption={(o, item, {inputValue}) => {
        const matches = match(o.key, inputValue)
        const parts = parse(o.key, matches)
        return (
          <div {...o} className={classes.itemContainer}>
            {parts.map((part, index) => (
              <span key={index} className={classes.item} style={{fontWeight: part.highlight ? 700 : 400}}>
                {part.text}
              </span>
            ))}
          </div>
        )
      }}
    />
  )
})

PrefixComplete.propTypes = {
  onChange: PropTypes.func.isRequired, // callback invoked with the selected option value prop
  options: PropTypes.array.isRequired, // an array of objects
  getOptionLabel: PropTypes.func, // optional transformer to convert the options items to label. If not provided, the options are casted to string
  isRequired: PropTypes.bool,
  value: PropTypes.any, // the value of the option that is currently selected
  isDisabled: PropTypes.bool,
  label: PropTypes.string,
  textFieldStyle: PropTypes.any,
  error: PropTypes.bool,
  placeholder: PropTypes.string,
  clearOnSelect: PropTypes.bool,
}

export default PrefixComplete
