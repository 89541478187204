import {useSelector} from 'react-redux'

/**
 * @typedef {Object} RichResidentObject
 * @property {string} id
 * @property {string} displayName
 * @property {Object} occupancy
 * @property {Object} occupancy.unit
 * @property {string} occupancy.unit.unitBuildingId
 * @property {string} occupancy.unit.number
 * // optional props
 * @property {string?} moveInDate
 * @property {string?} moveOutDate
 * @property {string?} phoneNumber
 * @property {string?} referenceId
 * @property {string?} secondaryReferenceId
 * @property {string?} statusOverride
 * @property {string?} occupancy.status
 * @property {string?} occupancy.leaseToDate
 * @property {string?} occupancy.leaseFromDate
 */

function useResidentOrChatParticipantLookup() {
  const residentsLookup = useSelector(state => state.Residents.residentsLookup)

  const chatParticipantsLookup = useSelector(state => state.ChatParticipants.chatParticipantsLookup)

  /**
   * @param {string} residentId
   * @return {RichResidentObject}
   */
  const getResidentOrChatParticipant = residentOrChatParticipantId => {
    const resident = residentsLookup[residentOrChatParticipantId]

    const chatParticipant = chatParticipantsLookup[residentOrChatParticipantId]

    if (resident) {
      return resident
    }

    if (chatParticipant) {
      const residentId = chatParticipant.participantId
      const resident = residentsLookup[residentId]

      return resident
    }

    return {
      displayName: '',
      occupancy: {
        unit: {
          unitBuildingId: '',
          number: '',
        },
      },
    }
  }

  return {
    getResidentOrChatParticipant,
  }
}

export default useResidentOrChatParticipantLookup
