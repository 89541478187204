import React, {useEffect, useState, Fragment} from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@mui/styles'
import {KYRLoaderScreen} from '../../assets/styles/layout'
import Loader from '../Loader'
import KYRDataField from './KYRDataField'
import {getInvoiceInfo} from '../../lib/queries'
import TimelineEvent from './TimelineEvent'
import moment from 'moment'
import {connect} from 'react-redux'

const INVOICE_STATUS_LABELS = {
  PENDING_ISSUED: 'Issued',
  PENDING_LATE: 'Late',
  PENDING_PARTIAL_LATE: 'Late',
  PENDING_PROCESSING: 'Processing',
  COMPLETED_PAID: 'Paid',
}

const useStyles = makeStyles(theme => ({
  loader: {
    ...KYRLoaderScreen,
  },
}))

function Payment(props) {
  const classes = useStyles()
  const [paymentInfo, setPaymentInfo] = useState({})
  const [isPaymentInfoLoading, setPaymentInfoLoading] = useState(true)

  useEffect(() => {
    getInvoiceInfo(props.paymentId)
      .then(res => {
        setPaymentInfo(res)
        setPaymentInfoLoading(false)
      })
      .catch(err => console.error(err))
  }, [])

  let paymentStatus = INVOICE_STATUS_LABELS[paymentInfo.status]
  if (paymentInfo.status === 'COMPLETED_PAID') {
    let payeeResidentName = props.residentsLookup[paymentInfo.payments.items[0].residentId].displayName
    let paymentPaidDate = moment(paymentInfo.statusHistory.find(res => res.status === 'COMPLETED_PAID').timestamp).format('MM/DD/YYYY')
    paymentStatus = paymentStatus + `, ${payeeResidentName} made a payment of $${paymentInfo.total} on ${paymentPaidDate}`
  }

  let paymentType = ''
  if (paymentInfo && paymentInfo.payments && paymentInfo.payments.items.length > 0) {
    paymentType = paymentInfo.payments.items[0].paymentMethod.type
  }
  return (
    <Fragment>
      {isPaymentInfoLoading ? (
        <Loader className={classes.loader} />
      ) : (
        <div>
          <KYRDataField title="Amount" data={`$${paymentInfo.total}`} />
          <KYRDataField title="Status" data={paymentStatus} />
          <KYRDataField title="Payment type" data={paymentType} />
          <TimelineEvent primaryRecordId={props.paymentId} filterResidentId={props.residentId} />
        </div>
      )}
    </Fragment>
  )
}

Payment.propTypes = {
  paymentId: PropTypes.string.isRequired,
}

const mapStateToProps = state => ({
  residentsLookup: state.Residents.residentsLookup,
})

export default connect(mapStateToProps)(Payment)
