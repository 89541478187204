import React from 'react'
import KYRInsightCard from './KYRInsightCard'
import {makeStyles} from '@mui/styles'
import {connect} from 'react-redux'
import DiscardChangesDialog from '../DiscardChangesDialog'
import {deleteNudge} from '../../actions/nudgesActions'
import {IGNORE_TITLE, IGNORE_MESSAGE, IGNORE_CTA} from '../RXOAssistant/AssistantConstants'
import RXRIcon from '../RXRIcon'

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: 24,
    paddingRight: 24,
    marginBottom: 12,
  },
}))

const KYRInsights = props => {
  const classes = useStyles()
  const [dialogOpen, setDialogOpen] = React.useState(false)
  const [ignoreInsightId, setIgnoreInsightId] = React.useState(null)

  const onDiscard = async () => {
    try {
      await props.dispatchDeleteNudge(ignoreInsightId)

      setDialogOpen(false)
      setIgnoreInsightId(null)
    } catch (er) {
      console.error(er)
      setDialogOpen(false)
      setIgnoreInsightId(null)
      window.alert(er.message)
    }
  }

  // filtering nudges(insights) based on current resident in KYR widget
  const filteredInsights =
    Object.values(props.nudgesLookup).length > 0 && Object.values(props.nudgesLookup).filter(nudge => nudge.residentId === props.residentId)

  if (filteredInsights.length > 0) {
    return (
      <div className={classes.root}>
        {filteredInsights.map((nudge, index) => {
          return (
            <KYRInsightCard
              key={index}
              insightData={nudge}
              ignoreInsight={ignoreInsightId => {
                setDialogOpen(true)
                setIgnoreInsightId(ignoreInsightId)
              }}
            />
          )
        })}

        <DiscardChangesDialog
          isOpen={dialogOpen}
          discardIcon={RXRIcon.NOT_ALLOWED}
          discardTitle={IGNORE_TITLE}
          discardMessage={IGNORE_MESSAGE}
          discardButton={IGNORE_CTA}
          onDiscard={onDiscard}
          onCancel={() => setDialogOpen(false)}
        />
      </div>
    )
  }
  return null
}

const mapStateToProps = state => ({
  nudgesLookup: state.Nudges.nudgesLookup,
})

const mapDispatchToProps = dispatch => ({
  dispatchDeleteNudge: buildingNudgeId => deleteNudge(dispatch, buildingNudgeId),
})

export default connect(mapStateToProps, mapDispatchToProps)(KYRInsights)
