import React, {useState, useEffect} from 'react'
import {Grid} from '@mui/material'
import SelectInput from '../../SelectInput'
import {getMonthSelectorOptions} from '../residentSentimentUtilities'
import MetricDataAPI from '../../../lib/MetricData/MetricDataAPI'
import {useSelector} from 'react-redux'
import DetailItemsList from './DetailItemsList'
import LoadingStateIconAndMessage from '../../LoadingStateIconAndMessage'
import EmptyStateIconAndMessage from '../../EmptyStateIconAndMessage'
import RXRIcon from '../../RXRIcon'
import {capitalizeFirstLetter} from '../../../Utils/StringFormatter'
import SlimMultiSelectInput from '../../SlimMultiSelectInput'
import {makeStyles} from '@mui/styles'
import {valueToFixed} from '../utils'
import {Spacing, Typography} from '../../../assets/styles'

const dateOptions = getMonthSelectorOptions()

const CATEGORY_SERVICE = 'service'
const CATEGORY_MAINTENANCE = 'maintenance'
const CATEGORY_AMENITY = 'amenity'
const ALL_CATEGORIES = [CATEGORY_SERVICE, CATEGORY_MAINTENANCE, CATEGORY_AMENITY]
const categoryOptions = ALL_CATEGORIES.map(c => ({value: c, label: capitalizeFirstLetter(c)}))
export const mapCategoryToFeedbackType = {
  [CATEGORY_SERVICE]: 'VENDOR_APPOINTMENT',
  [CATEGORY_MAINTENANCE]: 'MAINTENANCE_REQUEST',
  [CATEGORY_AMENITY]: 'AMENITY_RESERVATION',
}
export const mapFeedbackTypeToCategory = Object.entries(mapCategoryToFeedbackType).reduce((acc, [category, feedbackType]) => {
  acc[feedbackType] = category
  return acc
}, {})

const SORT_BY_NEWEST = 'newest'
const SORT_BY_OLDEST = 'oldest'
const SORT_BY_HIGHEST = 'highest score'
const sortOptions = [SORT_BY_NEWEST, SORT_BY_OLDEST, SORT_BY_HIGHEST].map(s => ({value: s, label: `Sort by ${s}`}))

// TODO: this is going to depend on the response payload
function getSortFunctionFromSortType(sortType) {
  switch (sortType) {
    case SORT_BY_NEWEST:
      return (a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt)
      }
    case SORT_BY_OLDEST:
      return (a, b) => {
        return new Date(a.createdAt) - new Date(b.createdAt)
      }

    case SORT_BY_HIGHEST:
      return (a, b) => {
        return b.value - a.value
      }
  }
}

function CategoryRatingDetails(props) {
  const isMobile = useSelector(state => state.App.isMobile)
  const classes = useStyles({isMobile})
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)
  /** @type {FeedbackDetailsPayload} data */
  const [data, setData] = useState({})
  const [asOfDate, setAsOfDate] = useState(dateOptions[0].value)
  const [categories, setCategories] = useState(ALL_CATEGORIES)
  const [sortBy, setSortBy] = useState(SORT_BY_NEWEST)
  const activeBuildingId = useSelector(state => state.Buildings.activeBuildingId)
  const buildingsLookup = useSelector(state => state.Buildings.buildingsLookup)
  const activeBuildingYardiId = buildingsLookup[activeBuildingId]?.yardiId
  const [filteredItems, setFilteredItems] = useState([])

  // Convert filtered items into an aggregate count called filteredItemsCount and
  // an average score which is an average of the rating for each filteredItem
  const filteredItemsCount = filteredItems.length
  const totalScore = filteredItems.reduce((acc, item) => {
    return acc + item.value
  }, 0)
  const averageScore = filteredItemsCount > 0 ? totalScore / filteredItemsCount : 'N/A'

  useEffect(() => {
    setIsLoading(true)
    MetricDataAPI.fetchFeedbackCategoryDetails(activeBuildingYardiId, asOfDate)
      .then(r => {
        setData(r)
      })
      .catch(setError)
      .finally(() => {
        setIsLoading(false)
      })
  }, [activeBuildingId, asOfDate])

  useEffect(() => {
    const newFilteredItems = (data.items || []).filter(d => {
      return !d.type || categories.map(category => mapCategoryToFeedbackType[category]).includes(d.type)
    })
    newFilteredItems.sort(getSortFunctionFromSortType(sortBy))
    setFilteredItems(newFilteredItems)
  }, [data, categories, sortBy])

  return (
    <div className={classes.categoryRatingDetailsContainer}>
      <Grid container spacing={3}>
        <Grid item xs={7} sm={12} md={4}>
          <SelectInput onChange={setAsOfDate} value={asOfDate} label={''} options={dateOptions} />
        </Grid>
        <Grid item xs={7} md={4}>
          <SlimMultiSelectInput onChange={setCategories} value={categories} label={''} options={categoryOptions} />
        </Grid>

        <Grid item xs={7} md={4}>
          <SelectInput onChange={setSortBy} value={sortBy} label={''} options={sortOptions} />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          {isLoading ? (
            <LoadingStateIconAndMessage message={'Loading details'} />
          ) : error ? (
            <EmptyStateIconAndMessage message={error} icon={RXRIcon.ERROR} />
          ) : !data.items || data.items === 0 ? (
            <EmptyStateIconAndMessage message={'No details to show'} icon={RXRIcon.ELLIPSIS} />
          ) : (
            <>
              <div className={classes.aggregatedMetrics}>
                {filteredItemsCount} Comments, Average score: {valueToFixed(averageScore)}
              </div>
              <DetailItemsList items={filteredItems} noDescriptionText={'Resident has not left a comment.'} />
            </>
          )}
        </Grid>
      </Grid>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  aggregatedMetrics: {
    fontSize: Typography.fontSizeMediumLarge,
    fontWeight: Typography.heavyFontWeight,
    paddingTop: Spacing.spaceExtraSmall,
    paddingBottom: Spacing.spaceSmall,
  },
  categoryRatingDetailsContainer: props => ({
    width: props.isMobile ? '180%' : undefined,
  }),
}))

export default CategoryRatingDetails
