import React from 'react'
import {makeStyles} from '@mui/styles'
import {useSelector} from 'react-redux'
import Header from './Header'

const useStyles = makeStyles(theme => ({
  KYRCardContainer: props => ({
    minHeight: '669px',
    minWidth: props.isMobile ? '370px' : '400px',
    maxWidth: props.isMobile ? '370px' : '400px',
    backgroundColor: '#fafafc',
    position: 'absolute',
    borderRadius: '10px',
    filter: 'drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.15))',
    bottom: props.isMobile ? 'auto' : '11.15%',
    top: props.isMobile ? '10px' : 'auto',
    right: props.isMobile ? '0.60%' : '1.89%',
  }),
}))

function KYRGenericCardComponent(props) {
  const isMobile = useSelector(state => state.App.isMobile)
  const classes = useStyles({isMobile})

  return (
    <div className={classes.KYRCardContainer}>
      <Header residentId={props.residentId} />
      {props.children}
    </div>
  )
}

export default KYRGenericCardComponent
