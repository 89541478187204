import React, {useEffect, useState} from 'react'
import {makeStyles} from '@mui/styles'
import {spaceMedium, spaceSmall} from '../../../assets/styles/spacing'
import {Colors} from '../../../assets/styles'
import PropTypes from 'prop-types'
import FileHelper from '../../../lib/FileHelper'
import SimpleSpinner from '../../SimpleSpinner'
import useDoOnceTimer from '../../hooks/useDoOnceTimer'

const hardcodedS3Keys = [
  'bowl.png',
  'box.png',
  'box_open.png',
  'car.png',
  'cutlery.png',
  'dog.png',
  'flower.png',
  'gift.png',
  'hand.png',
  'paw.png',
  'runner.png',
  'swimmer.png',
]

function DefaultPartnerLogoSelector(props) {
  const classes = useStyles()
  const [ready, setReady] = useState(false)
  const [images, setImages] = useState([...hardcodedS3Keys])
  const {setTimer} = useDoOnceTimer()

  useEffect(() => {
    Promise.all(
      hardcodedS3Keys.map(fileName =>
        FileHelper.Instance().getFileUrlFromS3Object({
          key: 'default_partner_logos/' + fileName,
        }),
      ),
    ).then(urls => {
      setImages(urls)
      setTimer('show-images-timer', () => setReady(true), 200)
    })
  }, [])

  return (
    <div className={[classes.container, props.className].join(' ')}>
      {!ready && (
        <div className={classes.loadingContainer}>
          <SimpleSpinner size={SimpleSpinner.SIZE_LARGE} />
        </div>
      )}
      {images.map((url, index) => (
        <div
          key={index}
          className={classes.imageBox}
          onClick={() =>
            // we reconstruct the S3 object here because the parent component needs the full object
            props.onSelectLogo({
              key: 'default_partner_logos/' + hardcodedS3Keys[index],
              bucket: FileHelper.Instance().config.aws_user_files_s3_bucket,
              region: FileHelper.Instance().config.aws_project_region,
              fileUrl: url,
            })
          }
          style={{
            backgroundImage: `url(${url})`,
          }}
        />
      ))}
    </div>
  )
}

const imageSize = 80

const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
    overflow: 'hidden',
    backgroundColor: Colors.rxrMediumLightGreyColor,
    // the 4 and 3 are because we have 12 default logos
    width: imageSize * 4 + 5, // 1 + column count
    height: imageSize * 3 + 4, // 1 + row count
    border: `1px solid ${Colors.rxrBlueColor}`,
    borderRadius: 8,
    display: 'flex',
    flexWrap: 'wrap',
    gap: 1,
  },
  loadingContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.rxrWhiteColor,
    zIndex: 2,
  },
  imageBox: {
    cursor: 'pointer',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    height: imageSize,
    width: imageSize,
    flexShrink: 0,
    opacity: 1,
    transition: `opacity 0.3s`,

    '&:hover': {
      opacity: 0.8,
    },
  },
}))

DefaultPartnerLogoSelector.propTypes = {
  onSelectLogo: PropTypes.func.isRequired,
  className: PropTypes.string,
}

export default DefaultPartnerLogoSelector
