import React, {useContext, useMemo, Fragment} from 'react'
import SortableTable, {ColumnConfig} from '../../SortableTable'
import HighlightText from '../../HighlightText'
import {useHistory} from 'react-router-dom'
import Routes from '../../../constants/RouteConstants'
import moment from 'moment'
import {PromotionsContext} from './PromotionsContext'
import {VENDOR_SERVICE_TYPE_LABELS} from './PromotionConstants'
import RXRIcon from '../../RXRIcon'
import LoadingStateIconAndMessage from '../../LoadingStateIconAndMessage'
import EmptyStateIconAndMessage from '../../EmptyStateIconAndMessage'
import {TAB_ACTIVE, TAB_INACTIVE} from './PromotionConstants'

function PromotionsTable({filterTerm, currentTab}) {
  const history = useHistory()
  const {promotionsLookup, isLoading} = useContext(PromotionsContext)

  const columns = [
    new ColumnConfig({
      title: 'Promotion Title',
      renderPrimitive: g => (g.id ? g.title : ''),
      render: (g, col) => (g.id ? <HighlightText search={filterTerm}>{col.renderPrimitive(g)}</HighlightText> : null),
      comparator: ColumnConfig.simpleComparatorGenerator('title'),
    }),
    new ColumnConfig({
      title: 'Vendor Service Type',
      renderPrimitive: g => (g.id ? (g.vendorServiceTypes || []).map(k => VENDOR_SERVICE_TYPE_LABELS[k]).join(', ') : ''),
      render: (g, col) => (g.id ? <HighlightText search={filterTerm}>{col.renderPrimitive(g)}</HighlightText> : null),
      comparator: ColumnConfig.simpleComparatorGenerator('vendorServiceTypes'),
    }),
    new ColumnConfig({
      title: 'Discount Type',
      renderPrimitive: g => (g.id ? g.discountType : ''),
      render: (g, col) => (g.id ? <HighlightText search={filterTerm}>{col.renderPrimitive(g)}</HighlightText> : null),
      comparator: ColumnConfig.simpleComparatorGenerator('discountType'),
    }),
    new ColumnConfig({
      title: 'Discount Amount',
      renderPrimitive: g => (g.id ? (g.discountType === 'Fixed' ? `$${g.amount}` : `${g.amount}%`) : ''),
      render: (g, col) => (g.id ? <HighlightText search={filterTerm}>{col.renderPrimitive(g)}</HighlightText> : null),
      comparator: ColumnConfig.simpleComparatorGenerator('amount'),
    }),
    new ColumnConfig({
      title: 'Start Date',
      renderPrimitive: e => moment(e.startedAt).format('ll'),
      render: (g, col) => (g.id ? <HighlightText search={filterTerm}>{col.renderPrimitive(g)}</HighlightText> : null),
      comparator: (a, b) => moment(a.startedAt) - moment(b.startedAt),
      isDefaultSort: true,
    }),
    new ColumnConfig({
      title: 'End Date',
      renderPrimitive: e => moment(e.expiredAt).format('ll'),
      render: (g, col) => (g.id ? <HighlightText search={filterTerm}>{col.renderPrimitive(g)}</HighlightText> : null),
      comparator: (a, b) => moment(a.expiredAt) - moment(b.expiredAt),
    }),
  ]

  const filteredPromotions = useMemo(() => {
    const filterLowerCase = filterTerm.toLowerCase()
    return Object.values(promotionsLookup).filter(promotion => {
      return (
        !filterLowerCase ||
        promotion.title.toLowerCase().includes(filterLowerCase) ||
        promotion.vendorServiceTypes?.toString().toLowerCase().includes(filterLowerCase)
      )
    })
  }, [promotionsLookup, filterTerm])

  const currentDate = moment().format('YYYY-MM-DD')
  // now we filter a second time based on which tab we're on.
  const promotionsOnTab = useMemo(() => {
    return filteredPromotions.filter(promotion => {
      const promotionExpiryDate = moment(promotion.expiredAt).format('YYYY-MM-DD')
      switch (currentTab) {
        case TAB_ACTIVE:
          return moment(currentDate).isSameOrBefore(promotionExpiryDate)

        case TAB_INACTIVE:
          return moment(currentDate).isAfter(promotionExpiryDate)
      }
    })
  }, [filteredPromotions, currentTab, currentDate])

  return (
    <Fragment>
      {isLoading ? (
        <LoadingStateIconAndMessage message={'Loading promotions...'} />
      ) : filteredPromotions.length === 0 ? (
        <EmptyStateIconAndMessage message={'There are no promotions to view'} icon={RXRIcon.SETTINGS} />
      ) : (
        <SortableTable
          data={promotionsOnTab}
          columns={columns}
          downloadFileName={`Promotions`}
          onClickRow={r => history.push(Routes.constructPath(Routes.SETTINGS_PROMOTIONS_VIEW_SINGLE, {promotionId: r.id}))}
        />
      )}
    </Fragment>
  )
}

export default PromotionsTable
