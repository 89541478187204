import SearchResident from '../../SearchResident'
import React from 'react'
import {withStyles} from '@mui/styles'
import {Colors, TextFieldBorders} from '../../../assets/styles'
import {connect} from 'react-redux'
import {navigateToScreen} from '../../../actions/kyrWidgetActions'
import {SCREEN_RESIDENT_PROFILE} from '../KYRConstants'
import AbstractKYRScreen from './AbstractKYRScreen'
import {compose} from 'redux'

const styles = theme => ({
  floatingTextField: {
    '& .MuiOutlinedInput-root': {
      color: Colors.rxrMidBlue,
      borderRadius: '5px',
      ...Colors.backgroundWhite,
      '& fieldset': {
        ...TextFieldBorders.textfieldInputBorderBlue,
      },
      '&:hover fieldset': {
        ...TextFieldBorders.textfieldInputBorderBlue,
      },
      '&.Mui-focused fieldset': {
        ...TextFieldBorders.textfieldInputBorderBlue,
      },
    },
  },
})

class Search extends AbstractKYRScreen {
  handleTenantAndUnit(resident) {
    this.props.dispatchNavigateToResidentProfile(resident.id)
  }

  render() {
    return (
      <SearchResident
        value={null}
        labelText={''}
        placeholderText={'Search for a resident, unit...'}
        textFieldStyle={this.props.classes.floatingTextField}
        onSelectResident={this.handleTenantAndUnit.bind(this)}
      />
    )
  }
}

const mapDispatchToProps = dispatch => ({
  dispatchNavigateToResidentProfile: residentId => navigateToScreen(dispatch, SCREEN_RESIDENT_PROFILE, {residentId: residentId}),
})

export default compose(withStyles(styles), connect(undefined, mapDispatchToProps))(Search)
