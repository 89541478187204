import React, {useState} from 'react'
import SelectUnitTags from './SelectUnitTags'
import {selectResidentsByUnit} from '../../Utils/residentUtils'
import {useSelector} from 'react-redux'
import {pluralizeWord} from '../../Utils/StringFormatter'
import {makeStyles} from '@mui/styles'
import {fontSizeSmall} from '../../assets/styles/typography'
import {spaceExtraExtraSmall, spaceSmall} from '../../assets/styles/spacing'
import {rxrBlueColor, rxrRedColor} from '../../assets/styles/color'
import PropTypes from 'prop-types'

const useStyles = makeStyles(theme => ({
  selectedText: props => ({
    fontSize: fontSizeSmall,
    marginTop: spaceExtraExtraSmall,
    marginLeft: spaceSmall,
    color: props.hasError ? rxrRedColor : rxrBlueColor,
  }),
}))

function SelectResidentsByUnitTags(props) {
  const classes = useStyles(props)
  const [residentIds, setResidentIds] = useState([])
  const [tagIds, setTagIds] = useState([])
  const residentsLookup = useSelector(state => state.Residents.residentsLookup)

  const handleChangeTags = tags => {
    setTagIds(tags.map(t => t.id))

    const allUnitIds = tags.reduce((agr, t) => agr.concat(t.unitIds), [])
    const selectedResidentIds = allUnitIds
      .map(uId => selectResidentsByUnit(residentsLookup, uId).map(r => r.id))
      .reduce((agr, rIds) => agr.concat(rIds), [])

    // but we only want the UNIQUE resident ids (in case 1 unit is accounted for in multiple tags)
    const newResidentIds = [...new Set(selectedResidentIds)]
    setResidentIds(newResidentIds)
    props.onChange(newResidentIds, tags.map(t => t.label).join(', '))
  }

  return (
    <div>
      <SelectUnitTags
        selectedTagIds={tagIds}
        onChange={handleChangeTags}
        isDisabled={props.isDisabled}
        hasError={props.hasError}
        label={props.label || 'Select residents'}
        isRequired={props.isRequired}
      />
      <div className={classes.selectedText}>Selected: {pluralizeWord('resident', residentIds.length, true)}</div>
    </div>
  )
}

SelectResidentsByUnitTags.propTypes = {
  isDisabled: PropTypes.bool,
  hasError: PropTypes.bool,
  onChange: PropTypes.func,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
}

export default SelectResidentsByUnitTags
