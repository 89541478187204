import React, {useState} from 'react'
import RXRButton from '../../RXRButton'
import useSharedStyles from '../useSharedStyles'
import PropTypes from 'prop-types'
import {makeStyles} from '@mui/styles'
import {spaceSmall} from '../../../assets/styles/spacing'

function RejectOrAcceptCTAs(props) {
  const sharedClasses = useSharedStyles()
  const classes = useStyles()
  const [isHandlingRejection, setIsHandlingRejection] = useState(false)
  const [isHandlingAcceptance, setIsHandlingAcceptance] = useState(false)

  const handleClickReject = async () => {
    setIsHandlingRejection(true)
    try {
      await props.onReject()
    } catch (err) {
      window.alert(err.message)
      console.error(err)
    }
    setIsHandlingRejection(false)
  }

  const handleClickAccept = async () => {
    setIsHandlingAcceptance(true)
    try {
      await props.onAccept()
    } catch (err) {
      window.alert(err.message)
      console.error(err)
    }
    setIsHandlingAcceptance(false)
  }

  return (
    <React.Fragment>
      <div className={sharedClasses.multipleButtonsContainer}>
        <RXRButton
          onClick={handleClickReject}
          disabled={props.isRejected}
          className={sharedClasses.oneOfTwoCTA}
          isLoading={isHandlingRejection}
        >
          Reject
        </RXRButton>
        <RXRButton
          onClick={handleClickAccept}
          className={sharedClasses.oneOfTwoCTA}
          isLoading={isHandlingAcceptance}
          disabled={props.isDisabled}
        >
          Accept
        </RXRButton>
      </div>
    </React.Fragment>
  )
}

const useStyles = makeStyles(theme => ({}))

RejectOrAcceptCTAs.propTypes = {
  onReject: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
  isRejected: PropTypes.bool,
  isDisabled: PropTypes.bool,
}

export default RejectOrAcceptCTAs
