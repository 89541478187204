import React from 'react'
import PropTypes from 'prop-types'
import SortableTable, {ColumnConfig} from '../SortableTable'
import moment from 'moment'
import Constants from './DeliveryConstants'
import Routes from '../../constants/RouteConstants'
import {withRouter} from 'react-router-dom'
import HighlightText from '../HighlightText'
import useResidentLookup from '../hooks/useResidentLookup'
import {makeStyles} from '@mui/styles'
import RXRIcon from '../RXRIcon'

const useStyles = makeStyles(theme => ({
  lineThrough: {
    textDecoration: 'line-through',
  },
}))

const NULL_MUTATE = value => (value ? value.toLowerCase() : '0')

function DeliveredPackagesTable(props) {
  const classes = useStyles()
  const handleClickDelivery = delivery => {
    props.history.push(Routes.constructPath(Routes.DELIVERIES_IN_VIEW_SINGLE, {deliveryId: delivery.id}))
  }
  const {getResident} = useResidentLookup()
  const residentNameComparator = ColumnConfig.residentNameComparatorGenerator(getResident)

  const columns = [
    new ColumnConfig({
      title: 'Unit',
      renderPrimitive: g => getResident(g.residentId).occupancy.unit.number,
      render: (g, col) => (
        <HighlightText className={g.isDeleted ? classes.lineThrough : null} search={props.filterTerm}>
          {col.renderPrimitive(g)}
        </HighlightText>
      ),
      comparator: ColumnConfig.residentUnitComparatorGenerator(getResident),
    }),

    new ColumnConfig({
      title: 'Resident',
      renderPrimitive: g => getResident(g.residentId).displayName,
      render: (g, col) => (
        <HighlightText className={g.isDeleted ? classes.lineThrough : null} search={props.filterTerm}>
          {col.renderPrimitive(g)}
        </HighlightText>
      ),
      comparator: residentNameComparator,
    }),

    new ColumnConfig({
      title: 'Type',
      renderPrimitive: g => {
        let matching = Constants.PACKAGE_OPTIONS.find(o => o.value === g.packageType)
        return matching ? matching.label : 'Unknown'
      },
      render: (g, col) => (
        <HighlightText className={g.isDeleted ? classes.lineThrough : null} search={props.filterTerm}>
          {col.renderPrimitive(g)}
        </HighlightText>
      ),
      comparator: ColumnConfig.simpleComparatorGenerator('packageType'),
    }),

    new ColumnConfig({
      title: 'Carrier',
      renderPrimitive: g => {
        let matching = Constants.CARRIER_OPTIONS.find(o => o.value === g.carrier)
        return matching ? matching.label : '--'
      },
      render: (g, col) => (
        <HighlightText className={g.isDeleted ? classes.lineThrough : null} search={props.filterTerm}>
          {col.renderPrimitive(g)}
        </HighlightText>
      ),
      comparator: ColumnConfig.simpleComparatorGenerator('carrier', undefined, NULL_MUTATE),
    }),

    new ColumnConfig({
      title: 'Tracking number',
      renderPrimitive: g => g.trackingNumber || '--',
      render: (g, col) => (
        <HighlightText className={g.isDeleted ? classes.lineThrough : null} search={props.filterTerm}>
          {col.renderPrimitive(g)}
        </HighlightText>
      ),
      comparator: ColumnConfig.simpleComparatorGenerator('trackingNumber', undefined, NULL_MUTATE),
    }),

    new ColumnConfig({
      title: 'Delivered on',
      renderPrimitive: g => (g.receivedAt ? moment(g.receivedAt).format(Constants.PACKAGE_TABLE_DATETIME_FORMAT) : '--'),
      render: (g, col) => <span className={g.isDeleted ? classes.lineThrough : null}>{col.renderPrimitive(g)}</span>,
      comparator: (a, b) => {
        if (a.receivedAt && b.receivedAt) {
          return a.receivedAt < b.receivedAt ? -1 : a.receivedAt > b.receivedAt ? 1 : residentNameComparator(a, b)
        } else {
          // if only one is marked as received, we bubble the not-received ones to the top
          return a.receivedAt ? 1 : -1
        }
      },
      isDefaultSort: true,
      sortingDirection: 'desc',
    }),

    new ColumnConfig({
      title: 'Deleted on',
      renderPrimitive: g => {
        return g.updatedAt && g.isDeleted ? moment(g.updatedAt).format(Constants.PACKAGE_TABLE_DATETIME_FORMAT) : '--'
      },
      render: (g, col) => <span>{col.renderPrimitive(g)}</span>,
      comparator: (a, b) => {
        return a.updatedAt < b.updatedAt ? -1 : a.updatedAt > b.updatedAt ? 1 : residentNameComparator(a, b)
      },
    }),

    new ColumnConfig({
      title: 'PTE',
      renderPrimitive: g => (g.PTE ? 'Yes' : 'No'),
      render: g => (g.PTE ? <RXRIcon icon={RXRIcon.CHECK} /> : ''),
      comparator: (a, b) => b.PTE - a.PTE,
    }),
  ]
  return (
    <SortableTable
      downloadFileName={`Incoming Packages__Delivered`}
      data={props.deliveredPackages}
      columns={columns}
      onClickRow={handleClickDelivery}
    />
  )
}

DeliveredPackagesTable.propTypes = {
  deliveredPackages: PropTypes.array.isRequired,
}

export default withRouter(DeliveredPackagesTable)
