import React, {useState} from 'react'
import {makeStyles} from '@mui/styles'
import {useLocation, matchPath, useHistory} from 'react-router-dom'
import Routes from '../../constants/RouteConstants'
import {useSelector} from 'react-redux'
import PageHeader from '../PageHeader'
import {Grid} from '@mui/material'
import {AntTab, AntTabs} from '../AntTabs'
import ClearableInput from '../ClearableInput'
import {BTN_ADD, BTN_ADD_MOBILE, BUILDING_NUDGE_TYPE_LABELS, buildingNudgeComparator} from './AssistantConstants'

import InsightCardComponent from './InsightCardComponent'
import {EVENT_TYPE_TO_FILTER} from './AssistantConstants'

import EmptyStateIconAndMessage from '../EmptyStateIconAndMessage'
import LoggedActionTable from './LoggedActionTable'
import InsightsFilter, {ALL_FILTERS as INSIGHT_FILTERS} from './InsightsFilter'
import ViewLogActionForm from './ViewLogActionForm'
import RXRButton from '../RXRButton'
import {PageContainer} from '../../assets/styles/layout'
import SidePanel from '../SidePanel'
import useResidentLookup from '../hooks/useResidentLookup'
import RXRIcon from '../RXRIcon'
import CreateNewBuildingActionForm from './CreateNewBuildingActionForm'
import {rxrSidePanelBackgroundCoor} from '../../assets/styles/color'

const useStyles = makeStyles(theme => ({
  placeSearch: props => ({
    width: props.isMobile ? '100%' : '418px',
    borderBottom: '0px',
  }),
  selectDeliveryCloseIcon: {
    textAlign: 'right',
  },
  tableContainer: {
    height: '100%',
  },
  insightsContainer: {
    marginTop: '8px',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(2),
  },
  filterContainer: {
    position: 'absolute',
    right: 17,
    bottom: 7,
  },
  buttonIconOutline: {
    '&:focus': {
      outline: 'none',
    },
    width: 'auto',
  },
}))

const TAB_NEW_INSIGHTS = 0
const TAB_LOGGED_ACTIONS = 1

function Assistant() {
  const isMobile = useSelector(state => state.App.isMobile)
  const classes = useStyles({isMobile})
  const [tab, setTab] = useState(TAB_NEW_INSIGHTS)
  const [filterTerm, setFilterTerm] = useState('')
  const [insightsFilterState, setInsightsFilterState] = useState(INSIGHT_FILTERS)
  const {getResident} = useResidentLookup()
  const loggedNudgesActionsLookup = useSelector(state => state.Nudges.loggedNudgesActionsLookup)
  const nudgesLookup = useSelector(state => state.Nudges.nudgesLookup)
  const history = useHistory()

  const formattedTerm = filterTerm.toLowerCase()

  let visibleTableComponent
  let filteredActions = []
  if (tab === TAB_LOGGED_ACTIONS) {
    if (Object.values(loggedNudgesActionsLookup).length === 0) {
      visibleTableComponent = (
        <EmptyStateIconAndMessage
          message={'There are currently no logged actions. Select “Log new action” to log an insight.'}
          icon={RXRIcon.RXO_ASSISTANT}
        />
      )
    } else {
      filteredActions = Object.values(loggedNudgesActionsLookup).filter(v => {
        let resident = getResident(v.residentId)
        return (
          resident.displayName.toLowerCase().includes(formattedTerm) ||
          resident.occupancy.unit.number.toLowerCase().includes(formattedTerm) ||
          BUILDING_NUDGE_TYPE_LABELS[v.reason].toLowerCase().includes(formattedTerm)
        )
      })
      visibleTableComponent = (
        <div className={classes.tableContainer}>
          <LoggedActionTable loggedActions={filteredActions} filterTerm={filterTerm} />
        </div>
      )
    }
  } else if (tab === TAB_NEW_INSIGHTS) {
    // this is a special case in the InsightFilter.js filter types
    const isFilteringHighPriority = insightsFilterState.includes('HIGH_PRIORITY')
    const filteredInsights = Object.values(nudgesLookup)
      .filter(insight => {
        return insightsFilterState.includes(EVENT_TYPE_TO_FILTER[insight.type]) || (isFilteringHighPriority && insight.isHighPriority)
      })
      .sort(buildingNudgeComparator)

    if (filteredInsights.length > 0) {
      visibleTableComponent = (
        <div className={classes.insightsContainer}>
          {Object.values(filteredInsights).map((nudge, index) => {
            return <InsightCardComponent key={index} insightData={nudge} />
          })}
        </div>
      )
    } else {
      visibleTableComponent = (
        <EmptyStateIconAndMessage
          message={'There are currently no new insights. Select “Log new action” to log an insight.'}
          icon={RXRIcon.RXO_ASSISTANT}
        />
      )
    }
  }

  const currentLocation = useLocation()
  let isCreateMode = false
  let logNewActionMatch = matchPath(currentLocation.pathname, {path: Routes.RXO_ASSISTANT_LOG_NEW_ACTION})
  let logCardActionMatch = matchPath(currentLocation.pathname, {path: Routes.RXO_ASSISTANT_LOG_ACTION_FROM_NUDGE})
  let viewLogCardActionMatch = matchPath(currentLocation.pathname, {path: Routes.RXO_ASSISTANT_VIEW_LOG_ACTION})

  let loggedActionId
  let buildingNudgeId

  if (logNewActionMatch && logNewActionMatch.isExact) {
    isCreateMode = true
  } else if (logCardActionMatch && logCardActionMatch.isExact) {
    buildingNudgeId = logCardActionMatch.params.buildingNudgeId
  } else if (viewLogCardActionMatch && viewLogCardActionMatch.isExact) {
    loggedActionId = viewLogCardActionMatch.params.loggedActionId
  }

  const hideSlidingPanel = () => {
    history.push(Routes.RXO_ASSISTANT)
  }

  return (
    <div
      style={{
        ...PageContainer,
        height: '100%',
        backgroundColor: rxrSidePanelBackgroundCoor,
      }}
    >
      <PageHeader
        title={'RXO Assistant'}
        rightControlComponent={
          <RXRButton
            type={isMobile ? RXRButton.TYPE_DEFAULT : RXRButton.TYPE_LARGE}
            onClick={() => history.push(Routes.RXO_ASSISTANT_LOG_NEW_ACTION)}
          >
            {isMobile ? BTN_ADD_MOBILE : BTN_ADD}
          </RXRButton>
        }
      >
        <Grid container spacing={3}>
          <Grid item lg={6} md={6} xs={12}>
            <div className={classes.placeSearch}>
              <ClearableInput value={filterTerm} onChange={setFilterTerm} placeholder={'Search by resident, guest, or unit number...'} />
            </div>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item lg={6} md={6}>
            <AntTabs value={tab} onChange={(e, t) => setTab(t)}>
              <AntTab label="New insights" />
              <AntTab label="Logged actions" />
            </AntTabs>
          </Grid>
        </Grid>
        <div className={classes.filterContainer}>
          {tab === TAB_NEW_INSIGHTS ? <InsightsFilter onChange={setInsightsFilterState} selectedFilters={insightsFilterState} /> : null}
        </div>
      </PageHeader>
      <div style={{height: '100%'}}>{visibleTableComponent}</div>

      <SidePanel
        isOpen={!!(isCreateMode || buildingNudgeId || loggedActionId)}
        onClose={hideSlidingPanel}
        title={loggedActionId ? 'Logged action details' : 'Log an action'}
      >
        {loggedActionId ? (
          <ViewLogActionForm actionId={loggedActionId} />
        ) : (
          <CreateNewBuildingActionForm buildingNudgeId={buildingNudgeId} isCreateMode={isCreateMode} onCancel={hideSlidingPanel} />
        )}
      </SidePanel>
    </div>
  )
}

export default Assistant
