import React, {useState} from 'react'
import {rxrMidBlue} from '../../../../assets/styles/color'
import SubHeader from '../../SubHeader'
import KYRGenericCardComponent from '../../KYRGenericCardComponent'
import {KYRScreenLayout} from '../../../../assets/styles/layout'
import Cleaning from '../../VendorAppointments/Cleaning'
import DogWalking from '../../VendorAppointments/DogWalking'
import Massage from '../../VendorAppointments/Massage'
import PersonalTraining from '../../VendorAppointments/PersonalTraining'
import {withStyles} from '@mui/styles'
import {SCREEN_TIMELINE} from '../../KYRConstants'
import {navigateToScreen} from '../../../../actions/kyrWidgetActions'
import {connect} from 'react-redux'
import {compose} from 'redux'
import KYRWidgetBackButton from '../../KYRWidgetBackButton'
import {
  VENDOR_APPOINTMENT_TYPE_CLEANING,
  VENDOR_APPOINTMENT_TYPE_DOG_WALKING,
  VENDOR_APPOINTMENT_TYPE_MASSAGE,
  VENDOR_APPOINTMENT_TYPE_PERSONAL_TRAINING,
} from '../../KYRConstants'

const styles = theme => ({
  headerContainer: {
    width: '400px',
    backgroundColor: rxrMidBlue,
    minHeight: '154px',
    borderRadius: '10px 10px 0px 0px',
    boxShadow: '0px 1px 18px rgba(151, 151, 151, 0.2)',
    justifyContent: 'space-around',
  },
  scrollContainer: {
    ...KYRScreenLayout,
    marginLeft: '40px',
  },
})

function KYRVendorAppointmentComponent(props) {
  const [title, setTitle] = useState('')

  return (
    <KYRGenericCardComponent residentId={props.navParams.residentId}>
      <SubHeader title={title} LeftIconComponent={KYRWidgetBackButton} />
      <div className={props.classes.scrollContainer}>
        {props.type === VENDOR_APPOINTMENT_TYPE_CLEANING ? (
          <Cleaning vendorAppointmentId={props.navParams.primaryRecordId} setTitle={title => setTitle(title)} />
        ) : null}
        {props.type === VENDOR_APPOINTMENT_TYPE_DOG_WALKING ? (
          <DogWalking vendorAppointmentId={props.navParams.primaryRecordId} setTitle={title => setTitle(title)} />
        ) : null}
        {props.type === VENDOR_APPOINTMENT_TYPE_MASSAGE ? (
          <Massage vendorAppointmentId={props.navParams.primaryRecordId} setTitle={title => setTitle(title)} />
        ) : null}
        {props.type === VENDOR_APPOINTMENT_TYPE_PERSONAL_TRAINING ? (
          <Cleaning vendorAppointmentId={props.navParams.primaryRecordId} setTitle={title => setTitle(title)} />
        ) : null}
      </div>
    </KYRGenericCardComponent>
  )
}

const mapDispatchToProps = dispatch => ({
  dispatchNavigateToTimeline: residentId => navigateToScreen(dispatch, SCREEN_TIMELINE, {residentId: residentId}),
})

export default compose(withStyles(styles), connect(undefined, mapDispatchToProps))(KYRVendorAppointmentComponent)
