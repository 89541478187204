import React, {useEffect, useState, Fragment} from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {Grid} from '@mui/material'
import {makeStyles} from '@mui/styles'
import {getServiceRequest} from '../../lib/queries'
import {rxrBlueColor, rxrSecondaryBlueGrayColor} from '../../assets/styles/color'
import {KYRLoaderScreen} from '../../assets/styles/layout'
import Loader from '../Loader'
import TimelineEvent from './TimelineEvent'
import FileHelper from '../../lib/FileHelper'

const useStyles = makeStyles(theme => ({
  infoTitle: {
    fontSize: 12,
    lineHeight: '18px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    color: rxrSecondaryBlueGrayColor,
  },
  infoData: {
    fontSize: 16,
    lineHeight: '19px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    color: rxrBlueColor,
    paddingTop: '4px',
  },
  photoStyle: {
    height: 252,
    width: 336,
    borderRadius: '5px',
  },
  loader: {
    ...KYRLoaderScreen,
  },
}))

function MaintenanceDetail(props) {
  const classes = useStyles()
  const [maintenanceInfo, setMaintenanceInfo] = useState({})
  const [maintenancePhotos, setMaintenancePhotos] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const setPhotoUrls = attachments => {
    return Promise.all(
      attachments.map(async image => {
        if (image.key && !image.imageUrl) {
          image.imageUrl = await FileHelper.Instance().getFileUrlFromS3Object(image)
        }
      }),
    )
  }

  useEffect(() => {
    getServiceRequest(props.serviceRequestId)
      .then(res => {
        if (res.attachments && res.attachments.length > 0) {
          setPhotoUrls(res.attachments).then(a => setMaintenancePhotos(a))
        }
        setMaintenanceInfo(res)
        setIsLoading(false)
      })
      .catch(err => console.error(err))
  }, [])

  /**
   * Common function for rendeing data point.
   * @param {*} title
   * @param {*} data
   * @returns {string}
   */
  const renderInfo = (title, data) => (
    <Grid container direction="column">
      <Grid item>
        <div className={classes.infoTitle}>{title}</div>
      </Grid>
      <Grid item>
        <div className={classes.infoData}>{data ? data : '--'}</div>
      </Grid>
    </Grid>
  )

  const renderPhotos = () => (
    <Grid container spacing={3} direction="column">
      <Grid item>
        <div className={classes.infoTitle}>Photos</div>
      </Grid>

      {maintenancePhotos.map((maintenancePhoto, index) => {
        return (
          <Grid item key={index}>
            <div>
              <img src={maintenancePhoto.imageUrl} className={classes.photoStyle} />
            </div>
          </Grid>
        )
      })}
    </Grid>
  )

  return (
    <Fragment>
      {isLoading ? (
        <Loader className={classes.loader} />
      ) : (
        <div>
          {renderInfo('Status', maintenanceInfo.currentStatus)}
          {renderInfo('Maintenance type', maintenanceInfo.category)}
          {renderInfo('Resident’s request details', maintenanceInfo.fullDescription)}
          {maintenancePhotos.length > 0 ? renderPhotos() : null}
          {renderInfo('Access notes', maintenanceInfo.accessNotes)}
          <TimelineEvent primaryRecordId={props.serviceRequestId} />
        </div>
      )}
    </Fragment>
  )
}

MaintenanceDetail.propTypes = {
  serviceRequestId: PropTypes.string.isRequired,
}

const mapStateToProps = (state, ownProps) => ({
  resident: state.Residents.residentsLookup[ownProps.residentId],
  activeBuilding: state.Buildings.buildingsLookup[state.Buildings.activeBuildingId],
})

export default connect(mapStateToProps)(MaintenanceDetail)
