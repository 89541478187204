import React, {useState, useEffect, useRef, useMemo, useCallback} from 'react'
import {Grid} from '@mui/material'
import {makeStyles} from '@mui/styles'
import {spaceExtraSmall, spaceMedium, spaceSmall} from '../../assets/styles/spacing'
import {useSelector, useDispatch} from 'react-redux'
import {useHistory, matchPath, useLocation} from 'react-router-dom'
import TextInput from '../TextInput'
import Routes from '../../constants/RouteConstants'
import DateInput from '../DateInput'
import {rxrBlueColor, rxrBlackDarkerColor, rxrBlackColor} from '../../assets/styles/color'
import {fontSizeMediumLarge} from '../../assets/styles/typography'
import {fontSizeMedium} from '../../assets/styles/typography'
import OnboardingChecklistTask from './OnboardingChecklistTask'
import MultilineTextInput from '../MultilineTextInput'
import useDoOnceTimer from '../hooks/useDoOnceTimer'
import {updateOccupancyAction, loadOccupancyAction} from '../../actions/occupancyActions'
import {ONBOARDING_IMPORTANCE_CRITICAL, ONBOARDING_STATE_COMPLETED} from '../../constants/ModelConstants'
import {isOnboardingTaskComplete, convertOccupancyToString} from '../../Utils/onboardingUtils'
import RXRButton from '../RXRButton'
import {deepEquals, getOccupancyWithResidentAndUnitData} from '../../reducers/selectors'
import {updateOnboardingTaskAction} from '../../actions/onboardingTaskActions'
import Pollable from '../../lib/Pollable'
import IconButton from '@mui/material/IconButton'
import {isProductionEnvironment} from '../../Utils/environmentUtils'
import {Colors} from '../../assets/styles'
import RXRIcon from '../RXRIcon'
import copy from 'copy-to-clipboard'

const useStyles = makeStyles(theme => ({
  container: {
    flexGrow: 1,
    marginBottom: 22,
  },
  onboardingChecklistContainer: {
    marginTop: spaceMedium,
  },
  onboardingChecklistTitle: {
    fontSize: fontSizeMediumLarge,
    color: rxrBlueColor,
    fontWeight: 'bold',
  },
  onboardingChecklistSubtitle: {
    fontSize: fontSizeMedium,
    color: rxrBlackDarkerColor,
    fontWeight: 'bold',
    marginTop: spaceSmall,
    display: 'flex',
    alignItems: 'flex-end',
  },
  onboardingChecklistTaskContainer: {
    marginTop: spaceExtraSmall,
  },
  gridItem: {
    marginTop: spaceMedium,
    paddingRight: spaceMedium,
  },
  clickToAutoComplete: {
    marginLeft: spaceSmall,
    height: '25px',
    width: 'fit-content',
    paddingLeft: spaceSmall,
    paddingRight: spaceSmall,
    cursor: 'pointer',
    fontSize: fontSizeMedium,
  },
}))

// These are copied from amplify/backend/function/rexBackendDependencies/lib/nodejs/backend-helpers/constants.js -> RESIDENT_ONBOARDING_URL
const RESIDENT_ONBOARDING_URL = {
  test: 'https://rxo-onboarding.s3.amazonaws.com/devel/index.html?b=:buildingCode',
  production: 'https://onboarding-rxohome.rxr.io/index.html?b=:buildingCode',
}

function OnboardingDetails(props) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const currentLocation = useLocation()
  const {setTimer} = useDoOnceTimer()
  const [isHandlingClickToAutoComplete, setIsHandlingClickToAutoComplete] = useState(false)
  const currentLoggedInStaffId = useSelector(state => state.Staff.authedStaffModel.id)
  const pathMatch = matchPath(currentLocation.pathname, {path: Routes.ONBOARDING_VIEW_OCCUPANCY_DETAILS})
  const occupancyId = pathMatch ? pathMatch.params.occupancyId : null
  // the resulting array is contains composite objects so need to use deepEquals
  const occupancy = useSelector(state => getOccupancyWithResidentAndUnitData(state, occupancyId), deepEquals)
  const occupancyString = convertOccupancyToString(occupancy)
  const pollable = useRef(new Pollable('UpdateOnboardingTasks', 15000, undefined))

  const onboardingMeta = occupancy && occupancy.onboardingMeta ? JSON.parse(occupancy.onboardingMeta) : {}
  const [notes, setNotes] = useState(onboardingMeta.notes)
  const activeBuilding = useSelector(state => state.Buildings.buildingsLookup[state.Buildings.activeBuildingId])
  const [mandatoryTasks, optionalTasks] = (occupancy?.onboardingTasks || []).reduce(
    (agr, t) => {
      if (t.importance === ONBOARDING_IMPORTANCE_CRITICAL) {
        agr[0].push(t)
      } else {
        agr[1].push(t)
      }
      return agr
    },
    [[], []],
  )
  const getOnboardingLink = useCallback((onboardingUrl, buildingCode) => {
    return onboardingUrl.replace(':buildingCode', buildingCode)
  }, [])

  const residentPortalLink = useMemo(() => {
    const isProdEnv = isProductionEnvironment()
    if (!isProdEnv) {
      return getOnboardingLink(RESIDENT_ONBOARDING_URL.test, activeBuilding.yardiId)
    } else {
      return getOnboardingLink(RESIDENT_ONBOARDING_URL.production, activeBuilding.yardiId)
    }
  }, [activeBuilding])

  useEffect(() => {
    if (!occupancyId) {
      setNotes('')
      return
    }

    // If the occupancy has a notes field in its onboardingMeta JSON, set the notes state variable to that value
    if (onboardingMeta.notes) {
      setNotes(onboardingMeta.notes)
    }

    pollable.current.updateQueryFunction(() => loadOccupancyAction(dispatch, occupancyId))
    pollable.current.startPolling()

    return () => {
      pollable.current.stopPolling()
    }
  }, [occupancyId, !!occupancy])

  // Inside a useEffect that checks when the notes state variable changes
  // Utilize the setTimer function to set a timer to update the notes
  // stored in the onboardingMeta JSON on the occupancy
  useEffect(() => {
    if (notes === onboardingMeta.notes || !occupancyId) {
      return
    }

    console.log(`Updating note to: "${notes}", from "${onboardingMeta.notes}"`)

    setTimer(
      'updateNotes',
      () => {
        // Create the input object for the updateOccupancy mutation
        let updateOccupancyInput = {
          id: occupancyId,
          onboardingMeta: JSON.stringify({
            ...onboardingMeta,
            notes: notes,
          }),
        }

        // Update the occupancy with the new notes
        updateOccupancyAction(dispatch, updateOccupancyInput).then(() => console.log('SAVED'))
      },
      3000,
    )
  }, [notes])

  /**
   * Iterate over the optionalTasks array and
   * for each onboardingTask execute a GQLHelper mutation that updates the state field on each OnboardingTask to ONBOARDING_STATE_COMPLETED
   * then execute the updateOccupancyAction to update the occupancy's onboardingTasks field
   */

  const handleClickToAutoComplete = async () => {
    setIsHandlingClickToAutoComplete(true)

    let updatedOnboardingTasks = [...mandatoryTasks]

    // Iterate over optionalTasks
    for (let i = 0; i < optionalTasks.length; i++) {
      let onboardingTask = optionalTasks[i]
      // Create the input object for the updateOnboardingTask mutation
      let updatedOnboardingTask = {
        ...onboardingTask,
        state: ONBOARDING_STATE_COMPLETED,
        stateHistory: [
          ...onboardingTask.stateHistory,
          {
            state: ONBOARDING_STATE_COMPLETED,
            previousState: onboardingTask.state,
            timestamp: new Date(),
            modifiedBy: currentLoggedInStaffId,
          },
        ],
      }

      // If the onboardingTask has a form and the form has submissions, then we need to iterate over the submissions
      // and mark all as COMPLETED
      let taskHasForm = onboardingTask.form && typeof onboardingTask.form === 'object' && Object.values(onboardingTask.form).length > 0
      if (taskHasForm) {
        let taskForm = onboardingTask.form
        let taskHasSubmissions =
          taskHasForm && taskForm.submissions && Array.isArray(taskForm.submissions) && taskForm.submissions.length > 0
        let updatedSubmissions = []

        if (taskHasSubmissions) {
          for (let j = 0; j < taskForm.submissions.length; j++) {
            let submission = taskForm.submissions[j]
            let updatedSubmission = {
              ...submission,
              state: ONBOARDING_STATE_COMPLETED,
            }
            updatedSubmissions.push(updatedSubmission)
          }
          updatedOnboardingTask.form = JSON.stringify({...taskForm, submissions: updatedSubmissions})
        }
      }

      // Update the onboardingTask
      await updateOnboardingTaskAction(dispatch, updatedOnboardingTask)

      // Add the updated onboardingTask to the updatedOnboardingTasks array
      updatedOnboardingTasks.push(updatedOnboardingTask)
    }

    setIsHandlingClickToAutoComplete(false)
  }

  const handleCopyToClipboard = () => {
    if (residentPortalLink) {
      copy(residentPortalLink)
    }
  }

  const navigateToTask = task => {
    history.push({
      pathname: Routes.constructPath(Routes.ONBOARDING_TASK_DETAILS, {occupancyId: occupancyId, taskId: task.id}),
    })
  }

  if (!occupancy) {
    return null
  }

  return (
    <React.Fragment>
      <div className={classes.container}>
        <Grid container style={{marginTop: 0}} spacing={3}>
          <Grid item lg={4} md={4} sm={6} xs={12} className={classes.gridItem}>
            <TextInput label="Resident(s) and unit" value={occupancyString} isReadOnly={true} />
          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={12} className={classes.gridItem}>
            <DateInput label={'Lease start date'} value={occupancy.leaseFromDate} isReadOnly={true} />
          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={12} className={classes.gridItem}>
            <TextInput
              label="Resident Portal Link"
              value={residentPortalLink}
              InputProps={{
                endAdornment: (
                  <IconButton onClick={handleCopyToClipboard} aria-label="Copy to Clipboard" size="large">
                    <RXRIcon icon={RXRIcon.COPY} color={Colors.rxrBlueColor} />
                  </IconButton>
                ),
                readOnly: true,
              }}
            />
          </Grid>
        </Grid>

        <div>
          {/**
           * Iterate over residents and render
           * a row for each resident that contains uneditable TextInputs (for 1 and 3) and DateInput (for 2)
           * for each resident's:
           * 1. displayName with title Resident, 2. moveInDate with title Move-in date, and
           * 3. enum Yes or No that is Yes if the resident's onboardingMeta contains a truthy onboardingMeta with field firstLoggedInAt and No otherwise
           * with title Resident portal log-in*/}
          {occupancy.residents.map(resident => {
            return (
              <Grid container key={resident.displayName} spacing={3}>
                <Grid item md={4} sm={6} xs={12} className={classes.gridItem}>
                  <TextInput label="Resident" value={resident.displayName} isReadOnly={true} />
                </Grid>
                <Grid item md={4} sm={6} xs={12} className={classes.gridItem}>
                  <DateInput label={'Move-in date'} value={resident.moveInDate} isReadOnly={true} />
                </Grid>
                <Grid item md={4} sm={6} xs={12} className={classes.gridItem}>
                  <TextInput
                    label="Resident portal log-in"
                    value={resident.onboardingMeta && JSON.parse(resident.onboardingMeta).firstLoggedInAt ? 'Yes' : 'No'}
                    isReadOnly={true}
                  />
                </Grid>
              </Grid>
            )
          })}
        </div>

        <div className={classes.onboardingChecklistContainer}>
          <div className={classes.onboardingChecklistTitle}>Onboarding checklist</div>

          {mandatoryTasks.length > 0 ? (
            <React.Fragment>
              <div className={classes.onboardingChecklistSubtitle}>Mandatory for resident check-in</div>
              <div className={classes.taskListContainer}>
                {mandatoryTasks.map((item, index) => {
                  return (
                    <div key={index} className={classes.onboardingChecklistTaskContainer}>
                      <OnboardingChecklistTask task={item} navigateToTask={navigateToTask} />
                    </div>
                  )
                })}
              </div>
            </React.Fragment>
          ) : null}

          {optionalTasks.length > 0 ? (
            <React.Fragment>
              <div className={classes.onboardingChecklistSubtitle}>
                <div>Not required for resident check-in</div>

                {mandatoryTasks.every(isOnboardingTaskComplete) && !optionalTasks.every(isOnboardingTaskComplete) && (
                  <RXRButton
                    onClick={handleClickToAutoComplete}
                    className={classes.clickToAutoComplete}
                    isLoading={isHandlingClickToAutoComplete}
                  >
                    Click to auto-complete
                  </RXRButton>
                )}
              </div>
              <div className={classes.taskListContainer}>
                {optionalTasks.map((item, index) => {
                  return (
                    <div key={index} className={classes.onboardingChecklistTaskContainer}>
                      <OnboardingChecklistTask task={item} navigateToTask={navigateToTask} />
                    </div>
                  )
                })}
              </div>
            </React.Fragment>
          ) : null}

          {mandatoryTasks.length == 0 && optionalTasks.length == 0 && (
            <div className={classes.onboardingChecklistSubtitle} style={{fontWeight: 'normal', fontColor: rxrBlackColor}}>
              This unit is being onboarded manually
            </div>
          )}
        </div>

        <Grid container spacing={3} style={{marginTop: spaceSmall}}>
          <Grid item xs={12}>
            <MultilineTextInput label="Notes" placeholder="" rows={4} rowsMax={4} maxLength={10000} onChange={setNotes} value={notes} />
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  )
}

OnboardingDetails.propTypes = {}

export default OnboardingDetails
