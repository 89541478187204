import React, {useState} from 'react'
import {makeStyles} from '@mui/styles'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import {Colors, Spacing} from './../../assets/styles'
import {useDispatch, useSelector} from 'react-redux'
import {CONVERSATION_CLOSED, CONVERSATION_IN_PROGRESS, CONVERSATION_OPEN} from '../../constants/ModelConstants'
import RXRIcon from '../RXRIcon'
import ResidentProfileImage from '../ResidentProfiles/ResidentProfileImage'
import PropTypes from 'prop-types'
import useResidentLookup from '../hooks/useResidentLookup'
import {constructClassString} from '../../Utils/objectUtil'
import {fontSizeExtraSmall, fontSizeMedium} from '../../assets/styles/typography'
import {updateChatRoomStatus} from './ChatContainerQueries'
import {getAuthedUserFromChatRoom, getResidentUsersFromChatRoom} from '../../Utils/chatUtils'
import {selectAuthedUserId} from '../../reducers/selectors'
import ResidentStatusTag from '../UITags/ResidentStatusTag'
import GenericTag, {VARIANT_COMPACT} from '../UITags/GenericTag'

function ChatContainerHeader(props) {
  const isMobile = useSelector(state => state.App.isMobile)
  const classes = useStyles({isMobile})
  const [open, setOpen] = useState(false)
  const dispatch = useDispatch()
  const {getResident} = useResidentLookup()

  const authedStaffId = useSelector(selectAuthedUserId)
  const groupPermissions = useSelector(state => state.GroupPermissions.permissionsObject)
  const conversation = useSelector(state => state.Messages.conversationsLookup[props.conversationId])
  const staffLookup = useSelector(state => state.Staff.staffLookup)
  const activeBuilding = useSelector(state => state.Buildings.buildingsLookup[state.Buildings.activeBuildingId])
  const authedChatUser = getAuthedUserFromChatRoom(conversation, authedStaffId, staffLookup)

  if (!conversation) {
    return null
  }

  const residents = getResidentUsersFromChatRoom(conversation).map(u => getResident(u.userId))

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const changeStatus = async status => {
    if (status === conversation.status) {
      console.error('Trying to set the same status twice.')
      return
    }

    // record the event in the message log
    await updateChatRoomStatus(dispatch, conversation, authedChatUser, status)
  }

  // NOTE: this is copied from ChatListItem
  // if this chat is not an onboarding chat, but it is for a future state resident and we have an onboarding schema, show the future tag
  // the logic here is we don't want to only show Onboarding tag to ensure this chat appears different than the onboarding group chat room
  const showFutureTag =
    !conversation.isOnboardingChat && residents.some(r => r.occupancy.status === 'Future') && !!activeBuilding.onboardingSchema

  return (
    <div className={classes.root}>
      <div className={classes.profilesSection}>
        {residents.map(r => {
          return (
            <div className={classes.participantEntry} key={r.id}>
              <ResidentProfileImage residentId={r.id} hideAppStatus={true} className={classes.avatarContainer} />
              <div>
                <p className={classes.heading}>{r.displayName}</p>
                <p className={classes.subHeading}>{r.occupancy.unit.number}</p>
              </div>
            </div>
          )
        })}
        {/*TODO: staff profiles*/}
      </div>
      {showFutureTag ? (
        <GenericTag text="Future" background={Colors.purple} className={classes.onboardingTag} />
      ) : (
        <ResidentStatusTag residentId={residents[0].id} className={classes.onboardingTag} />
      )}
      <div className={constructClassString({[classes.disabledDropdown]: !groupPermissions.messagesWrite}, classes.statusContainer)}>
        <Select
          onChange={e => changeStatus(e.target.value)}
          value={conversation.status}
          IconComponent={() => <RXRIcon icon={RXRIcon.ARROW_DOWN} />}
          disableUnderline={true}
          className={classes.hideIconPadding}
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
        >
          <MenuItem value={CONVERSATION_OPEN}>
            <span className={classes.statusIcon} style={{backgroundColor: Colors.rxrGreenColor}} /> Open
          </MenuItem>
          <MenuItem value={CONVERSATION_IN_PROGRESS}>
            <span className={classes.statusIcon} style={{backgroundColor: Colors.rxrYellowColor}} /> In progress
          </MenuItem>
          <MenuItem value={CONVERSATION_CLOSED}>
            <span className={classes.statusIcon} style={{backgroundColor: Colors.rxrDarkGreyColor}} /> Closed
          </MenuItem>
        </Select>
      </div>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  root: props => ({
    display: 'flex',
    flexDirection: 'row',
    ...Colors.backgroundLightGrey,
    minHeight: '55px',
    justifyContent: 'space-between',
    alignItems: 'center',
    overflowX: props.isMobile ? 'auto' : undefined,
    whiteSpace: props.isMobile ? 'nowrap' : undefined,
  }),
  profilesSection: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  participantEntry: {
    display: 'flex',
    marginLeft: Spacing.spaceMedium,
  },
  avatarContainer: {
    height: '34px',
    width: '34px',
    marginRight: Spacing.spaceSmall,
  },
  heading: {
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '19px',
    ...Colors.black,
  },
  subHeading: {
    fontSize: '12px',
    lineHeight: '14px',
    ...Colors.black,
  },
  statusContainer: {
    display: 'flex',
    marginRight: '24px',
    justifyContent: 'flex-end',
  },
  disabledDropdown: {
    pointerEvents: 'none',
    cursor: 'not-allowed',
  },
  hideIconPadding: {
    cursor: 'pointer',
    '& .MuiSelect-select.MuiSelect-select': {
      display: 'flex',
      alignItems: 'center',
      paddingRight: Spacing.spaceSmall,
      fontSize: '12px',
      lineHeight: '14px',
    },
    '& .MuiSelect-select:focus': {
      ...Colors.backgroundLightGrey,
    },
  },
  statusIcon: {
    marginRight: '8px',
    width: 10,
    height: 10,
    borderRadius: '50%',
  },
  onboardingTag: {
    marginRight: Spacing.spaceMedium,
  },
}))

ChatContainerHeader.propTypes = {
  conversationId: PropTypes.string.isRequired,
}

export default ChatContainerHeader
