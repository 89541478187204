import React from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@mui/styles'
import ResidentProfileImage from './ResidentProfileImage'
import {constructClassString} from '../../Utils/objectUtil'
import {rxrBlueColor, rxrLightGreyColor, whiteOverlay50} from '../../assets/styles/color'

function MultiResidentProfileImages(props) {
  const classes = useStyles()
  // if there's just 1, we just use the ResidentProfileImage as is
  return props.residentIds.length === 1 ? (
    <ResidentProfileImage residentId={props.residentIds[0]} hideAppStatus={true} className={props.className} />
  ) : (
    <div className={constructClassString(null, classes.container, props.className)}>
      <ResidentProfileImage residentId={props.residentIds[0]} hideAppStatus={true} className={classes.shrunkImage} />
      <ResidentProfileImage residentId={props.residentIds[1]} hideAppStatus={true} className={classes.shrunkSecondImage} />
      {props.residentIds.length > 1 ? (
        <div className={constructClassString(null, classes.shrunkSecondImage, classes.moreCount)}>
          {/*By using an SVG we can be assured the text scales with the container size*/}
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" style={{width: '100%'}}>
            <text
              style={{fill: rxrBlueColor, fontSize: 60, transform: 'translate(20px, 68px)', fontFamily: 'monospace', fontWeight: 'bold'}}
            >
              +{props.residentIds.length - 1}
            </text>
          </svg>
        </div>
      ) : null}
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
  },
  shrunkImage: {
    width: '70%',
    height: '70%',
  },
  shrunkSecondImage: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    width: '70%',
    height: '70%',
    zIndex: 1,
    outline: `2px solid ${rxrLightGreyColor}`,
  },
  moreCount: {
    display: 'block',
    backgroundColor: whiteOverlay50,
    borderRadius: '50%',
    zIndex: 2,
  },
}))

MultiResidentProfileImages.propTypes = {
  residentIds: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string, // styles to pass the container (such as, height, width, borders, etc
}

export default MultiResidentProfileImages
