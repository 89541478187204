import React, {useContext} from 'react'
import {FormComponentPropTypes} from '../onboardingTypes'
import {ONBOARDING_STATE_AVAILABLE, ONBOARDING_STATE_IN_PROGRESS} from '../../../constants/ModelConstants'
import useSharedStyles from '../useSharedStyles'
import SelectInput from '../../SelectInput'
import SyncedFiles from '../SyncedFiles'
import RejectOrAcceptWithFileAttacher from '../FormCTAs/RejectOrAcceptWithFileAttacher'
import {makeStyles} from '@mui/styles'
import {rxrBlueColor} from '../../../assets/styles/color'
import {spaceExtraExtraSmall, spaceMedium} from '../../../assets/styles/spacing'
import {getSyncedAndNotSyncedFilesFromSubmission} from '../../../Utils/onboardingUtils'
import {PET_SCREENING_OPTIONS} from '../../../constants/OnboardingConstants'
import {WhitelabelContext} from '../OnboardingWhitelabel'

function PetScreening(props) {
  const classes = useStyles()
  const sharedClasses = useSharedStyles()
  const {petScreening} = useContext(WhitelabelContext)
  const {syncedAttachments, notSyncedAttachments} = getSyncedAndNotSyncedFilesFromSubmission(props.submission)

  const handleLinkOut = () => {
    window.open(petScreening.petScreeningURL, '_blank')
  }

  const handleSyncedAttachments = newSyncedAttachmentsArray => {
    props.onUpdateSubmission({
      ...props.submission,
      attachments: [...notSyncedAttachments, ...newSyncedAttachmentsArray],
    })
  }

  const handleSelectPetScreeningOption = option => {
    props.onUpdateSubmission({
      ...props.submission,
      petScreeningValue: option,
      state: props.submission.state === ONBOARDING_STATE_AVAILABLE ? ONBOARDING_STATE_IN_PROGRESS : props.submission.state,
    })
  }

  return (
    <React.Fragment>
      <div className={sharedClasses.bodyContainer}>
        <div className={classes.linkOutCopy}>
          Check
          <a className={`${classes.linkOut} ${classes.spacedLink}`} onClick={handleLinkOut}>
            Pet Screening
          </a>
          site to verify screening. Residents without pets are exempt.
        </div>
        <div className={classes.paymentMethodContainer}>
          <SelectInput
            onChange={handleSelectPetScreeningOption}
            label={'Pet ownership and status'}
            isRequired={true}
            options={PET_SCREENING_OPTIONS}
            value={props.submission.petScreeningValue}
          />
        </div>
        <div className={classes.syncedAttachmentsContainer}>
          <SyncedFiles value={syncedAttachments} onChange={handleSyncedAttachments} />
        </div>
      </div>
      <RejectOrAcceptWithFileAttacher submission={props.submission} onUpdateSubmission={props.onUpdateSubmission} />
    </React.Fragment>
  )
}

const useStyles = makeStyles(theme => ({
  linkOutCopy: {
    display: 'flex',
    alignItems: 'center',
  },
  linkOut: {
    color: rxrBlueColor,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  spacedLink: {
    marginLeft: spaceExtraExtraSmall,
    marginRight: spaceExtraExtraSmall,
  },
  paymentMethodContainer: {
    marginTop: spaceMedium,
  },
  syncedAttachmentsContainer: {
    marginTop: spaceMedium,
  },
}))

PetScreening.propTypes = FormComponentPropTypes

export default PetScreening
